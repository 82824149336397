var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "dis-select",
    attrs: {
      "id": "onboarding-modal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "centered": "",
      "size": "xl",
      "hide-footer": "",
      "hide-header": ""
    }
  }, [_c('div', {
    staticClass: "center p-4 main-container dis-select"
  }, [_c('img', {
    staticClass: "dis-select",
    attrs: {
      "src": "/img/tour/wellcum_drink.svg"
    }
  }), _c('h3', [_vm._v("Добро пожаловать в Энтерсайт!")]), _c('span', {
    staticClass: "text-welcome mb-5"
  }, [_vm._v("Чтобы вам было проще начать работу мы подготовили набор "), _c('br'), _vm._v(" стартовых настроек для скорейшего начала работы")]), _c('br'), _c('b-button', {
    staticClass: "m-4 p-4",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.goStart
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "16px"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": "/img/tour/flag.svg"
    }
  }), _vm._v("Быстрый старт ")])]), _c('div', [_c('span', {
    staticClass: "underline-text",
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("Разберусь самостоятельно")]), _vm._v(" / "), _c('span', {
    staticClass: "underline-text"
  }, [_vm._v("Нужна помощь менеджера")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }