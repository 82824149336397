<template>
  <fragment>
    <div class="wrapper d-flex">
      <sidebar />
      <div class="wrapper__content">
        <!-- <header-content /> -->
        <confirm-email-alert v-if="!getIsConfirmed && user.id" />
        <pay-subscription-alert v-if="isEnds" />
        <breadcrumbs />
        <div class="wrapper__inner">
          <router-view />
        </div>
      </div>
    </div>
    <portal-target
      id="print-price-tags"
      name="clear"
    />
    <portal-target
      id="print-labels"
      name="dirty"
    />
    <portal-target
      id="print-badge"
      name="badge-print"
    />
  </fragment>
</template>

<script>
  import Sidebar from '../core/sidebar/Sidebar'
  import Breadcrumbs from '../core/breadcrumbs/breadcrumbs.vue'
  import ConfirmEmailAlert from '@/views/core/header/components/ConfirmEmailAlert'
  import { mapGetters } from 'vuex'
  import PaySubscriptionAlert from '@/views/core/header/components/PaySubscriptionAlert.vue'

  export default {
    components: {
      PaySubscriptionAlert,
      ConfirmEmailAlert,
      Sidebar,
      Breadcrumbs
    },
    computed: {
      ...mapGetters({
        getIsConfirmed: 'core/getIsConfirmed',
        branch: 'settings/getCurrentBranch',
        user: 'core/user'
      }),
      isEnds() {
        return (
          new Date(this.branch?.subscription?.cancel_date).getTime() - new Date().getTime() < 7 * 24 * 60 * 60 * 1000
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  @media print {
    .wrapper {
      display: none !important;
      position: static !important;
    }
  }
  #print-badge {
    display: none;
  }

  .print-grid {
    padding-top: 40px;
    display: grid;
    grid-template-columns: (3, 1fr);
    gap: 2px;
  }

  #print-price-tags {
    width: 1400px;
    display: block;
  }

  .wrapper__inner {
    display: flex;
    flex-direction: column;
  }
</style>
