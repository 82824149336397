var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "310",
      "height": "51",
      "viewBox": "0 0 310 51",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M51.1994 29.7856C51.1994 22.325 47.6684 16.8576 40.8342 16.8576C34 16.8576 30.4691 22.325 30.4691 29.7856C30.4691 37.2463 34 42.7136 40.8342 42.7136C47.6684 42.7136 51.1994 37.2463 51.1994 29.7856ZM36.0503 1.7655L30.2982 0L15.1491 41.062L20.8442 42.8275L36.0503 1.7655ZM20.6734 13.0419C20.6734 5.58125 17.1994 0.113902 10.3652 0.113902C3.53099 0.113902 0 5.58125 0 13.0419C0 20.5025 3.53099 25.9699 10.3652 25.9699C17.1994 25.9699 20.6734 20.5025 20.6734 13.0419ZM44.1374 29.6717C44.1374 34.3418 42.9984 36.5629 40.8342 36.5629C38.6701 36.5629 37.531 34.3418 37.531 29.6717C37.531 25.0017 38.6701 22.7806 40.8342 22.7806C42.9984 22.7806 44.1374 25.0017 44.1374 29.6717ZM13.6114 12.928C13.6114 17.598 12.5293 19.8191 10.3652 19.8191C8.20102 19.8191 7.06199 17.598 7.06199 12.928C7.06199 8.25797 8.20102 6.03686 10.3652 6.03686C12.5293 6.03686 13.6114 8.25797 13.6114 12.928Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M96.7913 40.4925L93.8298 32.5763C92.7477 33.3736 91.0392 34.1139 89.0459 34.1139C84.3189 34.1139 81.2435 29.5009 81.2435 21.2999C81.2435 13.0988 84.3189 8.54273 89.0459 8.54273C91.0392 8.54273 92.7477 9.22614 93.8298 10.0235L96.7913 2.33501C94.798 1.02513 91.4379 0.113902 87.793 0.113902C78.5668 0.113902 71.277 7.11894 71.277 21.4138C71.277 35.7086 78.5668 42.7136 87.793 42.7136C91.6087 42.7136 94.798 41.8024 96.7913 40.4925Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M134.872 42.0302L129.177 27.6215C127.696 23.8627 125.418 21.0151 121.944 19.3635L134.53 0.797321H123.482L112.376 19.0218H112.262V0.797321H102.58V42.0302H112.262V23.1223C115.622 23.1223 118.128 25.5712 119.21 28.6466L124.051 42.0302H134.872Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M170.795 42.0302V0.797321H162.765L150.577 21.5277C149.21 23.8057 148.128 26.4824 148.128 26.4824H148.014C148.014 26.4824 148.071 23.521 148.071 20.7873V0.797321H139.585V42.0302H147.274L159.803 20.6164C161.227 18.2245 162.309 15.6617 162.309 15.6617H162.423C162.423 15.6617 162.309 18.5092 162.309 21.186V42.0302H170.795Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M216.696 35.3669L213.564 33.9431L209.976 0.797321H188.106L186.853 11.1625C185.714 20.5025 183.55 30.2982 176.659 35.3669L177.115 50.3451H183.721L183.949 42.0302H209.406L209.634 50.3451H216.183L216.696 35.3669ZM204.224 34.4557H188.676C192.947 29.8426 194.884 20.2178 195.795 12.1876L196.193 8.65663H201.661L204.224 34.4557Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M255.231 42.0302L249.536 27.6215C248.055 23.8627 245.777 21.0151 242.303 19.3635L254.889 0.797321H243.84L232.735 19.0218H232.621V0.797321H222.939V42.0302H232.621V23.1223C235.981 23.1223 238.487 25.5712 239.569 28.6466L244.41 42.0302H255.231Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M294.628 42.0302L281.301 0.797321H269.797L256.47 42.0302H266.608L269 33.6583H282.099L284.491 42.0302H294.628ZM280.276 26.6533H270.822L274.467 13.9531C275.15 11.5612 275.492 8.77053 275.492 8.77053H275.606C275.606 8.77053 275.948 11.5612 276.631 13.9531L280.276 26.6533Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M309.547 0.797321H298.499L299.98 27.052H308.124L309.547 0.797321ZM309.775 36.8476C309.775 33.6583 307.212 31.1525 304.023 31.1525C300.834 31.1525 298.271 33.6583 298.271 36.8476C298.271 40.0369 300.834 42.5997 304.023 42.5997C307.212 42.5997 309.775 40.0369 309.775 36.8476Z",
      "fill": "#F17D00"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }