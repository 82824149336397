var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-print-tags",
      "hide-header": "",
      "hide-footer": "",
      "body-class": "p-0",
      "content-class": "p-0",
      "centered": "",
      "size": "xl"
    },
    on: {
      "show": _vm.onModalShow
    }
  }, [_c('b-tabs', {
    staticClass: "nowrap-tab",
    attrs: {
      "nav-class": "tab-nav-custom p-0",
      "content-class": "tab-content-custom",
      "pills": "",
      "card": "",
      "vertical": "",
      "active-nav-item-class": "active-nav-item-custom",
      "nav-wrapper-class": "nav-wrapper-custom"
    },
    scopedSlots: _vm._u([{
      key: "tabs-start",
      fn: function () {
        return [_c('div', {
          staticClass: "tab-header-text"
        }, [_vm._v("Действия")])];
      },
      proxy: true
    }, {
      key: "tabs-end",
      fn: function () {
        return [_c('div', {
          staticClass: "tab-footer-action mt-auto"
        }, [_c('b-button', {
          staticClass: "cursor",
          attrs: {
            "disabled": !(_vm.actions.print || _vm.actions.load_cash || _vm.actions.print_label),
            "variant": "primary"
          },
          on: {
            "click": _vm.accept
          }
        }, [_vm._v(" Выполнить ")]), _c('b-button', {
          staticClass: "cursor ml-2",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v(" Отложить ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.activeIndex,
      callback: function ($$v) {
        _vm.activeIndex = $$v;
      },
      expression: "activeIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Печать ценнников",
      "active": _vm.actions.print
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          model: {
            value: _vm.actions.print,
            callback: function ($$v) {
              _vm.$set(_vm.actions, "print", $$v);
            },
            expression: "actions.print"
          }
        })], 1), _vm._v(" Печать ценников "), _c('tab-arrow', {
          staticClass: "ml-auto",
          attrs: {
            "fill": _vm.getArrowColor(0)
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('print-price-tab', {
    ref: "tab-price-tags",
    attrs: {
      "products": _vm.products
    }
  })], 1)], 1)]), _c('b-tab', {
    attrs: {
      "title": "Печать этикеток",
      "active": _vm.actions.print_label
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          model: {
            value: _vm.actions.print_label,
            callback: function ($$v) {
              _vm.$set(_vm.actions, "print_label", $$v);
            },
            expression: "actions.print_label"
          }
        })], 1), _vm._v(" Печать этикеток "), _c('tab-arrow', {
          staticClass: "ml-auto",
          attrs: {
            "fill": _vm.getArrowColor(1)
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', [_c('print-labels', {
    attrs: {
      "products": _vm.products
    }
  })], 1)]), _vm.currentBranch.cash ? _c('b-tab', {
    attrs: {
      "title": "Загрузка касс"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "cash-load"
          },
          model: {
            value: _vm.actions.load_cash,
            callback: function ($$v) {
              _vm.$set(_vm.actions, "load_cash", $$v);
            },
            expression: "actions.load_cash"
          }
        })], 1), _vm._v(" Загрузка касс "), _c('tab-arrow', {
          staticClass: "ml-auto",
          attrs: {
            "fill": _vm.getArrowColor(2)
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1879383086)
  }, [_c('div', {
    staticStyle: {
      "min-height": "542.5px"
    }
  }, [_c('cash-tab')], 1)]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }