var render = function render(){
  var _vm$product, _vm$product$supplier, _vm$product2, _vm$product2$package, _vm$product2$package$, _vm$product2$package$2, _vm$product3, _vm$product3$package, _vm$product3$package$, _vm$product3$package$2, _vm$product4, _vm$product4$country, _vm$product5, _vm$product5$country, _vm$product6, _vm$product7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "container",
    staticClass: "containers"
  }, [_vm.orange ? _c('background600on400-svg') : _vm._e(), _c('div', {
    staticClass: "header"
  }, [_c('p', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('p', {
    staticClass: "company"
  }, [_vm._v(_vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$supplier = _vm$product.supplier) === null || _vm$product$supplier === void 0 ? void 0 : _vm$product$supplier.name))])]), _c('div', {
    staticClass: "product-name"
  }, [_c('b', [_vm._v(_vm._s(_vm.productName) + " ")])]), _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "price"
  }, [_c('div', {
    staticClass: "values"
  }, [_c('div', {
    staticClass: "price__old"
  }, [_c('b', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.priceMainPrev) + " ")]), _c('old-price-line-svg'), _c('div', {
    staticClass: "price__for"
  }, [_c('div', [_c('b', {
    staticClass: "kopecks"
  }, [_vm._v(" " + _vm._s(_vm.pricePartPrev) + " ")]), _c('b', [_vm._v("₽/" + _vm._s(_vm.measurement))])])])], 1), _c('div', {
    staticClass: "price__new"
  }, [_c('b', {
    staticClass: "newPrice"
  }, [_vm._v(" " + _vm._s(_vm.priceMain) + " ")]), _c('div', {
    staticClass: "price__for"
  }, [_c('div', [_c('b', {
    staticClass: "kopecks"
  }, [_vm._v(" " + _vm._s(_vm.pricePart) + " ")]), _c('b', {
    staticClass: "product-measurement"
  }, [_vm._v("₽/" + _vm._s(_vm.measurement))])])])])]), _c('div', {
    staticClass: "qrcode"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.qrcode,
      "size": "64"
    }
  })], 1)]), _c('div', {
    staticClass: "footer__block"
  }, [_c('div', {
    staticClass: "barcode"
  }, [(_vm$product2 = _vm.product) !== null && _vm$product2 !== void 0 && (_vm$product2$package = _vm$product2.package) !== null && _vm$product2$package !== void 0 && (_vm$product2$package$ = _vm$product2$package[0]) !== null && _vm$product2$package$ !== void 0 && (_vm$product2$package$2 = _vm$product2$package$.barcode) !== null && _vm$product2$package$2 !== void 0 && _vm$product2$package$2.barcode ? _c('barcode-generator', {
    attrs: {
      "width": 180,
      "height": 84,
      "barcode": (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$package = _vm$product3.package) === null || _vm$product3$package === void 0 ? void 0 : (_vm$product3$package$ = _vm$product3$package[0]) === null || _vm$product3$package$ === void 0 ? void 0 : (_vm$product3$package$2 = _vm$product3$package$.barcode) === null || _vm$product3$package$2 === void 0 ? void 0 : _vm$product3$package$2.barcode
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "information"
  }, [_c('span', [_c('p', [_vm._v(_vm._s((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$country = _vm$product4.country) === null || _vm$product4$country === void 0 ? void 0 : _vm$product4$country.name))])]), _c('span', [_c('p', [_vm._v(_vm._s((_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$country = _vm$product5.country) === null || _vm$product5$country === void 0 ? void 0 : _vm$product5$country.name))])]), _c('div', {
    staticClass: "information__articul-and-plu-blok",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('p', {
    staticClass: "information__articul"
  }, [_vm._v(" " + _vm._s((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : _vm$product6.articul) + " ")]), (_vm$product7 = _vm.product) !== null && _vm$product7 !== void 0 && _vm$product7.plu ? _c('span', [_c('div', {
    staticClass: "information__plu-title"
  }, [_vm._v("PLU:")]), _vm._l(_vm.getPluArr, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "information__plu"
    }, [_c('div', {
      staticClass: "information__plu-text"
    }, [_vm._v(_vm._s(item))]), _vm.getPluArr[idx + 1] ? _c('div', {
      staticClass: "information__plu-delimiter"
    }) : _vm._e()]);
  })], 2) : _vm._e()])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }