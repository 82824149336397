<template>
  <div class="operational-processes">
    <div class="dashboard__title">Операционные процессы</div>
    <div class="card border-none d-flex">
      <div class="op-block">
        <div class="op-card mr16">
          <div
            class="op"
            @click.prevent="goToIncoming"
          >
            <div class="op__logo income-goods">
              <span v-if="count_incoming > 0">{{ count_incoming }}</span>
            </div>
            <div class="op__title">Приёмка товара</div>
            <div class="op__text">Регистрируйте документы прихода от поставщиков</div>
          </div>
        </div>
        <div class="op-card">
          <div
            class="op"
            @click.prevent="goToOutcoming"
          >
            <div class="op__logo return">
              <span v-if="count_outgoing > 0">{{ count_outgoing }}</span>
            </div>
            <div class="op__title">Списание/Возврат</div>
            <div class="op__text">Проводите документы по просроченным товарам</div>
          </div>
        </div>
      </div>
      <div class="op-block">
        <div class="op-card mr16">
          <div
            class="op"
            @click.prevent="goMercury"
          >
            <div class="op__logo merkuriy">
              <span v-if="count_mercury > 0">{{ count_mercury }}</span>
            </div>
            <div class="op__title">Меркурий</div>
            <div class="op__text">Обрабатывайте входящие ВСД</div>
          </div>
        </div>
        <div class="op-card">
          <div
            class="op"
            @click.prevent="goOrder"
          >
            <div class="op__logo goods">
              <span v-if="count_order > 0">{{ count_order }}</span>
            </div>
            <div class="op__title">Автозаказ</div>
            <div class="op__text">Просматривайте и заказывайте предложенные системой товары</div>
          </div>
        </div>
      </div>
      <div class="op-block">
        <div class="op-card mr16">
          <div
            class="op"
            @click.prevent="goRec"
          >
            <div class="op__logo goods">
              <span v-if="count_rec > 0">{{ count_rec }}</span>
            </div>
            <div class="op__title">Рекомендации</div>
            <div class="op__text">Ознакомьтесь с действиями для корректного ведения учёта</div>
          </div>
        </div>
        <div class="op-card">
          <div
            class="op"
            @click.prevent="goPlan"
          >
            <div class="op__logo goods">
              <span v-if="count_plan > 0">{{ count_plan }}</span>
            </div>
            <div class="op__title">План платежей</div>
            <div class="op__text">Узнавайте по каким поставщикам ожидается платеж</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    apollo: {
      CountUnfixed: {
        query: require('../gql/getCountUnfixed.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.count_incoming = data?.CountUnfixed?.incoming ?? 0
          this.count_outgoing = data?.CountUnfixed?.outgoing ?? 0
          this.count_mercury = data?.CountUnfixed?.mercury ?? 0
          this.count_order = data?.CountUnfixed?.order ?? 0
          this.count_rec = data?.CountUnfixed?.recomendations ?? 0
          this.count_plan = data?.CountUnfixed?.money ?? 0
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        },
        skip() {
          return !this.currentBranch.id
        }
      }
    },

    data: function () {
      return {
        count_incoming: 0,
        count_outgoing: 0,
        count_unfixed: 0,
        count_mercury: 0,
        count_order: 0,
        count_rec: 0,
        count_plan: 0
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    methods: {
      async goToIncoming() {
        this.$router.push({ name: 'operation-process.overhead.incoming' })
      },

      goToOutcoming() {
        this.$router.push({ name: 'operation-process.overhead.outcoming' })
      },
      goMercury() {
        this.$router.push({ name: 'mercury' })
      },
      goOrder() {
        this.$router.push({ name: 'order/auto' })
      },
      goRec() {
        this.$router.push({ name: 'diagnostics.list' })
      },
      goPlan() {
        this.$router.push({ name: 'payment.plan' })
      }
    }
  }
</script>
<style scoped>
  .mr16 {
    margin-right: 16px;
  }
  .half {
    width: 50%;
  }
  .op-block {
    display: flex;
    margin-bottom: 16px;
  }
  .op-card {
    flex: 1;
  }
  .dashboard .operational-processes .op__logo span {
    padding: 2px 8px;
    background: #00a3ff;
  }
  @media screen and (max-width: 1230px) {
    .op-block {
      display: block;
      margin-bottom: 0;
    }
    .op-card {
      flex: 1;
      margin: 0 !important;
    }
    .half {
      width: 100%;
      padding: 0 !important;
    }
  }
</style>
