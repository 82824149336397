export const formatMoney = (value, minimumFractionDigits = 0) => {
  if (!Number.isInteger(minimumFractionDigits)) minimumFractionDigits = 0
  if (!value) value = 0
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    currency: 'RUB',
    maximumFractionDigits: 2,
    minimumFractionDigits: minimumFractionDigits
  })

  return formatter.format(value)
}

export const formatRemainder = (value) => {
  if (!value) value = 0
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    currency: 'RUB',
    maximumFractionDigits: 3,
    minimumFractionDigits: 0
  })

  return formatter.format(value)
}
