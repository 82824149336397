import View from '@/views/layouts/layout-view'

export default {
  path: '/manufacture',
  name: 'manufacture',
  component: View,
  redirect: '/manufacture/dashboard',
  meta: {
    auth: true
  },
  children: [
    {
      path: 'dashboard',
      name: 'manufacture.analytics.dashboard',
      component: () => import('@/views/analytics/pages/Analytics'),
      meta: { title: 'Аналитика' }
    },
    {
      path: 'manufacture/document/:id',
      name: 'manufacture.equipment.document.edit',
      component: () => import('@/views/manufacture/pages/equipment/Equipment'),
      meta: {
        title: 'Документ комплектации',
        type: 'manufacture',
        isEdit: true
      }
    }
  ]
}
