<template>
  <div class="products__menu">
    <div class="products__menu-title d-flex align-items-center">Группы товаров</div>
    <template v-if="block_add_group">
      <div class="p-2">
        <add-group
          @closeAddGroup="closeAddGroup"
          @groupAddedSuccessfully="groupAddedSuccessfully"
        />
      </div>
    </template>
    <div
      v-if="menu_is_loading"
      class="products__menu-no-goups center"
    >
      <b-spinner variant="primary" />
    </div>
    <template v-if="menu_arr && menu_arr.length > 0 && !menu_is_loading">
      <!--<div v-if="child_is_load" class="menu-is-loading center p-3">
        <b-spinner variant="primary" />
      </div>-->
      <div class="b-search-result">
        <menu-item
          :close_add_group="closeAddGroup"
          :items="menu_arr"
          :open_group="openGroup"
          :update_menu="groupAddedSuccessfully"
          :modal_remove="openModalRemove"
          :current_ids="current_ids"
          hide_control
          @close_add_form="closeAddGroup"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import * as R from 'ramda'
  import { mapActions, mapGetters } from 'vuex'
  import AddGroup from '../../../../products/components/menu/AddGroup.vue'
  import MenuItem from '../../../../products/components/menu/MenuItem.vue'

  export default {
    components: {
      // MenuItem: () => import('./menu/MenuItem.vue'),
      AddGroup,
      MenuItem
    },
    apollo: {
      Categories: {
        query: require('../../../../products/gql/getCategories.gql'),
        variables: {
          level: 0
        },
        result({ data }) {
          const categories = R.pathOr([], ['Categories'], data)
          this.menu_arr = this.updateCategories(categories, this.current_category_path)

          if (this.isCreatedNewGroup) {
            this.$apollo.queries.Category.skip = false
            this.$apollo.queries.Category.refetch()
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        watchLoading(isLoading) {
          this.menu_is_loading = isLoading
        }
      },
      Category: {
        query: require('../../../../products/gql/getCategory.gql'),
        fetchPolicy: 'no-cache',
        manual: true,
        variables() {
          return {
            id: this.current_group_id
          }
        },
        result({ data }) {
          const category = R.pathOr([], ['Category'], data)
          const isOpen = this.current_ids.includes(this.current_group_id)
          this.setCurrentsIds(category.path || [])
          if (isOpen) {
            this.setCurrentsIds(this.current_ids.filter((id) => id !== this.current_group_id))
          }
          this.addChild(category)
          this.$apollo.queries.Category.skip = true

          if (this.isCreatedNewGroup) {
            this.openGroup(this.current_group_id)
            this.isCreatedNewGroup = false
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        watchLoading(isLoading) {
          this.group_is_loading = isLoading
        },
        skip() {
          return true
        }
      },
      ProductsV2: {
        query: require('../../../../products/gql/getProducts.gql'),
        variables() {
          return {
            category: {
              id: null
            },
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          if (data?.ProductsV2?.total)
            this.menu_arr = [
              {
                id: null,
                name: 'Товары без категории',
                last: true,
                level: 0,
                int_id: 0,
                path: [null]
              },
              ...this.menu_arr
            ]
        }
      }
    },

    data: () => ({
      menu_is_loading: true,
      modal_show: false,
      block_add_group: false,
      child_is_load: false,
      menu_group: {
        name: ''
      },
      menu_arr: [],
      current_group_id: '',
      group: {
        name: '',
        id: ''
      },
      remove_group_is_loading: false,
      isCreatedNewGroup: false
    }),

    computed: {
      ...mapGetters({
        menuIsVisible: 'products/getMenuVisibleStatus',
        current_category_path: 'products/getCurrentCategoryPath',
        current_category: 'products/getCurrentCategory',
        current_ids: 'products/getCurrentIds',
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    created() {
      this.$apollo.queries.Categories.refetch()
    },

    methods: {
      ...mapActions({
        setMenuVisibleStatus: 'products/setMenuVisibleStatus',
        setCurrentCategoryPath: 'products/setCurrentCategoryPath',
        setCurrentsIds: 'products/setCurrentIds',
        setCurrentCategory: 'products/setCurrentCategory',
        setAddgroupVisibleStatus: 'products/setAddgroupVisibleStatus'
      }),
      modal_add_group() {
        this.$refs.modal_add_group.show()
      },

      closeAddGroup() {
        this.block_add_group = false
      },

      showAddGroup() {
        this.block_add_group = true
        this.setAddgroupVisibleStatus(true)
      },

      groupAddedSuccessfully() {
        this.current_group_id = this.current_category.id
        this.isCreatedNewGroup = true
        this.closeAddGroup()
        this.$apollo.queries.Categories.refetch()
      },

      recursionMenu(items, childs) {
        const new_items = R.clone(items)

        return new_items?.map((item) => {
          if (item.id === childs.id) {
            item.child_groups = childs.child_groups?.map((child) => {
              if (child.child_groups) child.child_groups = null

              return child
            })
          } else if (item.child_groups) {
            item.child_groups = this.recursionMenu(item.child_groups, childs)
          }

          return item
        })
      },

      getItemsIds(items, curId = null) {
        let result = []

        if (items?.id) {
          result.push(items.id)
          if (items.id !== curId && items?.child_groups?.length) {
            items.child_groups.forEach((t) => {
              result = [...result, ...this.getItemsIds(t)]
            })
          }
        }
        return result
      },

      getCurrentCategoriesPath(path, items) {
        let result = path
        if (!result) {
          result = items
        } else if (result?.child_groups?.length) {
          result.child_groups.forEach((t, index) => {
            if (t?.id === items?.id) {
              result.child_groups[index] = items
            } else {
              const chGroups = this.getCurrentCategoriesPath(t, items)
              if (chGroups?.child_groups === null) {
                delete chGroups.child_groups
              }
              result.child_groups[index] = chGroups
            }
          })
        }
        return result
      },

      addChild(items) {
        this.menu_arr = this.recursionMenu(this.menu_arr, items)

        if (this.getItemsIds(this.current_category_path).includes(items.id)) {
          this.setCurrentCategoryPath(this.getCurrentCategoriesPath(this.current_category_path, items))
        } else {
          this.setCurrentCategoryPath(items)
        }

        this.toggleChildLoading()
      },

      updateCategories(categories, current) {
        const updateCategories = [...categories]

        if (current) {
          updateCategories.forEach((category, index) => {
            if (category.id === current.id) updateCategories[index] = current
          })
        }

        return updateCategories
      },

      openGroup({ id }) {
        this.current_group_id = id
        if (this.current_ids.includes(id)) return this.setCurrentsIds(this.current_ids.filter((cId) => cId !== id))

        this.$apollo.queries.Category.skip = false
        this.$apollo.queries.Category.refetch()
      },

      toggleChildLoading() {
        this.child_is_load = !this.child_is_load
      },

      hideProductMenu() {
        this.setMenuVisibleStatus(false)
      },

      openModalRemove(group) {
        this.group = group
        this.$bvModal.show('modal-remove-group')
      },

      hideModalRemove() {
        this.$bvModal.hide('modal-remove-group')
      },

      removeItemMenu(items, id) {
        items.forEach((item, i) => {
          if (item.id === id) {
            items.splice(i, 1)
          } else if (item.child_groups) {
            this.removeItemMenu(item.child_groups, id)
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .products__menu {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 600px !important;

    &-title {
      justify-content: space-between;

      button {
        background: #efefef;
        width: 32px;
        height: 32px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease !important;
        transform: scale(1);

        img {
          width: 16px;
          height: 16px;
        }

        &:hover {
          background-color: #ececec !important;
        }

        &:active {
          transform: scale(0.98);
          background-color: #dadada !important;
          border-color: #d3d3d3 !important;
        }
      }
    }

    &-no-goups {
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;
      color: #cacaca;
    }

    .b-search-result {
      overflow-y: auto;
      padding-top: 15px;
      padding-right: 0;
      padding-left: 0;

      & > .menu-item {
        max-width: calc(100% - 15px);

        & > li {
          width: calc(100% - 15px) !important;
        }
      }
    }

    .menu-is-loading {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.7);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .toggle-sidebar {
      width: 22px;
      height: 22px;
      position: absolute;
      right: -10px;
      bottom: 33px;
      text-align: center;
      background: #ffffff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: 3px;
      z-index: 11;
      padding: 0;
      transition: 0.1s;

      img {
        margin: 0 auto;
      }
    }
  }
</style>
