import { DiscontCardsList } from '../models/discont-cards-list.model'
import { DiscontCard } from '../models/discont-card.model'

export const mutations = {
  SET_CARD_TYPE_LIST(state, data) {
    state.cardTypeList = data
  },

  SET_DISCONT_CARDS_DOCUMENT(state, data) {
    state.discontCardsDocument = new DiscontCardsList(data)
  },

  SET_DISCONT_CARD(state, data) {
    state.discontCard = new DiscontCard(data)
  },

  SET_DISCONT_CARD_PARAM(state, param) {
    if (param.key === 'number') state.discontCard.setNumber(param.value)
    if (param.key === 'prefix') state.discontCard.setPrefix(param.value)
    if (param.key === 'suffix') state.discontCard.setSuffix(param.value)
    if (param.key === 'name') state.discontCard.setCustomerName(param.value)
    if (param.key === 'phone') state.discontCard.setCustomerPhone(param.value)
    if (param.key === 'gender') state.discontCard.setCustomerGender(param.value)
    if (param.key === 'blocked') state.discontCard.setBlocked(param.value)
    if (param.key === 'birthday') state.discontCard.setCustomerBirthday(param.value)
    if (param.key === 'surname') state.discontCard.setCustomerSurname(param.value)
    if (param.key === 'patronymic') state.discontCard.setCustomerPatronymic(param.value)
  }
}
