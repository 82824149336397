<template>
  <div
    ref="container"
    class="containers"
  >
    <background600on400-svg v-if="orange" />
    <div class="header">
      <p class="date">{{ currentDate }}</p>
      <p class="company">{{ product?.supplier?.name }}</p>
    </div>
    <div class="product-name">
      <b>{{ productName }} </b>
    </div>
    <div class="footer">
      <div class="price">
        <div class="values">
          <div class="price__old">
            <b class="value">
              {{ priceMainPrev }}
            </b>
            <old-price-line-svg />
            <div class="price__for">
              <div>
                <b class="kopecks">
                  {{ pricePartPrev }}
                </b>
                <b>₽/{{ measurement }}</b>
              </div>
            </div>
          </div>
          <div class="price__new">
            <b class="newPrice">
              {{ priceMain }}
            </b>
            <div class="price__for">
              <div>
                <b class="kopecks">
                  {{ pricePart }}
                </b>
                <b class="product-measurement">₽/{{ measurement }}</b>
              </div>
            </div>
          </div>
        </div>

        <div class="qrcode">
          <qrcode
            :value="qrcode"
            size="64"
          />
        </div>
      </div>
      <div class="footer__block">
        <div class="barcode">
          <barcode-generator
            v-if="product?.package?.[0]?.barcode?.barcode"
            :width="180"
            :height="84"
            :barcode="product?.package?.[0]?.barcode?.barcode"
          />
        </div>
        <div class="information">
          <span>
            <!-- Страна: -->
            <p>{{ product?.country?.name }}</p>
          </span>
          <span>
            <!-- Производитель: -->
            <p>{{ product?.country?.name }}</p>
          </span>

          <div
            class="information__articul-and-plu-blok"
            style="display: flex"
          >
            <p class="information__articul">
              <!-- Артикул:  -->
              {{ product?.articul }}
            </p>

            <span v-if="product?.plu">
              <div class="information__plu-title">PLU:</div>
              <div
                v-for="(item, idx) of getPluArr"
                :key="idx"
                class="information__plu"
              >
                <div class="information__plu-text">{{ item }}</div>
                <div
                  v-if="getPluArr[idx + 1]"
                  class="information__plu-delimiter"
                ></div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  import OldPriceLineSvg from '@/views/core/modal/price-tag/svg/OldPriceLineSvg.vue'
  import Background600on400Svg from '@/views/core/modal/price-tag/svg/Background600on400Svg.vue'
  import Qrcode from 'qrcode.vue'
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'

  export default {
    name: 'PriceTag600on400',
    components: {
      BarcodeGenerator,
      OldPriceLineSvg,
      Background600on400Svg,
      Qrcode
    },
    props: {
      product: {
        type: Object,
        default: () => {}
      },
      company_name: {
        type: String,
        default: ''
      },
      orange: Boolean,
      weight_format: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        currentDate: formatDate(new Date(), 'numeric')
      }
    },
    computed: {
      ...mapGetters({
        companyName: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      qrcode() {
        const barcode = this.product?.package?.[0]?.barcode?.barcode || '-'
        const article = this.product?.articul || '-'
        const price = this.product?.retailPrice.toFixed(2) || '-'

        return `${barcode} ${article} ${price}`
      },
      productName() {
        return this.product?.print_name?.length >= 83
          ? this.product?.print_name.slice(0, 83) + '...'
          : this.product?.name.slice(0, 83)
      },
      priceMain() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return Math.trunc(price)
      },
      pricePart() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return (+price % 1).toFixed(2).split('.')[1]
      },
      priceMainPrev() {
        let price = this.product?.promotion?.old_price || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return Math.trunc(price)
      },
      pricePartPrev() {
        let price = this.product?.promotion?.old_price || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return (+price % 1).toFixed(2).split('.')[1]
      },
      measurement() {
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') return '100г'
        return this.product?.measurement?.name?.toLowerCase()
      },
      getPluArr() {
        const pluArr = this.product?.plu.map((el) => el.plu)
        const result = new Set(pluArr)
        return [...result]
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
  p {
    padding: 0 !important;
    margin: 0 !important;
  }
  .containers {
    box-sizing: border-box;
    background-color: #fff;
    padding: 16px;
    position: relative;
    width: 600px;
    height: 400px;
    font-family: 'Source Sans Pro', sans-serif;
    border: 1px solid black;
    .background {
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .header {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    p {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
  .product-name {
    position: relative;
    z-index: 2;
    width: 592px;
    b {
      overflow: hidden;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 45px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
  .price {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 64px;
    margin-top: 48px;
    margin-bottom: 28px;
    .values {
      display: flex;
    }
    .qrcode {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    &__new {
      margin-left: 12px;
      display: flex;
      .newPrice {
        height: 64px;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 86.1644px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #000000;
      }
      .price__for {
        margin-left: 12px;
        display: flex;
        align-items: center;
        .kopecks {
          height: 40px;
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 46px;
          display: flex;
          align-items: center;
          color: #000000;
        }
        b {
          height: 24px;
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          display: flex;
          align-items: center;
          color: #000000;
        }
      }
    }
    &__old {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;
      overflow: hidden;
      .line {
        position: absolute;
        bottom: 3px;
        left: 0;
      }
      .value {
        height: 40px;
        display: flex;
        align-items: center;
        color: #474747;
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 46px;
      }
      .price__for {
        display: flex;
        align-items: flex-end;
        margin-left: 6px;
        color: #474747;
        b {
          display: block;
          font-size: 16px;
        }
        .kopecks {
          height: 19px;
          font-family: 'Source Sans Pro';
          font-style: normal;
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 24px;
        }
      }
    }
  }
  .footer {
    position: absolute;
    z-index: 2;
    width: 568px;
    bottom: 16px;
    left: 16px;

    &__block {
      display: flex;

      .barcode {
        width: 180px;
        height: 84px;
      }
      .information {
        margin-left: 16px;

        &__articul-and-plu-blok {
          display: flex;
        }

        &__articul {
          text-transform: capitalize;
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          margin-right: 5px !important;
        }

        &__plu-title {
          margin-right: 3px;
        }

        &__plu {
          display: flex;
          margin-right: 5px;
          align-items: center;
        }
        &__plu-text {
          margin-left: 2px !important;
          margin-right: 5px !important;
          text-transform: capitalize;
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          color: #000000;
        }

        &__plu-delimiter {
          height: 4px;
          width: 4px;
          border-radius: 50%;
          background-color: #000000;
        }

        span {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          color: #000000;
          &:nth-child(1) {
            padding-bottom: 8px;
          }
          &:nth-child(2) {
            padding-bottom: 8px;
          }
        }
      }
    }
  }
  .product-measurement {
    display: flex;
  }
</style>
