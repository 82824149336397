import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
// import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
// import store from '@/store'
// import { onError } from 'apollo-link-error'
// import store from '@/store'
// import store from '@/store'

const authLink = setContext((_, { headers }) => {
  const localstorage = JSON.parse(localStorage.getItem('entersight'))
  const token = localstorage ? localstorage.core.token : ''
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT,
  ...authLink
})

// Create the subscription websocket link
const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_ENDPOINT.replace('https', 'wss'),
  options: {
    reconnectionAttempts: 20,
    reconnect: true,
    timeout: 1000,
    lazy: true,
    connectionParams: () => {
      const localstorage = JSON.parse(localStorage.getItem('entersight'))
      const token = localstorage ? localstorage.core.token : ''
      return {
        Authorization: token ? `Bearer ${token}` : '',
        headers: {
          Authorization: token ? `Bearer ${token}` : ''
        }
      }
    }
  }
})

// // Error Handling
// const errorLink = onError(async ({ graphQLErrors, networkError }) => {
//   // if (graphQLErrors && process.env.NODE_ENV !== 'production') {
//   // }
//   for (let item of graphQLErrors) {
//     if (item.extensions) return console.info(item.extensions.ru)
//     if (item.message === 'Unauthorized') {
//       // window.location.reload(true)
//       if (store.state.core.refresh) {
//         store.dispatch('core/refreshGQL')
//       } else {
//         store.dispatch('core/logout')
//       }
//     }
//   }
//   if (networkError) console.error(`[Network error]: ${networkError}`)
// })

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  authLink.concat(httpLink)
)

export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true
})
