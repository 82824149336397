export class DiscontCustomer {
  constructor(request = {}) {
    this.id = request?.id
    this.name = request?.name
    this.surname = request?.surname
    this.patronymic = request?.patronymic
    this.phone = request?.phone
    this.gender = this.initGender(request?.gender)
    this.birthday = request?.birthday ? new Date(request?.birthday) : null
  }

  get inputDiscontCustomer() {
    return {
      id: this.id,
      name: this.name,
      surname: this.surname,
      patronymic: this.patronymic,
      phone: this.phone,
      gender: this.gender?.[0]?.value,
      birthday: this.birthday
    }
  }

  initGender(val) {
    if (Array.isArray(val)) {
      return val
    } else {
      return val ? [{ id: val === 'male' ? 1 : 2, name: val === 'male' ? 'М' : 'Ж', value: val }] : []
    }
  }

  get getName() {
    return this.name
  }

  get getSurname() {
    return this.surname
  }

  get getPatronymic() {
    return this.patronymic
  }

  get getPhone() {
    return this.phone
  }

  get getGender() {
    return this.gender
  }

  get getBirthday() {
    return this.birthday
  }

  setName(name) {
    this.name = name
  }

  setSurname(surname) {
    this.surname = surname
  }

  setPatronymic(patronymic) {
    this.patronymic = patronymic
  }

  setPhone(phone) {
    this.phone = phone
  }

  setGender(gender) {
    this.gender = gender
  }

  setBirthday(birthday) {
    this.birthday = birthday
  }
}
