<template>
  <div>
    <div
      class="comaprison-item"
      :class="{
        padding1: item.level === 1,
        padding2: item.level === 2,
        padding3: item.level === 3,
        padding4: item.level === 4
      }"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
      @click.stop="(e) => open(e)"
    >
      <div class="item-left">
        <div v-if="!item?.last && !opened.includes(item.id) && !new_product">
          <img
            class="arrow-down arrow-down__rotate"
            src="/img/icons/menu_arrow_down.svg"
            alt="Открыть"
          />
        </div>
        <div v-if="opened.includes(item.id)">
          <img
            class="arrow-down"
            src="/img/icons/menu_arrow_down.svg"
            alt="Открыть"
          />
        </div>
        <div
          v-if="!item.franchise_id && item.product_count"
          class="error-svg"
        >
          <img
            src="/img/icons/attention.svg"
            alt="error"
          />
        </div>
        <div v-if="!edit && !new_product">{{ parent_number + item.int_id }}. {{ item.name }}</div>
        <div
          v-else
          draggable="false"
          class="input-block"
          @click.stop
        >
          <p>{{ parent_number + item.int_id }}</p>
          <e-input
            :id="`input-name${item.id}`"
            :ref="`input-name${item.id}`"
            v-model="name_string"
            draggable="false"
            placeholder="Название категории"
            :class="{
              input__padding1: item.level === 1,
              input__padding2: item.level === 2,
              input__padding3: item.level === 3,
              input__padding4: item.level === 4,
              new_input: new_product
            }"
            class="input-name"
            :value="item.name"
            @enter="saveGroup"
            @click.stop
          />
        </div>
        <div
          v-if="edit || new_product"
          class="d-flex"
          @click.stop
        >
          <e-button
            size="m"
            class="ml-2 mini-button"
            @click="saveGroup"
          >
            <img
              size="m"
              src="/img/icons/icon_yes.svg"
              alt="Переименовать группу"
            />
          </e-button>
          <div @click.stop>
            <e-button
              class="ml-2 mini-button"
              @click="closeButtonClick"
            >
              <img
                src="/img/icons/icon_cancel.svg"
                alt="Отменить"
              />
            </e-button>
          </div>
        </div>
        <div style="height: 32px"></div>
        <button
          v-if="hover && item.level !== 4 && !new_product"
          id="tooltip-add-subgroup"
          v-b-tooltip:top="'Добавить подгруппу ниже'"
          @click.stop="showAddChildGroup"
        >
          <img
            src="/img/icons/icon_add_group_hover.svg"
            alt=""
          />
        </button>
        <b-dropdown
          v-if="hover && !new_product"
          no-caret
          variant="none"
          @click="outsideClick"
        >
          <template #button-content>
            <img
              class="ellipsis"
              src="/img/icons/more_gray_hover.svg"
              alt="ellipsis"
              @click="outsideClick"
            />
          </template>
          <b-dropdown-item @click.stop="updateName"> Переименовать </b-dropdown-item>
          <b-dropdown-item @click.stop="openRemoveModal">
            <div class="remove-text">Удалить</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="item-right">
        <div
          v-if="item.product_count && !new_product"
          class="connect-svg"
        >
          <connect-svg :color="!item.franchise_id.id ? '#E53835' : '#00CB91'" />
        </div>
        <div class="connect">
          <p v-if="!item.product_count && !new_product">Связь не требуется</p>
          <comparison-select
            v-if="item.product_count && !new_product"
            :item="item"
            :select_id="item?.id"
            :franchise_arr="franchise_arr"
            :franchise_path="franchise_path"
            @refetch="refetchCategories"
            @setPath="setPath"
          />
        </div>
        <!--        <img-->
        <!--          class="drag-icon"-->
        <!--          src="/img/icons/drag.svg"-->
        <!--          alt="drag" />-->
      </div>
    </div>
    <div
      v-if="new_prod"
      class="add-child"
    >
      <comparison-item
        :opened="opened"
        :item="{ id: 1, name: 'Новая категория', int_id: item.int_id, level: item.level + 1, parent_id: this.item.id }"
        :new_product="true"
        :category_path="category_path"
        :franchise_arr="franchise_arr"
        :franchise_path="franchise_path"
        @close-new="closeButtonClick"
        @set_franchise_path="setPath"
        @refetch="refetchCategories"
        @openCategory="(path) => $emit('openCategory', path)"
      />
    </div>
    <div v-if="item?.child_groups?.length && opened.includes(item.id)">
      <comparison-item
        v-for="children in item?.child_groups"
        :key="children.id + children.name"
        :opened="opened"
        :item="children"
        :category_path="category_path"
        :franchise_arr="franchise_arr"
        :franchise_path="franchise_path"
        :parent_number="'' + parent_number + item.int_id + '.'"
        @set_franchise_path="setPath"
        @refetch="refetchCategories"
        @openCategory="(path) => $emit('openCategory', path)"
      />
    </div>
    <remove-modal
      :group="item"
      @remove-item="removeItem"
    />
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  import ConnectSvg from '@/views/products/components/modal/ConnectSvg'
  import ComparisonSelect from '@/views/products/components/modal/ComparisonSelect'
  import RemoveModal from '@/views/products/modal/RemoveModal'
  export default {
    name: 'ComparisonItem',
    components: { RemoveModal, ComparisonSelect, ConnectSvg, draggable },
    props: {
      parent_number: {
        type: [Number, String],
        default: ''
      },
      new_product: {
        type: Boolean,
        default: false
      },
      item: {
        type: Object,
        default: () => {}
      },
      opened: {
        type: Array,
        default: () => []
      },
      franchise_arr: {
        type: Array,
        default: () => []
      },
      franchise_path: {
        type: Array,
        default: () => []
      },
      category_path: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        edit: false,
        name_string: '',
        hover: false,
        new_prod: false
      }
    },
    mounted() {
      this.name_string = this.item.name
      window.addEventListener('click', this.outsideClick, true)
      console.log(this.new_product)
      if (this.new_product) {
        setTimeout(() => {
          const input = document.querySelector('.new_input input')
          input?.focus()
        }, 10)
      }
    },
    destroyed() {
      window.removeEventListener('click', this.outsideClick)
    },
    methods: {
      outsideClick(e) {
        if (
          !(this.$refs[`input-name${this.item.id}`] === e.target) &&
          !e.target.closest(`#input-name${this.item.id}`) &&
          !e.target.closest(`.mini-button`)
        ) {
          this.edit = false
        }
      },
      mouseOver() {
        this.hover = true
      },
      mouseLeave() {
        this.hover = false
      },
      showAddChildGroup() {
        this.new_prod = true
      },
      closeButtonClick() {
        if (this.new_prod || this.new_product) {
          this.new_prod = false
          return this.$emit('close-new')
        }
        setTimeout(() => {
          this.edit = false
          this.name_string = this.item.name
        }, 10)
      },
      updateName() {
        this.edit = true
        setTimeout(() => {
          const a = document.querySelector(`#input-name${this.item.id} input`)
          a.focus()
        }, 10)
      },
      async saveGroup() {
        if (!this.name_string?.length) {
          this.$noty.error('Имя категории не может быть пустым полем')
          return (this.name_string = this.item.name)
        }
        if (this.new_prod || this.new_product) {
          await this.$apollo
            .mutate({
              mutation: require('../../gql/CreateCategory.gql'),
              fetchPolicy: 'no-cache',
              variables: {
                name: this.name_string,
                parent: this.item.parent_id
              }
            })
            .then(() => {
              this.$noty.show('Категория успешно создана')
            })
            .catch((e) => {
              console.error('error: ', e)
              this.$noty.show(`При создании группы "${this.item.name}" что-то пошло не так. Попробуйте еще раз`)
            })
            .finally(() => {
              this.$emit('refetch')
              this.closeButtonClick()
            })
          return
        }
        this.$emit('setItemName', { val: this.name_string, id: this.item.id })
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/updateCategory.gql'),
            variables: {
              id: this.item.id,
              name: this.name_string
            }
          })
          this.edit = false
        } catch (e) {
          this.$noty.error('Ошибка обновления')
          setTimeout(() => this.$emit('refetch'), 10)
        }
        this.$emit('refetch')
      },
      openRemoveModal() {
        setTimeout(() => this.$bvModal.show(`modal-remove-group${this.item.id}`), 100)
      },
      removeItem() {
        this.$emit('refetch')
      },
      open(e) {
        if (!this.item.last) {
          this.$emit('openCategory', { id: this.item.id, path: this.item.path })
        }
        this.outsideClick(e)
      },
      async refetchCategories() {
        await this.$emit('refetch')
      },
      setPath(path) {
        this.$emit('set_franchise_path', path)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .comaprison-item {
    display: flex;
    justify-content: space-between;
    padding: 17px;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    .item-left {
      display: flex;
      align-items: center;
      .arrow-down {
        margin-right: 14px;
        &__rotate {
          transform: rotate(-90deg);
        }
      }
      .error-svg {
        margin-right: 6px;
      }
    }
    .item-right {
      display: flex;
      align-items: center;
      .connect {
        p {
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #bcbcbc;
          margin: 0;
        }
      }
      .connect-svg {
        margin-right: 12px;
      }
    }
  }
  #tooltip-add-subgroup {
    margin-left: 12px;
    border: none;
    background-color: transparent;
    &:hover {
      opacity: 0.6;
    }
  }
  .ellipsis {
    margin-left: 12px;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  .drag-icon {
    cursor: grab;
    margin-left: 12px;
  }
  .padding1 {
    padding-left: 40px;
  }
  .padding2 {
    padding-left: 70px;
  }
  .padding3 {
    padding-left: 100px;
  }
  .padding4 {
    padding-left: 130px;
  }
  .remove-text {
    color: #e53835;
  }
  .input-block {
    width: fit-content;
    position: relative;
    p {
      z-index: 20;
      margin: 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(10px, -50%);
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #bcbcbc;
    }
    .input-name {
      width: 300px;
    }
  }
  ::v-deep .input {
    padding-left: 20px !important;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .input__padding1 {
    ::v-deep .input {
      padding-left: 30px !important;
    }
  }
  .input__padding2 {
    ::v-deep .input {
      padding-left: 40px !important;
    }
  }
  .input__padding3 {
    ::v-deep .input {
      padding-left: 50px !important;
    }
  }
  .input__padding4 {
    ::v-deep .input {
      padding-left: 60px !important;
    }
  }
  ::v-deep .e_button {
    padding: 10px 12px !important;
  }
</style>
