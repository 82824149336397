<template>
  <svg
    class="orange_footer"
    width="651"
    height="78"
    viewBox="0 0 651 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 78V0H651V78H0Z"
      fill="#EE7C21"
    />
  </svg>
</template>
<script>
  export default {
    name: 'OrangeLineSvg'
  }
</script>
<style lang="scss" scoped></style>
