var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "background",
    attrs: {
      "width": "596",
      "height": "396",
      "viewBox": "0 0 600 400",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "600",
      "height": "400",
      "fill": "#FFE999"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }