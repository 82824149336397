import * as R from 'ramda'

export const isNillOrEmpty = R.anyPass([R.isNil, R.isEmpty])

export const checkObjectPropertyOnNull = (object) => {
  const obj = {}

  for (const item in object) {
    if (!isNillOrEmpty(object[item])) obj[item] = object[item]
  }

  return R.omit(['__typename'], obj)
}

export const formatPrice = (summ = 0) => {
  const val = summ === null ? 0 : summ
  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'decimal',
    currency: 'RUB',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(val)
}

export const formatCurrencyPrice = (val) => {
  return val.toLocaleString('ru-RU', {
    style: 'currency',
    currency: 'RUB'
  })
}
