import { TourModel } from '@/models/tour.model'
import store from '@/store'

export const mutations = {
  LOGIN_USER(state, userData) {
    state.isAuth = true
    state.user = { ...userData.user }
    state.token = userData.token
    state.refresh = userData.refresh
  },

  SET_INFO_BINDING_MODAL(state, data) {
    state.infoBindingModal = data
  },

  SET_REFRESH(state, refresh) {
    state.refresh = refresh
  },

  SET_TOKEN(state, payload) {
    state.token = payload
  },

  SET_IS_CONFIRMED(state, is_confirmed) {
    state.user.is_confirmed = is_confirmed
  },

  SET_USER(state, user) {
    state.user = user
  },

  SET_REGFLOW_COMPANY(state, comapny) {
    state.regflow.company = comapny
  },

  SET_USER_ID(state, id) {
    state.user.id = id
  },

  SET_USER_EMAIL(state, email) {
    state.user.email = email
  },

  SET_STORYLINE_STATUS(state, step) {
    if (step === 'activity' || step === 'company' || step === 'confirm') {
      state.regflow.storyline.registr = true
    }
    if (step === 'company' || step === 'confirm') {
      state.regflow.storyline.activity = true
    }
    if (step === 'confirm') {
      state.regflow.storyline.company = true
    }
    if (step === 'employees') {
      state.regflow.storyline.company = true
    }

    state.regflow.storyline.current = step
  },

  SET_ACTIVITY_LIST(state, list) {
    state.regflow.activity.list = list
    state.regflow.activity.isLoaded = true
  },

  SET_ACTIVITIES(state, activities_id) {
    state.regflow.activity.selected_ids = activities_id
  },

  SET_IS_FASOL(state, is_fasol) {
    state.regflow.activity.is_fasol = is_fasol
  },

  SET_CURRENT_STEP(state, currentStep) {
    state.regflow.storyline.current = currentStep
  },

  SET_COMPANY_NAME(state, companyName) {
    state.regflow.company.name = companyName
  },

  SET_CURRENT_STORE(state, id) {
    state.regflow.company.current_store_id = id
  },

  UPDATE_COMPANY_NAME(state, company_name) {
    state.user.company.name = company_name
  },

  SET_TOUR(state, tour) {
    state.tour = tour
  },

  SET_BINDING_MODAL(state, modal) {
    if (modal) {
      state.binding_modal = { ...state.binding_modal, ...modal }
    } else {
      state.binding_modal = {
        product: {},
        target_product: {},
        spec_id: {},
        supplier_name: ''
      }
    }
  },

  LOGOUT_USER(state) {
    store.dispatch('products/initialState')
    store.dispatch('settings/initialState')
    state.token = ''
    state.refresh = ''
    state.isAuth = false
    state.user = {
      id: '',
      first_name: '',
      second_name: '',
      last_name: '',
      phone: '',
      email: '',
      role: '',
      avatar: '',
      is_confirmed: false
    }
    state.current_store = {}
    state.regflow = {
      storyline: {
        activity: false,
        company: false,
        confirm: false,
        current: ''
      },
      activity: {
        list: '',
        selected_ids: '',
        isLoaded: false,
        is_fasol: false
      },
      company: {
        name: ''
      },
      tour: new TourModel()
    }
    localStorage.removeItem('entersight')
    sessionStorage.clear()
  }
}
