var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id_modal ? _vm.id_modal : 'sort-table-modal',
      "static": "",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "body-class": "p-0"
    }
  }, [_c('h5', {
    staticClass: "title-text"
  }, [_vm._v("Настройка столбцов")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "header first"
  }, [_vm._v("Все столбцы")]), _c('div', {
    staticClass: "header"
  }, [_vm._v("Показанные столбцы")])]), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "columns first"
  }, [_c('div', {
    staticClass: "d-flex check-box"
  }, [_c('b-checkbox', {
    attrs: {
      "checked": _vm.allSelected
    },
    on: {
      "change": _vm.selectAll
    }
  }), _vm._v(" Выбрать все ")], 1), _vm._l(_vm.defaultFields.filter(function (el) {
    return el.key !== 'id';
  }), function (field, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex check-box"
    }, [_c('b-checkbox', {
      on: {
        "change": function ($event) {
          return _vm.checkParent(field);
        }
      },
      model: {
        value: field.checked,
        callback: function ($$v) {
          _vm.$set(field, "checked", $$v);
        },
        expression: "field.checked"
      }
    }), _c('span', [_vm._v(_vm._s(field.label))])], 1), _c('div', [_c('div', {
      staticClass: "ml-4"
    }, _vm._l(field.children, function (el, index) {
      return _c('div', {
        key: index,
        staticClass: "d-flex check-box"
      }, [_c('b-checkbox', {
        on: {
          "change": function ($event) {
            return _vm.checkChild(field);
          }
        },
        model: {
          value: el.checked,
          callback: function ($$v) {
            _vm.$set(el, "checked", $$v);
          },
          expression: "el.checked"
        }
      }), _vm._v(" " + _vm._s(el.label) + " ")], 1);
    }), 0)])]);
  })], 2), _c('div', {
    staticClass: "columns"
  }, [_c('draggable', {
    attrs: {
      "ghost-class": "ghost",
      "list": _vm.newFields
    },
    on: {
      "start": function ($event) {
        _vm.drag = true;
      },
      "end": function ($event) {
        _vm.drag = false;
      }
    }
  }, [_c('transition-group', _vm._l(_vm.newFields, function (field) {
    return _c('div', {
      key: field.key,
      staticClass: "d-flex item",
      class: {
        hide: !field.checked
      }
    }, [_c('div', {
      staticClass: "d-flex check-box"
    }, [_c('b-checkbox', {
      on: {
        "change": function ($event) {
          return _vm.checkParent(field);
        }
      },
      model: {
        value: field.checked,
        callback: function ($$v) {
          _vm.$set(field, "checked", $$v);
        },
        expression: "field.checked"
      }
    }), _vm._v(" " + _vm._s(field.label) + " "), !_vm.no_drag ? _c('img', {
      staticClass: "ml-auto move_button",
      attrs: {
        "src": "/img/icons/move_button.svg",
        "alt": ""
      }
    }) : _vm._e()], 1), _c('div', {
      staticClass: "ml-4"
    }, _vm._l(field.children, function (el, index) {
      return _c('div', {
        key: index,
        staticClass: "d-flex check-box"
      }, [_c('b-checkbox', {
        on: {
          "change": function ($event) {
            return _vm.checkChild(field);
          }
        },
        model: {
          value: el.checked,
          callback: function ($$v) {
            _vm.$set(el, "checked", $$v);
          },
          expression: "el.checked"
        }
      }), _vm._v(" " + _vm._s(el.label) + " ")], 1);
    }), 0)]);
  }), 0)], 1)], 1)]), _c('div', {
    staticClass: "d-flex footer-modal align-items-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.handleBack
    }
  }, [_vm._v(" Отмена ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleOk
    }
  }, [_vm._v(" Применить ")]), _c('a', {
    staticClass: "ml-auto",
    on: {
      "click": _vm.setDefault
    }
  }, [_vm._v("Вернуть по умолчанию")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }