import { PersonModel } from '@/models/person.model'
import { ContractModel } from '@/models/contract.model'
import { EntityModel } from '@/models/entity.model'
import { ExternalSystemModel } from '@/models/external.model'

export class SupplierModel {
  constructor(request = {}) {
    this.contacts = request?.contacts?.map((el) => new PersonModel(el))
    this.contract = new ContractModel(request?.contract)
    this.entity = request?.entity?.map((el) => new EntityModel(el))
    this.external = request?.external?.map((el) => new ExternalSystemModel(el))
    this.id = request?.id
    this.name = request?.name
    this.inn = request?.inn
    this.kpp = request?.kpp
  }
}
