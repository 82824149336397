var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "menu-item p-0"
  }, _vm._l(_vm.items, function (item, index) {
    var _vm$selected_categori, _vm$selected_categori2, _item$name;

    return _c('li', {
      key: index,
      ref: `item_${item.id}`,
      refInFor: true,
      staticClass: "menu",
      class: {
        open: _vm.ids.includes(item.id),
        current: _vm.getCategory.id === item.id,
        last: item.last,
        selected: _vm.getCurrentCategoryMenuId === item.id
      },
      attrs: {
        "id": `item_${item.id}`
      }
    }, [_vm.rename !== item.id ? _c('div', {
      staticClass: "elm-buttont",
      class: {
        active: _vm.getCategory.id === item.id
      }
    }, [_vm.is_multiple ? _c('b-checkbox', {
      attrs: {
        "checked": (_vm$selected_categori = _vm.selected_categories) === null || _vm$selected_categori === void 0 ? void 0 : _vm$selected_categori.includes(item.id)
      },
      on: {
        "change": function (value) {
          return _vm.selectCategory(item, `${_vm.int_number}${item.int_id}.`, index, value);
        }
      }
    }) : _vm._e(), _c('button', {
      staticClass: "item cursor",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          _vm.is_multiple ? null : _vm.selectCategory(item, `${_vm.int_number}${item.int_id}.`, index);
        }
      }
    }, [!item.last ? _c('span', {
      staticClass: "open-category cursor",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          $event.preventDefault();
          return _vm.openSubMenu(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/img/icons/menu_arrow_down.svg",
        "alt": "Открыть"
      }
    })]) : _vm._e(), _vm.mass_add ? _c('e-checkbox', {
      staticClass: "mr-3 ml-1",
      attrs: {
        "type": "-",
        "checked": (_vm$selected_categori2 = _vm.selected_categories) === null || _vm$selected_categori2 === void 0 ? void 0 : _vm$selected_categori2.includes(item.id)
      },
      on: {
        "click": function (value) {
          return _vm.massAdd(item, `${_vm.int_number}${item.int_id}.`, index, value, true);
        }
      }
    }) : _vm._e(), _c('span', {
      staticClass: "count mr-1"
    }, [_vm._v(_vm._s(_vm.int_number) + _vm._s(item.int_id) + ".")]), _c('span', {
      staticClass: "title",
      attrs: {
        "id": item.id
      }
    }, [_vm._v(_vm._s(item.name))])], 1), _vm.getCategory.id === item.id && !_vm.is_select_group && !_vm.hide_control ? _c('div', {
      staticClass: "actions"
    }, [item.id ? _c('b-tooltip', {
      attrs: {
        "target": item.id,
        "delay": {
          show: 1000,
          hide: 100
        },
        "boundary": "viewport"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]) : _vm._e(), _vm.getCategory.id === item.id && !_vm.is_multiple ? _c('div', {
      staticClass: "actions"
    }, [item.id ? _c('button', {
      staticClass: "cursor mr-1",
      attrs: {
        "id": "tooltip-add-subgroup"
      },
      on: {
        "click": function ($event) {
          return _vm.showAddChildGroup(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/img/icons/icon_add_group.svg",
        "alt": ""
      }
    }), _c('img', {
      staticClass: "hover",
      attrs: {
        "src": "/img/icons/icon_add_group_hover.svg",
        "alt": ""
      }
    })]) : _vm._e(), _c('b-tooltip', {
      attrs: {
        "target": "tooltip-add-subgroup",
        "triggers": "hover",
        "delay": {
          show: 500,
          hide: 200
        },
        "boundary": "viewport",
        "placement": "topleft"
      }
    }, [_vm._v(" Добавить подгруппу ")]), item.id ? [_c('b-dropdown', {
      attrs: {
        "no-caret": "",
        "variant": "none"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('img', {
            staticClass: "cursor",
            attrs: {
              "src": "/img/icons/more_gray.svg",
              "alt": ""
            }
          }), _c('img', {
            staticClass: "hover cursor",
            attrs: {
              "src": "/img/icons/more_gray_hover.svg",
              "alt": ""
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.updateName(item);
        }
      }
    }, [_vm._v(" Переименовать ")]), _vm.sections.length ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.addSection(item);
        }
      }
    }, [_vm._v(" Добавить секции ")]) : _vm._e(), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.setGroupMarkup(item);
        }
      }
    }, [_vm._v(" Назначить наценку ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.removeMenuItem(item);
        }
      }
    }, [_vm._v(" Удалить ")])], 1)] : _vm._e()], 2) : _vm._e()], 1) : _vm._e()], 1) : _c('div', [_c('b-input-group', [_c('b-input-group-prepend', {
      staticStyle: {
        "height": "32px"
      },
      attrs: {
        "size": "sm"
      }
    }, [_c('b-input-group-text', {
      staticClass: "bg-transparent",
      staticStyle: {
        "font-size": "14px",
        "color": "#bcbcbc"
      },
      style: _vm.isFocused ? 'border-color: #80bdff!important' : ''
    }, [_vm._v(" " + _vm._s(_vm.int_number) + _vm._s(item.int_id) + ". ")])], 1), _c('b-form-input', {
      staticClass: "mb-2 border-left-0",
      attrs: {
        "id": item.id
      },
      on: {
        "keydown": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.saveGroup(item);
        }
      },
      model: {
        value: item.name,
        callback: function ($$v) {
          _vm.$set(item, "name", $$v);
        },
        expression: "item.name"
      }
    })], 1), _c('div', {
      staticClass: "b-btns",
      staticStyle: {
        "display": "flex",
        "flex-direction": "row",
        "justify-content": "flex-end",
        "gap": "4px"
      }
    }, [_c('b-button', {
      staticClass: "cursor",
      attrs: {
        "disabled": !((_item$name = item.name) !== null && _item$name !== void 0 && _item$name.length)
      },
      on: {
        "click": function ($event) {
          return _vm.saveGroup(item);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/img/icons/icon_yes.svg",
        "alt": "Переименовать группу"
      }
    })]), _c('b-button', {
      staticClass: "cursor",
      on: {
        "click": function ($event) {
          _vm.rename = false;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/img/icons/icon_cancel.svg",
        "alt": "Отменить"
      }
    })])], 1)], 1), _vm.add_child_group && _vm.add_group_id === item.id ? [_c('add-group', {
      attrs: {
        "id": item.id,
        "number": `${_vm.int_number}${item.int_id}.`
      },
      on: {
        "closeAddGroup": _vm.closeAddGroup,
        "groupAddedSuccessfully": _vm.groupAddedSuccessfully
      }
    })] : _vm._e(), item.child_groups ? _c('menu-item', {
      attrs: {
        "items": item.child_groups,
        "int_number": _vm.int_number + `${item.int_id}.`,
        "open_group": _vm.open_group,
        "update_menu": _vm.update_menu,
        "modal_remove": _vm.modal_remove,
        "current_ids": _vm.current_ids,
        "product_category": _vm.getCategory,
        "is_select_group": _vm.is_select_group,
        "close_add_group": _vm.closeAddGroup,
        "is_multiple": _vm.is_multiple,
        "sections": _vm.sections,
        "mass_add": _vm.mass_add,
        "hide_extra_charge": "",
        "close_add_parrent_group": _vm.closeAddParrentGroup,
        "selected_categories": _vm.selected_categories
      },
      on: {
        "close_add_form": _vm.closeAddParrentGroup,
        "selected_group": _vm.selectedGroup,
        "select_group": _vm.selectGroup,
        "set-group-markup": _vm.setGroupMarkup,
        "add-section": _vm.addSection
      }
    }) : _vm._e()], 2);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }