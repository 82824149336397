import { AddressModel } from '@/models/address.model'
import { CompanyEntityModel } from '@/models/entity.model'

export class BranchEntityModel {
  constructor(request = {}) {
    this.address_entity = new AddressModel(request?.address_entity)
    this.branch_kpp = request?.branch_kpp
    this.ceo = request?.ceo
    this.company_entity = new CompanyEntityModel(request?.company_entity)
    this.fullname = request?.fullname
    this.id = request?.id
    this.inn = request?.inn
    this.kpp = request?.kpp
    this.name = request?.name
    this.ogrn = request?.ogrn
  }
}
