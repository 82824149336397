<template>
  <svg
    width="18"
    height="3"
    viewBox="0 0 18 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.17968V0.950806H15.1767L17.5115 2.17968H0Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'LeftLine200on140Svg'
  }
</script>
<style lang="scss" scoped>
  svg {
    transform: translateY(24.5px);
  }
</style>
