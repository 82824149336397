var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "binding-accept",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Подтвердите привязку товара")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-footer"
        }, [_c('e-button', {
          staticClass: "text-nowrap w-auto",
          attrs: {
            "variant": "primary",
            "disabled": _vm.loading,
            "loading": _vm.loading
          },
          on: {
            "click": _vm.replaceProduct
          }
        }, [_vm._v(" Привязать ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.hideModal
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "modal-inner"
  }, [_c('div', {
    staticClass: "product-name"
  }, [_vm._v(" " + _vm._s(_vm.product.name || _vm.product.productItem_name) + " ")]), _c('img', {
    staticClass: "m-3",
    attrs: {
      "src": "/img/icons/ext_systems/bind.svg",
      "alt": "bind"
    }
  }), _c('div', {
    staticClass: "product-name"
  }, [_vm._v(" " + _vm._s(_vm.selected_product.name) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }