var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "22",
      "height": "23",
      "viewBox": "0 0 22 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20.0278 20.5H1.97411C1.62609 20.5 1.30451 20.3094 1.1305 20C0.956497 19.6905 0.956499 19.3093 1.13051 18.9999L10.1569 2.99912C10.331 2.69021 10.6523 2.5 11 2.5C11.3477 2.5 11.669 2.69021 11.8431 2.99912L20.8695 18.9999C21.0434 19.3092 21.0435 19.6902 20.8697 19.9995C20.696 20.3089 20.3757 20.4996 20.0278 20.5ZM11.0015 5.49924L3.66522 18.4999H18.3397L11.0015 5.49924ZM11.9707 14.5007H10.0225V9.49944H11.9707V14.5007Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M10.0273 15.5017H11.9756V17.5018H10.0273V15.5017Z",
      "fill": _vm.color
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }