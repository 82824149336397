export const state = {
  declaration: {
    entity: '',
    type: 'first',
    quarter: 'first'
  },
  branches: [],
  specifications: [],
  opened_category: [],
  checked: [],
  products: [],
  date_range: {},

  abc_date_range: {},
  abc_products: [],
  abc_checked: [],
  abc_suppliers: [],
  abc_opened_category: [],
  abc_branches: [],
  group_by: {
    abc: [
      { id: 'branch', name: 'Магазин', grouped: false },
      { id: 'supplier', name: 'Поставщик', grouped: false },
      { id: 'category', name: 'Товарная группа', grouped: false }
    ],
    sales: [
      { id: 'branch', name: 'Магазин', grouped: false },
      { id: 'category', name: 'Товарная группа', grouped: false },
      { id: 'storage', name: 'Место хранения', grouped: false },
      { id: 'supplier', name: 'Поставщик', grouped: false }
    ]
  },
  analytics_params: {
    byRawMaterials: false
  }
}
