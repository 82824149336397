export const actions = {
  setCurrentCategory({ commit }, category) {
    commit('SET_CURRENT_CATEGORY', category)
  },

  setCurrentProduct({ commit }, product) {
    commit('SET_CURRENT_PRODUCT', product)
  },

  initialState({ commit }) {
    commit('INITIAL_STATE')
  },

  setSelectedCategories({ commit }, categories) {
    commit('SET_SELECTED_CATEGORIES', categories)
  },

  setSelectedProducts({ commit }, products) {
    commit('SET_SELECTED_PRODUCTS', products)
  },

  setExcludedProducts({ commit }, excluded_products) {
    commit('SET_EXLUDED_PRODUCTS', excluded_products)
  },

  setExcludedCategories({ commit }, excluded_categories) {
    commit('SET_EXLUDED_CATEGORIES', excluded_categories)
  },

  setSearchStr({ commit }, str) {
    commit('SET_SEARCH_STR', str)
  },

  setPlaceholder({ commit }, placeholder) {
    commit('SET_PLACEHOLDER', placeholder)
  },

  setCurrentCategoryPath({ commit }, categoryPath) {
    commit('SET_CURRENT_CATEGORY_PATH', categoryPath)
  },

  setCurrentIds({ commit }, ids) {
    commit('SET_CURRENT_IDS', ids)
  },

  setCurrentProducts({ commit }, ids) {
    commit('SET_CURRENT_PRODUCTS', ids)
  },

  setMenuVisibleStatus({ commit }, status) {
    commit('SET_MENU_VISIBLE_STATUS', status)
  },

  setCurrentProductId({ commit }, id) {
    commit('SET_CURRENT_PRODUCT_ID', id)
  },

  setAddgroupVisibleStatus({ commit }, status) {
    commit('SET_ADDGROUP_VISIBLE_STATUS', status)
  },

  setCurrentCategoryMenuId({ commit }, id) {
    commit('SET_CURRENT_CATEGORY_MENU_ID', id)
  },

  setPrintActions({ commit }, actions) {
    commit('SET_PRINT_ACTIONS', actions)
  },
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters)
  }
}
