var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: [_vm.getSend || _vm.sending ? 'confirm-block__sending' : 'confirm-block']
  }, [_c('div', {
    staticClass: "confirm-block__left"
  }, [_c('warning-svg', {
    staticClass: "warning-img",
    attrs: {
      "color": _vm.getSend || _vm.sending ? '#313131' : 'white'
    }
  }), _c('div', {
    staticClass: "text"
  }, [!_vm.sending ? _c('p', {
    staticClass: "confirm-text"
  }, [_vm._v(" Подтвердите ваш E-mail ")]) : _vm._e(), _vm.sending ? _c('p', {
    staticClass: "sending__mini"
  }, [_vm._v(" Письмо отправлено ")]) : _vm._e(), _vm.sending ? _c('p', {
    staticClass: "sending"
  }, [_vm._v(" Письмо с подтверждением отправлено на ")]) : _vm._e(), _c('p', {
    staticClass: "email"
  }, [_vm._v(_vm._s(_vm.user.email))]), !_vm.sending ? _c('p', {
    staticClass: "service-text"
  }, [_vm._v(" чтобы продолжить пользоваться сервисом. ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "confirm-block__right"
  }, [_vm.getSend || _vm.sending ? _c('p', {
    staticClass: "not-sending"
  }, [_vm._v(" Не пришло? ")]) : _vm._e(), _c('div', {
    staticClass: "button-block",
    staticStyle: {
      "margin-right": "8px"
    }
  }, [_c('e-button', {
    attrs: {
      "size": "m",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.confirm
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])], 1), _c('div', {
    staticClass: "button-block"
  }, [_c('e-button', {
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Изменить E-mail ")])], 1)]), _c('confirm-email-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }