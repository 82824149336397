<template>
  <b-modal
    id="help-modal"
    title="Обращение в поддержку"
    centered
  >
    <b-form-textarea
      v-model="message"
      placeholder="Опишите вашу проблему"
    />
    <template #modal-footer>
      <div class="d-flex">
        <b-button
          variant="primary"
          @click="help"
        >
          Отправить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="cancel"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'HelpModal',
    data() {
      return {
        message: ''
      }
    },
    methods: {
      async help() {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/Support.graphql'),
            variables: {
              input: this.message + '\n' + window.location.href
            }
          })
          this.$noty.show('Обращение отправлено')
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка отправки')
        }

        this.message = ''
        this.$bvModal.hide('help-modal')
      },
      cancel() {
        this.$bvModal.hide('help-modal')
      }
    }
  }
</script>

<style scoped></style>
