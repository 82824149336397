import { render, staticRenderFns } from "./OldPriceLineSvg.vue?vue&type=template&id=5cef8488&scoped=true&"
import script from "./OldPriceLineSvg.vue?vue&type=script&lang=js&"
export * from "./OldPriceLineSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cef8488",
  null
  
)

export default component.exports