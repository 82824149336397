<template>
  <svg
    class="line__logo"
    width="7"
    height="1"
    viewBox="0 0 7 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1V0H7V1H0Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'RightLine100on140Svg'
  }
</script>
<style lang="scss" scoped>
  .line__logo {
    position: absolute;
    right: 0;
    transform: translateY(14px);
  }
</style>
