export class AddressModel {
  constructor(request = {}) {
    this.apartment = request?.apartment ?? ''
    this.city = request?.city ?? ''
    this.country = request?.country ?? ''
    this.house = request?.house ?? ''
    this.post_index = request?.post_index ?? ''
    this.street = request?.street ?? ''
  }
}
