var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "confirm-email-modal",
      "hide-footer": "",
      "title": "Введите новый E-mail",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Введите новый E-mail")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }])
  }, [_c('div', {
    staticClass: "container-modal"
  }, [_c('div', {
    staticClass: "input-block"
  }, [_c('e-input', {
    attrs: {
      "error": _vm.emailError,
      "type": "email",
      "label": "E-mail",
      "error_text": "Введите корректный E-mail",
      "placeholder": "E-mail"
    },
    on: {
      "input": function ($event) {
        _vm.emailError = false;
      }
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('e-button', {
    attrs: {
      "size": "l",
      "variant": "primary"
    },
    on: {
      "click": _vm.send
    }
  }, [_vm._v(" Отправить письмо с подтверждением ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }