var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-pagination d-flex"
  }, [_c('div', {
    staticClass: "b-perpage"
  }, [_c('label', [_vm._v("Показывать по:")]), _c('i-select', {
    on: {
      "on-change": _vm.changeTake
    },
    model: {
      value: _vm.option.take,
      callback: function ($$v) {
        _vm.$set(_vm.option, "take", $$v);
      },
      expression: "option.take"
    }
  }, [_c('i-option', {
    attrs: {
      "value": 20
    }
  }, [_vm._v("20")]), _c('i-option', {
    attrs: {
      "value": 50
    }
  }, [_vm._v("50")]), _c('i-option', {
    attrs: {
      "value": 100
    }
  }, [_vm._v("100")])], 1)], 1), _vm.total ? _c('b-pagination', {
    attrs: {
      "total-rows": _vm.total,
      "per-page": _vm.option.take,
      "first-number": "",
      "last-number": "",
      "aria-controls": _vm.table_name
    },
    on: {
      "change": _vm.changeCurrentPage
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function () {
        return [_c('img', {
          staticClass: "arrow-left",
          attrs: {
            "src": "/img/icons/arrow_pagination.svg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function () {
        return [_c('img', {
          staticClass: "arrow-right",
          attrs: {
            "src": "/img/icons/arrow_pagination.svg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }], null, false, 2671181028),
    model: {
      value: _vm.option.current_page,
      callback: function ($$v) {
        _vm.$set(_vm.option, "current_page", $$v);
      },
      expression: "option.current_page"
    }
  }) : _c('div', {
    staticClass: "d-flex"
  }, _vm._l(5, function (index) {
    return _c('vue-skeleton-loader', {
      key: index,
      staticClass: "skeleton mr-2",
      attrs: {
        "type": "rect",
        "height": 42,
        "width": 42,
        "animation": "fade",
        "rounded": "",
        "color": "rgba(0,0,0,0.03)",
        "radius": "11"
      }
    });
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }