<template>
  <b-modal
    id="modal-print-tags"
    hide-header
    hide-footer
    body-class="p-0"
    content-class="p-0"
    centered
    size="xl"
    @show="onModalShow"
  >
    <b-tabs
      v-model="activeIndex"
      class="nowrap-tab"
      nav-class="tab-nav-custom p-0"
      content-class="tab-content-custom"
      pills
      card
      vertical
      active-nav-item-class="active-nav-item-custom"
      nav-wrapper-class="nav-wrapper-custom"
    >
      <template #tabs-start>
        <div class="tab-header-text">Действия</div>
      </template>
      <b-tab
        title="Печать ценнников"
        :active="actions.print"
      >
        <template #title>
          <div class="d-flex align-items-center">
            <div @click.stop>
              <b-form-checkbox v-model="actions.print" />
            </div>
            Печать ценников
            <tab-arrow
              class="ml-auto"
              :fill="getArrowColor(0)"
            />
          </div>
        </template>
        <div>
          <b-overlay :show="loading">
            <print-price-tab
              ref="tab-price-tags"
              :products="products"
            />
          </b-overlay>
        </div>
      </b-tab>
      <b-tab
        title="Печать этикеток"
        :active="actions.print_label"
      >
        <template #title>
          <div class="d-flex align-items-center">
            <div @click.stop>
              <b-form-checkbox v-model="actions.print_label" />
            </div>
            Печать этикеток
            <tab-arrow
              class="ml-auto"
              :fill="getArrowColor(1)"
            />
          </div>
        </template>
        <div>
          <print-labels :products="products" />
        </div>
      </b-tab>
      <b-tab
        v-if="currentBranch.cash"
        title="Загрузка касс"
        @click.prevent
      >
        <template #title>
          <div class="d-flex align-items-center">
            <div @click.stop>
              <b-form-checkbox
                v-model="actions.load_cash"
                name="cash-load"
              />
            </div>
            Загрузка касс
            <tab-arrow
              class="ml-auto"
              :fill="getArrowColor(2)"
            />
          </div>
        </template>
        <div style="min-height: 542.5px">
          <cash-tab />
        </div>
      </b-tab>
      <template #tabs-end>
        <div class="tab-footer-action mt-auto">
          <b-button
            :disabled="!(actions.print || actions.load_cash || actions.print_label)"
            variant="primary"
            class="cursor"
            @click="accept"
          >
            Выполнить
          </b-button>
          <b-button
            variant="outline-primary"
            class="cursor ml-2"
            @click="closeModal"
          >
            Отложить
          </b-button>
        </div>
      </template>
    </b-tabs>
  </b-modal>
</template>

<script>
  import PrintPriceTab from '@/views/core/modal/PrintPriceTab'
  import TabArrow from '@/views/core/modal/TabArrow'
  import { mapGetters } from 'vuex'
  import CashTab from '@/views/core/modal/CashTab'
  import PrintLabels from '@/views/core/modal/PrintLabels'

  export default {
    components: {
      PrintLabels,
      TabArrow,
      PrintPriceTab,
      CashTab
    },
    data: () => ({
      actions: {
        print: true,
        print_label: true,
        load_cash: true
      },
      activeIndex: 0
    }),
    watch: {
      activeIndex() {
        if (this.activeIndex === 0) {
          setTimeout(() => {
            this.$refs['tab-price-tags'].$forceUpdate()
            this.$refs['tab-price-tags'].getRatio()
          }, 100)
        }
        if (this.activeIndex === 1) {
          setTimeout(() => {
            this.$refs['tab-price-tags'].$forceUpdate()
            this.$refs['tab-price-tags'].getRatio()
          }, 100)
        }
      }
    },
    methods: {
      onModalShow() {
        this.actions = this.printActions
      },
      getArrowColor(index) {
        return index === this.activeIndex ? '#313131' : '#BCBCBC'
      },
      closeModal() {
        this.$bvModal.hide('modal-print-tags')
      },
      async accept() {
        if (this.getCurrentPrinterDevice && this.getCurrentPrinterDevice !== 'window-print') {
          this.$refs['tab-price-tags'].$forceUpdate()
          this.$refs['tab-price-tags'].getRatio()
          if (this.actions.load_cash && this.currentBranch.cash) {
            await this.$apollo.mutate({
              mutation: require(`./gql/updateProductInIntegrations.gql`),
              variables: {
                input: {
                  branch: this.currentBranch.id,
                  date: this.date ? new Date(this.date) : new Date(),
                  ids: this.products?.map((el) => el.product_id || el.id)
                }
              }
            })
          }

          let pt_port = document.getElementById('print-price-tags')
          pt_port.style.display = 'block'
          let port = document.getElementById('print-labels')
          port.style.display = 'block'
          await this.$apollo.mutate({
            mutation: require(`./gql/PrintPriceTags.gql`),
            variables: {
              input: {
                branch: this.currentBranch.id,
                product: this.products?.map((el) => el.product_id || el.id)
              }
            }
          })

          await this.$apollo.mutate({
            mutation: require(`./gql/CreateBixolonTask.gql`),
            variables: {
              input: {
                branch: this.currentBranch.id,
                specs: this.getPrintProductData
              }
            }
          })
          this.closeModal()
          return
        }

        this.$refs['tab-price-tags'].$forceUpdate()
        this.$refs['tab-price-tags'].getRatio()
        if (this.actions.load_cash && this.currentBranch.cash) {
          await this.$apollo.mutate({
            mutation: require(`./gql/updateProductInIntegrations.gql`),
            variables: {
              input: {
                branch: this.currentBranch.id,
                date: this.date ? new Date(this.date) : new Date(),
                ids: this.products?.map((el) => el.product_id || el.id)
              }
            }
          })
        }
        if (this.actions.print) {
          let pt_port = document.getElementById('print-price-tags')
          pt_port.style.display = 'block'
          let port = document.getElementById('print-labels')
          port.style.display = 'none'
          window.print()
        }
        if (this.actions.print_label) {
          let pt = document.getElementById('print-price-tags')
          pt.style.display = 'none'
          let lb = document.getElementById('print-labels')
          lb.style.display = 'block'
          window.print()
        }
        let pt_port = document.getElementById('print-price-tags')
        pt_port.style.display = 'block'
        let port = document.getElementById('print-labels')
        port.style.display = 'block'
        await this.$apollo.mutate({
          mutation: require(`./gql/PrintPriceTags.gql`),
          variables: {
            input: {
              branch: this.currentBranch.id,
              product: this.products?.map((el) => el.product_id || el.id)
            }
          }
        })
        this.closeModal()
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        printActions: 'products/getPrintActions',
        getCurrentPrinterDevice: 'settings/getCurrentPrinterDevice',
        getPrintProductData: 'products/getPrintProductData'
      })
    },
    props: {
      date: String,
      products: {
        type: Array,
        default: () => []
      },
      modal_id: {
        type: String,
        default: () => 'modal-print-tags'
      },
      loading: Boolean
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    @media print {
      .modal-dialog {
        display: none !important;
        position: static !important;
      }
      .modal-backdrop {
        display: none !important;
        position: static !important;
      }
    }
    .tab-content-custom {
      overflow-x: auto;
      background: #f8f8f9;
      padding-left: 16px !important;
    }

    .tab-nav-custom {
      padding: 0;
      background: #ffffff !important;
      border-right: 1px solid #e1e1e1;
    }

    .active-nav-item-custom {
      background: #f8f8f9 !important;
      color: #313131 !important;
    }

    .nav-wrapper-custom {
      color: #000;
    }

    .nav-item a {
      color: #888888;
    }
  }
  .nowrap-tab {
    overflow: hidden;
    flex-wrap: nowrap;
    border-radius: 5px;
  }

  .tab-header-text {
    color: #313131;
    font-size: 16px;
    font-weight: 700;
    margin: 17px 0px 15px 17px;
  }

  .nav-item a {
    color: #888888;
  }

  .tab-footer-action {
    margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 16px;
  }
</style>
