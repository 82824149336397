import { AccountModel } from '@/models/account.model'
import { ContactModel } from '@/models/contact.model'
import { AddressModel } from '@/models/address.model'

export class EntityModel {
  constructor(request = {}) {
    this.account = request?.account?.map((el) => new AccountModel(el)) || []
    this.contacts = request?.contacts?.map((el) => new ContactModel(el)) || []
    this.address_entity = new AddressModel(request?.address_entity ?? {})
    this.address_fact = new AddressModel(request?.address_fact ?? {})
    this.ceo = request?.ceo
    this.email = request?.email
    this.fullname = request?.fullname
    this.full_name = request?.full_name
    this.is_supplier = request?.is_supplier
    this.id = request?.id
    this.inn = request?.inn
    this.kpp = request?.kpp
    this.name = request?.name
    this.ogrn = request?.ogrn
    this.phone = request?.phone
    this.taxRate = request?.taxRate || { id: 'null' }
    this.region = request?.region
    this.treaty = request?.treaty || []
    this.tax_system = request?.tax_system || 'null'
  }

  addContact(contact) {
    this.contacts.push(new ContactModel(contact))
  }

  removeAccount(account) {
    this.account = this.account.filter((el) => el.id !== account)
  }
  addAccount(account) {
    if (!this.account) this.account = []
    this.account.push(new AccountModel(account))
  }
  setMainAccount(account) {
    this.account.forEach((el) => (el.main = el.id === account))
  }

  get address_entity_str() {
    const country = this.address_entity?.country ? `${this.address_entity.country}` : ''
    const city = this.address_entity?.city ? `, г. ${this.address_entity.city}` : ''
    const street = this.address_entity?.street ? `, ул. ${this.address_entity.street}` : ''
    const house = this.address_entity?.house ? `, д. ${this.address_entity.house}` : ''

    return `${country}${city}${street}${house}`
  }

  get address_fact_str() {
    const country = this.address_fact?.country ? `${this.address_fact.country}` : ''
    const city = this.address_fact?.city ? `, г. ${this.address_fact.city}` : ''
    const street = this.address_fact?.street ? `, ул. ${this.address_fact.street}` : ''
    const house = this.address_fact?.house ? `, д. ${this.address_fact.house}` : ''

    return `${country}${city}${street}${house}`
  }
  get input() {
    return {
      account: this.account,
      contacts: this.contacts,
      address_entity: this.address_entity,
      address_fact: this.address_fact,
      ceo: this.ceo,
      email: this.email,
      fullname: this.fullname,
      id: this.id ? this.id : undefined,
      inn: this.inn,
      kpp: this.kpp,
      name: this.name,
      ogrn: this.ogrn,
      phone: this.phone,
      tax_rate: this.tax_rate,
      region: this.region?.id,
      tax_system: this.tax_system
    }
  }
}

export class CompanyEntityModel {
  constructor(request = {}) {
    this.account = new AccountModel(request?.account)
    this.address_entity = request?.address_entity
    this.address_fact = request?.address_fact
    this.ceo = request?.ceo
    this.email = request?.email
    this.fullname = request?.fullname
    this.full_name = request?.full_name
    this.id = request?.id
    this.inn = request?.inn
    this.kpp = request?.kpp
    this.name = request?.name
    this.ogrn = request?.ogrn
    this.phone = request?.phone
    this.tax_rate = request?.tax_rate
  }
}
