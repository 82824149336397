var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "d-flex justify-content-center",
    attrs: {
      "to": {
        name: 'dashboard'
      }
    }
  }, [_c('div', {
    staticClass: "b-sidebar__top-logo"
  }, [_c('svg', {
    attrs: {
      "height": "29",
      "viewBox": "0 0 209 209",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M177.957 150.355L194.481 68.606H158.422L141.898 150.355H58.1029L50.98 185.545H134.795L177.957 150.355Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M104.875 131.573C120.45 131.573 133.077 119.007 133.077 103.506C133.077 88.0054 120.45 75.4393 104.875 75.4393C89.2991 75.4393 76.6726 88.0054 76.6726 103.506C76.6726 119.007 89.2991 131.573 104.875 131.573Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M66.577 58.8192H150.373L157.496 23.6293H73.6806L30.5185 58.8192L13.9949 140.588H50.0534L66.577 58.8192Z",
      "fill": "black"
    }
  })])]), _c('div', {
    staticClass: "b-sidebar__top-title"
  }, [_c('svg', {
    attrs: {
      "height": "29",
      "viewBox": "0 0 1080 200",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.90002 83.9C14.4 60 33.1 48.5 62.5 48.5C97.9 48.5 119.1 69 119.1 102.4C119.1 135.8 97.7 156.3 62.5 156.3C31.1 156.3 13.7 144.1 8.40002 119.9L36.5 113.1C38.9 125.3 46.7 129.9 62.4 129.9C77.6 129.9 86.5 124.8 89.6 113.3H49.4V90H89.3C85.9 79.4 77.1 74.8 62.6 74.8C47.7 74.8 39.6 79.2 37 90.7L8.90002 83.9Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M136.3 50.5H165.7V84.8H210.9V50.5H240.3V154.1H210.9V113H165.5V154.1H136.3V50.5Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M288.9 78.8H253.2V50.5H354.3V78.8H318.4V154.3H289V78.8H288.9Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M361.6 102.4C361.6 70.2 382.6 48.5 418 48.5C451 48.5 473 67.3 473 98.9C473 103.5 472.6 106.8 471.9 110.6H390.8C393.3 123.4 402.4 129.8 418.6 129.8C433.7 129.8 441.5 126 444 117.9L472.5 124.1C466.5 145.3 451.1 156.3 418.4 156.3C383.2 156.3 361.6 135.8 361.6 102.4ZM391.4 90.9H443.8C441.3 79 432.5 74.1 418.2 74.1C403 74.1 394.5 79.5 391.4 90.9Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M518.8 137.6V191.9H489.4V50.5H515.9L517.7 68.8C525.9 56.2 539.5 48.5 555.6 48.5C584.4 48.5 602.4 69.1 602.4 102.4C602.4 135.7 584.6 156.3 555.6 156.3C540 156.3 527.3 149.1 518.8 137.6ZM572.5 102.4C572.5 85.6 563.8 76.5 546.4 76.5C528.6 76.5 518.8 86 518.8 102.4C518.8 119 528.6 128.3 546.4 128.3C563.8 128.3 572.5 119 572.5 102.4Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M694.2 94.2C691.7 82 684 75.9 669.7 75.9C651.9 75.9 643.6 84.7 643.6 102.2C643.6 119.7 651.9 128.5 669.7 128.5C684.8 128.5 692.2 122.7 694.4 109.3L723.2 115.9C718.3 141.7 699.3 156.1 669.7 156.1C634.5 156.1 613.6 136 613.6 102.2C613.6 68.4 634.5 48.3 669.7 48.3C699.1 48.3 717.8 61.6 723.2 87.2L694.2 94.2Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M783.1 48.5C816.7 48.5 831.4 61.1 831.4 88.3V123.6C831.4 128.2 832.9 130 836.5 130C837.6 130 839 129.8 840.1 129.6L839.6 153.7C835.4 155.3 830.5 156.3 826.4 156.3C814.1 156.3 807 150.1 805 137.7C796.3 149.6 782.3 156.3 765.3 156.3C743.2 156.3 730.1 144.6 730.1 125.1C730.1 105.6 742.1 95.5 769.8 92.8L804.1 89.3C803.6 78.5 797.6 73.8 782.9 73.8C768.7 73.8 762.4 78.2 761.1 87.5L732.8 81.8C737.8 59.1 753.5 48.5 783.1 48.5ZM771.9 131.8C790.4 131.8 804.2 122.3 804.2 109.9V109.4L768.1 113.4C761.4 114.1 758.1 117.4 758.1 122.7C758.3 128.9 762.4 131.8 771.9 131.8Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M854.2 50.5H883V111.9L928.7 50.5H957.5V154.1H928.7V92.9L883 154.1H854.2V50.5ZM943.7 8.10001C943.7 29.7 928.5 41.7 906.5 41.7C884.5 41.7 869.5 29.6 869.5 8.10001H894.4C894.4 16 898.8 20.7 906.6 20.7C914.6 20.7 918.8 15.9 918.8 8.10001H943.7Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M1006.3 78.8H970.6V50.5H1071.7V78.8H1035.8V154.3H1006.4V78.8H1006.3Z",
      "fill": "black"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }