<template>
  <b-modal
    id="onboarding-modal"
    class="dis-select"
    no-close-on-backdrop
    no-close-on-esc
    centered
    size="xl"
    hide-footer
    hide-header
  >
    <div class="center p-4 main-container dis-select">
      <img
        src="/img/tour/wellcum_drink.svg"
        class="dis-select"
      />
      <h3>Добро пожаловать в Энтерсайт!</h3>
      <span class="text-welcome mb-5"
        >Чтобы вам было проще начать работу мы подготовили набор <br />
        стартовых настроек для скорейшего начала работы</span
      >
      <br />
      <b-button
        class="m-4 p-4"
        variant="primary"
        @click="goStart"
      >
        <div style="font-size: 16px">
          <img
            src="/img/tour/flag.svg"
            class="mr-4"
          />Быстрый старт
        </div>
      </b-button>
      <div>
        <span
          class="underline-text"
          @click="hideModal"
          >Разберусь самостоятельно</span
        >
        /
        <span class="underline-text">Нужна помощь менеджера</span>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'OnboardingModal',
    methods: {
      ...mapActions({
        setTour: 'core/setTourStatus'
      }),
      goStart() {
        this.$router.push('start')
      },
      async hideModal() {
        await this.setTour({ ...this.tour, tour_modal: false })
        this.$bvModal.hide('onboarding-modal')
      }
    },
    mounted() {
      this.$bvModal.show('onboarding-modal')
    },
    computed: {
      ...mapGetters({
        tour: 'core/getTour'
      })
    }
  }
</script>

<style scoped>
  .dis-select > * {
    user-select: none;
  }

  .text-welcome {
    color: black;
    font-size: 18px;
  }

  .underline-text {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: #888888;
  }
</style>
