<template>
  <div class="container-tag">
    <div class="container-tag__head">
      <div class="date">{{ date }}</div>
      <div class="entity">{{ companyName }}</div>
    </div>
    <div class="container-tag__product-name">
      {{ product?.print_name || product?.name }}
    </div>
    <div class="container-tag__price-block">
      <div
        v-if="product?.promotion?.old_price"
        class="price-content-old"
      >
        <div class="price-old">{{ priceMainPrev }}</div>
        <old-price-line-svg />
        <div class="kopecks-block-old">
          <div class="kopecks-old">{{ pricePartPrev }}</div>
          <div class="measurement-old">
            {{ '₽/' + measurement }}
          </div>
        </div>
      </div>

      <div class="price-content-current">
        <div class="price-current">{{ priceMain ?? '-' }}</div>
        <div class="kopecks-block">
          <div class="kopecks">{{ pricePart }}</div>
          <div class="measurement">₽/{{ measurement }}</div>
        </div>
      </div>
      <div class="price-qr">
        <qrcode
          :value="qrcode"
          size="71"
        />
      </div>
    </div>
    <div class="container-tag__articul-content">
      <div class="container-tag__barcode-block">
        <barcode-generator
          :barcode="getBarcode(product)"
          :height="83"
          :width="216"
        />
      </div>

      <div class="container-tag__product-info">
        <div>{{ product?.country?.name ?? '' }}</div>
        <div>{{ product?.manufacturer?.name ?? '' }}</div>
        <div class="container-tag__product-info-articul-and-plu">
          <div class="container-tag__articul">{{ product?.articul }}</div>

          <div
            v-if="product?.plu"
            class="manufacturer"
          >
            <div class="plu-title">PLU:</div>
            <div
              v-for="(item, idx) of getPluArr"
              :key="idx"
              class="plu-content"
            >
              <div class="information__plu-text">{{ item }}</div>
              <div
                v-if="getPluArr[idx + 1]"
                class="plu-delimiter"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Qrcode from 'qrcode.vue'
  import { ProductModel } from '@/models/product.model'
  import { mapGetters } from 'vuex'
  import formatDate from '@/utils/formatDate'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  import OldPriceLineSvg from '@/views/core/modal/price-tag/svg/OldPriceLineSvg.vue'

  export default {
    name: 'Tag6x6',
    components: {
      BarcodeGenerator,
      Qrcode,
      OldPriceLineSvg
    },
    props: {
      product: {
        type: ProductModel,
        default: () => new ProductModel()
      },
      weight_format: {
        type: String,
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return entity_name(this.product.entity) || entity_name(this.getCurrentBranch.entities?.[0]) || this.company_name
      },
      date() {
        return formatDate(new Date(), 'numeric')
      },
      qrcode() {
        const barcode = this.product?.package?.[0]?.barcode?.barcode || '-'
        const article = this.product?.articul || '-'
        const price = this.product?.retailPrice.toFixed(2) || '-'
        return `${barcode} ${article} ${price}`
      },
      priceMain() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return Math.trunc(price)
      },
      pricePart() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return (+price % 1).toFixed(2).split('.')[1]
      },
      priceMainPrev() {
        let price = this.product?.promotion?.old_price || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return Math.trunc(price)
      },
      pricePartPrev() {
        let price = this.product?.promotion?.old_price || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return (+price % 1).toFixed(2).split('.')[1]
      },
      measurement() {
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') return '100г'
        return this.product?.measurement?.name?.toLowerCase()
      },
      getPluArr() {
        const pluArr = this.product?.plu.map((el) => el.plu)
        const result = new Set(pluArr)
        return [...result]
      }
    },

    methods: {
      getKopecks() {
        if (this.product?.retailPrice) {
          const kop = (this.product?.retailPrice?.toFixed(2) + '').split('.')?.[1]
          if (kop.length === 2) {
            return kop
          } else if (kop.length === 1) return kop + '0'
        }
        return '00'
      },
      getKopecsOld() {
        return 1 >= this.product?.promotion?.old_price?.toFixed(2)?.toString().split('.').length
          ? '00'
          : this.product?.promotion?.old_price?.toFixed(2)?.toString().split('.')[1]
      },
      getBarcode(product) {
        let barcode = product.package?.[0]?.barcode?.barcode
        if (['КГ.'].includes(product.measurement?.name)) {
          barcode += '01000'
          return barcode + this.getCheckSum(barcode)
        }
        return barcode
      },
      getCheckSum(ean) {
        if (!ean || ean.length !== 12) return ''
        const multiply = [1, 3]
        let total = 0
        ean.split('').forEach((letter, index) => {
          total += parseInt(letter, 10) * multiply[index % 2]
        })
        const base10Superior = Math.ceil(total / 10) * 10
        return base10Superior - total
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container-tag {
    position: absolute;
    width: 600px;
    height: 600px;
    padding: 8px;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    flex-direction: column;
    &__head {
      height: 20px;
      color: #000;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'Source Sans Pro', sans-serif;
      .date {
        margin-right: 20px;
      }
    }
    &__product-name {
      height: 235px;
      margin-top: 12px;
      color: #000;
      text-align: center;
      font-size: 46px;
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
      line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      line-height: normal;
      text-overflow: ellipsis;
    }
    &__price-block {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 76.5px;

      .price-content-old {
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;
        overflow: hidden;

        .line {
          position: absolute;
          bottom: 10px;
          left: 0;
          color: #000;
        }
      }
      .price-old {
        text-align: right;
        font-family: Source Sans Pro;
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        color: #000;
        line-height: 40px;
      }
      .kopecks-block-old {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        margin-left: 2px;

        div {
          color: #000;
          font-family: Source Sans Pro;
          font-size: 16.8px;
          font-style: normal;
          font-weight: 600;
          line-height: 23px;
        }
      }
      .price-content-current {
        display: flex;
        align-items: center;
        justify-content: end;
        .price-current {
          color: #000;
          text-align: right;
          font-size: 126.5px;
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 600;
          line-height: 90px;
          margin-left: 16px;
        }
        .kopecks-block {
          margin-right: 15px;
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          .kopecks {
            color: #000;
            font-size: 54.5px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            line-height: 86.7%;
          }
          .measurement {
            color: #000;
            font-size: 35.5px;
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            line-height: 86.7%;
          }
        }
      }
      .price-qr {
        margin-top: 5px;
      }
    }

    &__articul-content {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: auto;
    }
    &__barcode-block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
    &__product-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      div {
        color: #000;
        text-align: left;
        font-size: 22px;
        font-family: 'Source Sans Pro', sans-serif;
        line-height: normal;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &__product-info-articul-and-plu {
      display: flex;
      align-items: center;
      justify-content: start;

      div {
        width: min-content;
      }
    }
    &__articul {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-right: 8px;
      color: #000;
      font-size: 18px;
      font-family: 'Source Sans Pro', sans-serif;
    }
  }

  .manufacturer {
    display: flex !important;
  }

  .plu-content {
    display: flex;
    margin-right: 5px;
    align-items: center;
  }

  .plu-delimiter {
    height: 4px !important;
    width: 4px !important;
    border-radius: 50%;
    background-color: #000000;
    margin-left: 5px;
  }

  .plu-title {
    margin-right: 4px;
  }
</style>
