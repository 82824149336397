import { BranchModel } from '@/models/branch.model'
import { CategoryModel } from '@/models/category.model'
import { ProductModel } from '@/models/product.model'

export class PropsModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request.branch)
    this.category = new CategoryModel(request.category)
    this.id = request.id
    this.name = request.name
    this.product = new ProductModel(request.product)
    this.value = request.value
  }
}
