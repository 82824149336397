import gql from 'graphql-tag'

export const GET_COMPANIES = gql`
  query Companies {
    Companies {
      id
      name
    }
  }
`
