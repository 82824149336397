import { IncomingSpecificationModel } from '@/models/incoming-spec.model'

export const getters = {
  getCurrentDocument: (state) => state.current_document || {},
  getDateRange: (state) => state.date_range,
  getShowingTooltipStatus: (state) => state.showing_input_tooltip,
  getIncomingPagination: (state) => state.incoming.pagination,
  getIncomingDocument: (state) => state.incoming.document,
  getIncomingSpecs: (state) =>
    state.incoming.specs?.map((el) => {
      return new IncomingSpecificationModel(el, state.incoming?.document?.operation)
    }),
  getIncomingCursor: (state) => state.incoming.cursor
}
