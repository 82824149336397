var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "help-modal",
      "title": "Обращение в поддержку",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.help
          }
        }, [_vm._v(" Отправить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Опишите вашу проблему"
    },
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }