export const state = {
  current_supplier: {
    entity_data: {},
    entity_id: '',
    supplier_id: '',
    contract: [],
    current_tab: 0,
    is_edit: false
  },
  banks: {
    table: [],
    isLoaded: false
  },
  suppliers: {
    table: [],
    isLoaded: false
  },
  persons: {
    table: [],
    isLoaded: false
  },
  treaty: []
}
