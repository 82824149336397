import Vue from 'vue'
import { locale, Page } from 'view-design'
import lang from 'view-design/dist/locale/ru-RU'
import VCalendar from 'v-calendar/lib/components/calendar.umd'
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'

locale(lang)

import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  TabPane,
  Tabs,
  Spin,
  DatePicker,
  Select,
  Option,
  OptionGroup,
  Icon,
  Upload,
  Tooltip,
  Radio,
  RadioGroup,
  Poptip,
  Progress,
  Slider,
  InputNumber,
  Collapse,
  AutoComplete,
  TimePicker,
  Switch
} from 'view-design'

Vue.component('VCalendar', VCalendar)
Vue.component('VDatePicker', VDatePicker)
Vue.component('IInput', Input)
Vue.component('IButton', Button)
Vue.component('IButtonGroup', ButtonGroup)
Vue.component('ISelect', Select)
Vue.component('ISwitch', Switch)
Vue.component('IOption', Option)
Vue.component('IOptionGroup', OptionGroup)

Vue.component('Checkbox', Checkbox)
Vue.component('CheckboxGroup', CheckboxGroup)
Vue.component('Dropdown', Dropdown)
Vue.component('DropdownMenu', DropdownMenu)
Vue.component('DropdownItem', DropdownItem)
Vue.component('Tabs', Tabs)
Vue.component('TabPane', TabPane)
Vue.component('Spin', Spin)
Vue.component('DatePicker', DatePicker)
Vue.component('Icon', Icon)
Vue.component('Upload', Upload)
Vue.component('Tooltip', Tooltip)
Vue.component('RadioGroup', RadioGroup)
Vue.component('Radio', Radio)
Vue.component('Poptip', Poptip)
Vue.component('IProgress', Progress)
Vue.component('Slider', Slider)
Vue.component('InputNumber', InputNumber)
Vue.component('Collapse', Collapse)
Vue.component('CollapsePanel', Collapse.Panel)
Vue.component('AutoComplete', AutoComplete)
Vue.component('TimePicker', TimePicker)
Vue.component('Page', Page)
