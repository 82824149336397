import { ProductModel } from '@/models/product.model'

export class CompoundProductModel {
  constructor(request) {
    this.id = request.id
    this.value = request.value || 0
    this.gross = request.gross || 0
    this.net = request.net || 0
    this.price = request.price || 0
    this.sum = request.sum || 0
    this.product = new ProductModel(request.product)
  }

  setGross = (val) => {
    this.gross = val
    this.net = val
  }

  setNet = (val) => {
    this.net = val
  }

  setNetPer = (val) => {
    this.net = this.gross * (val / 100)
  }

  get net_per() {
    return (this.net / this.gross) * 100
  }

  get input() {
    return {
      ...this,
      product: this.product.id
    }
  }
}
