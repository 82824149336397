import View from '@/views/layouts/layout-view'

export default {
  path: '/ext-systems',
  name: 'extsystems',
  component: View,
  // redirect: '/ext-systems/list',
  children: [
    {
      path: 'list',
      name: 'extsystems.list',
      component: () => import('@/views/external-systems/pages/ExternalSystems'),
      meta: { title: 'Внешние системы' }
    },
    {
      path: 'mercury',
      name: 'mercury',
      component: () => import('@/views/external-systems/pages/Mercury'),
      meta: {
        title: 'Меркурий'
      }
    },
    {
      path: 'egais',
      name: 'egais',
      component: () => import('@/views/external-systems/pages/Egais'),
      meta: {
        title: 'ЕГАИС'
      }
    },
    {
      path: 'egais/document/:id',
      name: 'egais.document.id',
      component: () => import('@/views/external-systems/pages/Specifications'),
      meta: {
        title: 'ТТН'
      }
    }
  ]
}
