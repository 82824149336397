var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['e-switch', _vm.position],
    style: _vm.background_color,
    on: {
      "click": _vm.click
    }
  }, [_c('div', {
    staticClass: "control"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }