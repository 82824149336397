import { ProductModel } from '@/models/product.model'
import { BarcodeModel } from '@/models/barcode.model'

export class PackageModel {
  constructor(request = {}) {
    this.barcode = request?.barcode ? new BarcodeModel(request?.barcode) : null
    this.id = request?.id
    this.product = new ProductModel(request?.product)
    this.value = request?.value
    this.name = `Упаковка 1*${request?.value}`
  }
}
