import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'

export const state = {
  current_document: {
    id: '',
    fixed: '',
    number: '',
    date: ''
  },
  date_range: '',
  incoming: {
    pagination: {
      skip: 0,
      take: 20,
      search: '',
      order: [{ key: 'order', value: 'ASC' }]
    },
    cursor: {
      next: '',
      prev: '',
      height: 0
    },
    document: new IncomingHeadModel(),
    specs: []
  }
}
