import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import breadcrumbs from './breadcrumbs'
import { core } from '@/views/core/store'
import { contractors } from '@/views/contractors/store'
import { dashboard } from '@/views/dashboard/store'
import { settings } from '@/views/settings/store'
import { products } from '@/views/products/store'
import { operprocess } from '@/views/operational-processes/store'
import { promotion } from '@/views/operational-processes/pages/promo/store'
import { incoming } from '@/views/operational-processes/pages/incoming/store'
import { outgoing } from '@/views/operational-processes/pages/outgoing/store'
import { movement } from '@/views/operational-processes/pages/movement/store'
import { analytics } from '@/views/analytics/store'
import { cashless } from '@/views/operational-processes/pages/cashless/store'
import { invoice } from '@/views/operational-processes/pages/invoice/store'
import { payment } from '@/views/payment/store'
import { marketing } from '@/views/marketing/store'
import { contracts } from '@/views/operational-processes/pages/contract/store'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    breadcrumbs,
    contractors,
    core,
    dashboard,
    settings,
    products,
    operprocess,
    incoming,
    outgoing,
    movement,
    analytics,
    promotion,
    payment,
    cashless,
    invoice,
    marketing,
    contracts
  },
  plugins: [
    createPersistedState({
      key: 'entersight'
    })
  ]
})
