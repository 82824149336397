import View from '@/views/layouts/layout-view'

export default {
  path: '/marketing',
  name: 'marketing',
  component: View,
  meta: {
    auth: true,
    title: 'Маркетинг',
    submenu: true
  },
  children: [
    {
      path: 'discount-cards',
      name: 'discount-cards',
      component: () => import('@/views/marketing/pages/DiscountCards'),
      meta: {
        title: 'Дисконтные карты'
      }
    },
    {
      path: 'segments',
      name: 'segments',
      component: () => import('@/views/marketing/pages/Segments'),
      meta: {
        title: 'Сегменты'
      }
    },
    {
      path: 'segment-cards',
      name: 'segment-cards',
      component: () => import('@/views/marketing/pages/SegmentCards'),
      meta: {
        title: 'Карты сегмента'
      }
    }
  ]
}
