var render = function render(){
  var _vm$product, _vm$product2, _vm$product3, _vm$product3$name, _vm$product4, _vm$product5, _vm$product6, _vm$product6$measurem, _vm$product7, _vm$product7$package, _vm$product7$package$, _vm$product7$package$2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-cont"
  }, [_c('div', {
    staticClass: "header-label",
    class: _vm.format === '6x4' ? 'format-6x4' : '',
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "align-items": "center",
      "justify-content": "space-between",
      "width": "100%"
    }
  }, [_c('span', {
    staticClass: "artic frst",
    class: _vm.format === '6x4' ? 'artic-6x4' : ''
  }, [_vm._v(" " + _vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.articul) + " ")]), _c('div', {
    staticClass: "artic company",
    class: _vm.format === '6x4' ? 'artic-6x4 artic-6x4-name' : ''
  }, [_vm._v(" " + _vm._s(_vm.companyName) + " ")])]), _c('div', {
    staticClass: "name-block",
    class: [_vm.format === '6x4' && 'name-6x4'],
    style: _vm.styleName
  }, [_c('span', {
    staticClass: "prd-name",
    class: _vm.format === '6x4' ? 'prd-name-6x4' : ''
  }, [_vm._v(" " + _vm._s((_vm$product2 = _vm.product) !== null && _vm$product2 !== void 0 && _vm$product2.print_name ? _vm.product.print_name : ((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$name = _vm$product3.name) === null || _vm$product3$name === void 0 ? void 0 : _vm$product3$name.length) > (_vm.format !== '6x4' ? 41 : 110) ? ((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : _vm$product4.name.slice(0, _vm.format !== '6x4' ? 38 : 100)) + '...' : (_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : _vm$product5.name) + " ")])]), _c('div', {
    class: [_vm.format === '6x4' && 'd-flex position-relative align-items-center footer-6x4']
  }, [_vm.show_price ? _c('div', {
    staticClass: "price-label"
  }, [_vm.show_price ? _c('div', {
    staticClass: "price-label__full"
  }, [_vm._v(" " + _vm._s(_vm.getPrice) + " ")]) : _vm._e(), _vm.show_price ? _c('div', {
    staticClass: "price-label__kop"
  }, [_c('p', [_vm._v(_vm._s(_vm.getKopecks))]), _c('p', {
    staticClass: "text-nowrap"
  }, [_vm._v("₽/" + _vm._s((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : (_vm$product6$measurem = _vm$product6.measurement) === null || _vm$product6$measurem === void 0 ? void 0 : _vm$product6$measurem.name))])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "barcode-block"
  }, [_c('div', {
    staticClass: "barcode",
    staticStyle: {
      "align-self": "center"
    }
  }, [(_vm$product7 = _vm.product) !== null && _vm$product7 !== void 0 && (_vm$product7$package = _vm$product7.package) !== null && _vm$product7$package !== void 0 && (_vm$product7$package$ = _vm$product7$package[0]) !== null && _vm$product7$package$ !== void 0 && (_vm$product7$package$2 = _vm$product7$package$.barcode) !== null && _vm$product7$package$2 !== void 0 && _vm$product7$package$2.barcode ? [_c('barcode-generator', {
    attrs: {
      "barcode": _vm.getBarcode(_vm.product),
      "height": 60,
      "width": _vm.format === '6x4' ? 140 : 230
    }
  })] : _vm._e()], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }