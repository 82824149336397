import { PersonModel } from '@/models/person.model'
import { BranchModel } from '@/models/branch.model'

export class ContractModel {
  constructor(request = {}) {
    this.begin_date = new Date(request?.begin_date)
    this.branch = request?.branch?.map((el) => new BranchModel(el))
    this.createddate = new Date(request?.createddate)
    this.deferment = request?.deferment
    // this.delivery_schedule = new DeliveryScheduleModel(request?.delivery_schedule)
    this.end_date = new Date(request?.end_date)
    this.external_date = new Date(request?.external_date)
    this.external_number = request?.external_number
    this.id = request?.id
    this.name = request?.name
    this.number = request?.number
    this.person = request?.person?.map((el) => new PersonModel(el))
  }
}
