<template>
  <div class="b-sidebar__user">
    <b-dropdown
      variant="link"
      left
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <div>
          <img
            :src="userAvatar"
            :alt="fullName"
          />
        </div>
        <div
          class="b-sidebar__user-info"
          style="margin-left: 14px"
        >
          <div class="name">{{ fullName }}</div>
          <!-- <div class="email">{{ getUserInfo.email }}</div> -->
        </div>
      </template>
      <!-- <template #button-content>
        <img src="/img/icons/sidebar/dots_more.svg" alt="" />
      </template> -->
      <b-dropdown-item @click="goSettings">
        <img
          class="img"
          width="16"
          height="16"
          src="/img/icons/sidebar/settings.svg"
          alt=""
        />
        Личные настройки
      </b-dropdown-item>
      <b-dropdown-item @click="logoutHandler">
        <img
          class="img"
          src="/img/icons/exit-icon.svg"
          alt=""
        />
        Выход
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        getUserInfo: 'core/getUserInfo'
      }),

      fullName: {
        get() {
          const firstName = this.getUserInfo.first_name ? this.getUserInfo.first_name : ''
          const lastName = this.getUserInfo.last_name ? ' ' + this.getUserInfo.last_name : ''

          return firstName + lastName
        }
      },

      userAvatar: {
        get() {
          const link = this.getUserInfo.avatar ? this.getUserInfo.avatar : '/img/user/no-photo.svg'

          return link
        }
      }
    },

    created() {
      this.get_user_info()
    },

    methods: {
      ...mapActions({
        logout: 'core/logout',
        clear_settings: 'settings/clear_settings_storage',
        get_user_info: 'core/getUserInfo'
      }),

      goSettings() {
        this.$router.push({ name: 'user.edit', params: { id: this.getUserInfo.id } })
      },

      logoutHandler() {
        this.logout()
        this.clear_settings()
        location.reload()
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .btn-group .dropdown-menu {
    width: 183px !important;
  }
  ::v-deep() {
    .btn-group {
      display: flex;
      width: 100%;

      button.btn {
        display: flex;
        padding: 0;
        align-items: center;
      }

      .dropdown-menu {
        width: 100%;
        transform: unset !important;
        inset: unset !important;
        bottom: 50px !important;
      }
    }
  }
  .b-sidebar__user-info .name {
    font-family: 'Gilroy-Medium', sans-serif;
    font-weight: 500 !important;
    color: #313131;
  }

  .img {
    margin-right: 5px;
  }
</style>
