var render = function render(){
  var _vm$product, _vm$product2, _vm$product3, _vm$product$country$n, _vm$product4, _vm$product4$country, _vm$product$manufactu, _vm$product5, _vm$product5$manufact, _vm$product6, _vm$product$plu$0$plu, _vm$product7, _vm$product7$plu, _vm$product7$plu$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-tag"
  }, [_c('div', {
    staticClass: "container-tag__head"
  }, [_c('div', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.date))]), _c('div', {
    staticClass: "entity"
  }, [_vm._v(_vm._s(_vm.companyName))])]), _c('div', {
    staticClass: "container-tag__product-name"
  }, [_vm._v(" " + _vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.print_name) || ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.name)) + " ")]), _c('div', {
    staticClass: "container-tag__price-block"
  }, [_c('div', {
    staticClass: "price-content"
  }, [_c('div', {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm.priceMain))]), _c('div', {
    staticClass: "kopecks-block"
  }, [_c('div', {
    staticClass: "kopecks"
  }, [_vm._v(_vm._s(_vm.pricePart))]), _c('div', {
    staticClass: "measurement"
  }, [_vm._v("₽/" + _vm._s(_vm.measurement))])])]), _c('div', {
    staticClass: "price-qr"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.qrcode,
      "size": "64"
    }
  })], 1)]), _c('div', {
    staticClass: "container-tag__articul"
  }, [_vm._v(_vm._s((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.articul))]), _c('div', {
    staticClass: "container-tag__barcode-block"
  }, [_c('barcode-generator', {
    attrs: {
      "barcode": _vm.getBarcode(_vm.product),
      "height": 72,
      "width": 165
    }
  })], 1), _c('div', {
    staticClass: "container-tag__product-info"
  }, [_c('div', [_vm._v("Страна: " + _vm._s((_vm$product$country$n = (_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$country = _vm$product4.country) === null || _vm$product4$country === void 0 ? void 0 : _vm$product4$country.name) !== null && _vm$product$country$n !== void 0 ? _vm$product$country$n : '-'))]), _c('div', [_vm._v("Прозводитель: " + _vm._s((_vm$product$manufactu = (_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$manufact = _vm$product5.manufacturer) === null || _vm$product5$manufact === void 0 ? void 0 : _vm$product5$manufact.name) !== null && _vm$product$manufactu !== void 0 ? _vm$product$manufactu : '-'))]), (_vm$product6 = _vm.product) !== null && _vm$product6 !== void 0 && _vm$product6.plu ? _c('div', {
    staticClass: "manufacturer"
  }, [_c('div', {
    staticClass: "plu-title"
  }, [_vm._v("PLU:")]), _vm._l(_vm.getPluArr, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "plu-content"
    }, [_c('div', {
      staticClass: "information__plu-text"
    }, [_vm._v(_vm._s(item))]), _vm.getPluArr[idx + 1] ? _c('div', {
      staticClass: "plu-delimiter"
    }) : _vm._e()]);
  })], 2) : _c('div', [_vm._v("PLU: " + _vm._s((_vm$product$plu$0$plu = (_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : (_vm$product7$plu = _vm$product7.plu) === null || _vm$product7$plu === void 0 ? void 0 : (_vm$product7$plu$ = _vm$product7$plu[0]) === null || _vm$product7$plu$ === void 0 ? void 0 : _vm$product7$plu$.plu) !== null && _vm$product$plu$0$plu !== void 0 ? _vm$product$plu$0$plu : '-'))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }