import View from '@/views/layouts/layout-view'

export default {
  path: '/integrations',
  name: 'integrations',
  component: View,
  redirect: '/integrations/list',
  meta: {
    auth: true
  },
  children: [
    {
      path: 'list',
      name: 'integrations.list',
      component: () => import('@/views/integrations/pages/Integrations'),
      meta: { title: 'Интеграции' }
    }
  ]
}
