var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fragment', [_c('div', {
    staticClass: "wrapper d-flex"
  }, [_c('sidebar'), _c('div', {
    staticClass: "wrapper__content"
  }, [!_vm.getIsConfirmed && _vm.user.id ? _c('confirm-email-alert') : _vm._e(), _vm.isEnds ? _c('pay-subscription-alert') : _vm._e(), _c('breadcrumbs'), _c('div', {
    staticClass: "wrapper__inner"
  }, [_c('router-view')], 1)], 1)], 1), _c('portal-target', {
    attrs: {
      "id": "print-price-tags",
      "name": "clear"
    }
  }), _c('portal-target', {
    attrs: {
      "id": "print-labels",
      "name": "dirty"
    }
  }), _c('portal-target', {
    attrs: {
      "id": "print-badge",
      "name": "badge-print"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }