var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-sidebar__user"
  }, [_c('b-dropdown', {
    attrs: {
      "variant": "link",
      "left": "",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', [_c('img', {
          attrs: {
            "src": _vm.userAvatar,
            "alt": _vm.fullName
          }
        })]), _c('div', {
          staticClass: "b-sidebar__user-info",
          staticStyle: {
            "margin-left": "14px"
          }
        }, [_c('div', {
          staticClass: "name"
        }, [_vm._v(_vm._s(_vm.fullName))])])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.goSettings
    }
  }, [_c('img', {
    staticClass: "img",
    attrs: {
      "width": "16",
      "height": "16",
      "src": "/img/icons/sidebar/settings.svg",
      "alt": ""
    }
  }), _vm._v(" Личные настройки ")]), _c('b-dropdown-item', {
    on: {
      "click": _vm.logoutHandler
    }
  }, [_c('img', {
    staticClass: "img",
    attrs: {
      "src": "/img/icons/exit-icon.svg",
      "alt": ""
    }
  }), _vm._v(" Выход ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }