import { AddressModel } from '@/models/address.model'
import { UserModel } from '@/models/user.model'
import { StorageModel } from '@/models/storage.model'
import { CompanyModel } from '@/models/company.model'
import { CashModel } from '@/models/cash.model'
import { BranchEntityModel } from '@/models/branch-entity.model'

export class BranchModel {
  constructor(request = {}) {
    this.address = new AddressModel(request?.address)
    this.cash = request?.cash?.map((el) => new CashModel(el))
    this.company = new CompanyModel(request?.company)
    this.entities = request?.entities?.map((el) => new BranchEntityModel(el)) || []
    this.id = request?.id
    this.kpp = request?.kpp
    this.name = request?.name
    this.storage = request?.storage?.map((el) => new StorageModel(el))
    this.users = request?.users?.map((el) => new UserModel(el))
  }
}
