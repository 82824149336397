<template>
  <section class="analytics">
    <div class="dashboard__title">Аналитика</div>
    <div class="d-flex">
      <analytics-widget
        class="mr-3"
        :on_click="openCashSales"
        :data="{
          name: 'Выручка за ',
          value: analytics.revenue,
          date,
          currency: true,
          returns: analytics.returns,
          payment_types: analytics.payment_types ?? []
        }"
        :click_more="showRevenueMore"
        :show_drop_down="revenueMore"
      />
      <analytics-widget
        class="mr-3"
        :data="{
          name: 'Количество чеков за ',
          value: analytics.quantity,
          date,
          currency: false
        }"
      />
      <analytics-widget
        class="mr-3"
        :data="{
          name: 'Средний чек за ',
          value: analytics.average,
          date,
          currency: true
        }"
      />
      <analytics-widget
        class="mr-3"
        :on_click="onClick"
        :data="{
          name: 'Маржинальная прибыль за ',
          value: margin,
          date,
          currency: true
        }"
      />
      <abc-analytics-widget
        :on_click="onClick"
        :data="{ name: 'ABC - анализ,' }"
      />
    </div>
  </section>
</template>

<script>
  import AnalyticsWidget from '@/views/dashboard/components/AnalyticsWidget'
  import { mapGetters } from 'vuex'
  import * as R from 'ramda'
  import AbcAnalyticsWidget from '@/views/dashboard/components/AbcAnalyticsWidget'

  export default {
    components: { AbcAnalyticsWidget, AnalyticsWidget },

    apollo: {
      CheckAnalytics: {
        query: require('../gql/getAnalytics.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          const begin = new Date()
          const end = new Date()
          begin.setDate(begin.getDate() - 1)
          begin.setHours(0, 0, 0)
          end.setDate(end.getDate() - 1)
          end.setHours(23, 59, 59)
          this.date = begin
          return {
            branch: this.currentBranch.id,
            period: {
              begin,
              end
            }
          }
        },
        result({ data }) {
          this.analytics = { ...this.analytics, ...data.CheckAnalytics }
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      },

      Margin: {
        query: require('../gql/getMargin.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          const begin = new Date()
          const end = new Date()
          begin.setDate(begin.getDate() - 1)
          begin.setHours(0, 0, 0)
          end.setDate(end.getDate() - 1)
          end.setHours(23, 59, 59)
          this.date = begin
          return {
            branch: this.currentBranch.id,
            period: {
              begin,
              end
            }
          }
        },
        result({ data }) {
          this.margin = R.pathOr(0, ['Margin', 'margin'], data)
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },

    data: function () {
      return {
        date: null,
        analytics: {
          returns: 0,
          average: 0,
          quantity: 0,
          revenue: 0,
          payment_types: []
        },
        margin: 0,
        revenueMore: false
      }
    },

    methods: {
      onClick() {
        this.$router.push({ name: 'analytics.trading-margins.report', params: {date: this.date} })
      },
      openCashSales() {
        this.$router.push({ name: 'analytics.cash-sales.report' })
      },
      showRevenueMore(val) {
        if (val != null) {
          this.revenueMore = val
        } else {
          this.revenueMore = !this.revenueMore
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  img {
    max-height: 100%;
  }
</style>
