var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "products__menu"
  }, [_c('div', {
    staticClass: "products__menu-title d-flex align-items-center"
  }, [_vm._v("Группы товаров")]), _vm.block_add_group ? [_c('div', {
    staticClass: "p-2"
  }, [_c('add-group', {
    on: {
      "closeAddGroup": _vm.closeAddGroup,
      "groupAddedSuccessfully": _vm.groupAddedSuccessfully
    }
  })], 1)] : _vm._e(), _vm.menu_is_loading ? _c('div', {
    staticClass: "products__menu-no-goups center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  })], 1) : _vm._e(), _vm.menu_arr && _vm.menu_arr.length > 0 && !_vm.menu_is_loading ? [_c('div', {
    staticClass: "b-search-result"
  }, [_c('menu-item', {
    attrs: {
      "close_add_group": _vm.closeAddGroup,
      "items": _vm.menu_arr,
      "open_group": _vm.openGroup,
      "update_menu": _vm.groupAddedSuccessfully,
      "modal_remove": _vm.openModalRemove,
      "current_ids": _vm.current_ids,
      "hide_control": ""
    },
    on: {
      "close_add_form": _vm.closeAddGroup
    }
  })], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }