export const mutations = {
  SET_COMPANIES(state, companies) {
    state.companies = companies
  },

  SET_CURRENT_PRINTER_DEVICE(state, date) {
    state.currentPrinterDevice = date
  },

  INITIAL_STATE(state) {
    state.branch = {
      list: [],
      current: {}
    }
    state.pagination = {
      ['incoming-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['outgoing-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['movement-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['cashless-table']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['invoice-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['orders-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['revaluations-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['inv-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['equipments-docs']: {
        skip: 0,
        take: 20,
        search: '',
        current_page: 1
      },
      ['products']: {
        skip: 0,
        take: 20,
        current_page: 1
      }
    }
  },

  SET_CURRENT_BRANCH(state, branch) {
    state.branch.current = branch
  },

  SET_BRANCHES(state, branch_list) {
    state.branch.list = branch_list
  },

  SET_SELECT_BRANCH(state, branch) {
    state.branch.current = branch
  },

  CLEAR_SETTINGS_STORAGE(state) {
    state.branch = {}
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = pagination
  },

  SET_BRANCH_CLASSIF_LIST(state, list) {
    state.branchClassifList = list
  },

  SET_CURRENT_BRANCH_CLASSIF_LIST(state, data) {
    state.currentBranchClassifList = data
  }
}
