<template>
  <svg
    class="line__logo"
    width="14"
    height="2"
    viewBox="0 0 14 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.177734 1.9628V0.0146484H13.8148V1.9628H0.177734Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'RightLine200on280Svg'
  }
</script>
<style scoped lang="scss"></style>
