import { render, staticRenderFns } from "./SeizeMoment.vue?vue&type=template&id=407608dc&scoped=true&"
import script from "./SeizeMoment.vue?vue&type=script&lang=js&"
export * from "./SeizeMoment.vue?vue&type=script&lang=js&"
import style0 from "./SeizeMoment.vue?vue&type=style&index=0&id=407608dc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407608dc",
  null
  
)

export default component.exports