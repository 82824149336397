var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      height0: _vm.none
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('date-picker', {
    staticClass: "elm-calendar hidden-picker",
    attrs: {
      "clearable": false,
      "type": "daterange",
      "open": _vm.datepicker_open,
      "editable": false,
      "format": "dd.MM.yyyy",
      "placeholder": "Рабочий период"
    },
    on: {
      "on-change": function ($event) {
        return _vm.setPeriod({
          period: 'custom'
        });
      },
      "on-clickoutside": _vm.clickedOutside
    },
    model: {
      value: _vm.filter_date_range,
      callback: function ($$v) {
        _vm.filter_date_range = $$v;
      },
      expression: "filter_date_range"
    }
  }), _c('b-dropdown', {
    staticClass: "period_dropdown",
    class: {
      'd-none': _vm.none
    },
    attrs: {
      "no-caret": "",
      "variant": "none",
      "disabled": _vm.datepicker_open
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "content-button"
        }, [_c('span', {
          staticStyle: {
            "color": "black"
          }
        }, [_vm._v(_vm._s(_vm.getPeriod))]), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/calendar-dark.svg",
            "alt": "calendar"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.datepicker_open = true;
      }
    }
  }, [_vm._v(" Указать период ")]), _c('b-dropdown-divider'), _vm.type_doc !== 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'today'
        });
      }
    }
  }, [_vm._v(" Сегодня ")]) : _vm._e(), _vm.type_doc !== 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'yesterday'
        });
      }
    }
  }, [_vm._v(" Вчера ")]) : _vm._e(), _vm.type_doc !== 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'week'
        });
      }
    }
  }, [_vm._v(" Текущая неделя ")]) : _vm._e(), _vm.type_doc !== 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lweek'
        });
      }
    }
  }, [_vm._v(" Прошлая неделя ")]) : _vm._e(), _vm.type_doc !== 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'month'
        });
      }
    }
  }, [_vm._v(" Текущий месяц ")]) : _vm._e(), _vm.type_doc !== 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lmonth'
        });
      }
    }
  }, [_vm._v(" Прошлый месяц ")]) : _vm._e(), !['abc', 'cash'].includes(_vm.type_doc) ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: 'lyear'
        });
      }
    }
  }, [_vm._v(" Последние 365 дней ")]) : _vm._e(), _vm.type_doc === 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: '30days'
        });
      }
    }
  }, [_vm._v(" За 30 дней ")]) : _vm._e(), _vm.type_doc === 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: '90days'
        });
      }
    }
  }, [_vm._v(" За 90 дней ")]) : _vm._e(), _vm.type_doc !== 'cash' && _vm.type_doc !== 'abc' ? _c('b-dropdown-item', {
    staticClass: "sm-dropdown",
    on: {
      "click": function ($event) {
        return _vm.setPeriod({
          period: null
        });
      }
    }
  }, [_vm._v(" Все время ")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }