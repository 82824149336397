<template>
  <div
    :class="{ height0: none }"
    @click.stop
  >
    <date-picker
      v-model="filter_date_range"
      :clearable="false"
      type="daterange"
      :open="datepicker_open"
      :editable="false"
      format="dd.MM.yyyy"
      class="elm-calendar hidden-picker"
      placeholder="Рабочий период"
      @on-change="setPeriod({ period: 'custom' })"
      @on-clickoutside="clickedOutside"
    />
    <b-dropdown
      no-caret
      class="period_dropdown"
      :class="{ 'd-none': none }"
      variant="none"
      :disabled="datepicker_open"
    >
      <template #button-content>
        <div class="content-button">
          <span style="color: black">{{ getPeriod }}</span>
          <img
            src="/img/icons/calendar-dark.svg"
            alt="calendar"
            class="ml-2"
          />
        </div>
      </template>
      <b-dropdown-item
        class="sm-dropdown"
        @click.stop="datepicker_open = true"
      >
        Указать период
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        v-if="type_doc !== 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: 'today' })"
      >
        Сегодня
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc !== 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: 'yesterday' })"
      >
        Вчера
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc !== 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: 'week' })"
      >
        Текущая неделя
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc !== 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: 'lweek' })"
      >
        Прошлая неделя
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc !== 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: 'month' })"
      >
        Текущий месяц
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc !== 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: 'lmonth' })"
      >
        Прошлый месяц
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!['abc', 'cash'].includes(type_doc)"
        class="sm-dropdown"
        @click="setPeriod({ period: 'lyear' })"
      >
        Последние 365 дней
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc === 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: '30days' })"
      >
        За 30 дней
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc === 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: '90days' })"
      >
        За 90 дней
      </b-dropdown-item>
      <b-dropdown-item
        v-if="type_doc !== 'cash' && type_doc !== 'abc'"
        class="sm-dropdown"
        @click="setPeriod({ period: null })"
      >
        Все время
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'WorkPeriod',
    props: {
      type_doc: {
        type: String,
        default: ''
      },
      open: {
        type: Boolean,
        default: false
      },
      none: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        filter_date_range: [],
        selected_type: null,
        datepicker_open: false,
        moddate: new Date()
      }
    },
    watch: {
      open() {
        if (this.open) this.datepicker_open = this.open
      },
      datepicker_open() {
        if (!this.datepicker_open) this.$emit('set_open')
      }
    },
    mounted() {
      this.datepicker_open = this.open
      if (this.type_doc === 'abc') this.setPeriod({ period: '30days' })
      if (this.type_doc === 'cash') this.setPeriod({ period: 'yesterday' })
      if (this.dateRange) {
        this.filter_date_range = this.dateRange.date
        this.setPeriod({ period: this.dateRange.type })
        this.updateDateRange(this.filter_date_range)
      }
    },

    destroyed() {
      if (this.type_doc === 'abc' && (this.dateRange.type === '30days' || this.dateRange.type === '90days')) {
        this.setPeriod({ period: null })
      }
    },

    computed: {
      ...mapGetters({
        dateRange: 'operprocess/getDateRange',
        currentBranch: 'settings/getCurrentBranch'
      }),
      getPeriod() {
        switch (this.selected_type) {
          case 'today': {
            return 'Сегодня'
          }
          case 'yesterday': {
            return 'Вчера'
          }
          case 'week': {
            return 'Текущая неделя'
          }
          case 'lweek': {
            return 'Прошлая неделя'
          }
          case 'month': {
            return 'Текущий месяц'
          }
          case 'lmonth': {
            return 'Прошлый месяц'
          }
          case '30days': {
            return '30 дней'
          }
          case '90days': {
            return '90 дней'
          }
          case 'lyear': {
            return 'Последние 365 дней'
          }
          case 'custom': {
            const start = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[0] ? new Date(this.filter_date_range[0]) : new Date()
            )
            const end = new Intl.DateTimeFormat('ru-RU').format(
              this.filter_date_range[1] ? new Date(this.filter_date_range[1]) : new Date()
            )
            return start + ' - ' + end
          }
          default: {
            return 'За все время'
          }
        }
      }
    },

    methods: {
      ...mapActions({
        setDateRange: 'operprocess/setDateRange'
      }),

      clickedOutside() {
        this.datepicker_open = false
      },

      setPeriod({ period }) {
        switch (period) {
          case 'today': {
            this.filter_date_range[0] = new Date(new Date().setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date().setHours(23, 59, 59))
            break
          }
          case 'yesterday': {
            const today = new Date()
            this.filter_date_range[0] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(new Date().setDate(today.getDate() - 1)).setHours(23, 59, 59))
            break
          }
          case 'week': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() + 1
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'lweek': {
            let curr = new Date()
            let first = curr.getDate() - curr.getDay() - 6
            let last = first + 6
            this.filter_date_range[0] = new Date(new Date(curr.setDate(first)).setHours(0, 0, 0))
            this.filter_date_range[1] = new Date(new Date(curr.setDate(last)).setHours(23, 59, 59))
            break
          }
          case 'month': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth(), 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth() + 1, 0, 23, 59, 59)
            break
          }
          case 'lmonth': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth() - 1, 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth(), 0, 23, 59, 59)
            break
          }
          case '30days': {
            this.filter_date_range[0] = new Date()
            this.filter_date_range[0].setDate(this.filter_date_range[0].getDate() - 30)
            this.filter_date_range[0].setHours(0, 0, 0, 0)
            this.filter_date_range[1] = new Date()
            this.filter_date_range[1].setHours(23, 59, 59, 59)
            break
          }
          case '90days': {
            this.filter_date_range[0] = new Date()
            this.filter_date_range[0].setDate(this.filter_date_range[0].getDate() - 90)
            this.filter_date_range[0].setHours(0, 0, 0, 0)
            this.filter_date_range[1] = new Date()
            this.filter_date_range[1].setHours(23, 59, 59, 59)
            break
          }
          case 'lyear': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear() - 1, curr.getMonth(), curr.getDay(), 0, 0, 0)
            this.filter_date_range[1] = new Date()
            break
          }
          case 'custom': {
            this.datepicker_open = false
            this.moddate = new Date()
            // const today = new Date()
            // if (isMount && today.getDate() !== new Date(this.getPeriod.moddate).getDate()) {
            //   this.setPeriod({ period: null })
            // }
            break
          }
          default: {
            this.filter_date_range[0] = null
            this.filter_date_range[1] = null
            break
          }
        }
        this.selected_type = period
        this.updateDateRange()
        this.$emit('select')
      },

      updateDateRange() {
        this.setDateRange({
          type: this.selected_type,
          date: this.filter_date_range,
          moddate: this.moddate
        })
        this.$emit('date_range', this.filter_date_range)
      }
    }
  }
</script>

<style scoped lang="scss">
  img {
    max-width: none;
  }

  ::v-deep() {
    .datepicker-range {
      min-width: 210px;
    }

    .hidden-picker > .ivu-date-picker-rel {
      max-width: 0px !important;
    }

    .hidden-picker > div > div > span {
      display: none;
    }

    .sm-dropdown > a {
      padding: 4px 12px !important;
    }

    .hidden-picker > div > div > input {
      display: none;
    }
  }

  .hidden-picker {
    max-width: 0px !important;
  }

  .height0 {
    height: 0;
  }

  .content-button {
    width: 100%;
    justify-content: space-between;
    background: white;
    padding: 8px 10px;
    display: flex;
    flex-direction: row;
    height: 33.48px;
    border: 1px solid #bcbcbc;
  }
  .period_dropdown {
    width: 100%;
  }
</style>
