export const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = filters
  },

  SET_SELECTED(state, data) {
    state.selectedItems = data
  },

  SET_SELECT_ALL(state, data) {
    state.select_all = data
  }
}
