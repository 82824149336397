import { render, staticRenderFns } from "./BindingProductTable.vue?vue&type=template&id=16a8f3fb&scoped=true&"
import script from "./BindingProductTable.vue?vue&type=script&lang=js&"
export * from "./BindingProductTable.vue?vue&type=script&lang=js&"
import style0 from "./BindingProductTable.vue?vue&type=style&index=0&id=16a8f3fb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a8f3fb",
  null
  
)

export default component.exports