var render = function render(){
  var _vm$tour;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [(_vm$tour = _vm.tour) !== null && _vm$tour !== void 0 && _vm$tour.tour_modal ? _c('onboarding-modal') : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('analytics', {
    attrs: {
      "id": "analytics-block"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "flex-grow-1 flex-shrink-1 mr-3"
  }, [_c('operational-processes')], 1), _c('div', {}, [_c('events')], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }