import { render, staticRenderFns } from "./SuperPriceSvg.vue?vue&type=template&id=54c75e06&scoped=true&"
import script from "./SuperPriceSvg.vue?vue&type=script&lang=js&"
export * from "./SuperPriceSvg.vue?vue&type=script&lang=js&"
import style0 from "./SuperPriceSvg.vue?vue&type=style&index=0&id=54c75e06&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c75e06",
  null
  
)

export default component.exports