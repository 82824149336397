var render = function render(){
  var _vm$product, _vm$product2, _vm$product3, _vm$product3$package, _vm$product3$package$, _vm$product3$package$2, _vm$product4, _vm$product4$country, _vm$product5, _vm$product5$manufact, _vm$product6, _vm$product7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.product.promotion && _vm.promotion ? _c('price-tag600on400', {
    staticClass: "price-tag600on400",
    attrs: {
      "product": _vm.product,
      "weight_format": _vm.weight_format
    }
  }) : _c('div', {
    staticClass: "price-tag"
  }, [_c('div', {
    staticClass: "header d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('div', {
    staticClass: "company"
  }, [_vm._v(_vm._s(_vm.companyName))])]), _c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.print_name) || ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.name)))]), _c('div', {
    staticClass: "price d-flex"
  }, [_c('div', {
    staticClass: "price-item d-flex"
  }, [_c('div', {
    staticClass: "price-total"
  }, [_vm._v(_vm._s(_vm.priceMain))]), _c('div', {
    staticClass: "price-info"
  }, [_c('div', {
    staticClass: "price-part"
  }, [_vm._v(_vm._s(_vm.pricePart))]), _c('div', {
    staticClass: "currency"
  }, [_vm._v("₽/" + _vm._s(_vm.measurement))])])]), _c('div', {
    staticClass: "price-qr"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.qrcode,
      "size": "35"
    }
  })], 1)]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "barcode-item"
  }, [_c('div', {
    staticClass: "barcode-row"
  }, [_c('div', {
    staticClass: "barcode"
  }, [(_vm$product3 = _vm.product) !== null && _vm$product3 !== void 0 && (_vm$product3$package = _vm$product3.package) !== null && _vm$product3$package !== void 0 && (_vm$product3$package$ = _vm$product3$package[0]) !== null && _vm$product3$package$ !== void 0 && (_vm$product3$package$2 = _vm$product3$package$.barcode) !== null && _vm$product3$package$2 !== void 0 && _vm$product3$package$2.barcode ? [_c('div', {
    staticClass: "barcode-body"
  }, [_c('barcode-generator', {
    attrs: {
      "width": 120,
      "height": 45,
      "barcode": _vm.getUrlBarcode
    }
  })], 1)] : _vm._e()], 2), _c('div', {
    staticClass: "info",
    staticStyle: {
      "flex": "1"
    }
  }, [_c('div', {
    staticClass: "country"
  }, [_vm._v(" " + _vm._s((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$country = _vm$product4.country) === null || _vm$product4$country === void 0 ? void 0 : _vm$product4$country.name) + " ")]), _c('div', {
    staticClass: "manufacturer"
  }, [_vm._v(" " + _vm._s((_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$manufact = _vm$product5.manufacturer) === null || _vm$product5$manufact === void 0 ? void 0 : _vm$product5$manufact.name) + " ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "manufacturer mr-1"
  }, [_vm._v(" " + _vm._s((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : _vm$product6.articul) + " ")]), (_vm$product7 = _vm.product) !== null && _vm$product7 !== void 0 && _vm$product7.plu ? _c('div', {
    staticClass: "manufacturer"
  }, [_c('div', {
    staticClass: "plu-title"
  }, [_vm._v("PLU:")]), _vm._l(_vm.getPluArr, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "plu-content"
    }, [_c('div', {
      staticClass: "plu-text"
    }, [_vm._v(_vm._s(item))]), _vm.getPluArr[idx + 1] ? _c('div', {
      staticClass: "plu-delimiter"
    }) : _vm._e()]);
  })], 2) : _vm._e()])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }