var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "binding-product-table"
    }
  }, [_c('sort-table-modal', {
    attrs: {
      "fields": _vm.fields,
      "default_fields": _vm.fieldsDefault,
      "id_modal": "bindingProductTable"
    },
    on: {
      "change": _vm.changeFields,
      "set-default": _vm.setDefault
    }
  }), _c('div', {
    staticClass: "products-wrapper",
    staticStyle: {
      "height": "inherit",
      "flex": "1"
    }
  }, [_c('div', {
    staticClass: "card p-0",
    class: {
      'no-margin': _vm.items_total < 20,
      'table-wrapper-flex': _vm.currentCategory.id === undefined,
      'table-wrapper-width': _vm.currentCategory.id !== undefined
    },
    staticStyle: {
      "min-height": "inherit"
    }
  }, [_c('div', {
    staticClass: "head-table"
  }, [_c('div', {
    staticClass: "m-3 w-50 head-table__input-block",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('b-form-input', {
    ref: "inputBindingProduct",
    staticClass: "large",
    attrs: {
      "type": "text",
      "is-keyup": true,
      "placeholder": "Поиск товаров..."
    },
    model: {
      value: _vm.search_str,
      callback: function ($$v) {
        _vm.search_str = $$v;
      },
      expression: "search_str"
    }
  }), _c('b-button', {
    staticClass: "icon btn-delete-text",
    on: {
      "click": _vm.clearInput
    }
  }, [_c('img', {
    staticClass: "scanner",
    attrs: {
      "src": "/img/icons/incoming/Delete.svg",
      "alt": "scanner"
    }
  })]), _c('b-button', {
    staticClass: "icon btn-scan",
    attrs: {
      "id": "tooltip-btn-scan"
    }
  }, [_c('img', {
    staticClass: "scanner",
    attrs: {
      "src": "/img/icons/mini-scanner.svg",
      "alt": "scanner"
    }
  })]), _c('b-tooltip', {
    attrs: {
      "target": "tooltip-btn-scan",
      "triggers": "hover",
      "custom-class": "tooltip-scan"
    }
  }, [_vm._v(" Возможен поиск с помощью считывания штрих-кода сканнером ")]), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showSortModal
    }
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('b-button', {
    staticClass: "btn-add mr-3 small-button",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.add_product
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/plus_dark.svg",
      "alt": "plus"
    }
  })]), _c('b-button', {
    staticClass: "btn-add mr-3",
    attrs: {
      "variant": "light"
    },
    on: {
      "click": _vm.add_product
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/plus_dark.svg",
      "alt": ""
    }
  }), _c('span', [_vm._v("Добавить товар")])])], 1), _vm.currentCategory.id !== undefined ? [_c('resizable-table', {
    ref: "table_products",
    staticClass: "table-products",
    attrs: {
      "id": "products-table",
      "table_name": "binding-tab",
      "items": _vm.products,
      "default_fields": _vm.fields.filter(function (el) {
        return el.checked;
      }),
      "empty_text": "Нет товаров",
      "resizable": true,
      "busy": _vm.tableIsBusy
    },
    on: {
      "row-click": _vm.bindProduct
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('div', {
          staticClass: "head-lable"
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('img', {
          staticClass: "cursor ml-2",
          attrs: {
            "src": _vm.icon(item),
            "alt": "bind"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.bindProduct(item);
            }
          }
        })];
      }
    }, {
      key: "body_name",
      fn: function (data) {
        var _data$item$supplier$n, _data$item$supplier;

        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_vm._v(" " + _vm._s(data.value) + " "), _c('div', {
          staticClass: "product-info-div"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(data.item.articul))]), _c('span', {
          staticClass: "supplier"
        }, [_vm._v(" " + _vm._s((_data$item$supplier$n = (_data$item$supplier = data.item.supplier) === null || _data$item$supplier === void 0 ? void 0 : _data$item$supplier.name) !== null && _data$item$supplier$n !== void 0 ? _data$item$supplier$n : '-') + " ")])])])];
      }
    }, {
      key: "body_measurement",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.name) + " ")];
      }
    }, {
      key: "body_markingType",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.name) + " ")];
      }
    }, {
      key: "body_remainders",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getTotalRemainders(data)) + " ")];
      }
    }, {
      key: "body_productType",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.name) + " ")];
      }
    }, {
      key: "body_markup",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value ? data.value + ' %' : '-') + " ")];
      }
    }, {
      key: "body_retailPrice",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(data.value)) + " ₽ ")];
      }
    }, {
      key: "body_purchasePrice",
      fn: function (data) {
        var _data$item;

        return [_vm._v(" " + _vm._s(_vm.formatPrice((_data$item = data.item) === null || _data$item === void 0 ? void 0 : _data$item.purchasePrice)) + " ₽ ")];
      }
    }, {
      key: "body_nds",
      fn: function (data) {
        var _data$item2, _data$item2$taxRate;

        return [_vm._v(" " + _vm._s(((_data$item2 = data.item) === null || _data$item2 === void 0 ? void 0 : (_data$item2$taxRate = _data$item2.taxRate) === null || _data$item2$taxRate === void 0 ? void 0 : _data$item2$taxRate.name) || '-') + " ")];
      }
    }, {
      key: "body_package",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getBarcode(data)) + " ")];
      }
    }, {
      key: "infoTable",
      fn: function () {
        return [_vm.items_total > 0 ? _c('div', {
          staticClass: "p-2 total-info"
        }, [_vm._v(" Всего: " + _vm._s(_vm.items_total) + " ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 893715671)
  })] : [_c('div', {
    staticClass: "center empty-block w-100"
  }, [_vm._v(" Выберите группу товаров, создайте группу товаров или загрузите со своей кассы ")])]], 2), _c('pagination', {
    staticClass: "pagination-wrapper",
    attrs: {
      "total": _vm.items_total
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  }), _c('binding-accept-modal', {
    attrs: {
      "product": _vm.product,
      "selected_product": _vm.selectedProduct,
      "document_head": _vm.document_head,
      "type": _vm.type
    },
    on: {
      "bind": _vm.bind
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }