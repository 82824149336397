var render = function render(){
  var _vm$item, _vm$item2, _vm$item3, _vm$item3$child_group, _vm$item4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "comaprison-item",
    class: {
      padding1: _vm.item.level === 1,
      padding2: _vm.item.level === 2,
      padding3: _vm.item.level === 3,
      padding4: _vm.item.level === 4
    },
    on: {
      "mouseover": _vm.mouseOver,
      "mouseleave": _vm.mouseLeave,
      "click": function ($event) {
        $event.stopPropagation();
        return function (e) {
          return _vm.open(e);
        }.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "item-left"
  }, [!((_vm$item = _vm.item) !== null && _vm$item !== void 0 && _vm$item.last) && !_vm.opened.includes(_vm.item.id) && !_vm.new_product ? _c('div', [_c('img', {
    staticClass: "arrow-down arrow-down__rotate",
    attrs: {
      "src": "/img/icons/menu_arrow_down.svg",
      "alt": "Открыть"
    }
  })]) : _vm._e(), _vm.opened.includes(_vm.item.id) ? _c('div', [_c('img', {
    staticClass: "arrow-down",
    attrs: {
      "src": "/img/icons/menu_arrow_down.svg",
      "alt": "Открыть"
    }
  })]) : _vm._e(), !_vm.item.franchise_id && _vm.item.product_count ? _c('div', {
    staticClass: "error-svg"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/attention.svg",
      "alt": "error"
    }
  })]) : _vm._e(), !_vm.edit && !_vm.new_product ? _c('div', [_vm._v(_vm._s(_vm.parent_number + _vm.item.int_id) + ". " + _vm._s(_vm.item.name))]) : _c('div', {
    staticClass: "input-block",
    attrs: {
      "draggable": "false"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.parent_number + _vm.item.int_id))]), _c('e-input', {
    ref: `input-name${_vm.item.id}`,
    staticClass: "input-name",
    class: {
      input__padding1: _vm.item.level === 1,
      input__padding2: _vm.item.level === 2,
      input__padding3: _vm.item.level === 3,
      input__padding4: _vm.item.level === 4,
      new_input: _vm.new_product
    },
    attrs: {
      "id": `input-name${_vm.item.id}`,
      "draggable": "false",
      "placeholder": "Название категории",
      "value": _vm.item.name
    },
    on: {
      "enter": _vm.saveGroup,
      "click": function ($event) {
        $event.stopPropagation();
      }
    },
    model: {
      value: _vm.name_string,
      callback: function ($$v) {
        _vm.name_string = $$v;
      },
      expression: "name_string"
    }
  })], 1), _vm.edit || _vm.new_product ? _c('div', {
    staticClass: "d-flex",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('e-button', {
    staticClass: "ml-2 mini-button",
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.saveGroup
    }
  }, [_c('img', {
    attrs: {
      "size": "m",
      "src": "/img/icons/icon_yes.svg",
      "alt": "Переименовать группу"
    }
  })]), _c('div', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('e-button', {
    staticClass: "ml-2 mini-button",
    on: {
      "click": _vm.closeButtonClick
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/icon_cancel.svg",
      "alt": "Отменить"
    }
  })])], 1)], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "height": "32px"
    }
  }), _vm.hover && _vm.item.level !== 4 && !_vm.new_product ? _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip:top",
      value: 'Добавить подгруппу ниже',
      expression: "'Добавить подгруппу ниже'",
      arg: "top"
    }],
    attrs: {
      "id": "tooltip-add-subgroup"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.showAddChildGroup.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/icon_add_group_hover.svg",
      "alt": ""
    }
  })]) : _vm._e(), _vm.hover && !_vm.new_product ? _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    on: {
      "click": _vm.outsideClick
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('img', {
          staticClass: "ellipsis",
          attrs: {
            "src": "/img/icons/more_gray_hover.svg",
            "alt": "ellipsis"
          },
          on: {
            "click": _vm.outsideClick
          }
        })];
      },
      proxy: true
    }], null, false, 2735846272)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.updateName.apply(null, arguments);
      }
    }
  }, [_vm._v(" Переименовать ")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.openRemoveModal.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "remove-text"
  }, [_vm._v("Удалить")])])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "item-right"
  }, [_vm.item.product_count && !_vm.new_product ? _c('div', {
    staticClass: "connect-svg"
  }, [_c('connect-svg', {
    attrs: {
      "color": !_vm.item.franchise_id.id ? '#E53835' : '#00CB91'
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "connect"
  }, [!_vm.item.product_count && !_vm.new_product ? _c('p', [_vm._v("Связь не требуется")]) : _vm._e(), _vm.item.product_count && !_vm.new_product ? _c('comparison-select', {
    attrs: {
      "item": _vm.item,
      "select_id": (_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.id,
      "franchise_arr": _vm.franchise_arr,
      "franchise_path": _vm.franchise_path
    },
    on: {
      "refetch": _vm.refetchCategories,
      "setPath": _vm.setPath
    }
  }) : _vm._e()], 1)])]), _vm.new_prod ? _c('div', {
    staticClass: "add-child"
  }, [_c('comparison-item', {
    attrs: {
      "opened": _vm.opened,
      "item": {
        id: 1,
        name: 'Новая категория',
        int_id: _vm.item.int_id,
        level: _vm.item.level + 1,
        parent_id: this.item.id
      },
      "new_product": true,
      "category_path": _vm.category_path,
      "franchise_arr": _vm.franchise_arr,
      "franchise_path": _vm.franchise_path
    },
    on: {
      "close-new": _vm.closeButtonClick,
      "set_franchise_path": _vm.setPath,
      "refetch": _vm.refetchCategories,
      "openCategory": function (path) {
        return _vm.$emit('openCategory', path);
      }
    }
  })], 1) : _vm._e(), (_vm$item3 = _vm.item) !== null && _vm$item3 !== void 0 && (_vm$item3$child_group = _vm$item3.child_groups) !== null && _vm$item3$child_group !== void 0 && _vm$item3$child_group.length && _vm.opened.includes(_vm.item.id) ? _c('div', _vm._l((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.child_groups, function (children) {
    return _c('comparison-item', {
      key: children.id + children.name,
      attrs: {
        "opened": _vm.opened,
        "item": children,
        "category_path": _vm.category_path,
        "franchise_arr": _vm.franchise_arr,
        "franchise_path": _vm.franchise_path,
        "parent_number": '' + _vm.parent_number + _vm.item.int_id + '.'
      },
      on: {
        "set_franchise_path": _vm.setPath,
        "refetch": _vm.refetchCategories,
        "openCategory": function (path) {
          return _vm.$emit('openCategory', path);
        }
      }
    });
  }), 1) : _vm._e(), _c('remove-modal', {
    attrs: {
      "group": _vm.item
    },
    on: {
      "remove-item": _vm.removeItem
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }