import View from '@/views/layouts/layout-view'

export default {
  path: '/settings',
  name: 'settings',
  component: View,
  meta: {
    auth: true,
    title: 'Настройки',
    submenu: true
  },
  children: [
    {
      path: 'company-structure',
      name: 'company.structure',
      component: () => import('@/views/settings/pages/Structure.vue'),
      meta: {
        title: 'Структура компании',
        parrent: 'settings'
      },
      children: [
        {
          path: 'branches',
          name: 'company.branches',
          component: () => import('@/views/settings/pages/Branches.vue'),
          meta: {
            title: 'Структура компании',
            parrent: 'settings'
          }
        },
        {
          path: 'entities',
          name: 'company.entities',
          component: () => import('@/views/contractors/pages/Contractors.vue'),
          props: { entity: true },
          meta: {
            title: 'Структура компании',
            parrent: 'settings'
          }
        }
      ]
    },
    {
      path: 'entities/new',
      name: 'company.entities.new',
      component: () => import('@/views/settings/pages/Entities.vue'),
      props: { entity: true },
      meta: {
        title: 'Структура компании',
        parrent: 'settings'
      }
    },
    {
      path: 'entities/edit/:id',
      name: 'company.entities.edit',
      component: () => import('@/views/settings/pages/Entities.vue'),
      props: { entity: true },
      meta: {
        title: 'Структура компании',
        isEdit: true,
        parrent: 'settings'
      }
    },
    {
      path: 'create',
      name: 'company.create.branch',
      component: () => import('@/views/settings/components/CreateEditBranch'),
      meta: {
        title: 'Новый магазин',
        parrent: 'company.branches'
      }
    },
    {
      path: 'edit/:id',
      name: 'company.edit.branch',
      component: () => import('@/views/settings/components/CreateEditBranch'),
      meta: {
        title: 'Редактировать магазин',
        parrent: 'company.branches',
        edit: true
      }
    },
    {
      path: 'users',
      name: 'users.list',
      component: () => import('@/views/settings/pages/Users.vue'),
      meta: {
        title: 'Сотрудники',
        parrent: 'company.branches',
        edit: true
      }
    },
    {
      path: 'user/:id',
      name: 'user.edit',
      component: () => import('@/views/settings/pages/UserInfo.vue'),
      meta: {
        title: 'Редактировать пользователя',
        parrent: 'users.list',
        edit: true
      }
    },
    {
      path: 'devices',
      name: 'devices.list',
      component: () => import('@/views/settings/pages/Devices.vue'),
      meta: {
        title: 'Оборудование',
        edit: true
      }
    },
    {
      path: 'devices/:id',
      name: 'devices.item',
      component: () => import('@/views/settings/pages/Device.vue'),
      meta: {
        title: 'Оборудование',
        parrent: 'devices.list',
        edit: true
      }
    },
    {
      path: 'devices/new/:integration',
      name: 'devices.item.new',
      component: () => import('@/views/settings/pages/Device.vue'),
      meta: {
        title: 'Оборудование',
        parrent: 'devices.list',
        edit: true
      }
    },
    {
      path: 'devices/printer/:id',
      name: 'devices.printer',
      component: () => import('@/views/settings/pages/PrinterDevice.vue'),
      meta: {
        title: 'Принтер',
        edit: true
      }
    },
    {
      path: '/scales-list',
      name: 'devices.scale',
      component: () => import('@/views/settings/pages/Scale.vue'),
      meta: {
        title: 'Список весовых товаров',
        edit: true
      }
    },
    {
      path: 'devices/new/scale',
      name: 'devices.scale.new',
      component: () => import('@/views/settings/pages/Scale.vue'),
      meta: {
        title: 'Список весовых товаров',
        parrent: 'devices.list',
        edit: true
      }
    },
    {
      path: 'access_rights',
      name: 'access.rights',
      component: () => import('@/views/settings/pages/AccessRights.vue'),
      meta: {
        title: 'Права доступа'
      }
    }
  ]
}
