var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "confirm-block"
  }, [_c('div', {
    staticClass: "confirm-block__left"
  }, [_c('warning-svg', {
    staticClass: "warning-img",
    attrs: {
      "color": 'white'
    }
  }), _c('div', {
    staticClass: "text"
  }, [_c('p', {
    staticClass: "confirm-text"
  }, [_vm._v(_vm._s(_vm.getDays) + " Оплатите подписку чтобы продолжить пользоваться сервисом.")])])], 1), _c('div', {
    staticClass: "confirm-block__right"
  }, [_c('div', {
    staticClass: "button-block"
  }, [_c('e-button', {
    attrs: {
      "size": "m"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Оплатить ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }