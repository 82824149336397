import { is_not_auth } from '@/router/middleware/auth.js'

export default {
  path: '/auth',
  redirect: '/auth/login',
  beforeEnter: is_not_auth,
  component: () => import(/* webpackChunkName: "auth" */ '@/views/layouts/AuthLayout'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/auth/Login'),
      meta: { page: 'login' }
    },
    {
      path: 'reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/ResetPassword'),
      meta: { page: 'reset-password' },
      auth: false,
      children: [
        {
          path: ':token',
          name: 'reset-password',
          component: () => import('@/views/auth/ResetPassword'),
          meta: { page: 'reset-password' },
          auth: false
        }
      ]
    },
    {
      path: 'registr',
      name: 'registr',
      component: () => import('@/views/auth/Registr'),
      meta: { page: 'registr' },
      children: [
        {
          path: 'activity',
          name: 'registr.activity',
          component: () => import('@/views/auth/RegistrProcess'),
          meta: {
            auth: true,
            register: true,
            page: 'process'
          }
        },
        {
          path: 'company',
          name: 'registr.company',
          component: () => import('@/views/auth/RegistrProcess'),
          meta: {
            auth: true,
            register: true,
            page: 'process'
          }
        },
        {
          path: 'confirm',
          name: 'registr.confirm',
          component: () => import('@/views/auth/RegistrProcess'),
          meta: {
            auth: true,
            register: true,
            page: 'process'
          }
        },
        {
          path: 'employees',
          name: 'registr.employees',
          component: () => import('@/views/auth/RegistrProcess'),
          meta: {
            auth: true,
            register: true,
            page: 'process'
          }
        }
      ]
    }
  ]
}
