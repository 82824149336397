import store from '@store'
import * as R from 'ramda'

export function is_auth(from, to, next) {
  const state = R.pathOr(null, ['state'], store)
  const token = R.pathOr(null, ['core', 'token'], state)
  const isAuth = store['getters']['core/isAuth']
  const isConfirmed = store['getters']['core/getIsConfirmed']
  const isOld = store['getters']['core/user']
  const getCurrentStep = store['getters']['core/getCurrentStep']

  if ((getCurrentStep === 'OK' && isAuth) || (isOld.is_old && isAuth) || (isConfirmed && isAuth)) return next()

  if (!token) {
    return next('/auth/login')
  }

  if (!isConfirmed && token && !isOld?.is_old) return next('/auth/registr/confirm')

  return next()
}

export function is_not_auth(from, to, next) {
  const state = R.pathOr(null, ['state'], store)
  const isAuth = store['getters']['core/isAuth']
  const isConfirmed = store['getters']['core/getIsConfirmed']
  const isOld = store['getters']['core/user']
  const getCurrentStep = store['getters']['core/getCurrentStep']

  if ((isConfirmed && isAuth) || (getCurrentStep === 'OK' && isAuth) || (isOld?.is_old && isAuth)) return next('/')

  if (from.path === '/auth/reset-password' && !isAuth) return next()

  if (from.path === '/auth/registr/activity' && !isAuth) {
    return next('/auth/login')
  }
  if (from.path === '/auth/registr/activity') return next()
  if (isAuth && !state.core.user.company) {
    return next('/auth/registr/activity')
  }

  if (from.path === '/auth/registr/confirm' && !isAuth) return next('/auth/login')
  if (from.path === '/auth/registr/confirm') return next()
  if (isAuth && state.core.user.company && !isConfirmed && !isOld?.is_old) {
    return next('/auth/registr/confirm')
  }

  localStorage.removeItem('entersight')
  return next()
}
