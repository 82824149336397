<template>
  <div class="dashboard">
    <onboarding-modal v-if="tour?.tour_modal" />
    <div class="row">
      <div class="col-12">
        <analytics id="analytics-block" />
        <!--        <tour-tooltip target="training">-->
        <!--          <template #header> Обучающий тур</template>-->
        <!--          <template #inner>-->
        <!--            <div class="tooltip-in">-->
        <!--              <div style="font-size: 14px; margin-bottom: 18px">-->
        <!--                Вернуться к обучающему туру можно здесь-->
        <!--              </div>-->
        <!--              <img src="/img/tour/tour_tooltip_picture.svg" />-->
        <!--            </div>-->
        <!--          </template>-->
        <!--          <template #footer>-->
        <!--            <b-button variant="primary">Понятно</b-button>-->
        <!--          </template>-->
        <!--        </tour-tooltip>-->
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-grow-1 flex-shrink-1 mr-3">
        <operational-processes />
      </div>
      <div class="">
        <events />
      </div>
    </div>
  </div>
</template>

<script>
  import Analytics from './components/Analytics.vue'
  import Events from './components/Events.vue'
  import OperationalProcesses from './components/OperationalProcesses.vue'
  import { mapActions, mapGetters } from 'vuex'
  import OnboardingModal from '@/views/tour/components/modal/OnboardingModal.vue'

  export default {
    components: {
      OnboardingModal,
      Analytics,
      Events,
      OperationalProcesses
    },
    apollo: {
      GetTourStatus: {
        query: require('@/gql/tour/getTourStatus.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          if (data.GetTourStatus) this.setTour(data.GetTourStatus)
        }
      }
    },

    computed: {
      ...mapGetters({
        getToken: 'core/getToken',
        tour: 'core/getTour'
      })
    },

    beforeMount() {
      this.setBreadcrumbs({})
    },

    created() {
      // this.setTest()
    },
    methods: {
      ...mapActions({
        setTour: 'core/setTourStatus',
        setBreadcrumbs: 'breadcrumbs/set_current'
      })
    },
    mounted() {
      this.$bvModal.show('onboarding-modal')

      // TODO normalnyy tour
      // document
      //   .getElementsByClassName('wrapper__content')[0]
      //   .classList.add('highlight-div')
    }
  }
</script>

<style scoped lang="scss">
  .highlight-div:after {
    content: '';
    background: linear-gradient(180deg, rgba(186, 38, 255, 0.03) 0%, rgba(136, 0, 243, 0.0099) 100%),
      rgba(136, 0, 243, 0.01);
    border: 3px solid #7f00e3;
    border-radius: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>
