<template>
  <div class="b-submenu__wrapper">
    <div class="b-submenu-title">{{ title }}</div>
    <div class="b-sidebar__menu b-submenu">
      <div v-if="submenu_array?.name === 'store'">
        <!-- <search /> -->
        <div class="b-storelist__search">
          <input
            v-model="search_str"
            class="store-search"
            type="text"
            placeholder="Поиск..."
          />
          <img
            v-if="search_str"
            class="clear-input"
            src="/img/icons/Delete.svg"
            alt="clear input"
            @click="() => (search_str = '')"
          />
        </div>
        <div
          v-for="item of filteredBranches"
          :key="item?.id"
          @click="selectStore(item)"
        >
          <div
            class="radio-block"
            :class="{
              'child-block': item?.child
            }"
          >
            <e-radio
              :checked="getCurrentBranch.id === item.id"
              class="mr-2"
            />
            <img
              v-if="item?.__typename === 'BranchClassifModel'"
              src="/img/icons//settings/icon_group.svg"
              class="mr-2"
              alt=""
            />
            <img
              v-if="item?.__typename === 'Branch'"
              src="/img/icons//settings/icon_branch.svg"
              class="mr-2"
              alt=""
            />
            <div>
              <div
                class="radio-btns"
                :class="{
                  'radio-btns-without-address': !item?.address
                }"
              >
                <p
                  v-if="item?.name.length > 25"
                  v-b-tooltip.hover
                  class="name-entity"
                  :title="item?.name"
                >
                  {{ item?.name?.slice(0, 25) + '...' }}
                </p>
                <p
                  v-else
                  class="name-entity"
                >
                  {{ item?.name }}
                </p>
              </div>
              <p
                v-if="adressCompany(item).length > 35"
                v-b-tooltip.hover
                class="adress-entity"
                :title="adressCompany(item)"
              >
                {{ adressCompany(item).slice(0, 35) + '...' }}
              </p>
              <p
                v-else
                class="adress-entity"
              >
                {{ adressCompany(item) }}
              </p>
            </div>
          </div>
        </div>

        <b
          v-if="filteredBranches.length === 0"
          class="emty"
          >По данному запросу ничего не найденно</b
        >
      </div>
      <ul v-else>
        <template>
          <li
            v-for="(item, index) in menu"
            :key="index"
            class="menu"
            :class="{ opened: item.opened }"
          >
            <template v-if="item.link_name">
              <router-link
                :to="{ name: item.link_name }"
                class="item item-link"
              >
                {{ item.title }}
              </router-link>
            </template>
            <template v-else-if="item.submenu">
              <span
                class="title cursor"
                style="font-weight: 600"
                @click.prevent="toggleSubmenu(index)"
              >
                {{ item.title }}
              </span>
              <span
                class="arrow cursor"
                @click.prevent="toggleSubmenu(index)"
              ></span>
              <ul class="b-submenu">
                <li
                  v-for="(subitem, index) in item.submenu"
                  :key="index"
                >
                  <router-link
                    :to="{ name: subitem.link_name }"
                    class="submenu-item"
                    exact-active-class="active-router"
                    active-class="active-router"
                  >
                    {{ subitem.title }}
                  </router-link>
                </li>
              </ul>
            </template>
            <template v-else>
              <span class="title">{{ item.title }}</span>
            </template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/main'
  import submenu from '@/assets/menu/submenu'
  import * as R from 'ramda'
  import { mapActions, mapGetters, mapMutations } from 'vuex'

  export default {
    apollo: {
      BranchClassif: {
        query: require('../../../settings/gql/BranchClassif.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          const classif = data?.BranchClassif?.classif ?? []
          const brabches = data?.BranchClassif?.branches ?? []

          const dataList = [...classif, ...brabches] ?? []
          const branchClassifList = this.parsBranchClassifList(dataList)

          this.SET_BRANCH_CLASSIF_LIST(branchClassifList)

          const currentItem = branchClassifList.find((el) => el.id === this.getCurrentBranch.id)

          if (!currentItem?.id) {
            if (this.getCurrentBranch?.branches?.[0]?.id) {
              this.setCurrentBranch(this.getCurrentBranch?.branches?.[0])
            } else {
              this.setCurrentBranch(branchClassifList?.[0])
            }
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Branches: {
        query: require('../../header/gql/getBranches.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.selected = this.getCurrentBranch?.id
          this.handlerBranches(data)
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        }
      }
    },
    props: {
      submenu_array: {
        type: Object,
        default: () => {}
      }
    },

    data: () => ({
      menu: [],
      title: '',
      selected: null,
      search_str: '',
      branch_list: []
    }),

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch',
        getBranchList: 'settings/getBranchList',
        getBranchClassifList: 'settings/getBranchClassifList'
      }),
      filteredBranches() {
        const filter = this.getBranchClassifList.filter((item) => {
          return item?.name?.toLowerCase().includes(this.search_str.toLowerCase())
        })

        const result = JSON.parse(JSON.stringify(filter))

        let parent_id = ''
        result.forEach((el) => {
          if (el.__typename === 'BranchClassifModel') {
            parent_id = el?.id
          } else {
            if (el.parentId !== parent_id) {
              el.child = false
            }
          }
        })

        return result
      }
    },

    watch: {
      submenu_array({ title, icon }) {
        const name = (item) => item?.name === icon
        const menu = R.filter(name, submenu)
        this.menu = menu[0]?.children
        this.title = title
      }
    },

    created() {
      EventBus.$on('refetchBranchClassif', () => {
        this.refetchBranchClassif()
      })
    },

    destroyed() {
      EventBus.$off('refetchBranchClassif', () => this.refetchBranchClassif())
    },

    mounted() {
      this.selected = this.getCurrentBranch?.id
    },

    methods: {
      ...mapActions({
        setCurrentBranch: 'settings/setCurrentBranch',
        setBranchList: 'settings/setBranchList'
      }),
      ...mapMutations({
        SET_BRANCH_CLASSIF_LIST: 'settings/SET_BRANCH_CLASSIF_LIST',
        SET_CURRENT_BRANCH_CLASSIF_LIST: 'settings/SET_CURRENT_BRANCH_CLASSIF_LIST'
      }),

      refetchBranchClassif() {
        this.$apollo.queries.BranchClassif.refetch()
      },

      parsBranchClassifList(list) {
        const resultParsList = JSON.parse(JSON.stringify(list))

        for (let i = 0; resultParsList.length > i; i++) {
          if (resultParsList[i].branches?.length > 0) {
            const branches = resultParsList[i].branches
            branches.forEach((item) => {
              item.__typename = 'Branch'
              item.child = true
              item.parentId = resultParsList[i]?.id
            })

            resultParsList?.splice(i + 1, 0, ...branches)
          }
        }

        return resultParsList ?? []
      },

      adressCompany(item) {
        if (!item?.address) return ''
        else return item?.address?.city + ',' + item?.address?.street + ',' + item?.address?.house
      },
      toggleSubmenu(index) {
        this.menu[index].opened = !this.menu[index].opened
      },
      selectStore(item) {
        this.setCurrentBranch(item)
        this.SET_CURRENT_BRANCH_CLASSIF_LIST(item?.branches)
        // this.setCurrentBranch(this.branch_list.find((el) => el.id === id))
      },
      handlerBranches(data) {
        const branches = data.Branches || []
        const current_branch_name = this.getCurrentBranch?.name || []

        branches.forEach((branch) => {
          const branch_item = R.mergeAll([branch, { active: false }])

          if (!this.branch_list?.includes(branch?.id)) this.branch_list.push(branch_item)
        })

        if (current_branch_name.length === 0) {
          this.current_branch_name = branches[0]?.name
          this.selected_branch = branches[0]
          this.setCurrentBranch({ ...branches[0], active: true })
        }

        let active
        this.branch_list.forEach((branch) => {
          if (branch.active) active = true
        })
        if (active) {
          this.setBranchList(this.branch_list)
        } else {
          this.branch_list[0].active = true
          this.setBranchList(this.branch_list)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .stores {
      margin-top: 20px !important;
    }

    .b-sidebar__menu.b-submenu {
      padding: 0;
      overflow-y: visible;

      ul {
        li.menu {
          padding: 0;
          padding-bottom: 25px;

          a {
            font-size: 14px;
            line-height: 14px;
            color: #313131;
            padding-left: 0px;

            &:hover {
              padding-left: 24px !important;
            }
          }

          .arrow {
            top: 7px;
          }

          .b-submenu {
            li {
              padding: 0;
              margin: 14px 0 0 0;

              a {
                font-size: 12px;
                padding-left: 19px;

                &:hover {
                  padding-left: 24px;
                }
              }
            }
          }
        }
      }
    }

    //   .custom-control-label {
    //   width: 100% !important;
    //   margin-top: 2px;
    // }
    // .custom-control-label::before,
    // .custom-control-label::after {
    //   top: 0.55rem !important;
    //   left: -0.8rem !important;
    // }
  }
  .custom-control-label {
    //width: 100% !important;
    //margin-top: 2px;
  }
  .custom-control-label::before,
  .custom-control-label::after {
    //top: 0.55rem !important;
    //left: -0.8rem !important;
  }

  .radio-btns-without-address {
    margin-bottom: 10px;
  }

  .child-block {
    margin-left: 30px;
  }
  .radio-block {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    margin-top: 2px;
    overflow: hidden;
    .radio-btns {
      cursor: pointer;
      // margin-left: 20px;
    }
    &:hover {
      background-color: #f2f3f7;
      border-radius: 6px !important;
    }
    .name-entity {
      width: 100%;
      margin-top: 10px !important;
      white-space: nowrap;
      cursor: pointer;
      padding: 0;
      margin: 0;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #313131;
    }
    .adress-entity {
      width: 100%;
      white-space: nowrap;
      cursor: pointer;
      padding: 0;
      margin: 0;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: #888888;
    }
  }
  .emty {
    display: block;
    width: 100% !important;
    text-align: center;
    padding: 0 30px;
    color: #888888;
  }
  .b-storelist__search {
    position: relative;
  }
  .clear-input {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0%, -50%);
  }
  .store-search {
    border: 1px solid #bcbcbc !important;
    border-radius: 2px !important;
  }
  .store-search::placeholder {
    color: var(--Text-placeholder---gray300, #BCBCBC);
  }

  // .stores {
  //   margin-top: 20px !important;
  // }
  .submenu-item {
    padding: 6px 6px 6px 14px !important;
    font-size: 14px !important;
  }

  .b-submenu__wrapper {
    height: 100%;

    .b-submenu-title {
      font-size: 16px;
      line-height: 14px;
      color: #313131;
      margin-bottom: 32px;
    }

    .b-sidebar__menu.b-submenu {
      padding: 0;
      overflow-y: visible;

      ul {
        li.menu {
          padding: 0;
          padding-bottom: 25px;

          a {
            // font-size: 14px;
            // line-height: 14px;
            // color: #313131;
            // padding-left: 0px;

            &:hover {
              padding-left: 24px !important;
            }
          }

          .arrow {
            top: 7px;
          }

          .b-submenu {
            li {
              padding: 0;
              margin: 14px 0 0 0;

              a {
                // font-size: 12px;
                // padding-left: 19px;

                &:hover {
                  padding-left: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
