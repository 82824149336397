export const state = {
  current_product: {},
  printProductData: [],
  quantity_products: [],
  current_category: null,
  current_category_path: null,
  current_ids: [],
  current_products: [],
  selected_categories: [],
  selected_products: [],
  excluded_products: [],
  excluded_categories: [],
  settings: {
    current_category_path: null,
    menu_is_visible: true
  },
  search_str: '',
  placeholder: 'Поиск товаров по названию, коду или постав...',
  current_product_id: '',
  add_group_visible: false,
  current_category_menu_id: null,
  print_actions: {
    print: true,
    print_label: false
  },
  filters: {
    supplier: [],
    storages: [],
    status: [],
    owners: []
  }
}
