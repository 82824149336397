<template>
  <ul class="menu-item p-0">
    <li
      v-for="(item, index) in items"
      :id="`item_${item.id}`"
      :key="index"
      :ref="`item_${item.id}`"
      class="menu"
      :class="{
        open: ids.includes(item.id),
        current: getCategory.id === item.id,
        last: item.last,
        selected: getCurrentCategoryMenuId === item.id
      }"
    >
      <div
        v-if="rename !== item.id"
        class="elm-buttont"
        :class="{ active: getCategory.id === item.id }"
      >
        <b-checkbox
          v-if="is_multiple"
          :checked="selected_categories?.includes(item.id)"
          @change="(value) => selectCategory(item, `${int_number}${item.int_id}.`, index, value)"
        />
        <button
          class="item cursor"
          @click.prevent="is_multiple ? null : selectCategory(item, `${int_number}${item.int_id}.`, index)"
        >
          <span
            v-if="!item.last"
            class="open-category cursor"
            @click.stop.prevent="openSubMenu(item)"
          >
            <img
              src="/img/icons/menu_arrow_down.svg"
              alt="Открыть"
            />
          </span>
          <e-checkbox
            v-if="mass_add"
            class="mr-3 ml-1"
            type="-"
            :checked="selected_categories?.includes(item.id)"
            @click="(value) => massAdd(item, `${int_number}${item.int_id}.`, index, value, true)"
          />
          <span class="count mr-1">{{ int_number }}{{ item.int_id }}.</span>
          <span
            :id="item.id"
            class="title"
            >{{ item.name }}</span
          >
        </button>
        <div
          v-if="getCategory.id === item.id && !is_select_group && !hide_control"
          class="actions"
        >
          <b-tooltip
            v-if="item.id"
            :target="item.id"
            :delay="{ show: 1000, hide: 100 }"
            boundary="viewport"
          >
            {{ item.name }}
          </b-tooltip>
          <div
            v-if="getCategory.id === item.id && !is_multiple"
            class="actions"
          >
            <button
              v-if="item.id"
              id="tooltip-add-subgroup"
              class="cursor mr-1"
              @click="showAddChildGroup(item)"
            >
              <img
                src="/img/icons/icon_add_group.svg"
                alt=""
              />
              <img
                class="hover"
                src="/img/icons/icon_add_group_hover.svg"
                alt=""
              />
            </button>
            <b-tooltip
              target="tooltip-add-subgroup"
              triggers="hover"
              :delay="{ show: 500, hide: 200 }"
              boundary="viewport"
              placement="topleft"
            >
              Добавить подгруппу
            </b-tooltip>
            <template v-if="item.id">
              <b-dropdown
                no-caret
                variant="none"
              >
                <template #button-content>
                  <img
                    src="/img/icons/more_gray.svg"
                    class="cursor"
                    alt=""
                  />
                  <img
                    class="hover cursor"
                    src="/img/icons/more_gray_hover.svg"
                    alt=""
                  />
                </template>
                <b-dropdown-item @click="updateName(item)"> Переименовать </b-dropdown-item>
                <b-dropdown-item
                  v-if="sections.length"
                  @click="addSection(item)"
                >
                  Добавить секции
                </b-dropdown-item>
                <b-dropdown-item @click="setGroupMarkup(item)"> Назначить наценку </b-dropdown-item>
                <b-dropdown-item @click="removeMenuItem(item)"> Удалить </b-dropdown-item>
              </b-dropdown>
            </template>
          </div>
        </div>
      </div>
      <div v-else>
        <b-input-group>
          <b-input-group-prepend
            size="sm"
            style="height: 32px"
          >
            <b-input-group-text
              class="bg-transparent"
              :style="isFocused ? 'border-color: #80bdff!important' : ''"
              style="font-size: 14px; color: #bcbcbc"
            >
              {{ int_number }}{{ item.int_id }}.
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            :id="item.id"
            v-model="item.name"
            class="mb-2 border-left-0"
            @keydown.enter="saveGroup(item)"
          />
        </b-input-group>
        <div
          class="b-btns"
          style="display: flex; flex-direction: row; justify-content: flex-end; gap: 4px"
        >
          <b-button
            class="cursor"
            :disabled="!item.name?.length"
            @click="saveGroup(item)"
          >
            <img
              src="/img/icons/icon_yes.svg"
              alt="Переименовать группу"
            />
          </b-button>
          <b-button
            class="cursor"
            @click="rename = false"
          >
            <img
              src="/img/icons/icon_cancel.svg"
              alt="Отменить"
            />
          </b-button>
        </div>
      </div>
      <template v-if="add_child_group && add_group_id === item.id">
        <add-group
          :id="item.id"
          :number="`${int_number}${item.int_id}.`"
          @closeAddGroup="closeAddGroup"
          @groupAddedSuccessfully="groupAddedSuccessfully"
        />
      </template>
      <menu-item
        v-if="item.child_groups"
        :items="item.child_groups"
        :int_number="int_number + `${item.int_id}.`"
        :open_group="open_group"
        :update_menu="update_menu"
        :modal_remove="modal_remove"
        :current_ids="current_ids"
        :product_category="getCategory"
        :is_select_group="is_select_group"
        :close_add_group="closeAddGroup"
        :is_multiple="is_multiple"
        :sections="sections"
        :mass_add="mass_add"
        hide_extra_charge
        :close_add_parrent_group="closeAddParrentGroup"
        :selected_categories="selected_categories"
        @close_add_form="closeAddParrentGroup"
        @selected_group="selectedGroup"
        @select_group="selectGroup"
        @set-group-markup="setGroupMarkup"
        @add-section="addSection"
      />
    </li>
  </ul>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import AddGroup from './AddGroup.vue'
  import { getFlatChildGroups } from '@/utils/getFlatChildGroups'

  export default {
    name: 'MenuItem',
    components: {
      AddGroup
    },

    props: {
      items: {
        type: Array,
        default: () => []
      },
      sections: {
        type: Array,
        default: () => []
      },
      current_group: {
        type: String,
        default: () => null
      },
      selected_categories: {
        type: Array,
        default: () => []
      },
      is_multiple: {
        type: Boolean,
        default: false
      },
      int_number: {
        type: String,
        default: ''
      },
      mass_add: {
        type: Boolean,
        default: false
      },
      hide_extra_charge: {
        type: Boolean,
        default: false
      },
      open_group: Function,
      product_category: Object,
      update_menu: Function,
      modal_remove: Function,
      close_add_group: Function,
      close_add_form: Function,
      close_add_parrent_group: Function,
      current_ids: {
        type: Array,
        default: () => []
      },
      is_select_group: {
        type: Boolean,
        default: false
      },
      hide_control: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      add_child_group: false,
      add_group_id: '',
      rename: false,
      isFocused: false
    }),

    created() {
      document.addEventListener('focusin', this.focusChanged)
      document.addEventListener('focusout', this.focusChanged)
    },

    beforeDestroy() {
      document.removeEventListener('focusin', this.focusChanged)
      document.removeEventListener('focusout', this.focusChanged)
    },

    computed: {
      ...mapGetters({
        getPagination: 'settings/getPagination',
        getCurrentIds: 'products/getCurrentIds',
        current_category: 'products/getCurrentCategory',
        addgroupVisibleStatus: 'products/getAddgroupVisibleStatus',
        getCurrentCategoryMenuId: 'products/getCurrentCategoryMenuId',
        currentBranch: 'settings/getCurrentBranch',
        getSelectedCategories: 'products/getSelectedCategories'
      }),
      getCategory() {
        return this.product_category || this.current_category
      },
      ids: function () {
        return this.current_ids || []
      }
    },

    watch: {
      addgroupVisibleStatus(newVal) {
        if (newVal) this.closeAddGroup()
      }
    },

    methods: {
      ...mapActions({
        setPagination: 'settings/set_pagination',
        setCurrentIds: 'products/setCurrentIds',
        setCurrentCategory: 'products/setCurrentCategory',
        setAddgroupVisibleStatus: 'products/setAddgroupVisibleStatus',
        setSearchStr: 'products/setSearchStr',
        setPlaceholder: 'products/setPlaceholder',
        setCurrentCategoryMenuId: 'products/setCurrentCategoryMenuId',
        setSelectedCategories: 'products/setSelectedCategories'
      }),

      massAdd(item, number, index, value, clickCheck) {
        if (value) {
          this.setSelectedCategories(this.getSelectedCategories.filter((obj) => obj !== item.id))
        } else {
          this.setSelectedCategories([...this.getSelectedCategories, item.id])
        }
        setTimeout(() => this.selectCategory(item, number, index, !value, clickCheck), 10)
      },

      openGroup(item) {
        this.open_group(item)
      },

      addSection(item) {
        this.$emit('add-section', item)
      },

      async saveGroup(item) {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/updateCategory.gql'),
            variables: {
              id: item.id,
              name: item.name
            }
          })
          this.rename = false
        } catch (e) {
          this.$noty.error('Ошибка обновления')
          this.$emit('refetch-category')
        }
      },

      focusChanged(el) {
        if (el.originalTarget?.id === this.rename && el.type === 'focusin') this.isFocused = true
        if (el.originalTarget?.id === this.rename && el.type === 'focusout') this.isFocused = false
      },

      scrollToItem(id) {
        const el = this.$refs['item_' + id][0]
        if (el) {
          el.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
      },

      selectCategory(item, number, index, value, clickCheck) {
        this.setSearchStr('')
        this.setPlaceholder('Поиск товаров по названию, коду или постав...')
        const category = {
          ...item,
          child_groups: null,
          parent_groups: {
            path: item.path?.map((obj, i, arr) => {
              if (i === arr.length - 1) {
                return
              }
              return obj
            })
          },
          number
        }

        this.setCurrentCategoryMenuId(item.id)
        this.$emit('select_group', {
          category,
          value,
          clickCheck
        })
        if (this.is_select_group) return this.$emit('selected_group', category)
        if (!this.is_select_group) {
          this.setCurrentCategory(category)
        }
      },

      openSubMenu(item) {
        this.openGroup(item)
      },

      groupAddedSuccessfully(newCategory = null) {
        this.update_menu()

        if (!newCategory) return

        this.$nextTick(() => this.selectCategory(newCategory, newCategory.number))
      },

      showAddChildGroup({ id }) {
        this.setAddgroupVisibleStatus(true)
        this.closeAddGroup()
        this.$emit('close_add_form')
        this.$nextTick(() => {
          this.add_group_id = id
          this.add_child_group = true
        })
      },

      closeAddGroup() {
        this.add_child_group = false
        this.setAddgroupVisibleStatus(false)
        this.add_group_id = null
      },

      closeAddParrentGroup() {
        this.add_child_group = false
        this.setAddgroupVisibleStatus(false)
        this.add_group_id = null
        this.$emit('close_add_form')
      },

      removeMenuItem({ id, name }) {
        const group = {
          id: id,
          name: name
        }
        this.modal_remove(group)
      },
      updateName(item) {
        this.rename = item.id
        this.$nextTick(() => document.getElementById(item.id).select())
      },

      inCurrentItems(item) {
        return (
          this.ids?.includes(item?.id) &&
          getFlatChildGroups(item).findIndex((t) => t.id === this.current_group?.id) > -1
        )
      },

      selectedGroup(items) {
        this.$emit('selected_group', items)
      },
      selectGroup(items) {
        this.$emit('select_group', items)
      },
      setGroupMarkup(item) {
        this.$emit('set-group-markup', item)
      }
    }
  }
</script>

<style scoped lang="scss">
  .b-btns {
    display: flex;
    justify-content: flex-end;

    button {
      background: #fff;
      border-radius: 2px;
      margin-left: 4px;
      filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));

      &:hover {
        opacity: 0.98;
      }
    }
  }

  .menu-item {
    height: 100%;
    > li {
      position: relative;
      margin-bottom: 8px;
      padding-left: 15px;
      width: 100%;

      > .elm-buttont {
        height: 32px;
        display: flex;
        align-items: center;

        > .item {
          position: relative;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #888888;
          padding-left: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: none;
          background: none;
          text-align: left;
          width: 100%;

          .open-category {
            position: absolute;
            left: -4px;
            top: 4px;
            width: 15px;
            height: 15px;

            img {
              margin-top: -9px;
              transform: rotate(-90deg);
              opacity: 0.65;
            }
          }

          span {
            &.title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &:hover {
            color: #313131;
          }
        }

        .actions {
          display: none;
          align-items: center;
          position: relative;

          button {
            border: none;
            background: none !important;
            width: 21px !important;
            height: 21px !important;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              &.hover {
                display: none;
              }
            }

            &:hover {
              background: none !important;

              img {
                display: none;

                &.hover {
                  display: block;
                }
              }
            }
          }
        }
      }

      .menu-item {
        display: none;
        padding-left: 10px;
        margin-top: 10px;
        margin-left: 15px;
      }

      &.open {
        > .elm-buttont {
          .item {
            color: #313131;

            img {
              transform: rotate(0);
              opacity: 1;
            }
          }
        }

        > .menu-item {
          display: block;
        }
      }

      &.last {
        .item {
          padding-left: 0;
        }
      }

      &.current {
        > .elm-buttont {
          > .item {
            color: #313131;
            width: calc(100% - 50px);

            img {
              opacity: 1;
            }
          }

          .actions {
            display: flex;
          }
        }

        & > .elm-buttont {
          position: relative;
          z-index: 1;

          &:after {
            position: absolute;
            content: '';
            left: -15px;
            top: -5px;
            bottom: -5px;
            right: -15px;
            background: #f2f3f7;
            border-radius: 6px 0px 0px 6px;
            z-index: -2;
          }
        }
      }
    }
  }

  ::v-deep() {
    .menu-item {
      > li {
        > .elm-buttont {
          .actions {
            .dropdown.b-dropdown {
              width: 21px !important;
              height: 21px !important;

              button.btn.dropdown-toggle {
                width: 21px !important;
                height: 21px !important;
              }
            }
          }
        }

        &.current {
          .b-add-group {
            padding-right: 10px;
          }
        }
      }
    }
  }
</style>
