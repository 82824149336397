<template>
  <div class="select-container">
    <div
      ref="select"
      class="select"
      :class="[`select${item.id}`]"
      @click.stop="open"
    >
      <div class="select-block">
        <div
          v-if="!selected?.length"
          class="placeholder"
        >
          Выберите связь
        </div>
        <div
          v-else
          class="selected-item"
        >
          {{ selected[0].name?.length > 8 ? selected[0].name.slice(0, 8) + '...' : selected[0].name }}
          <div
            class="delete-selected"
            @click.stop="removeSelected"
          >
            <img
              src="/img/icons/close-comparison.svg"
              alt="remove"
            />
          </div>
        </div>
      </div>
      <div
        class="arrow-block"
        :class="{ rotate: opened }"
      >
        <img
          src="/img/icons/arrow-comparison.svg"
          alt="open"
        />
      </div>
    </div>
    <div
      class="menu"
      :class="[`menu${item.id}`, opened ? '' : 'menu__hidden']"
    >
      <div class="path">
        <p
          class="menu--title"
          :class="{ active: !selectedPath.length }"
          @click.stop="clearPath"
        >
          Группы
        </p>
        <p
          v-for="name of selectedPath"
          :key="name.id"
          :class="{ active: name.id === selectedPath[selectedPath.length - 1].id }"
          class="menu--title"
          @click.stop="selectPath(name)"
        >
          <img
            style="transform: rotate(-90deg)"
            src="/img/icons/arrow-comparison.svg"
            alt="open"
          />
          {{ name.name.length > 10 ? name.name.slice(0, 10) + '...' : name.name }}
        </p>
      </div>

      <ul class="menu--list">
        <li
          v-for="item in franchise_arr"
          :key="item.id"
          class="menu--item"
          @click.stop="select(item)"
        >
          <p>{{ item.name }}</p>
          <img
            v-if="!item?.last"
            style="transform: rotate(-90deg)"
            src="/img/icons/arrow-comparison.svg"
            alt="open"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ComparisonSelect',
    props: {
      franchise_arr: {
        type: Array,
        default: () => []
      },
      franchise_path: {
        type: Array,
        default: () => []
      },
      item: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        selectedPath: [],
        selected: [],
        opened: false
      }
    },
    mounted() {
      if (this.item.franchise_id?.id) {
        this.selected = [this.item.franchise_id]
      }
      this.$nextTick(() =>
        setTimeout(() => {
          const select = document.querySelector(`.select${this.item.id}`)
          const rect = select?.getBoundingClientRect()
          const drop = document.querySelector(`.menu${this.item.id}`)
          if (window.innerHeight - rect?.bottom - 200 < drop?.offsetHeight) {
            setTimeout(() => {
              this.$nextTick(() => {
                const dropOff = drop?.offsetHeight
                const selectOff = drop?.parentNode?.offsetHeight
                drop.style.top = -(dropOff + selectOff - 10) + 'px'
              })
            }, 0)
          } else {
            drop.style.top = 'initial'
          }
        }, 2)
      )
      addEventListener('click', this.outsideClick, true)
    },
    updated() {
      this.$nextTick(() =>
        setTimeout(() => {
          const select = document.querySelector(`.select${this.item.id}`)
          const rect = select?.getBoundingClientRect()
          const drop = document.querySelector(`.menu${this.item.id}`)
          if (window.innerHeight - rect?.bottom - 200 < drop?.offsetHeight) {
            setTimeout(() => {
              this.$nextTick(() => {
                const dropOff = drop?.offsetHeight
                const selectOff = drop?.parentNode?.offsetHeight
                drop.style.top = -(dropOff + selectOff - 10) + 'px'
              })
            }, 0)
          } else {
            drop.style.top = 'initial'
          }
        }, 2)
      )
    },
    destroyed() {
      removeEventListener('click', this.outsideClick)
    },
    methods: {
      selectPath(id) {
        const newPath = []
        for (let i = 0; i <= this.selectedPath.length - 1; i++) {
          if (this.selectedPath[i].id === id.id) {
            newPath.push(this.selectedPath[i])
            break
          }
          newPath.push(this.franchise_path[i])
        }
        this.selectedPath = newPath
        this.$emit(
          'setPath',
          newPath.map((obj) => obj.id)
        )
      },
      clearPath() {
        this.selectedPath = []
        this.$emit('setPath', [])
      },
      open() {
        this.opened = !this.opened
      },
      removeSelected() {
        this.selected = []
      },
      outsideClick(e) {
        if (!(this.$refs.select === e.target) && !e.target.closest('.menu')) {
          this.opened = false
          this.selectedPath = []
          this.$emit('setPath', [])
        }
      },
      async select(select) {
        if (select.last) {
          try {
            this.selected = [select]
            await this.$apollo.query({
              query: require('../../gql/LinkFranchiseCategory.graphql'),
              variables: {
                input: {
                  category_id: this?.item?.id,
                  fasol_category: select?.id
                }
              }
            })
            this.opened = false
            this.selectedPath = []
            this.$emit('setPath', [])
            this.$emit('refetch')
          } catch (e) {
            this.$noty.error('Произошла ошибка при связывании')
          }
        } else {
          this.selectedPath.push(select)
          this.$emit('setPath', [...this.franchise_path, select.id])
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .select-container {
    position: relative;
    overflow: visible;
  }
  .menu {
    right: 0;
    width: 333px;
    position: absolute;
    z-index: 2332;
    background-color: #fff;
    margin-top: 10px;
    border: 1px solid #e2e2e2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    &__hidden {
      visibility: hidden;
    }
    .path {
      display: flex;
      padding: 6.5px 12px;
      align-items: center;
      border-bottom: 1px solid #e2e2e2;
    }
    &--title {
      cursor: pointer;
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
      text-decoration: underline;
      text-underline-offset: 3.2px;
      margin: 0 7px 0 0;
      &.active {
        cursor: default;
        text-decoration: none;
      }
    }
    &--list {
      max-height: 230px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 8px;
        height: 7px;
        background: #f8f8f9;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #e1e3ea;
      }
    }
    &--item {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      min-height: 28px;
      padding: 10px 12px;
      p {
        margin: 0;
      }
      &:hover {
        background-color: #f8f8f9;
      }
    }
  }
  .select {
    cursor: pointer;
    display: flex;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    width: 186px;
    background: #ffffff;
    border: 1px solid #bcbcbc;
    border-radius: 2px;
    &-block {
      .placeholder {
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #bcbcbc;
      }
      .selected-item {
        cursor: default;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        background: #ddf3ff;
        border-radius: 3px;
        color: #00a3ff;
        padding: 3px 12px;
        .delete-selected {
          cursor: pointer;
          margin-left: 8px;
        }
      }
    }
    .arrow-block {
      transition: all 0.2s ease-in-out;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
</style>
