import gql from 'graphql-tag'

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $email: String
    $phone: String
    $first_name: String!
    $second_name: String!
    $last_name: String!
    $password: String!
  ) {
    Register(
      input: {
        email: $email
        phone: $phone
        first_name: $first_name
        second_name: $second_name
        last_name: $last_name
        password: $password
      }
    ) {
      token
      user {
        id
        email
        is_confirmed
        is_old
      }
    }
  }
`

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $name: String!
    $activities: [ID]
    $entity: CreateEntityInput!
    $branch_name: String
    $franchise: String
  ) {
    CreateCompany(
      input: { name: $name, activities: $activities, entity: $entity, branch_name: $branch_name, franchise: $franchise }
    ) {
      name
    }
  }
`

export const REMOVE_STORE_ITEM = gql`
  mutation RemoveStoreItem($id: ID!) {
    RemoveBranch(id: $id) {
      id
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser(
    $first_name: String
    $second_name: String
    $last_name: String
    $email: String!
    $phone: String
    $branches: [ID]!
    $company: ID!
  ) {
    CreateUser(
      input: {
        first_name: $first_name
        second_name: $second_name
        last_name: $last_name
        email: $email
        phone: $phone
        branches: $branches
        company: $company
      }
    ) {
      first_name
      last_name
      branches {
        name
      }
    }
  }
`

// export const UPDATE_BRANCH = gql`
//   mutation UpdateBranch($id: ID!) {
//     UpdateBranch(id: $id) {
//       id
//     }
//   }
// `

// export const REMOVE_STORE = gql`
//   mutation RemoveStore($id: ID!) {
//     RemoveStore(id: $id) {
//       id
//     }
//   }
// `

// export const REMOVE_ENTITY_STORE = gql`
//   mutation RemoveEntityStore($id: ID!) {
//     RemoveEntity(id: $id) {
//       id
//     }
//   }
// `

export const REMOVE_STORAGE = gql`
  mutation RemoveStorage($id: ID!) {
    RemoveStorage(id: $id) {
      id
    }
  }
`

export const REMOVE_USER = gql`
  mutation RemoveUser($id: ID!) {
    RemoveUser(id: $id) {
      id
    }
  }
`
