var render = function render(){
  var _vm$product, _vm$product2, _vm$product2$name, _vm$product3, _vm$product4, _vm$product4$measurem, _vm$product4$measurem2, _vm$product5, _vm$product6, _vm$product6$package$, _vm$product6$package$2, _vm$product7, _vm$product7$country, _vm$product8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "price-container"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "country"
  }, [_c('p', [_vm._v(_vm._s(_vm.companyName))])]), _c('div', {
    staticClass: "logo-block"
  }, [_c('div', {
    staticClass: "cart-block"
  }, [_c('left-line200on140-svg'), _c('cart200on140-svg')], 1), _c('fasol200on140-svg'), _c('right-line200on140-svg'), _c('green-line200on140-svg')], 1)]), _c('div', {
    staticClass: "name-block"
  }, [_c('b', [_vm._v(" " + _vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.name.length) > 43 ? ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$name = _vm$product2.name) === null || _vm$product2$name === void 0 ? void 0 : _vm$product2$name.slice(0, 43)) + '...' : (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name) + " ")])]), _c('div', {
    staticClass: "footer-block"
  }, [_c('div', {
    staticClass: "bar-block"
  }, [_c('div', {
    staticClass: "container-barcode"
  }, [_c('div', {
    staticClass: "barcode-block"
  }, [_c('div', {
    staticClass: "price-for"
  }, [_c('b', [_vm._v("Цена:")]), _c('p', [_vm._v("за " + _vm._s((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$measurem = _vm$product4.measurement) === null || _vm$product4$measurem === void 0 ? void 0 : (_vm$product4$measurem2 = _vm$product4$measurem.name) === null || _vm$product4$measurem2 === void 0 ? void 0 : _vm$product4$measurem2.slice(0, 2)))])]), _c('div', {
    staticStyle: {
      "width": "43px",
      "height": "20px"
    }
  }, [(_vm$product5 = _vm.product) !== null && _vm$product5 !== void 0 && _vm$product5.package ? _c('barcode-generator', {
    attrs: {
      "width": 43,
      "height": 20,
      "barcode": (_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : (_vm$product6$package$ = _vm$product6.package[0]) === null || _vm$product6$package$ === void 0 ? void 0 : (_vm$product6$package$2 = _vm$product6$package$.barcode) === null || _vm$product6$package$2 === void 0 ? void 0 : _vm$product6$package$2.barcode
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "price"
  }, [_c('b', [_vm._v(_vm._s(_vm.price))])])]), _c('div', {
    staticClass: "footer"
  }, [_c('p', [_vm._v(_vm._s(_vm.date))]), _c('p', [_vm._v(_vm._s((_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : (_vm$product7$country = _vm$product7.country) === null || _vm$product7$country === void 0 ? void 0 : _vm$product7$country.name))]), _c('p', [_vm._v("Арт: " + _vm._s((_vm$product8 = _vm.product) === null || _vm$product8 === void 0 ? void 0 : _vm$product8.articul))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }