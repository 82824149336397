export class ProductDescriptionModel {
  constructor(request) {
    this.id = request?.id
    this.branch = request?.branch
    this.description = request?.description
    this.product = request?.product
    this.integration = request?.integration
    this.tags = request?.tags
  }
}
