<template>
  <div class="header_blocks">
    <help-modal />
    <div
      class="breadcrumbs"
      style="margin-bottom: 0"
    >
      <div
        v-if="getBreadcrumbs.is_go_back"
        class="breadcrumbs__back cursor"
        @click="go_back"
      >
        <img
          class="img_back"
          src="/img/icons/breadcrumbs/arrow_back.svg"
          alt=""
        />
        Назад
      </div>
      <div
        class="breadcrumbs__title"
        :class="{ docs: !getBreadcrumbs.is_go_back }"
      >
        {{ getBreadcrumbs.title || page_title }}
      </div>
      <div
        v-if="this.getBreadcrumbs?.art"
        class="line-title"
      ></div>
      <p
        v-if="this.getBreadcrumbs?.art"
        class="article"
      >
        {{ 'Арт: ' + this.getBreadcrumbs?.art }}
      </p>
      <div class="breadcrumbs__extra d-flex">
        <template v-if="extra || getBreadcrumbs.number">
          <div class="b-number">№ {{ extra?.number || getBreadcrumbs.number }}</div>
        </template>
        <template v-if="extra || getBreadcrumbs.date">
          <div
            v-if="extra"
            class="b-date"
          >
            от {{ new Date(extra.date).toLocaleDateString() }}
          </div>
          <div
            v-else
            class="b-date"
          >
            от {{ new Date(getBreadcrumbs.date).toLocaleDateString() }}
          </div>
        </template>
        <template v-if="extra || this.getBreadcrumbs?.fixed === false || this.getBreadcrumbs?.fixed === true">
          <div
            class="b-status"
            :class="{ success: statusClass.class }"
          >
            Статус: {{ statusClass?.class ? 'Проведён' : 'Черновик' }}
          </div>
        </template>
        <template v-if="getBreadcrumbs?.scales">
          <div class="scales">
            <div
              v-if="getBreadcrumbs?.scales?.name"
              class="scales__name"
            >
              {{ getBreadcrumbs?.scales?.name }}
            </div>
            <div
              class="b-status"
              :class="{ success: getBreadcrumbs?.scales?.visible }"
            >
              Статус: {{ getBreadcrumbs?.scales?.visible ? 'Видымый' : 'Отключен' }}
            </div>
          </div>
        </template>
        <template v-if="$route.name === 'analytics.sales-analysis.report'">
          <div class="d-flex align-items-center justify-content-center">
            <div
              v-if="getAnalyticsParams.byRawMaterials"
              class="mr-3 raw"
            >
              Сырье
            </div>
            <b-dropdown
              class="dropdown-help"
              no-caret
              variant="none"
            >
              <template #button-content>
                <div class="dropdown-help">
                  <img
                    src="/img/icons/more.svg"
                    style="margin-right: 10px; transform: scale(1.3)"
                    alt=""
                  />
                </div>
              </template>
              <b-dropdown-item @click="setByRawMaterials({ byRawMaterials: false })">
                <div
                  class="d-flex"
                  style="margin-right: 20px"
                >
                  <div>Анализ товародвижения</div>
                  <img
                    src="/img/icons/icon-info.svg"
                    style="margin-left: 10px"
                    alt=""
                  />
                  <img
                    v-if="!getAnalyticsParams.byRawMaterials"
                    src="/img/icons/accept-black.svg"
                    style="margin-left: 10px"
                    alt=""
                  />
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="setByRawMaterials({ byRawMaterials: true })">
                <div class="d-flex">
                  <div>Анализ движения сырья</div>
                  <img
                    src="/img/icons/icon-info.svg"
                    style="margin-left: 10px"
                    alt=""
                  />
                  <img
                    v-if="getAnalyticsParams.byRawMaterials"
                    src="/img/icons/accept-black.svg"
                    style="margin-left: 10px"
                    alt=""
                  />
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template v-if="getBreadcrumbs?.discount?.value">
          <div class="discount-block">
            <div class="delimiter"></div>
            <div>Скидка {{ getBreadcrumbs?.discount?.value }} %</div>
          </div>
        </template>
      </div>
    </div>
    <div>
      <b-dropdown
        class="dropdown-help"
        no-caret
        variant="none"
      >
        <template #button-content>
          <div class="dropdown-help">
            <img
              src="/img/icons/help.svg"
              style="margin-right: 10px; transform: scale(1.3)"
              alt=""
            />
            Помощь
          </div>
        </template>
        <b-dropdown-item @click="openDocs">
          <img
            src="/img/icons/docs.svg"
            style="margin-right: 10px"
            alt=""
          />
          Справочный центр
        </b-dropdown-item>
        <b-dropdown-item @click="openModal">
          <div
            class="d-flex"
            style="margin-right: 15px"
          >
            <img
              src="/img/icons/send-help.svg"
              style="margin-right: 10px"
              alt=""
            />
            <div>Написать в поддержку</div>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { mapActions, mapGetters } from 'vuex'
  import HelpModal from '@/views/core/header/components/HelpModal'

  export default {
    components: { HelpModal },
    props: {
      is_go_back: Boolean,
      barcode_length: {
        type: Number
      },
      path: {
        type: Array,
        default: () => []
      },

      scales: {
        type: Object,
        default: null
      },

      page_name: {
        type: String,
        default: ''
      },

      title: {
        type: String,
        default: null
      },

      extra: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      ...mapGetters({
        getBreadcrumbs: 'breadcrumbs/current',
        getAnalyticsParams: 'analytics/getAnalyticsParams'
      }),
      statusClass: function () {
        if (this.getBreadcrumbs?.fixed === false || this.getBreadcrumbs?.fixed === true) {
          return {
            class: this.getBreadcrumbs?.fixed
          }
        }
        return {
          class: this.extra?.fixed
        }
      },
      page_title() {
        return this.title || this.$route.meta.title
      }
    },

    // data: function () {},

    mounted() {},

    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current',
        setByRawMaterials: 'analytics/setAnalyticsParams'
      }),
      convertDate(date) {
        if (date && date instanceof Date && !isNaN(date)) return formatDate(new Date(date), 'numeric')
        else return formatDate(new Date(), 'numeric')
      },

      openDocs() {
        window.open('https://docs.entersight.ru')
      },
      openModal() {
        this.$bvModal.show('help-modal')
      },

      go_back() {
        // const backPage = this.$route.meta.parrent || this.$route.matched[0].name
        // this.$router.push({ name: backPage })
        this.$router.back()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header_blocks {
    padding: 20px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 0px #e2e2e2;
    // margin-bottom: 24px;
  }
  .breadcrumbs__title {
    font-family: 'Gilroy-Medium', sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
    &.docs {
      margin: 0;
    }
  }
  .b-status {
    &.success {
      color: #00cb91 !important;
    }
  }
  .article {
    margin: 0;
    padding: 0;
    margin-left: 11px;
    height: 24px;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
  }
  .line-title {
    width: 1px;
    height: 12px;
    background-color: #bcbcbc;
    border-radius: 16px;
  }
  .scales {
    display: flex;
    &__name {
      border-left: 1px solid #bcbcbc;
      padding: 0 11px;
    }
  }
  .raw {
    display: flex;
    padding: 0px 7px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--text-system-blue-400, #00a3ff);
    color: #00a3ff;
  }

  .discount-block {
    display: flex;
    align-items: center;
  }

  .delimiter {
    width: 1px;
    height: 12px;
    border-radius: 14px;
    background: var(--Text-placeholder---gray300, #BCBCBC);
    margin-left: 11px;
    margin-right: 11px;
  }
</style>
