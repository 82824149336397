var render = function render(){
  var _vm$product, _vm$product$name, _vm$product2, _vm$product2$name, _vm$product3, _vm$product4, _vm$product4$retailPr, _vm$product4$retailPr2, _vm$product4$retailPr3, _vm$product4$retailPr4, _vm$product5, _vm$product5$retailPr, _vm$product6, _vm$product6$retailPr, _vm$product6$retailPr2, _vm$product6$retailPr3, _vm$product7, _vm$product7$retailPr, _vm$product7$retailPr2, _vm$product7$retailPr3, _vm$product8, _vm$product8$measurem, _vm$product8$measurem2, _vm$product9, _vm$product10, _vm$product10$package, _vm$product10$package2, _vm$product11, _vm$product11$country, _vm$product12;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "containers"
  }, [_c('div', {
    staticClass: "company"
  }, [_c('p', [_vm._v(_vm._s(_vm.companyName))])]), _vm.franchise === 'fasol' ? _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "logo-block"
  }, [_c('left-line200on280-svg'), _c('fasol200on280-svg'), _c('right-line200on280-svg')], 1), _c('green-line200on280-svg')], 1) : _vm._e(), _c('div', {
    staticClass: "content-block"
  }, [_c('b', [_vm._v(_vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$name = _vm$product.name) === null || _vm$product$name === void 0 ? void 0 : _vm$product$name.length) >= (_vm.franchise ? 55 : 65) ? ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$name = _vm$product2.name) === null || _vm$product2$name === void 0 ? void 0 : _vm$product2$name.slice(0, _vm.franchise ? 55 : 65)) + '...' : (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name))])]), _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "price"
  }, [_c('b', [_vm._v(_vm._s(1 >= ((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$retailPr = _vm$product4.retailPrice) === null || _vm$product4$retailPr === void 0 ? void 0 : (_vm$product4$retailPr2 = _vm$product4$retailPr.toFixed(2)) === null || _vm$product4$retailPr2 === void 0 ? void 0 : (_vm$product4$retailPr3 = _vm$product4$retailPr2.toString()) === null || _vm$product4$retailPr3 === void 0 ? void 0 : (_vm$product4$retailPr4 = _vm$product4$retailPr3.split('.')) === null || _vm$product4$retailPr4 === void 0 ? void 0 : _vm$product4$retailPr4.length) ? ((_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$retailPr = _vm$product5.retailPrice) === null || _vm$product5$retailPr === void 0 ? void 0 : _vm$product5$retailPr.toFixed(2)) + 'р.' + '00к.' : ((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : (_vm$product6$retailPr = _vm$product6.retailPrice) === null || _vm$product6$retailPr === void 0 ? void 0 : (_vm$product6$retailPr2 = _vm$product6$retailPr.toFixed(2)) === null || _vm$product6$retailPr2 === void 0 ? void 0 : (_vm$product6$retailPr3 = _vm$product6$retailPr2.toString()) === null || _vm$product6$retailPr3 === void 0 ? void 0 : _vm$product6$retailPr3.split('.')[0]) + 'р.' + ((_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : (_vm$product7$retailPr = _vm$product7.retailPrice) === null || _vm$product7$retailPr === void 0 ? void 0 : (_vm$product7$retailPr2 = _vm$product7$retailPr.toFixed(2)) === null || _vm$product7$retailPr2 === void 0 ? void 0 : (_vm$product7$retailPr3 = _vm$product7$retailPr2.toString()) === null || _vm$product7$retailPr3 === void 0 ? void 0 : _vm$product7$retailPr3.split('.')[1]) + 'к.'))])]), _c('div', {
    staticClass: "barcode"
  }, [_c('div', {
    staticClass: "barcode-block"
  }, [_c('div', {
    staticClass: "price-for"
  }, [_c('b', [_vm._v("Цена:")]), _c('p', [_vm._v("за " + _vm._s((_vm$product8 = _vm.product) === null || _vm$product8 === void 0 ? void 0 : (_vm$product8$measurem = _vm$product8.measurement) === null || _vm$product8$measurem === void 0 ? void 0 : (_vm$product8$measurem2 = _vm$product8$measurem.name) === null || _vm$product8$measurem2 === void 0 ? void 0 : _vm$product8$measurem2.slice(0, 2)))])]), _c('div', {
    staticStyle: {
      "width": "49px",
      "height": "23.4px"
    }
  }, [(_vm$product9 = _vm.product) !== null && _vm$product9 !== void 0 && _vm$product9.package ? _c('barcode-generator', {
    attrs: {
      "width": 49,
      "height": 23.4,
      "barcode": (_vm$product10 = _vm.product) === null || _vm$product10 === void 0 ? void 0 : (_vm$product10$package = _vm$product10.package[0]) === null || _vm$product10$package === void 0 ? void 0 : (_vm$product10$package2 = _vm$product10$package.barcode) === null || _vm$product10$package2 === void 0 ? void 0 : _vm$product10$package2.barcode
    }
  }) : _vm._e()], 1)])]), _c('div', {
    staticClass: "footer-text"
  }, [_c('p', [_vm._v(_vm._s(_vm.date))]), _c('p', [_vm._v(_vm._s((_vm$product11 = _vm.product) === null || _vm$product11 === void 0 ? void 0 : (_vm$product11$country = _vm$product11.country) === null || _vm$product11$country === void 0 ? void 0 : _vm$product11$country.name))]), _c('p', [_vm._v("Арт: " + _vm._s((_vm$product12 = _vm.product) === null || _vm$product12 === void 0 ? void 0 : _vm$product12.articul))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }