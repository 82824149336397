var render = function render(){
  var _vm$product, _vm$product2, _vm$product3, _vm$product3$promotio, _vm$priceMain, _vm$product$country$n, _vm$product4, _vm$product4$country, _vm$product$manufactu, _vm$product5, _vm$product5$manufact, _vm$product6, _vm$product7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-tag"
  }, [_c('div', {
    staticClass: "container-tag__head"
  }, [_c('div', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.date))]), _c('div', {
    staticClass: "entity"
  }, [_vm._v(_vm._s(_vm.companyName))])]), _c('div', {
    staticClass: "container-tag__product-name"
  }, [_vm._v(" " + _vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.print_name) || ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.name)) + " ")]), _c('div', {
    staticClass: "container-tag__price-block"
  }, [(_vm$product3 = _vm.product) !== null && _vm$product3 !== void 0 && (_vm$product3$promotio = _vm$product3.promotion) !== null && _vm$product3$promotio !== void 0 && _vm$product3$promotio.old_price ? _c('div', {
    staticClass: "price-content-old"
  }, [_c('div', {
    staticClass: "price-old"
  }, [_vm._v(_vm._s(_vm.priceMainPrev))]), _c('old-price-line-svg'), _c('div', {
    staticClass: "kopecks-block-old"
  }, [_c('div', {
    staticClass: "kopecks-old"
  }, [_vm._v(_vm._s(_vm.pricePartPrev))]), _c('div', {
    staticClass: "measurement-old"
  }, [_vm._v(" " + _vm._s('₽/' + _vm.measurement) + " ")])])], 1) : _vm._e(), _c('div', {
    staticClass: "price-content-current"
  }, [_c('div', {
    staticClass: "price-current"
  }, [_vm._v(_vm._s((_vm$priceMain = _vm.priceMain) !== null && _vm$priceMain !== void 0 ? _vm$priceMain : '-'))]), _c('div', {
    staticClass: "kopecks-block"
  }, [_c('div', {
    staticClass: "kopecks"
  }, [_vm._v(_vm._s(_vm.pricePart))]), _c('div', {
    staticClass: "measurement"
  }, [_vm._v("₽/" + _vm._s(_vm.measurement))])])]), _c('div', {
    staticClass: "price-qr"
  }, [_c('qrcode', {
    attrs: {
      "value": _vm.qrcode,
      "size": "71"
    }
  })], 1)]), _c('div', {
    staticClass: "container-tag__articul-content"
  }, [_c('div', {
    staticClass: "container-tag__barcode-block"
  }, [_c('barcode-generator', {
    attrs: {
      "barcode": _vm.getBarcode(_vm.product),
      "height": 83,
      "width": 216
    }
  })], 1), _c('div', {
    staticClass: "container-tag__product-info"
  }, [_c('div', [_vm._v(_vm._s((_vm$product$country$n = (_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$country = _vm$product4.country) === null || _vm$product4$country === void 0 ? void 0 : _vm$product4$country.name) !== null && _vm$product$country$n !== void 0 ? _vm$product$country$n : ''))]), _c('div', [_vm._v(_vm._s((_vm$product$manufactu = (_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$manufact = _vm$product5.manufacturer) === null || _vm$product5$manufact === void 0 ? void 0 : _vm$product5$manufact.name) !== null && _vm$product$manufactu !== void 0 ? _vm$product$manufactu : ''))]), _c('div', {
    staticClass: "container-tag__product-info-articul-and-plu"
  }, [_c('div', {
    staticClass: "container-tag__articul"
  }, [_vm._v(_vm._s((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : _vm$product6.articul))]), (_vm$product7 = _vm.product) !== null && _vm$product7 !== void 0 && _vm$product7.plu ? _c('div', {
    staticClass: "manufacturer"
  }, [_c('div', {
    staticClass: "plu-title"
  }, [_vm._v("PLU:")]), _vm._l(_vm.getPluArr, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "plu-content"
    }, [_c('div', {
      staticClass: "information__plu-text"
    }, [_vm._v(_vm._s(item))]), _vm.getPluArr[idx + 1] ? _c('div', {
      staticClass: "plu-delimiter"
    }) : _vm._e()]);
  })], 2) : _vm._e()])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }