<template>
  <b-modal
    id="barcode-prefix-modal"
    title="
    Префиксы весовых штрих-кодов"
    scrollable
    centered
    @shown="getData"
  >
    <div class="br-setting-block">
      <div class="br-entersight">
        <div class="br-title">Энтерсайт</div>
        <div class="br-radio">
          <e-radio
            :checked="entersight === '20'"
            @click="setEntersight('20')"
          />
          20
        </div>
        <div class="br-radio">
          <e-radio
            :checked="entersight === '21'"
            @click="setEntersight('21')"
          />
          21
        </div>
        <div class="br-radio">
          <e-radio
            :checked="entersight === '22'"
            @click="setEntersight('22')"
          />
          22
        </div>
        <div class="br-radio">
          <e-radio
            :checked="entersight === '23'"
            @click="setEntersight('23')"
          />
          23
        </div>
      </div>
      <div class="br-external">
        <div class="br-title">Поставщик</div>
        <div
          v-for="i of ['20', '21', '22', '23', '24', '25', '26', '27', '28']"
          :key="i"
          class="br-radio"
        >
          <e-switch
            class="switch"
            :value="checkExternal(i)"
            @input="clickExternal(i)"
          />
          {{ i }}
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex">
        <e-button
          variant="primary"
          @click="updateBcPrefix"
        >
          Сохранить
        </e-button>
        <e-button
          variant="outline-primary"
          @click="close"
        >
          Отмена
        </e-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import ESwitch from '@/views/settings/components/access_rights/ESwitch.vue'

  export default {
    name: 'BarcodePrefixModal',
    components: { ESwitch },
    apollo: {
      BarcodePrefixes: {
        fetchPolicy: 'no-cache',
        query: require('./gql/BarcodePrefixes.graphql'),
        result({ data }) {
          this.entersight = data.BarcodePrefixes.int_weight_prefix[0] || ''
          this.external = data.BarcodePrefixes.ext_weight_prefix || []
          this.prefix = data.BarcodePrefixes.prefix || []
        }
      }
    },
    data() {
      return {
        entersight: '20',
        external: [],
        prefix: []
      }
    },
    methods: {
      setEntersight(value) {
        this.entersight = value
      },
      checkExternal(value) {
        return this.external.some((el) => el === value) ? 'all' : 'off'
      },
      clickExternal(value) {
        if (!this.external.some((el) => el === value)) {
          this.external.push(value)
        } else {
          this.external = this.external.filter((el) => el !== value)
        }
      },
      async updateBcPrefix() {
        await this.$apollo.mutate({
          mutation: require('./gql/UpdateBarcodePrefixes.graphql'),
          variables: {
            input: {
              int_weight_prefix: [this.entersight],
              ext_weight_prefix: this.external,
              prefix: this.prefix
            }
          }
        })
        this.close()
      },
      close() {
        this.$bvModal.hide('barcode-prefix-modal')
      },
      getData() {
        this.$apollo.queries.BarcodePrefixes.refetch()
      }
    }
  }
</script>

<style scoped lang="scss">
  .br-title {
    color: #313131;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    margin-bottom: 16px;
  }

  .br-radio {
    display: flex;
    align-items: center;
    height: 22px;
    margin-bottom: 12px;
    .switch {
      margin-right: 16px;
    }
  }
  .br-entersight {
    border-right: 1px solid var(--gray-gray-100, #e2e2e2);
    padding: 16px;
  }
  .br-external {
    padding: 16px;
  }
  .br-setting-block {
    border-radius: 4px;
    border: 1px solid var(--gray-gray-100, #e2e2e2);
    display: flex;

    .br-entersight {
      flex: 1;
    }

    .br-external {
      flex: 1;
    }
  }
</style>
