<template>
  <svg
    class="line__logo"
    width="20"
    height="2"
    viewBox="0 0 20 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.9628V0.0146484H16.884L19.4815 1.9628H0Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'LeftLine200on280Svg'
  }
</script>
<style scoped lang="scss"></style>
