export class AccountModel {
  constructor(request = {}) {
    this.bank = request?.bank
    this.bik = request?.bik
    this.check_acc = request?.check_acc
    this.comment = request?.comment
    this.corr_acc = request?.corr_acc
    this.id = request?.id
    this.main = request?.main
    this.balance = request?.balance
  }
}
