var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('b-row', [_vm.printers.length > 0 ? _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Принтер")]), _c('i-select', {
    attrs: {
      "value": _vm.getCurrentPrinterDevice
    },
    on: {
      "input": _vm.changePrinterDevice
    }
  }, [_c('i-option', {
    attrs: {
      "value": _vm.printerDefault
    }
  }, [_vm._v(" По умолчанию ")]), _vm._l(_vm.printers, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2)], 1)]) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Печатная форма")]), _c('i-select', {
    attrs: {
      "disabled": _vm.formats.length === 1,
      "default-label": "6 x 4"
    },
    model: {
      value: _vm.format,
      callback: function ($$v) {
        _vm.format = $$v;
      },
      expression: "format"
    }
  }, _vm._l(_vm.formats, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Цена за")]), _c('i-select', {
    attrs: {
      "default-label": "6 x 4"
    },
    on: {
      "input": _vm.weightFormatChange
    },
    model: {
      value: _vm.weightFormat,
      callback: function ($$v) {
        _vm.weightFormat = $$v;
      },
      expression: "weightFormat"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "gram"
    }
  }, [_vm._v("100 г")]), _c('i-option', {
    attrs: {
      "value": "kilogram"
    }
  }, [_vm._v("1 кг")])], 1)], 1)])], 1), _vm.format === '6x3.9' ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Акционные ценники")]), _c('i-select', {
    attrs: {
      "disabled": _vm.type_price_tags.length === 1,
      "default-label": "Выгодно"
    },
    model: {
      value: _vm.type_price_tag,
      callback: function ($$v) {
        _vm.type_price_tag = $$v;
      },
      expression: "type_price_tag"
    }
  }, _vm._l(_vm.type_price_tags, function (item, index) {
    return _c('i-option', {
      key: index,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "card mt-4 print-products"
  }, [_c('b-table', {
    ref: "table_products",
    staticClass: "table-products mt-4",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.products,
      "empty-filtered-text": "Не выбраны ценники для печати",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, {
      key: "head(print)",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-form-checkbox', {
          model: {
            value: _vm.selected_all,
            callback: function ($$v) {
              _vm.selected_all = $$v;
            },
            expression: "selected_all"
          }
        }), _vm._v(" Печать ")], 1)];
      },
      proxy: true
    }, {
      key: "cell(print)",
      fn: function (data) {
        var _vm$printProducts$dat;

        return [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$printProducts$dat = _vm.printProducts[data.item.id]) === null || _vm$printProducts$dat === void 0 ? void 0 : _vm$printProducts$dat.enabled
          },
          on: {
            "change": function (value) {
              return _vm.setSelected(data.item.id, value);
            }
          }
        })];
      }
    }, {
      key: "cell(count)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticClass: "input-count",
          attrs: {
            "type": "number",
            "value": 1
          },
          on: {
            "input": function (value) {
              return _vm.setSelectedProductPrintCount(data.item.id, value);
            }
          }
        })];
      }
    }])
  })], 1)]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "card p-0 print-right"
  }, [_c('div', {
    staticClass: "print-right-header d-flex align-content-center"
  }, [_c('div', {
    staticClass: "print-zoom-actions ml-auto"
  }, [_c('button', {
    staticClass: "btn btn-light",
    attrs: {
      "disabled": _vm.zoom === 3
    },
    on: {
      "click": _vm.onZoomPlus
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/btn-plus-dark.svg",
      "alt": ""
    }
  })]), _c('button', {
    staticClass: "btn btn-light",
    attrs: {
      "disabled": _vm.zoom === 1
    },
    on: {
      "click": _vm.onZoomMinus
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/btn-minus-dark.svg",
      "alt": ""
    }
  })]), _c('i-select', {
    staticClass: "print-zoom-select",
    attrs: {
      "placeholder": "Масштаб"
    },
    on: {
      "change": _vm.resetPrintMainScroll
    },
    model: {
      value: _vm.zoom,
      callback: function ($$v) {
        _vm.zoom = $$v;
      },
      expression: "zoom"
    }
  }, [_c('i-option', {
    attrs: {
      "value": 1
    }
  }, [_vm._v("50%")]), _c('i-option', {
    attrs: {
      "value": 2
    }
  }, [_vm._v("100%")]), _c('i-option', {
    attrs: {
      "value": 3
    }
  }, [_vm._v("150%")])], 1)], 1)]), _c('div', {
    ref: "printMain",
    staticClass: "print-right-main",
    class: {
      half: _vm.zoom === 1,
      full: _vm.zoom === 3
    }
  }, [_c('div', {
    staticClass: "sized-page",
    attrs: {
      "id": "block-to-print"
    }
  }, [_c('div', {
    staticClass: "print-right-page"
  }, [_c('div', {
    staticClass: "grid mb-3",
    style: {
      'grid-template-columns': _vm.grid_columns.grid,
      width: _vm.width_grid
    }
  }, [_vm._l(_vm.showProducts, function (product, index) {
    return _c('div', {
      key: index + (product === null || product === void 0 ? void 0 : product.id),
      staticClass: "print-grid-item",
      style: {
        'aspect-ratio': _vm.grid_columns.aspect
      }
    }, [_vm.format === '6x9' ? _c('tag4x6', {
      ref: "price-preview",
      refInFor: true,
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x6' ? _c('tag6x6', {
      ref: "price-preview",
      refInFor: true,
      style: _vm.tag_print_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x4' ? _c('price-tag', {
      ref: "price-preview",
      refInFor: true,
      style: _vm.tag_preview_style,
      attrs: {
        "promotion": _vm.promotion,
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '19x14' ? _c('tag200on140', {
      ref: "price-preview",
      refInFor: true,
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '9.5x14' ? _c('tag100on140', {
      ref: "price-preview",
      refInFor: true,
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '19x26' ? _c('tag200on280', {
      ref: "price-preview",
      refInFor: true,
      style: _vm.tag_preview_style,
      attrs: {
        "franchise": _vm.getCurrentBranch.franchise,
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === '2on1big' ? _c('two-for-one-big', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'profitable' ? _c('profitable', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'discount' ? _c('discount', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'superprice' ? _c('super-price', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'exellentchoice' ? _c('excellent-choice', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === '2on1' ? _c('two-for-one', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'stock' ? _c('stock', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'seizemoment' ? _c('seize-moment', {
      ref: "price-preview",
      refInFor: true,
      staticStyle: {
        "position": "absolute"
      },
      style: _vm.tag_preview_style,
      attrs: {
        "weight_format": _vm.weightFormat,
        "product": product
      }
    }) : _vm._e()], 1);
  })], 2)])]), _c('portal', {
    attrs: {
      "to": "clear"
    }
  }, [[_c('div', {
    staticClass: "pricetag-center"
  }, _vm._l(_vm.pages, function (page, pageIndex) {
    return _c('div', {
      key: pageIndex,
      staticClass: "print-grid",
      style: {
        'grid-template-columns': _vm.grid_columns.grid,
        width: _vm.width_grid,
        'page-break-after': _vm.pages.length - 1 !== pageIndex ? 'always' : 'avoid'
      },
      attrs: {
        "id": "print-grid"
      }
    }, [_vm._l(_vm.showProducts.slice(_vm.perPages * (page - 1), _vm.perPages * page), function (product, index) {
      return _c('div', {
        key: index + (product === null || product === void 0 ? void 0 : product.id),
        ref: "print-grid",
        refInFor: true,
        staticClass: "print-grid-item",
        style: {
          'aspect-ratio': _vm.grid_columns.aspect
        }
      }, [_vm.format === '6x9' ? _c('tag4x6', {
        ref: "price-print",
        refInFor: true,
        style: _vm.tag_print_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x6' ? _c('tag6x6', {
        ref: "price-print",
        refInFor: true,
        style: _vm.tag_print_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x4' ? _c('price-tag', {
        ref: "price-print",
        refInFor: true,
        style: _vm.tag_print_style,
        attrs: {
          "promotion": _vm.promotion,
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '19x14' ? _c('tag200on140', {
        ref: "price-print",
        refInFor: true,
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '9.5x14' ? _c('tag100on140', {
        ref: "price-print",
        refInFor: true,
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '19x26' ? _c('tag200on280', {
        ref: "price-print",
        refInFor: true,
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === '2on1big' ? _c('two-for-one-big', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_print_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'profitable' ? _c('profitable', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'discount' ? _c('discount', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'superprice' ? _c('super-price', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'exellentchoice' ? _c('excellent-choice', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === '2on1' ? _c('two-for-one', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'stock' ? _c('stock', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e(), _vm.format === '6x3.9' && _vm.type_price_tag === 'seizemoment' ? _c('seize-moment', {
        ref: "price-print",
        refInFor: true,
        staticStyle: {
          "position": "absolute"
        },
        style: _vm.tag_preview_style,
        attrs: {
          "weight_format": _vm.weightFormat,
          "product": product
        }
      }) : _vm._e()], 1);
    })], 2);
  }), 0)]], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }