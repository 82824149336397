export const mutations = {
  SET_IS_NAME_ERROR(state, bool) {
    state.isNameError = bool
  },
  SET_IS_DATE_START_ERROR(state, bool) {
    state.isDateStartError = bool
  },
  SET_IS_DATE_END_ERROR(state, bool) {
    state.isDateEndError = bool
  }
}
