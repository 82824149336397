import gql from 'graphql-tag'

export const ADD_STORE = gql`
  mutation CreateBranch($name: String!, $address: AddressInput, $newStorage: [String]) {
    CreateBranch(input: { name: $name, address: $address, new_storage_names: $newStorage }) {
      name
      id
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: ID!, $name: String) {
    UpdateCompany(input: { id: $id, name: $name }) {
      name
      id
    }
  }
`

export const REMOVE_COMPANY = gql`
  mutation RemoveCompany($id: ID!) {
    RemoveCompany(id: $id) {
      name
      id
    }
  }
`
