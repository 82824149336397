<template>
  <div class="main-cont">
    <div
      style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; width: 100%"
      class="header-label"
      :class="format === '6x4' ? 'format-6x4' : ''"
    >
      <span
        class="artic frst"
        :class="format === '6x4' ? 'artic-6x4' : ''"
      >
        {{ product?.articul }}
      </span>
      <div
        class="artic company"
        :class="format === '6x4' ? 'artic-6x4 artic-6x4-name' : ''"
      >
        {{ companyName }}
      </div>
    </div>
    <div
      class="name-block"
      :class="[format === '6x4' && 'name-6x4']"
      :style="styleName"
    >
      <span
        class="prd-name"
        :class="format === '6x4' ? 'prd-name-6x4' : ''"
      >
        {{
          product?.print_name
            ? product.print_name
            : product?.name?.length > (format !== '6x4' ? 41 : 110)
            ? product?.name.slice(0, format !== '6x4' ? 38 : 100) + '...'
            : product?.name
        }}
      </span>
    </div>
    <div :class="[format === '6x4' && 'd-flex position-relative align-items-center footer-6x4']">
      <div
        v-if="show_price"
        class="price-label"
      >
        <div
          v-if="show_price"
          class="price-label__full"
        >
          {{ getPrice }}
        </div>
        <div
          v-if="show_price"
          class="price-label__kop"
        >
          <p>{{ getKopecks }}</p>
          <p class="text-nowrap">₽/{{ product?.measurement?.name }}</p>
        </div>
      </div>
      <div class="barcode-block">
        <div
          class="barcode"
          style="align-self: center"
        >
          <template v-if="product?.package?.[0]?.barcode?.barcode">
            <barcode-generator
              :barcode="getBarcode(product)"
              :height="60"
              :width="format === '6x4' ? 140 : 230"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import formatDate from '../../../utils/formatDate'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  import { mapGetters } from 'vuex'

  export default {
    components: { BarcodeGenerator },
    props: {
      product: {
        type: Object,
        default: () => {}
      },
      show_price: {
        type: Boolean,
        default: false
      },
      weight_format: String,
      format: {
        type: String,
        default: () => ''
      }
    },

    data: () => ({
      currentDate: formatDate(new Date(), 'numeric')
    }),

    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      styleName() {
        return { height: this.format === '6x4' ? '68px' : this.format !== '6x4' && !this.show_price ? '79px' : '42px' }
      },
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return (
          entity_name(this.product?.entity) || entity_name(this.getCurrentBranch?.entities?.[0]) || this.company_name
        )
      },
      getKopecks() {
        if (this.product?.retailPrice) {
          const kop = (this.product?.retailPrice?.toFixed(2) + '').split('.')?.[1]
          if (kop.length === 2) {
            return kop
          } else if (kop.length === 1) return kop + '0'
        }
        return '00'
      },
      getPrice() {
        if (this.product?.retailPrice) {
          return (this.product?.retailPrice + '').split('.')?.[0]
        }
        return 0
      },
      priceMain() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return Math.trunc(price)
      },
      pricePart() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return (+price % 1).toFixed(2).split('.')[1]
      },
      qrcode() {
        const barcode = this.product?.package?.[0]?.barcode?.barcode || '-'
        const article = this.product?.articul || '-'
        const price = this.product?.retailPrice.toFixed(2) || '-'

        return `${barcode} ${article} ${price}`
      },
      measurement() {
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') return '100г'
        return this.product?.measurement?.name?.toLowerCase()
      }
    },

    mounted() {},

    methods: {
      getBarcode(product) {
        let barcode = product.package[0].barcode.barcode
        if (['КГ.'].includes(product.measurement?.name)) {
          barcode += '01000'
          return barcode + this.getCheckSum(barcode)
        }
        return barcode
      },
      getCheckSum(ean) {
        if (!ean || ean.length !== 12) return ''
        const multiply = [1, 3]
        let total = 0
        ean.split('').forEach((letter, index) => {
          total += parseInt(letter, 10) * multiply[index % 2]
        })
        const base10Superior = Math.ceil(total / 10) * 10
        return base10Superior - total
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-label {
    position: relative;
    padding-bottom: 2px;
  }
  .main-cont {
    width: 260px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 5px !important;
  }

  .prd-name {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    line-height: 20px;
    font-size: 20px;
    letter-spacing: 1px;
    color: #000000;
    text-align: center;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .prd-name-6x4 {
    position: relative;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    line-height: 17px;
    font-size: 17px;
    width: 100%;
    color: #000000;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .artic {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: black;
    text-align: right;
    align-self: flex-end;
    &.company {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .artic-6x4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    color: black;
    text-align: right;
    align-self: flex-start;
  }

  .frst {
    padding-right: 16px;
  }

  .barcode-body {
    font-family: 'EAN', sans-serif;
    font-size: 90px;
    line-height: 90px;
  }

  .barcode-row {
    display: flex;
  }

  .price-tag {
    width: 100%;
    padding: 5px;
    position: relative;
    color: #000;
    user-select: none;
    display: flex;
    flex-direction: column;
    flex: 1;

    .header {
      text-align: center;
      overflow: hidden;
      color: #000000;
      font-size: 11px;
    }

    .name {
      font-weight: 600;
      font-size: 22px;
      line-height: 23px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .desc {
      font-weight: bold;
      white-space: nowrap;
      font-size: 16px;
    }

    .price-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .bottom {
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    .code {
      font-size: 11px;
    }

    .price {
      margin: 1px 0;
      display: flex;
      align-items: center;

      &-total {
        font-size: 36px;
        font-weight: bold;
        margin-right: 5px;
        line-height: 1;
      }

      &-part {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.2;
      }
    }

    .currency {
      line-height: 1.2;
      font-size: 12px;
    }

    .country,
    .manufacturer {
      font-size: 11px;
    }

    .barcode {
      margin-right: 10px;
      flex: 1;
      //height: 50px;
      &-graph {
        height: 30px;
        overflow: hidden;
        margin-left: -5px;
      }
    }

    ::v-deep .vue-barcode-element {
      width: 120px;
      height: auto;
      margin-top: -10px;
      margin-right: -10px;
    }
  }
  .price-label {
    height: 28px;
    display: flex;
    margin-bottom: 6px;
    margin-top: 3px;
    justify-content: center;
    &__full {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      height: 25px;
      display: flex;
      align-items: center;
      color: #000000;
    }
    &__kop {
      margin-left: 5px;
      p {
        height: 14px;
        margin-bottom: 0;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }
  .barcode-block {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  @media print {
    .prd-name {
      margin-bottom: 10px;
    }

    .barcode-block {
      margin-top: 3px;
    }

    .prd-name-6x4 {
      margin-bottom: 10px;
    }
  }
  .footer-6x4 {
    bottom: -5px;
  }
  .name-block {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    &.name-6x4 {
    }
  }
</style>
