var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "comparison-modal",
      "hide-header": "",
      "hide-footer": "",
      "centered": ""
    },
    on: {
      "hide": _vm.closeModal
    }
  }, [_c('div', {
    staticClass: "container-comaparison"
  }, [_c('h1', {
    staticClass: "title"
  }, [_vm._v("Связка моих групп товаров с группами франшизы")]), _c('div', {
    staticClass: "groups-block"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('p', [_vm._v("Ваше наименование")]), _c('p', [_vm._v("Наименование франшизы")])]), _c('div', {
    staticClass: "content-comaprison"
  }, [_vm.loading ? _c('div', {
    staticStyle: {
      "width": "100%",
      "display": "flex",
      "justify-content": "center",
      "height": "100%",
      "align-items": "center"
    }
  }, [_c('e-spinner', {
    attrs: {
      "width": "35",
      "height": "35"
    }
  })], 1) : _vm.error ? _c('div', [_c('b', [_vm._v("Ошибка на сервере")])]) : _vm._l(_vm.menu_arr, function (item) {
    return _c('comparison-item', {
      key: item.id,
      attrs: {
        "opened": _vm.opened,
        "item": item,
        "franchise_arr": _vm.franchise_arr,
        "category_path": _vm.category_path,
        "franchise_path": _vm.franchise_path
      },
      on: {
        "setItemName": _vm.setItemName,
        "refetch": function () {
          return _vm.$apollo.queries.ReadCategoryByPath.refresh();
        },
        "set_category_path": _vm.set_category_path,
        "set_franchise_path": _vm.setFranchisePath,
        "openCategory": function (id) {
          return _vm.openCategory(id);
        }
      }
    });
  })], 2)])]), _c('div', {
    staticClass: "buttons"
  }, [_c('e-button', {
    attrs: {
      "variant": "primary",
      "size": "m"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal('ok');
      }
    }
  }, [_vm._v(" Закрыть ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }