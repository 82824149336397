<template>
  <section id="resizeable-table">
    <div
      no-body
      class="p-0"
      :class="inside_card ? 'card' : 'resizeable-table'"
      style="flex: 1; height: 100%; overflow: hidden"
    >
      <sort-table-modal
        :fields="fields"
        :default_fields="default_fields"
        :no_drag="no_drag"
        :id_modal="sort_table_modal_id"
        @set-default="setDefault"
        @change="changeFields"
      />
      <slot name="table_header"></slot>
      <div
        class="table-inner"
        style="height: 100%"
      >
        <b-table-simple
          responsive
          hover
          :class="'table-docs ' + this.table_name + ' table-fixed'"
          fixed
          :sticky-header="height"
          bordered
          no-border-collapse
          :table-class="'resizable-table ' + this.table_name"
        >
          <b-thead>
            <b-tr>
              <b-th
                v-for="(td, index) in fields.filter((el) => el.checked)"
                :key="index"
                :class="
                  (first_fixed && index === 0 ? 'first-fixed ' : '') +
                  (td.class + ' field-column unselectable text-center text-nowrap ' + td.sortable ? 'cursor' : '')
                "
                :colspan="td.children?.filter((el) => el.checked)?.length || 1"
                :rowspan="td.children?.filter((el) => el.checked)?.length ? 1 : 2"
                :style="{
                  'min-width': td.width + 'px',
                  width: td.width + 'px'
                }"
              >
                <div
                  class="td-div"
                  style="display: flex; flex-direction: row; align-items: center; justify-content: center"
                >
                  <slot :name="'head_' + td.key">
                    <div
                      class="td-inner"
                      style="display: flex; flex-direction: row; align-items: center; justify-content: center"
                      @click="change_sort(td)"
                    >
                      <span>{{ td.label }}</span>
                      <img
                        v-if="td.sortable && !td.sort"
                        class="ml-1 no-hover"
                        src="/img/icons/table/sort_none.svg"
                      />
                      <img
                        v-if="td.sortable && !td.sort"
                        class="ml-1 sort-hover"
                        src="/img/icons/table/sort_hover.svg"
                      />
                      <img
                        v-if="td.sort"
                        src="/img/icons/table/hover_active.svg"
                        class="cursor img-sorting ml-1"
                        :style="td.sort === 'ASC' ? 'transform:rotate(180deg)' : ''"
                        style="transition: 150ms"
                        alt=""
                      />
                    </div>
                  </slot>
                  <span
                    v-if="td.hint?.length"
                    class="ml-2"
                  >
                    <img
                      v-b-tooltip.hover.top
                      src="/img/icons/icon-info.svg"
                      :title="td.hint"
                      height="12"
                      width="12"
                    />
                  </span>
                  <div
                    v-if="resizable"
                    class="resize-field"
                    @mousedown="(el) => mouseDown(el, td)"
                  >
                    &nbsp;
                  </div>
                </div>
              </b-th>
            </b-tr>
            <b-tr v-if="fields.filter((el) => el.checked && el.children?.filter((el) => el.checked)?.length)?.length">
              <b-th
                v-for="(tr, index) in fields
                  .filter((el) => el.checked && el.children?.filter((el) => el.checked)?.length)
                  .map((el) => el.children.filter((el) => el.checked))
                  .flat()"
                :key="index"
                class="center text-nowrap children"
                style="border-right: 1px solid #e1e1e1; overflow: hidden; border-top: none !important"
              >
                <slot :name="'children_' + tr.key">
                  <div @click="change_sort(tr)">
                    {{ tr.label }}
                    <img
                      v-if="tr.sortable && !tr.sort"
                      class="ml-1 no-hover"
                      src="/img/icons/table/sort_none.svg"
                    />
                    <img
                      v-if="tr.sort"
                      src="/img/icons/table/hover_active.svg"
                      class="cursor img-sorting ml-1"
                      :style="tr.sort === 'ASC' ? 'transform:rotate(180deg)' : ''"
                      style="transition: 150ms"
                      alt=""
                    />
                  </div>
                </slot>
              </b-th>
            </b-tr>
          </b-thead>

          <b-thead
            v-if="top_sticky_row && !busy"
            id="top-sticky-row"
            style="position: sticky; top: 54px"
          >
            <b-tr
              v-for="(tr, tr_index) in 1"
              :id="tr.id"
              :key="tr_index"
            >
              <b-td
                v-for="(field, index) in checkedFields"
                :key="index"
              >
                <slot
                  :name="'top-sticky-row_' + field.key"
                  :value="tr[field.key]"
                  :item="tr"
                  :index="tr_index"
                >
                  {{ tr[field.key] }}
                </slot>
              </b-td>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr v-if="busy">
              <td
                :colspan="
                  fields?.length +
                  (fields
                    .filter((el) => el.checked && el.children?.filter((el) => el.checked)?.length)
                    ?.map((el) => el.children.filter((el) => el.checked))
                    .flat()?.length ?? 0) -
                  (table_name == 'order-list' ? 1 : 0)
                "
              >
                <div class="text-center">
                  <b-spinner
                    variant="primary"
                    label="Загрузка..."
                  />
                  <div class="mt-2"><strong>Загрузка...</strong></div>
                </div>
              </td>
            </b-tr>
            <template v-else>
              <b-tr
                v-for="(tr, tr_index) in items"
                :id="tr.id"
                :key="tr_index"
                class="cursor"
                :style="{
                  'background-color': tr.rowTableColor ? tr.rowTableColor : ''
                }"
                @click="onRowClick(tr)"
              >
                <b-td
                  v-for="(field, index) in checkedFields"
                  :key="index"
                  style="overflow: hidden"
                  :style="{
                    'background-color': tr.rowTableColor ? tr.rowTableColor : ''
                  }"
                  class="text-nowrap"
                  :class="{
                    'position-relative': field.key === 'id',
                    'first-fixed': first_fixed && index === 0,
                    [field.class]: !!field.class
                  }"
                  :colspan="(field.default_colspan || 0) + field.children?.filter((el) => el.checked)?.length || 1"
                >
                  <slot
                    :name="'body_' + field.key"
                    :value="tr[field.key]"
                    :item="tr"
                    :index="tr_index"
                  >
                    <template v-if="field.slot_builder_name">
                      <slot
                        :name="field.slot_builder_name"
                        :parent="field.parent"
                        :field="field"
                        :item="tr"
                      >
                        {{ tr[field.key] }}
                      </slot>
                    </template>
                    <template v-else>
                      {{ tr[field.key] }}
                    </template>
                  </slot>
                </b-td>
              </b-tr>
            </template>
            <b-tr v-if="items?.length === 0 && !busy">
              <td
                :colspan="
                  fields.length +
                  (fields
                    .filter((el) => el.checked && el.children?.filter((el) => el.checked)?.length)
                    ?.map((el) => el.children?.filter((el) => el.checked))
                    .flat()?.length ?? 0) -
                  (table_name == 'order-list' ? 1 : 0)
                "
              >
                <p class="center mt-3">{{ empty_text }}</p>
              </td>
            </b-tr>
            <b-tr v-if="show_product_search && !scrollExist">
              <b-td :colspan="fields.filter((el) => el.checked).length">
                <slot name="productSearch"></slot>
              </b-td>
            </b-tr>
            <div
              v-if="items?.length && foot_row"
              class="info-table"
            >
              <slot name="infoTable"></slot>
            </div>
          </b-tbody>
          <b-tfoot v-if="foot && !busy">
            <b-tr
              v-for="(tr, tr_index) in foot"
              :id="tr.id"
              :key="tr_index"
              class="foot"
            >
              <b-td
                v-for="(field, index) in checkedFields"
                :key="index"
                style="overflow: hidden; border-right: none; border-left: none; border-top: none"
                class="text-nowrap"
                :class="first_fixed && index === 0 ? 'first-fixed foot ' : ''"
                :colspan="(field.default_colspan || 0) + field.children?.filter((el) => el.checked)?.length || 1"
              >
                <slot
                  :name="'foot_' + field.key"
                  :value="tr[field.key]"
                  :item="tr"
                  :index="tr_index"
                >
                  <template v-if="field.slot_builder_name">
                    <slot
                      :name="'foot_' + tr_index + '_' + field.slot_builder_name"
                      :parent="field.parent"
                      :field="field"
                      :item="tr"
                    >
                      {{ tr[field.key] }}
                    </slot>
                  </template>
                  <template v-else>
                    {{ tr[field.key] }}
                  </template>
                </slot>
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <slot
        name="footer-block"
        :scrollExist="scrollExist"
      >
        <div
          v-if="(show_product_search && scrollExist) || hasTotal"
          class="table-footer incoming-table-footer align-middle d-flex align-items-center p-3"
        >
          <div class="mr-auto">
            <slot
              v-if="scrollExist"
              name="productSearch"
            ></slot>
          </div>
          <div>
            <slot name="total"></slot>
          </div>
        </div>
      </slot>
    </div>
  </section>
</template>

<script>
  import SortTableModal from '@/views/operational-processes/components/SortTableModal'
  import { FieldsModel } from '@/models/fields.model'

  export default {
    name: 'ResizableTable',
    components: {
      SortTableModal
    },
    props: {
      foot: Number,
      first_fixed: Boolean,
      resizable: {
        default: true,
        type: Boolean
      },
      default_fields: {
        default: () => [],
        type: [FieldsModel]
      },
      inside_card: {
        default: () => true,
        type: Boolean
      },
      items: {
        default: () => [],
        type: Array
      },
      empty_text: {
        type: String,
        default: 'Нет товаров'
      },
      busy: Boolean,
      no_drag: Boolean,
      show_product_search: Boolean,
      table_name: { type: String, required: true },
      height: {
        type: String,
        default: '100%'
      },
      foot_row: {
        type: Boolean,
        default: true
      },
      top_sticky_row: {
        type: Boolean,
        default: false
      },
      sort_table_modal_id: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        fields: [],
        scrollExist: false
      }
    },
    computed: {
      hasTotal() {
        return !!this.$slots.total
      },
      checkedFields() {
        return this.fields
          .map((el) =>
            el.children && el.checked
              ? el.children
                  .filter((el) => el.checked)
                  .map((ch) => {
                    return { ...ch, parent: el }
                  })
              : el
          )
          .flat(Infinity)
          .filter((el) => el.checked)
      }
    },

    watch: {
      default_fields: {
        handler() {
          const srtFields = localStorage.getItem(this.table_name + '_fields')
          this.fields = srtFields ? JSON.parse(srtFields) : JSON.parse(JSON.stringify(this.default_fields))
          // document.addEventListener(
          //   'click',
          //   (e) => {
          //     this.listenerClick(e)
          //   },
          //   false
          // )

          this.loadFields()
        },
        immediate: true
      }
    },
    mounted() {
      this.$nextTick(() => this.tableResize())
      const srtFields = localStorage.getItem(this.table_name + '_fields')
      this.fields = srtFields ? JSON.parse(srtFields) : JSON.parse(JSON.stringify(this.default_fields))
      // document.addEventListener(
      //   'click',
      //   (e) => {
      //     this.listenerClick(e)
      //   },
      //   false
      // )

      this.loadFields()
      this.$nextTick(() => this.scrollListener())
    },

    methods: {
      saveFields() {
        localStorage.setItem(this.table_name + '_fields', JSON.stringify(this.fields))
        const toGql = (el) => {
          return {
            key: el.key,
            checked: el.checked,
            width: el.width,
            label: el.label,
            sortable: el.sortable,
            sort: el.sort ?? null,
            hint: el.hint ?? null,
            children: el.children?.map(toGql)
          }
        }
        this.$apollo.mutate({
          mutation: require('../views/operational-processes/gql/saveFields.graphql'),
          variables: {
            input: {
              table_name: this.table_name,
              fields: this.fields.map(toGql)
            }
          }
        })
      },
      onRowClick(row) {
        this.$emit('row-click', row)
      },

      changeFields(val) {
        this.fields = val
        this.saveFields()
      },

      setDefault() {
        this.fields = JSON.parse(JSON.stringify(this.default_fields))
        this.saveFields()
      },

      async loadFields() {
        if (!this.resizable) {
          this.fields = this.default_fields
          return
        }
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: require('../views/operational-processes/gql/ReadUserTable.graphql')
        })

        const table = data?.ReadUserTable?.tables?.find((el) => el.table_name === this.table_name)
        if (table?.fields) {
          const sortable = table.fields.find((el) => el.sort !== null)
          if (sortable) this.change_sort(sortable)
          this.fields = table.fields.map((el) => {
            return {
              ...el,
              children: this.default_fields
                .find((el2) => el2.key === el.key)
                ?.children?.map((chld) => {
                  const children = el.children.find((el) => el.key === chld.key)
                  return {
                    ...chld,
                    ...children
                  }
                })
            }
          })
          // if (this.default_fields?.length && this.fields?.length !== this.default_fields?.length) {
          //   const fields = this.default_fields
          //     .map((el) => {
          //       const index = this.fields.findIndex((el2) => el2.key === el.key)
          //       return {
          //         ...el,
          //         checked: this.fields.find((el2) => el2.key === el.key)?.checked ?? el.checked,
          //         width: this.fields.find((el2) => el2.key === el.key)?.width ?? el.width,
          //         index: index === -1 ? this.default_fields.findIndex((el2) => el2.key === el.key) : index
          //       }
          //     })
          //     .sort((a, b) => (a.index > b.index ? 1 : -1))
          //   this.fields = JSON.parse(JSON.stringify(fields))
          //   console.log(this.fields, table)
          //   this.saveFields()
          // }
        }
      },
      tableResize() {
        document.addEventListener('mousemove', (e) => {
          if (this.thElm && this.startOffset + e.pageX > 0) {
            if (this.table_name === 'auto-order') {
              this.thElm.style.minWidth = this.startOffset + e.pageX - 32 + 'px'
              this.thElm.style.width = this.startOffset + e.pageX - 32 + 'px'
              this.field.width = this.startOffset + e.pageX
              return
            }
            if (this.table_name === 'invspec-docs') {
              this.thElm.style.minWidth = this.startOffset + e.pageX - 32 + 'px'
              this.thElm.style.width = this.startOffset + e.pageX - 32 + 'px'
              this.field.width = this.startOffset + e.pageX
              return
            }
            this.thElm.style.minWidth = this.startOffset + e.pageX + 'px'
            this.thElm.style.width = this.startOffset + e.pageX + 'px'
            this.field.width = this.startOffset + e.pageX
          }
        })

        document.addEventListener('mouseup', () => {
          if (!this.thElm) return
          this.field.thStyle = this.thElm.style.cssText
          this.saveFields()
          this.startOffset = 0
          this.thElm = undefined
        })
      },
      mouseDown(e, field) {
        this.thElm = e.target.parentNode
        this.startOffset = this.thElm.offsetWidth - e.pageX
        this.field = field
      },
      scrollListener() {
        const resizeObserver = new ResizeObserver(() => {
          const table = document.getElementsByClassName('table-docs ' + this.table_name)[0] || {}
          this.scrollExist =
            table?.offsetHeight <=
              document.getElementsByClassName(`resizable-table ${this.table_name}`)[0]?.clientHeight ||
            (table.offsetHeight <=
              document.getElementsByClassName(`resizable-table ${this.table_name}`)[0]?.clientHeight + 55 &&
              this.scrollExist)
        })

        resizeObserver.observe(document.getElementsByClassName(`resizable-table ${this.table_name}`)[0], {
          childList: true,
          subtree: true
        })
        document.getElementsByClassName('table-docs ' + this.table_name)[0].addEventListener('scroll', this.scrollDown)
      },
      scrollDown(event) {
        let bottomOfWindow = event.target.scrollTop + 100 + event.target.clientHeight >= event.target.scrollHeight
        if (event.target.scrollTop === 0) {
          this.$emit('scroll-up', bottomOfWindow)
        } else if (bottomOfWindow) {
          this.$emit('scroll-down')
        }
      },
      change_sort(td) {
        if (!td.sortable) return
        let sort = 'DESC'
        if (td.sort === 'DESC') sort = 'ASC'
        td.sort = sort
        this.fields
          .flatMap((el) => (el.children ? [el, ...el.children] : [el]))
          .forEach((el) => {
            el.sort = null
            if (td.key === el.key) {
              el.sort = sort
            }
          })

        this.saveFields()
        this.$emit('sort_change', { ...td, sort })
      }
    }
  }
</script>

<style scoped lang="scss">
  .resizeable-table {
    display: flex;
    flex-direction: column;
  }

  #resizeable-table {
    overflow: hidden;
    width: 100% !important;

    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background: #7f7f7f;
      border-radius: 10px;
    }

    .foot {
      background: #efefef;

      .text-nowrap {
        font-weight: 700;
      }

      &:hover {
        background-color: #efefef;
      }
    }

    .td-inner {
      span {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 32px;
        line-height: 16px;
      }

      .no-hover {
        display: block;
        transition: 200ms;
      }

      .sort-hover {
        display: none;
        transition: 200ms;
      }
    }

    .td-inner:hover {
      .no-hover {
        display: none;
        transition: 200ms;
      }

      .sort-hover {
        display: block;
        transition: 200ms;
      }
    }

    .table-inner table tr td {
      padding: 10px 16px;
      vertical-align: middle;
      text-align: center;
    }

    .table-hover tbody tr:hover {
      color: #212529;
      //background-color: rgba(0, 0, 0, 0.075);
    }

    .table-docs thead th {
      border-bottom: 1px solid #e1e1e1 !important;
      border-right: 1px solid #e1e1e1;

      &:not(.inv-docs) {
        border-top: 1px solid #e1e1e1;
      }

      &:last-child {
        //border-right: none;
      }
    }

    .invspec-docs {
      max-height: 0 !important;
    }

    .table-docs {
      margin-bottom: -1px;
      height: 100%;
      overflow-x: scroll;

      .first-fixed {
        position: sticky;
        left: 0;
        z-index: 100;
        background: #fff;

        &.foot {
          background: #efefef;
        }
      }

      thead {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 9;

        th {
          border-bottom: 1px solid #e1e1e1 !important;
          border-right: 1px solid #e1e1e1;
          border-top: none;
          padding: 10px 16px;
        }
      }

      tbody {
        td {
          border-bottom: 1px solid #e1e1e1;
          border-top: none;
        }
      }
    }

    ::v-deep() {
      .resizable-table {
        //border: none !important;
      }
    }

    .table-footer {
      border-top: 1px solid #e1e1e1 !important;
    }

    #table-docs {
      ::v-deep() {
        .count {
          img {
            &.enter {
              position: absolute;
              top: 11px;
              right: 11px;
            }
          }
        }
      }

      .count {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 6px;

        .cursor {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin-left: 10px;
            opacity: 0;
          }
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }
  }

  .invoice-list {
    table thead tr .cursor {
      padding: 16px 21px 16px 16px !important;
    }
  }

  #resizeable-table {
    table thead tr .cursor {
      //padding: 16px 21px 16px 16px !important;
    }

    // td {
    //   padding: 0 !important;
    //   margin: 0 !important;
    // }

    height: 100%;
    min-height: 1px;

    .table-hover tbody tr:hover td,
    .table-hover tbody tr:hover th {
      background-color: white !important;
    }

    .table-hover tbody tr:hover td,
    .table-hover tbody tr:hover th {
      background-color: #f8f8f9 !important;
      transition: 1000ms ease;

      & .count {
        background-color: transparent;
        transition: none ease-in;
      }
    }

    .table-inner {
      min-height: 1px;
    }
  }

  .egais_spec_table {
    max-height: 0 !important;
  }

  @media (max-height: 700px) {
    .inv-docs {
      max-height: 100% !important;
    }
  }

  .resize-field {
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: col-resize;
  }

  .field-column {
    position: relative;
  }

  .resizable-table {
    width: auto !important;
    table-layout: fixed;
  }

  .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                 not supported by any browser */
  }

  .info-table {
    height: 55px;
    width: 500px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  #top-sticky-row {
    position: sticky;
    top: 54px;

    td {
      white-space: nowrap;
      //border-right: none;
      border-top: none;
      background: #f8f8f9;
      // padding: 0 !important;
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
</style>
