import { TourModel } from '@/models/tour.model'

export const state = {
  token: '',
  refresh: '',
  isAuth: false,

  user: {
    id: '',
    first_name: '',
    second_name: '',
    last_name: '',
    phone: '',
    email: '',
    role: '',
    avatar: '',
    is_confirmed: true,
    is_old: false
  },

  current_store: {},

  regflow: {
    storyline: {
      activity: false,
      company: false,
      confirm: false,
      current: ''
    },
    activity: {
      list: '',
      selected_ids: '',
      isLoaded: false,
      is_fasol: false
    },
    company: {
      name: '',
      inn: '',
      kpp: '',
      nameBranch: '',
      activities: []
    }
  },
  tour: new TourModel(),
  binding_modal: {
    product: {},
    target_product: {}
  },

  infoBindingModal: true
}
