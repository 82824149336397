export const actions = {
  setCurrentDocument({ commit }, document) {
    commit('SET_CURRENT_DOCUMENT', document)
  },

  clearCurrentDocument({ commit }) {
    commit('CLEAR_CURRENT_DOCUMENT')
  },

  setDateRange({ commit }, date_range) {
    commit('SET_DATA_RANGE', date_range)
  },

  setShowingInputTooltipStatus({ commit }, status) {
    commit('SET_SHOWING_INPUT_TOOLTIP', status)
  },

  setIncomingPagination({ commit }, status) {
    commit('SET_INCOMING_PAGINATION', status)
  },
  setIncomingDocument({ commit }, status) {
    commit('SET_INCOMING_DOCUMENT', status)
  },
  setIncomingSpecifications({ commit }, specs) {
    commit('SET_INCOMING_SPECIFICATIONS', specs)
  },
  setIncomingSpecificationById({ commit }, specs) {
    commit('SET_INCOMING_SPECIFICATIONS_BY_ID', specs)
  },
  setSpecByParam({ commit }, specs) {
    commit('SET_INCOMING_SPEC_BY_PARAM', specs)
  },
  setIncomingCursor({ commit }, cursor) {
    commit('SET_INCOMING_CURSOR', cursor)
  }
}
