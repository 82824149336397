<template>
  <b-row>
    <b-col cols="6">
      <div class="card">
        <b-row>
          <b-col
            v-if="printers.length > 0"
            cols="4"
          >
            <div class="form-group">
              <label>Принтер</label>
              <i-select
                :value="getCurrentPrinterDevice"
                @input="changePrinterDevice"
              >
                <i-option :value="printerDefault"> По умолчанию </i-option>
                <i-option
                  v-for="item in printers"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </i-option>
              </i-select>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label>Печатная форма</label>
              <i-select
                v-model="format"
                :disabled="formats.length === 1"
                default-label="6 x 4"
              >
                <i-option
                  v-for="(item, index) in formats"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </i-option>
              </i-select>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group">
              <label>Цена за</label>
              <i-select
                v-model="weightFormat"
                default-label="6 x 4"
                @input="weightFormatChange"
              >
                <i-option value="gram">100 г</i-option>
                <i-option value="kilogram">1 кг</i-option>
              </i-select>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="format === '6x3.9'">
          <b-col cols="6">
            <div class="form-group">
              <label>Акционные ценники</label>
              <i-select
                v-model="type_price_tag"
                :disabled="type_price_tags.length === 1"
                default-label="Выгодно"
              >
                <i-option
                  v-for="(item, index) in type_price_tags"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </i-option>
              </i-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="card mt-4 print-products">
        <b-table
          ref="table_products"
          :fields="fields"
          :items="products"
          empty-filtered-text="Не выбраны ценники для печати"
          show-empty
          class="table-products mt-4"
        >
          <template #empty="scope">
            <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
          </template>

          <template #head(print)>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="selected_all" />
              Печать
            </div>
          </template>

          <template #cell(print)="data">
            <b-form-checkbox
              :checked="printProducts[data.item.id]?.enabled"
              @change="(value) => setSelected(data.item.id, value)"
            />
          </template>

          <template #cell(count)="data">
            <b-form-input
              type="number"
              class="input-count"
              :value="1"
              @input="(value) => setSelectedProductPrintCount(data.item.id, value)"
            />
          </template>
        </b-table>
      </div>
    </b-col>
    <b-col cols="6">
      <div class="card p-0 print-right">
        <div class="print-right-header d-flex align-content-center">
          <div class="print-zoom-actions ml-auto">
            <button
              class="btn btn-light"
              :disabled="zoom === 3"
              @click="onZoomPlus"
            >
              <img
                src="/img/icons/btn-plus-dark.svg"
                alt=""
              />
            </button>
            <button
              class="btn btn-light"
              :disabled="zoom === 1"
              @click="onZoomMinus"
            >
              <img
                src="/img/icons/btn-minus-dark.svg"
                alt=""
              />
            </button>
            <i-select
              v-model="zoom"
              placeholder="Масштаб"
              class="print-zoom-select"
              @change="resetPrintMainScroll"
            >
              <i-option :value="1">50%</i-option>
              <i-option :value="2">100%</i-option>
              <i-option :value="3">150%</i-option>
            </i-select>
          </div>
        </div>
        <div
          ref="printMain"
          class="print-right-main"
          :class="{ half: zoom === 1, full: zoom === 3 }"
        >
          <div
            id="block-to-print"
            class="sized-page"
          >
            <div class="print-right-page">
              <div
                class="grid mb-3"
                :style="{ 'grid-template-columns': grid_columns.grid, width: width_grid }"
              >
                <template>
                  <div
                    v-for="(product, index) of showProducts"
                    :key="index + product?.id"
                    class="print-grid-item"
                    :style="{ 'aspect-ratio': grid_columns.aspect }"
                  >
                    <tag4x6
                      v-if="format === '6x9'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      :style="tag_preview_style"
                    />

                    <tag6x6
                      v-if="format === '6x6'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      :style="tag_print_style"
                    />

                    <price-tag
                      v-if="format === '6x4'"
                      ref="price-preview"
                      :promotion="promotion"
                      :weight_format="weightFormat"
                      :product="product"
                      :style="tag_preview_style"
                    />
                    <tag200on140
                      v-if="format === '19x14'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      :style="tag_preview_style"
                    />
                    <tag100on140
                      v-if="format === '9.5x14'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      :style="tag_preview_style"
                    />
                    <tag200on280
                      v-if="format === '19x26'"
                      ref="price-preview"
                      :franchise="getCurrentBranch.franchise"
                      :weight_format="weightFormat"
                      :product="product"
                      :style="tag_preview_style"
                    />
                    <two-for-one-big
                      v-if="format === '6x3.9' && type_price_tag === '2on1big'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                    <profitable
                      v-if="format === '6x3.9' && type_price_tag === 'profitable'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                    <discount
                      v-if="format === '6x3.9' && type_price_tag === 'discount'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                    <super-price
                      v-if="format === '6x3.9' && type_price_tag === 'superprice'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                    <excellent-choice
                      v-if="format === '6x3.9' && type_price_tag === 'exellentchoice'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                    <two-for-one
                      v-if="format === '6x3.9' && type_price_tag === '2on1'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                    <stock
                      v-if="format === '6x3.9' && type_price_tag === 'stock'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                    <seize-moment
                      v-if="format === '6x3.9' && type_price_tag === 'seizemoment'"
                      ref="price-preview"
                      :weight_format="weightFormat"
                      :product="product"
                      style="position: absolute"
                      :style="tag_preview_style"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <portal to="clear">
            <template>
              <div class="pricetag-center">
                <div
                  v-for="(page, pageIndex) of pages"
                  id="print-grid"
                  :key="pageIndex"
                  class="print-grid"
                  :style="{
                    'grid-template-columns': grid_columns.grid,
                    width: width_grid,
                    'page-break-after': pages.length - 1 !== pageIndex ? 'always' : 'avoid'
                  }"
                >
                  <template>
                    <div
                      v-for="(product, index) of showProducts.slice(perPages * (page - 1), perPages * page)"
                      :key="index + product?.id"
                      ref="print-grid"
                      class="print-grid-item"
                      style=""
                      :style="{ 'aspect-ratio': grid_columns.aspect }"
                    >
                      <tag4x6
                        v-if="format === '6x9'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        :style="tag_print_style"
                      />

                      <tag6x6
                        v-if="format === '6x6'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        :style="tag_print_style"
                      />

                      <price-tag
                        v-if="format === '6x4'"
                        ref="price-print"
                        :promotion="promotion"
                        :weight_format="weightFormat"
                        :product="product"
                        :style="tag_print_style"
                      />
                      <tag200on140
                        v-if="format === '19x14'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        :style="tag_preview_style"
                      />
                      <tag100on140
                        v-if="format === '9.5x14'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        :style="tag_preview_style"
                      />
                      <tag200on280
                        v-if="format === '19x26'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        :style="tag_preview_style"
                      />
                      <two-for-one-big
                        v-if="format === '6x3.9' && type_price_tag === '2on1big'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_print_style"
                      />
                      <profitable
                        v-if="format === '6x3.9' && type_price_tag === 'profitable'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_preview_style"
                      />
                      <discount
                        v-if="format === '6x3.9' && type_price_tag === 'discount'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_preview_style"
                      />
                      <super-price
                        v-if="format === '6x3.9' && type_price_tag === 'superprice'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_preview_style"
                      />
                      <excellent-choice
                        v-if="format === '6x3.9' && type_price_tag === 'exellentchoice'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_preview_style"
                      />
                      <two-for-one
                        v-if="format === '6x3.9' && type_price_tag === '2on1'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_preview_style"
                      />
                      <stock
                        v-if="format === '6x3.9' && type_price_tag === 'stock'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_preview_style"
                      />
                      <seize-moment
                        v-if="format === '6x3.9' && type_price_tag === 'seizemoment'"
                        ref="price-print"
                        :weight_format="weightFormat"
                        :product="product"
                        style="position: absolute"
                        :style="tag_preview_style"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </portal>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import Tag200on140 from '@/views/core/modal/price-tag/Tag200on140.vue'
  import { mapGetters, mapMutations } from 'vuex'
  import Tag100on140 from '@/views/core/modal/Tag100on140'
  import Tag200on280 from '@/views/core/modal/Tag200on280'
  import TwoForOneBig from '@/views/core/modal/TwoForOneBig'
  import Profitable from '@/views/core/modal/SevenPriceTag/Profitable'
  import Discount from '@/views/core/modal/SevenPriceTag/Discount'
  import SuperPrice from '@/views/core/modal/SevenPriceTag/SuperPrice'
  import ExcellentChoice from '@/views/core/modal/SevenPriceTag/ExcellentChoice'
  import TwoForOne from '@/views/core/modal/SevenPriceTag/TwoForOne'
  import Stock from '@/views/core/modal/SevenPriceTag/Stock'
  import SeizeMoment from '@/views/core/modal/SevenPriceTag/SeizeMoment'
  import PriceTag from '@/views/core/modal/PriceTag'
  import Tag4x6 from '@/views/core/modal/price-tag/Tag4x6'
  import Tag6x6 from '@/views/core/modal/price-tag/Tag6x6'

  export default {
    components: {
      Tag4x6,
      Tag6x6,
      Tag200on140,
      PriceTag,
      Tag100on140,
      Tag200on280,
      TwoForOneBig,
      Profitable,
      Discount,
      SuperPrice,
      ExcellentChoice,
      TwoForOne,
      Stock,
      SeizeMoment
    },

    apollo: {
      Devices: {
        query: require('./gql/GetPrinterDevices.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.getCurrentBranch?.id,
              tags: ['printer']
            }
          }
        },
        result({ data }) {
          this.printers = data?.Devices ?? []
        },
        error(err) {
          console.log(err)
        }
      }
    },

    props: {
      products: {
        type: Array,
        default: () => []
      }
    },

    data: () => ({
      format: '6x4',
      printerDefault: 'window-print',
      printers: [],
      zoom: 2,
      type_price_tag: '2on1big',
      selected_all: true,
      perPage: 18,
      printProducts: {},
      currentPage: 1,
      weightFormat: '',
      tag_preview_style: {
        'transform-origin': '0px 0px'
      },
      tag_print_style: {
        'transform-origin': '0px 0px'
      },
      promotion: true,
      width_grid: '83%'
    }),

    watch: {
      currentPage() {
        this.getRatio()
      },
      products() {
        this.init()
        this.getRatio()
      },
      type_price_tag() {
        this.getRatio()
      },
      format() {
        const page_wo_frame = 21
        const width = this.format.split('x')?.[0]
        if (width) {
          const row_count = Math.floor(page_wo_frame / +width)
          this.width_grid = ((row_count * +width) / page_wo_frame) * 100 + '%'
        }
        this.getRatio()
      },
      selected_all(newVal) {
        if (!newVal) {
          for (let key in this.printProducts) {
            this.printProducts[key].enabled = false
          }
        } else {
          this.products.forEach((item) => {
            const id = item.id
            this.checkIdIsAdded(id)
            this.$set(this.printProducts[id], 'enabled', true)
          })
        }
        this.getRatio()
      },
      zoom() {
        this.resetPrintMainScroll()
      }
    },

    beforeMount() {},

    updated() {
      // this.products.forEach(obj => {
      //   if (!obj?.promotion?.old_price) {
      //     this.promotion = false;
      //   }
      // });
    },

    mounted() {
      this.SET_PRINT_PRODUCT_DATA([])
      this.init()
    },
    methods: {
      init() {
        const printProducts = []
        this.loadWeightFormat()
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
          if (modalId === 'modal-print-tags') {
            this.printProducts = []
            this.selected_all = false
          }
        })

        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
          if (modalId === 'modal-print-tags') this.selected_all = true
        })

        this.products?.forEach((item) => {
          const id = item.id
          this.checkIdIsAdded(id)
          this.$set(this.printProducts[id], 'enabled', true)
          this.$set(this.printProducts[id], 'count', 1)

          printProducts.push({
            product: id,
            quantity: 1
          })
        })
        this.SET_PRINT_PRODUCT_DATA(printProducts)
        // this.products.forEach(obj => {
        //   if (!obj?.promotion?.old_price) {
        //     this.promotion = false;
        //   }
        // });
        setTimeout(() => this.getRatio(), 10)
      },

      ...mapMutations({
        SET_CURRENT_PRINTER_DEVICE: 'settings/SET_CURRENT_PRINTER_DEVICE',
        SET_PRINT_PRODUCT_DATA: 'products/SET_PRINT_PRODUCT_DATA',
        SET_PRINT_PRODUCT_ITEM_DATA: 'products/SET_PRINT_PRODUCT_ITEM_DATA'
      }),

      changePrinterDevice(value) {
        this.SET_CURRENT_PRINTER_DEVICE(value)
      },
      setRatio(priceTag) {
        if (!priceTag?.$el) return
        const heightFactor = priceTag?.$el.parentNode.clientHeight / priceTag?.$el.offsetHeight
        const widthFactor = priceTag?.$el.parentNode.clientWidth / priceTag?.$el.offsetWidth
        return `scale(${widthFactor},${heightFactor})`
      },
      getRatio() {
        setTimeout(() => {
          this.$nextTick(() => {
            for (const [index, priceTag] of this.$refs['price-preview']?.entries() || []) {
              const transform = this.setRatio(priceTag)
              priceTag.$el.style.transform = transform
              if (this.$refs['price-print']?.[index]) {
                const transformPrint = this.setRatio(this.$refs['price-print'][index])
                this.$refs['price-print'][index].$el.style.transform = transformPrint
              }
            }
          })
        }, 100)
      },

      onNextPage() {
        this.currentPage++
      },

      onPrevPage() {
        this.currentPage--
      },

      onZoomMinus() {
        this.zoom--
      },

      onZoomPlus() {
        this.zoom++
      },

      setSelected(id, value) {
        this.checkIdIsAdded(id)
        this.currentPage = 1
        this.$set(this.printProducts[id], 'enabled', value)
        this.getRatio()
      },

      setSelectedProductPrintCount(id, count) {
        this.checkIdIsAdded(id)
        this.currentPage = 1
        this.$set(this.printProducts[id], 'count', count)
        this.getRatio()
        this.SET_PRINT_PRODUCT_ITEM_DATA({ id, count })
      },

      resetPrintMainScroll() {
        this.$refs.printMain.scrollTo(0, 0)
      },

      checkIdIsAdded(id) {
        if (!this.printProducts[id]) {
          this.printProducts = Object.assign({}, this.printProducts, {
            [id]: {}
          })
        }
      },

      getProduct(id) {
        return this.products.find((product) => product.id === id)
      },

      printPriceTags() {
        // this.$set(this, 'showPrint', true)
        window.print()
        // this.$set(this, 'showPrint', false)
      },
      weightFormatChange() {
        localStorage.setItem('weightFormat', this.weightFormat)
      },
      loadWeightFormat() {
        this.weightFormat = localStorage.getItem('weightFormat') || 'kilogram'
      }
    },

    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch',
        getCurrentPrinterDevice: 'settings/getCurrentPrinterDevice'
      }),
      grid_columns() {
        if (this.format === '19x14') {
          return {
            grid: 'repeat(1, 1fr)',
            aspect: 20 / 14
          }
        }
        if (this.format === '9.5x14') {
          return {
            grid: 'repeat(2, 1fr)',
            aspect: 10 / 14
          }
        }
        if (this.format === '19x26') {
          return {
            grid: 'repeat(1, 1fr)',
            aspect: 20 / 28
          }
        }
        if (this.format === '6x3.9') {
          return {
            grid: 'repeat(3, 1fr)',
            aspect: 651 / 389
          }
        }
        if (this.format === '6x4') {
          return {
            grid: 'repeat(3, 1fr)',
            aspect: 6 / 4
          }
        }
        if (this.format === '6x6') {
          return {
            grid: 'repeat(3, 1fr)',
            aspect: 6 / 6
          }
        }
        if (this.format === '6x9') {
          return {
            grid: 'repeat(3, 1fr)',
            aspect: 6 / 9
          }
        }
        return {
          grid: 'repeat(3, 1fr)',
          aspect: 6 / 4
        }
      },
      formats() {
        if (['fasol', 'fasol_demo'].includes(this.getCurrentBranch.franchise)) {
          return [
            {
              value: '6x4',
              label: '6 x 4'
            },
            {
              value: '6x6',
              label: '6 x 6'
            },
            {
              value: '6x9',
              label: '6 x 9'
            },
            {
              value: '19x14',
              label: '20 x 14'
            },
            {
              value: '9.5x14',
              label: '10 x 14'
            },
            {
              value: '19x26',
              label: '20 x 28'
            },
            {
              value: '6x3.9',
              label: '6.5 x 3.9'
            }
          ]
        } else {
          return [
            {
              value: '6x4',
              label: '6 x 4'
            },
            {
              value: '6x6',
              label: '6 x 6'
            },
            {
              value: '6x9',
              label: '6 x 9'
            },
            {
              value: '19x26',
              label: '20 x 28'
            }
          ]
        }
      },
      type_price_tags() {
        return [
          {
            value: '2on1big',
            label: '2 по цене 1 (вар. 1)'
          },
          {
            value: '2on1',
            label: '2 по цене 1 (вар. 2)'
          },
          {
            value: 'profitable',
            label: 'Выгодно'
          },
          {
            value: 'discount',
            label: 'Скидка'
          },
          {
            value: 'superprice',
            label: 'Супер цена'
          },
          {
            value: 'exellentchoice',
            label: 'Отличный выбор'
          },
          {
            value: 'stock',
            label: 'Акция'
          },
          {
            value: 'seizemoment',
            label: 'Лови момент'
          }
        ]
      },
      fields: function () {
        return [
          {
            key: 'name',
            label: 'Наименование товара',
            sortable: true
          },
          {
            key: 'print',
            label: 'Печать',
            class: 'w-15 center'
          },
          {
            key: 'count',
            label: 'Кол-во к печати',
            class: 'w-25 center',
            thStyle: 'padding: 0;'
          }
        ]
      },
      perPages() {
        if (this.format === '6x4') {
          return 21
        } else if (this.format === '6x9') {
          return 9
        } else if (this.format === '9.5x14') {
          return 4
        } else if (this.format === '19x14') {
          return 2
        } else if (this.format === '19x26') {
          return 1
        } else {
          return 21
        }
      },
      showProducts() {
        const prds = []
        this.products.forEach((el) => {
          if (this.printProducts[el.id]?.enabled) {
            for (let i = 0; i < this.printProducts[el.id]?.count || 0; i++) prds.push(el)
          }
        })
        return prds
        // .slice(perPage * (this.currentPage - 1), perPage * this.currentPage)
      },
      pages() {
        const prds = []
        this.products.forEach((el) => {
          if (this.printProducts[el.id]?.enabled) {
            for (let i = 0; i < this.printProducts[el.id]?.count || 0; i++) prds.push(el)
          }
        })
        let perPage = 18
        if (this.format === '6x4') {
          perPage = 18
        } else if (this.format === '6x9') {
          perPage = 9
        } else if (this.format === '9.5x14') {
          perPage = 4
        } else if (this.format === '19x14') {
          perPage = 2
        } else if (this.format === '19x26') {
          perPage = 1
        } else {
          perPage = 18
        }
        const array = []
        for (let i = 1; i <= Math.ceil(prds.length / perPage); i++) {
          array.push(i)
        }
        return array
      }
    }
  }
</script>

<style lang="scss" scoped>
  #modal-print-tags {
    .card {
      padding: 15px;

      .form-group {
        margin-bottom: 0;

        label {
          font-size: 14px !important;
        }
      }
    }

    .print-products {
      height: 430px;
      padding: 0;
      margin-bottom: 0;
      overflow-y: scroll;
    }

    table.b-table {
      margin-top: -1px !important;

      .input-count {
        width: 46px;
      }
    }

    .print-right {
      height: 542px;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
        flex-shrink: 0;
        position: relative;
        z-index: 1;
      }

      &-main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: auto;
        justify-content: flex-start;
        align-items: flex-start;

        &.half {
          .sized-page {
            transform-origin: 190px 105px;
            transform: scale(0.25);
            // margin-bottom: -1040px;
          }
        }

        &.full {
          overflow-x: auto;

          .sized-page {
            transform: none;
            margin-bottom: 0;
          }
        }
      }
      .sized-page {
        transform-origin: 0 0;
        transform: scale(0.52);
      }
      &-page {
        padding: 20px 10px;
        width: 1070px;
        min-height: 1529px;
        overflow: auto;
        background: #fff;
        box-shadow: 0px 0px 25.3425px rgba(0, 0, 0, 0.1);
        margin-bottom: 50px;
      }
    }

    .grid {
      width: 85%;
      display: grid;
      position: relative;
      margin: 0 auto;
      gap: 2px;
    }

    .print-grid-item {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      position: relative !important;
      border: 1px solid #bcbcbc;
      //margin-left: -1px;
      min-width: 1px;

      &:first-child,
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(-n + 3) {
        border-top-width: 1px;
      }

      &:last-child,
      &:nth-child(3n) {
        border-right-width: 1px;
      }
    }

    .print-pager {
      display: flex;
      align-items: center;
      gap: 10px;

      &-prev,
      &-next {
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;

        img {
          display: block;
        }

        &:disabled {
          cursor: default;
          opacity: 0.3;
        }
      }

      &-prev {
        transform: rotate(180deg);
      }
    }

    .print-zoom-actions {
      display: flex;
      align-items: center;
      gap: 8px;

      .btn {
        width: 32px;
        justify-content: center;
        padding: 0;
        flex-shrink: 0;

        &:disabled {
          cursor: default;
        }
      }
    }

    .print-zoom-select {
      min-width: 105px;
    }
  }
</style>

<style scoped lang="scss">
  ::v-deep() {
    .modal-footer {
      justify-content: flex-start;
    }
  }

  .modal-footer {
    // justify-content: flex-start;

    button {
      width: auto;
      margin: 0 10px 0 0;
      padding: 0 32px;
    }
  }
  .print-right-page {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .print-grid {
    width: 83%;
    grid-template-columns: (3, 1fr);
    display: grid;

    .print-grid-item {
      display: flex;
      flex-direction: column;
      aspect-ratio: 6/4;
      page-break-inside: avoid;
      flex: 0;
      border: 1px solid #bcbcbc;

      &:first-child,
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(-n + 3) {
        border-top-width: 1px;
      }

      &:last-child,
      &:nth-child(3n) {
        border-right-width: 1px;
      }
    }

    .print-grid-line {
    }
  }

  @media print {
    // body {
    //   .wrapper,
    //   #modal-print-tags,
    //   #modal-print-tags *,
    //   .modal-backdrop {
    //     display: none !important;
    //     position: static !important;
    //   }
    // }
    .print-grid {
      padding-top: 40px;
      display: grid;
      grid-template-columns: (3, 1fr);
      gap: 2px;
    }
  }

  .pricetag-center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
</style>
