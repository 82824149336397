var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "events"
  }, [_c('div', {
    staticClass: "dashboard__title"
  }, [_vm._v("События")]), _c('div', {
    staticClass: "card events-body border-none d-flex justify-content-start align-items-stretch",
    staticStyle: {
      "width": "311px",
      "max-height": "517px"
    }
  }, [!_vm.loading && !_vm.tasks.length ? _c('div', {
    staticClass: "no-events"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/smile.svg",
      "alt": "smile"
    }
  }), _c('p', [_vm._v("Важных событий нет")])]) : _vm._l(_vm.tasks, function (item) {
    return _c('event-item', {
      key: item.id,
      attrs: {
        "item": item,
        "on_print_price": _vm.printPriceTags
      },
      on: {
        "openModal": _vm.openModal,
        "refetch": function ($event) {
          return _vm.$apollo.queries.tasks.refetch();
        }
      }
    });
  })], 2), _c('comparison-modal'), _c('modal-print-price-tags', {
    attrs: {
      "products": _vm.products
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }