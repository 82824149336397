<template>
  <div class="containers">
    <div class="logo">
      <div class="logo_block">
        <div class="line-cart">
          <left-line-svg />
          <cart-svg />
        </div>
        <two-for-one-big-svg />
        <right-line-svg />
      </div>
    </div>
    <div class="green-block">
      <twoin-one-green-line-svg />
      <b>за</b>
      <div class="price">
        <h2>
          {{ getRetailPrice }}
        </h2>
        <div class="rub">
          <rub-svg />
        </div>
      </div>
    </div>
    <div class="content-block">
      <div class="left-content">
        <b>{{ product?.name?.length >= 48 ? product?.name?.slice(0, 48) + '...' : product?.name }}</b>
      </div>
      <div class="right-content">
        <div class="head">
          <p>Старая цена</p>
          <p>{{ product?.measurement?.name?.slice(0, 2) }} (руб.)</p>
        </div>
        <div class="price">
          <b class="old-price"
            >{{ getOldPrice }}
            <line-through-svg gray />
          </b>
        </div>
        <div class="country">
          <p>{{ product?.country?.name }}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <orange-line-svg />
      <div class="footer__block">
        <div class="barcode">
          <div style="width: 201px; height: 73px">
            <barcode-generator
              v-if="product?.package"
              :width="201"
              :height="73"
              :barcode="product?.package[0]?.barcode?.barcode"
            />
          </div>
        </div>
        <p class="address">{{ companyName }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  import CartSvg from '@/views/core/modal/price-tag/svg/CartSvg.vue'
  import TwoForOneBigSvg from '@/views/core/modal/price-tag/svg/TwoForOneBigSvg.vue'
  import RightLineSvg from '@/views/core/modal/price-tag/svg/RightLineSvg.vue'
  import LeftLineSvg from '@/views/core/modal/price-tag/svg/LeftLineSvg.vue'
  import RubSvg from '@/views/core/modal/price-tag/svg/RubSvg.vue'
  import TwoinOneGreenLineSvg from '@/views/core/modal/price-tag/svg/TwoinOneGreenLineSvg.vue'
  import OrangeLineSvg from '@/views/core/modal/price-tag/svg/OrangeLineSvg.vue'
  import LineThroughSvg from '@/views/core/modal/price-tag/svg/LineThroughSvg.vue'
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  export default {
    name: 'PriceTagDiscount',
    components: {
      CartSvg,
      TwoForOneBigSvg,
      RightLineSvg,
      LeftLineSvg,
      RubSvg,
      TwoinOneGreenLineSvg,
      OrangeLineSvg,
      BarcodeGenerator,
      LineThroughSvg
    },
    props: {
      product: Object
    },
    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return entity_name(this.product.entity) || entity_name(this.getCurrentBranch.entities?.[0]) || this.company_name
      },
      date() {
        return formatDate(new Date())
      },
      getRetailPrice() {
        return 1 >= this.product.retailPrice.toFixed(2).toString().split('.').length
          ? this.product.retailPrice + '.00'
          : this.product.retailPrice.toFixed(2).toString().split('.')[0] +
              '.' +
              this.product.retailPrice.toFixed(2).toString().split('.')[1]
      },
      getOldPrice() {
        if (this.product?.promotion?.old_price) {
          return 1 >= this.product?.promotion?.old_price?.toFixed(2).toString().split('.').length
            ? this.product?.promotion?.old_price?.toFixed(2) + '.00'
            : this.product?.promotion?.old_price?.toFixed(2).toString().split('.')[0] +
                '.' +
                this.product?.promotion?.old_price?.toFixed(2).toString().split('.')[1]
        } else {
          return this.getRetailPrice
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  p {
    padding: 0;
    margin: 0;
  }
  .containers {
    background-color: #fff;
    overflow: hidden;
    position: relative;
    width: 651px;
    height: 389px;
    border: 2px solid black;
    text-align: center;
  }

  .logo {
    position: relative;
    height: 76px;
    .line-cart {
      display: flex;
    }
    &_block {
      display: flex;
      justify-content: space-between;
      .cart {
        margin-top: 2px;
        z-index: 2;
      }
    }
  }
  .green-block {
    position: relative !important;
    height: 114px;
    width: 100%;
    align-items: center;
    background-color: #75b727;
    align-items: center;
    display: flex;
    justify-content: center;
    .twoinone {
      position: absolute;
      top: 0;
      left: 0;
    }
    b {
      position: absolute;
      font-family: 'Arial';
      font-style: normal;
      font-weight: 700;
      font-size: 47.2263px;
      line-height: 54px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.015em;
      color: #000000;
      left: 0;
      top: 50%;
      transform: translate(38px, -50%);
    }
    .price {
      display: flex;
      width: 100%;
      justify-content: center;
      .rub {
        align-items: flex-end !important;
        display: flex;
        svg {
          z-index: 3;
          align-items: flex-end;
          margin-bottom: 15px;
          margin-left: 4px;
        }
      }
      h2 {
        z-index: 2;
        font-family: 'Ubuntu-Bold';
        font-style: normal;
        font-weight: 700;
        height: fit-content;
        font-size: 85.9843px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }
    }
  }
  .content-block {
    display: flex;
    justify-content: space-between;
    .left-content {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      b {
        font-family: 'Tahoma';
        font-style: normal;
        font-weight: 700;
        font-size: 25.9953px;
        line-height: 31px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.015em;
        color: #000000;
      }
    }
    .right-content {
      width: 50%;
      .head {
        display: flex;
        justify-content: center;
        margin: 7px 0 8px;
        p {
          font-weight: 400;
          font-size: 14.5889px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #000000;
          &:nth-child(1) {
            margin-right: 32px;
          }
        }
      }
      .price {
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        b {
          width: fit-content;
          overflow: hidden;
          color: #808080;
          font-family: 'Ubuntu-Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 47.4961px;
          line-height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: relative;
        }
        .line-through {
          position: absolute;
          transform: translate(-50%, 0%);
          left: 50%;
          z-index: 14;
          bottom: 31%;
        }
      }
      .country {
        display: flex;
        align-items: flex-start;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 25.3004px;
          line-height: 29px;
          display: flex;
          align-items: center;
          color: #000000;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    // background: #ee7c21;
    height: 78px;
    width: 100%;
    margin: 0 auto;
    .orange_footer {
      position: absolute;
      top: 0;
      left: 0;
    }
    &__block {
      display: flex;
      justify-content: space-between;
      margin: 0px 36px 0px 33px;
      .barcode {
        z-index: 2;
      }
      .address {
        z-index: 2;
        font-style: normal;
        font-weight: 400;
        font-size: 15.7705px;
        line-height: 18px;
        display: flex;
        align-items: center;

        color: #000000;
      }
    }
  }
</style>
