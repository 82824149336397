var render = function render(){
  var _vm$product, _vm$product$name, _vm$product2, _vm$product3, _vm$product3$country, _vm$product4, _vm$product4$measurem, _vm$product4$measurem2, _vm$product5, _vm$product6, _vm$product6$package$, _vm$product6$package$2, _vm$product7, _vm$product7$measurem, _vm$product7$measurem2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "containers"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('div', {
    staticClass: "logo_block"
  }, [_c('div', {
    staticClass: "line-cart"
  }, [_c('left-line-svg'), _c('cart-svg')], 1), _c('div', {
    staticClass: "title"
  }, [_c('p', {
    staticClass: "address"
  }, [_vm._v(_vm._s(_vm.companyName))]), _c('discount-svg')], 1), _c('right-line-svg')], 1), _c('green-line-svg')], 1), _c('div', {
    staticClass: "info_block"
  }, [_c('div', {
    staticClass: "nameBlock"
  }, [_c('b', {
    staticClass: "name"
  }, [_vm._v(_vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$name = _vm$product.name) === null || _vm$product$name === void 0 ? void 0 : _vm$product$name.length) >= 65 ? ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.name.slice(0, 65)) + '...' : _vm.product.name))])]), _c('div', {
    staticClass: "price-text"
  }, [_c('p', {
    staticClass: "country"
  }, [_vm._v(_vm._s((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$country = _vm$product3.country) === null || _vm$product3$country === void 0 ? void 0 : _vm$product3$country.name))])])]), _c('div', {
    staticClass: "price-block"
  }, [_c('div', [_c('div', {
    staticClass: "left-block"
  }, [_c('div', {
    staticClass: "little-text"
  }, [_c('p', [_vm._v("Старая цена за")]), _c('p', [_vm._v(_vm._s((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$measurem = _vm$product4.measurement) === null || _vm$product4$measurem === void 0 ? void 0 : (_vm$product4$measurem2 = _vm$product4$measurem.name) === null || _vm$product4$measurem2 === void 0 ? void 0 : _vm$product4$measurem2.slice(0, 2)) + " (руб.)")])]), _c('div', {
    staticClass: "old-price"
  }, [_c('b', {
    staticClass: "old"
  }, [_vm._v(_vm._s(_vm.getOldPrice) + " "), _c('line-through-svg')], 1)]), _c('div', {
    staticClass: "barcode"
  }, [_c('div', {
    staticStyle: {
      "width": "201px",
      "height": "73px"
    }
  }, [(_vm$product5 = _vm.product) !== null && _vm$product5 !== void 0 && _vm$product5.package ? _c('barcode-generator', {
    attrs: {
      "width": 201,
      "height": 73,
      "barcode": (_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : (_vm$product6$package$ = _vm$product6.package[0]) === null || _vm$product6$package$ === void 0 ? void 0 : (_vm$product6$package$2 = _vm$product6$package$.barcode) === null || _vm$product6$package$2 === void 0 ? void 0 : _vm$product6$package$2.barcode
    }
  }) : _vm._e()], 1)])])]), _c('div', {
    staticClass: "right-block"
  }, [_c('div', {
    staticClass: "bold-text"
  }, [_c('b', [_vm._v("Цена за")]), _c('b', [_vm._v(_vm._s((_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : (_vm$product7$measurem = _vm$product7.measurement) === null || _vm$product7$measurem === void 0 ? void 0 : (_vm$product7$measurem2 = _vm$product7$measurem.name) === null || _vm$product7$measurem2 === void 0 ? void 0 : _vm$product7$measurem2.slice(0, 2)) + " (руб.)")])]), _c('div', {
    staticClass: "new_price"
  }, [_c('img', {
    staticClass: "orange-block",
    attrs: {
      "src": require("../img/Vector 1.png"),
      "alt": ""
    }
  }), _c('b', [_vm._v(" " + _vm._s(_vm.getRetailPrice) + " ")])])])]), _c('green-line-big-svg')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }