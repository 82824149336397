<template>
  <svg
    class="twoinone"
    width="651"
    height="114"
    viewBox="0 0 651 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 114V0H651V114H0Z"
      fill="#75B727"
    />
  </svg>
</template>
<script>
  export default {
    name: 'TwoinOneGreenLineSvg'
  }
</script>
<style lang="scss" scoped></style>
