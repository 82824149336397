<template>
  <svg
    class="line__logo"
    width="10"
    height="1"
    viewBox="0 0 10 1"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1V0H8.66667L10 1H0Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'LeftLine100on140Svg'
  }
</script>
<style lang="scss" scoped>
  .line__logo {
    position: absolute;
    left: 0;
    transform: translateY(14px);
  }
</style>
