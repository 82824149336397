import { SupplierModel } from '@/models/supplier.model'
import { EntityModel } from '@/models/entity.model'
import { TreatyModel } from './treaty.model'
import { PaymentModel } from './payment.model'
import { UserModel } from '@/models/user.model'
import { DeliveryModel } from './delivery.model'
import { ContactModal } from './contact.model'
import { BranchModel } from '@/models/branch.model'

export class ContractHeadModel {
  constructor(request = {}) {
    this.id = request?.id
    this.number = request?.number
    this.name = request?.name
    this.begin = request?.begin ? new Date(request?.begin) : null
    this.end = request?.end ? new Date(request?.end) : null
    this.source = request?.source
    this.comment = request?.comment
    this.fixed = request?.fixed
    this.operation = request?.operation ? { id: 1, name: request?.operation } : { id: 1, name: 'Контракт' }
    this.date = request?.date ? new Date(request?.date) : new Date()
    this.supplier = request?.supplier ? new SupplierModel(request?.supplier) : null
    this.entity = new EntityModel(request?.entity)
    this.branch = this.initBranch(request?.branch)
    this.user = new UserModel(request?.user)
    this.contact = new ContactModal(request?.contact)
    this.treaty = new TreatyModel(request?.treaty)
    this.payment = new PaymentModel(request?.payment)
    this.delivery = new DeliveryModel(request?.delivery)
  }

  initBranch(branch) {
    if (branch?.id) return new BranchModel(branch)
    else return new BranchModel({ id: 1, name: 'Все магазины' })
  }

  get getPaymentActive() {
    return this.payment.getActive
  }

  get getPaymentTerm() {
    return this.payment.getPaymentTerm
  }

  get getPaymentDueDate() {
    return this.payment.getPaymentDueDate
  }

  get getDeliveryActive() {
    return this.delivery.getActive
  }

  get getDeliveryMinSum() {
    return this.delivery.getMinSum
  }

  get getDeliveryDays() {
    return this.delivery.getDeliveryDays
  }

  get getDeliveryWeeks() {
    return this.delivery.getDeliveryWeeks
  }

  get getDeliveryOrderDays() {
    return this.delivery.getDeliveryOrderDays
  }

  get getDeliveryOrderWeeks() {
    return this.delivery.getDeliveryOrderWeeks
  }

  get getContactName() {
    return this.contact.getName
  }

  get getContactPhone() {
    return this.contact.getPhone
  }

  get getContactEmail() {
    return this.contact.getEmail
  }

  get getPeriod() {
    const date_validity = [this.begin, this.end]
    if (this.begin && this.end) {
      const start = new Intl.DateTimeFormat('ru-RU').format(date_validity[0] ? new Date(date_validity[0]) : new Date())
      const end = new Intl.DateTimeFormat('ru-RU').format(date_validity[1] ? new Date(date_validity[1]) : new Date())
      return start + ' - ' + end
    } else {
      return 'Бессрочный'
    }
  }

  get getDate() {
    return this.date ? this.date : null
  }

  get getName() {
    if (this.name) {
      return this.name
    } else if (this.supplier?.id) {
      this.name = `Контракт с ${this.supplier?.name}`
      return `Контракт с ${this.supplier?.name}`
    } else {
      this.name = ''
      return ''
    }
  }

  get getOperation() {
    return [this.operation]
  }

  get getSupplier() {
    return this.supplier?.id ? [this.supplier] : []
  }

  get getEntity() {
    return this.entity?.id ? [this.entity] : []
  }

  get getBranch() {
    return [this.branch]
  }

  get inputDocumentOptions() {
    return {
      id: this.id,
      date: this.date,
      operation: this.operation?.name,
      supplier: this.supplier?.id,
      branch: this.branch?.value,
      entity: this.entity?.id,
      name: this.name,
      begin: this.begin,
      end: this.end
    }
  }

  get inputTreatyDelivery() {
    return {
      contract: this.id,
      contact: this.contact.inputContact,
      delivery_days: this.delivery.getDeliveryDays,
      delivery_weeks: this.delivery.getDeliveryWeeks,
      order_days: this.delivery.getDeliveryOrderDays,
      order_weeks: this.delivery.getDeliveryOrderWeeks,
      min_sum: this.delivery.getMinSum,
      active: this.delivery.getActive
    }
  }

  get inputTreatyPayment() {
    return {
      contract: this.id,
      payment_term: this.payment.getPaymentTerm,
      payment_due_date: this.payment.getPaymentDueDate,
      active: this.payment.getActive
    }
  }

  setPaymentActive(bool) {
    this.payment.setActive(bool)
  }

  setPaymentTerm(data) {
    this.payment.setPaymentTerm(data)
  }

  setPaymentDueDate(data) {
    this.payment.setPaymentDueDate(data)
  }

  setContactName(name) {
    this.contact.setName(name)
  }

  setContactPhone(phone) {
    this.contact.setPhone(phone)
  }
  setContactEmail(email) {
    this.contact.setEmail(email)
  }

  setDeliveryActive(bool) {
    this.delivery.setActive(bool)
  }

  setDeliveryMinSum(data) {
    this.delivery.setMinSum(data)
  }

  setDeliveryOrderDays(data) {
    this.delivery.setOrderDays(data)
  }

  setDeliveryOrderWeeks(data) {
    this.delivery.setOrderWeeks(data)
  }

  setDeliveryDays(data) {
    this.delivery.setDeliveryDays(data)
  }

  setDeliveryWeeks(data) {
    this.delivery.setDeliveryWeeks(data)
  }

  setName(name) {
    this.name = name
  }

  setDate(date) {
    this.date = date
  }

  setOperation(operation) {
    console.log(operation)
    this.operation = operation
  }

  setSupplier(supplier) {
    this.supplier = supplier
  }

  setEntity(entity) {
    this.entity = entity
  }

  setBranch(branch) {
    this.branch = branch
  }

  setPeriod(dateArr) {
    this.begin = dateArr[0]
    this.end = dateArr[1]
  }
}
