var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "barcode-prefix-modal",
      "title": "\n  Префиксы весовых штрих-кодов",
      "scrollable": "",
      "centered": ""
    },
    on: {
      "shown": _vm.getData
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('e-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.updateBcPrefix
          }
        }, [_vm._v(" Сохранить ")]), _c('e-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "br-setting-block"
  }, [_c('div', {
    staticClass: "br-entersight"
  }, [_c('div', {
    staticClass: "br-title"
  }, [_vm._v("Энтерсайт")]), _c('div', {
    staticClass: "br-radio"
  }, [_c('e-radio', {
    attrs: {
      "checked": _vm.entersight === '20'
    },
    on: {
      "click": function ($event) {
        return _vm.setEntersight('20');
      }
    }
  }), _vm._v(" 20 ")], 1), _c('div', {
    staticClass: "br-radio"
  }, [_c('e-radio', {
    attrs: {
      "checked": _vm.entersight === '21'
    },
    on: {
      "click": function ($event) {
        return _vm.setEntersight('21');
      }
    }
  }), _vm._v(" 21 ")], 1), _c('div', {
    staticClass: "br-radio"
  }, [_c('e-radio', {
    attrs: {
      "checked": _vm.entersight === '22'
    },
    on: {
      "click": function ($event) {
        return _vm.setEntersight('22');
      }
    }
  }), _vm._v(" 22 ")], 1), _c('div', {
    staticClass: "br-radio"
  }, [_c('e-radio', {
    attrs: {
      "checked": _vm.entersight === '23'
    },
    on: {
      "click": function ($event) {
        return _vm.setEntersight('23');
      }
    }
  }), _vm._v(" 23 ")], 1)]), _c('div', {
    staticClass: "br-external"
  }, [_c('div', {
    staticClass: "br-title"
  }, [_vm._v("Поставщик")]), _vm._l(['20', '21', '22', '23', '24', '25', '26', '27', '28'], function (i) {
    return _c('div', {
      key: i,
      staticClass: "br-radio"
    }, [_c('e-switch', {
      staticClass: "switch",
      attrs: {
        "value": _vm.checkExternal(i)
      },
      on: {
        "input": function ($event) {
          return _vm.clickExternal(i);
        }
      }
    }), _vm._v(" " + _vm._s(i) + " ")], 1);
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }