import { ProductModel } from '@/models/product.model'
import { MarkupModel } from '@/models/markup.model'
import { PropsModel } from '@/models/props.model'

export class CategoryModel {
  constructor(request = {}) {
    this.child_groups = request?.child_groups?.map((el) => new CategoryModel(el))
    this.id = request?.id
    this.int_id = request?.int_id
    this.last = request?.last
    this.level = request?.level
    this.level_four = request?.level_four
    this.level_one = request?.level_one
    this.level_three = request?.level_three
    this.level_two = request?.level_two
    this.name = request?.name
    // this.parent_groups = new CategoryModel(request?.parent_groups)
    this.path = request?.path
    this.products = request?.products?.map((el) => new ProductModel(el))
    this.markup = new MarkupModel(request?.markup ?? {})
    this.props = request?.props?.map((el) => new PropsModel(el))
  }
}
