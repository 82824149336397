import { ProductModel } from '@/models/product.model'
import { CompoundProductModel } from '@/models/compound-product.model'

export class CompoundModel {
  constructor(request = {}) {
    this.id = request.id
    this.product = new ProductModel(request.product)
    this.value = request.value || 0
    this.raw_material = request.raw_material?.map((el) => new CompoundProductModel(el))
    this.write_on_outgoing = request.write_on_outgoing
    this.date = new Date(request.date)
    this.name = request.name
    this.status = request.status
    this.cooking_technology = request.cooking_technology
    this.storage_technology = request.storage_technology
  }

  setName = (value) => {
    this.name = value
  }

  setCookingTechnology = (value) => {
    this.cooking_technology = value
  }
  setStorageTechnology = (value) => {
    this.storage_technology = value
  }

  setDate = (value) => {
    this.date = new Date(value)
  }

  setValue = (value) => {
    this.value = value
  }

  setWriteOnOutgoing = (value) => {
    this.write_on_outgoing = value
  }
  setStatus = (value) => {
    this.status = value
  }
  get input() {
    return {
      id: this.id,
      value: +this.value,
      write_on_outgoing: this.write_on_outgoing,
      date: this.date,
      name: this.name,
      status: this.status,
      cooking_technology: this.cooking_technology,
      storage_technology: this.storage_technology
    }
  }
}
