var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-add-group"
  }, [_c('b-form', {
    staticClass: "form-add-group p-3",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createGroup.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "pos-r"
  }, [_c('div', {
    staticClass: "b-elm-wrapper"
  }, [_c('div', {
    staticClass: "b-level-count"
  }, [_vm._v(_vm._s(_vm.number))]), _c('b-form-input', {
    ref: "name",
    attrs: {
      "type": "text",
      "is-keyup": true
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)])], 1), _c('b-row', [_c('b-col', {
    staticClass: "b-btns"
  }, [_c('b-button', {
    staticClass: "cursor",
    on: {
      "click": _vm.createGroup
    }
  }, [_vm.isCreateProgress ? _c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e(), !_vm.isCreateProgress ? _c('img', {
    attrs: {
      "src": "/img/icons/icon_yes.svg",
      "alt": "Добавить группу"
    }
  }) : _vm._e()], 1), _c('b-button', {
    staticClass: "cursor",
    on: {
      "click": _vm.close_add_group
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/icon_cancel.svg",
      "alt": "Отменить"
    }
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }