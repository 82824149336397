import { DiscontCustomer } from './discont-customer.models'
import { DiscontCompany } from './discont-company.model'

export class DiscontCard {
  constructor(request = {}) {
    this.id = request?.id
    this.number = request?.number
    this.prefix = request?.prefix
    this.suffix = request?.suffix
    this.customer = new DiscontCustomer(request?.customer)
    this.company = new DiscontCompany(request?.company)
    this.blocked = !request?.blocked
  }

  get getInputDiscontCard() {
    return {
      id: this.id,
      number: this.number,
      prefix: this.prefix,
      suffix: this.suffix,
      customer: this.customer.inputDiscontCustomer,
      blocked: !this.blocked
    }
  }

  get getBlocked() {
    return this.blocked
  }

  get getNumber() {
    return this.number
  }

  get getPrefix() {
    return this.prefix
  }

  get getSuffix() {
    return this.suffix
  }

  get getCustomer() {
    return this.customer
  }

  get getCompany() {
    return this.company
  }

  get getCustomerName() {
    return this.customer.getName
  }

  get getCustomerSurname() {
    return this.customer.getSurname
  }

  get getCustomerPatronymic() {
    return this.customer.getPatronymic
  }

  get getCustomerPhone() {
    return this.customer.getPhone
  }

  get getCustomerGender() {
    return this.customer.getGender
  }

  get getCustomerBirthday() {
    return this.customer.getBirthday
  }

  setNumber(num) {
    this.number = num
  }

  setPrefix(prefix) {
    this.prefix = prefix
  }

  setSuffix(suffix) {
    this.suffix = suffix
  }

  setBlocked(bool) {
    this.blocked = bool
  }

  setCustomerName(name) {
    this.customer.setName(name)
  }

  setCustomerSurname(surname) {
    this.customer.setSurname(surname)
  }

  setCustomerPatronymic(patronymic) {
    this.customer.setPatronymic(patronymic)
  }

  setCustomerPhone(phone) {
    this.customer.setPhone(phone)
  }

  setCustomerGender(gender) {
    this.customer.setGender(gender)
  }

  setCustomerBirthday(birthday) {
    this.customer.setBirthday(birthday)
  }
}
