export function getFlatChildGroups(item) {
  let result = []
  result.push(item)
  if (item?.child_groups?.length) {
    item.child_groups.forEach((t) => {
      result = [...result, ...getFlatChildGroups(t)]
    })
  }
  return result
}
