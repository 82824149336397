<template>
  <div>
    <h5>Загрузка касс</h5>
    <div class="col-6 cash-card mt-3">
      <div class="cash-card-title">Список касс для загрузки</div>
      <b-form-checkbox-group
        v-model="selectedCash"
        disabled
      >
        <div
          v-for="cash in currentBranch?.cash"
          :key="cash.id"
        >
          <b-form-checkbox
            class="custom-checkbox-centred"
            :value="cash.id"
          >
            {{ cash.name }}
          </b-form-checkbox>
        </div>
      </b-form-checkbox-group>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'CashTab',
    data: () => ({
      selectedCash: []
    }),
    mounted() {
      this.currentBranch?.cash?.forEach((el) => this.selectedCash.push(el.id))
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .b-step__activity {
      width: 100%;
      padding: 0;
      margin: 0 auto;
    }

    .spinner-border {
      margin-top: 52px;
    }

    .custom-checkbox,
    .custom-checkbox label {
      cursor: pointer;
    }
    .custom-checkbox-centred label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
  }

  .custom-checkbox-centred label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .cash-card {
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 4px;

    padding: 16px;
  }

  .cash-card-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
  }
</style>
