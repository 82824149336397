<template>
  <div
    class="anal__wiget"
    @click.stop="click"
  >
    <div class="container_abc">
      <div class="mr-2">{{ data.name }}</div>
      <div class="dropdowns_abc">
        <b-dropdown
          no-caret
          variant="none"
        >
          <template #button-content>
            <p class="dropdown-period mr-2">
              {{ activeType.name }}
            </p>
          </template>
          <b-dropdown-item @click.stop="setActiveType('profit')">
            <div class="d-flex justify-content-between">
              <div>Прибыль</div>
              <img
                v-if="activeType.key === 'profit'"
                src="/img/icons/Check.svg"
                alt="check"
              />
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click.stop="setActiveType('quantity')">
            <div class="d-flex justify-content-between">
              <div>Количество</div>
              <img
                v-if="activeType.key === 'quantity'"
                src="/img/icons/Check.svg"
                alt="check"
              />
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click.stop="setActiveType('revenue')">
            <div class="d-flex justify-content-between">
              <div>Выручка</div>
              <img
                v-if="activeType.key === 'revenue'"
                src="/img/icons/Check.svg"
                alt="check"
              />
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          no-caret
          variant="none"
        >
          <template #button-content>
            <p class="dropdown-period">
              <work-period
                type_doc="abc"
                :open="opened"
                none
                @set_open="opened = false"
              />
              {{ getPeriodName }}
            </p>
          </template>
          <b-dropdown-item @click.stop="setPeriod({ period: '30days' })">
            <div class="d-flex justify-content-between">
              <div>30 дней</div>
              <img
                v-if="dateRange.type === '30days'"
                src="/img/icons/Check.svg"
                alt="check"
              />
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click.stop="setPeriod({ period: '90days' })">
            <div class="d-flex justify-content-between">
              <div>90 дней</div>
              <img
                v-if="dateRange.type === '90days'"
                src="/img/icons/Check.svg"
                alt="check"
              />
            </div>
          </b-dropdown-item>
          <!--          <b-dropdown-item @click.stop="setPeriod({period: null})">-->
          <!--            <div class="d-flex justify-content-between">-->
          <!--              <div>За все время</div>-->
          <!--              <img-->
          <!--                v-if="dateRange.type === null"-->
          <!--                src="/img/icons/Check.svg"-->
          <!--                alt="check" />-->
          <!--            </div>-->
          <!--          </b-dropdown-item>-->
          <b-dropdown-item @click.stop="opened = true">
            <div class="d-flex justify-content-between">
              <div>Выбрать период</div>
              <img
                v-if="dateRange.type === 'custom'"
                src="/img/icons/Check.svg"
                alt="check"
              />
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="content_numbers">
      <div class="segment-container">
        <div class="segment segment__a">A</div>
        <div class="segment__price">{{ localeData((status.segment_a?.[activeType.key] / getProc) * 100) }}%</div>
      </div>
      <div class="segment-container">
        <div class="segment segment__b">B</div>
        <div class="segment__price">{{ localeData((status.segment_b?.[activeType.key] / getProc) * 100) }}%</div>
      </div>
      <div class="segment-container">
        <div class="segment segment__c">C</div>
        <div class="segment__price">{{ localeData((status.segment_c?.[activeType.key] / getProc) * 100) }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import WorkPeriod from '@/components/WorkPeriod'

  export default {
    name: 'AbcAnalyticsWidget',
    components: { WorkPeriod },
    apollo: {
      ProductsABC: {
        query: require('../../analytics/gql/ProductsABC.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              period: {
                begin: this.dateRange.date[0] ?? new Date(1970, 1, 1),
                end: new Date()
              },
              segment: 0,
              branches: [],
              suppliers: [],
              categories: [],
              pagination: {
                skip: 0,
                take: 0
              },
              a_metric: 80,
              b_metric: 15,
              c_metric: 5
            }
          }
        },
        result({ data }) {
          if (data?.ProductsABC) {
            this.total = data?.ProductsABC?.total ?? 0
            this.status = data?.ProductsABC?.status
          }
        }
      }
    },
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        activeType: { name: 'Прибыль', key: 'profit' },
        status: {},
        filter_date_range: [],
        selected_type: '',
        opened: false
      }
    },
    beforeMount() {
      this.selected_type = this.dateRange.type
    },
    computed: {
      ...mapGetters({
        dateRange: 'operprocess/getDateRange'
      }),
      getPeriodName() {
        if (this.dateRange.type === '90days') {
          return `за 90 дней`
        } else if (this.dateRange.type === 'custom') {
          const start = new Intl.DateTimeFormat('ru-RU').format(
            this.dateRange.date[0] ? new Date(this.dateRange.date[0]) : new Date()
          )
          const end = new Intl.DateTimeFormat('ru-RU').format(
            this.dateRange.date[1] ? new Date(this.dateRange.date[1]) : new Date()
          )
          return start + ' - ' + end
        }
        return `за 30 дней`
        // return 'За все время'
      },
      getProc() {
        return (
          +this.status.segment_a?.[this.activeType.key] +
          +this.status.segment_b?.[this.activeType.key] +
          +this.status.segment_c?.[this.activeType.key]
        )
      }
    },
    methods: {
      ...mapActions({
        setDateRange: 'operprocess/setDateRange'
      }),
      localeData(num) {
        return +num?.toFixed(2) ? (+num?.toFixed(2))?.toLocaleString() : 0
      },
      setActiveType(type) {
        if (type === 'revenue') {
          return (this.activeType = { name: 'Выручка', key: 'revenue' })
        } else if (type === 'profit') {
          return (this.activeType = { name: 'Прибыль', key: 'profit' })
        } else if (type === 'quantity') {
          return (this.activeType = { name: 'Количество', key: 'quantity' })
        }
      },
      setPeriod({ period }) {
        switch (period) {
          case '30days': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth() - 1, 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth(), 0, 23, 59, 59)
            break
          }
          case '90days': {
            let curr = new Date()
            this.filter_date_range[0] = new Date(curr.getFullYear(), curr.getMonth() - 3, 1, 0, 0, 0)
            this.filter_date_range[1] = new Date(curr.getFullYear(), curr.getMonth(), 0, 23, 59, 59)
            break
          }
        }
        this.selected_type = period
        this.updateDateRange()
      },
      updateDateRange() {
        this.setDateRange({
          type: this.selected_type,
          date: this.filter_date_range,
          moddate: this.dateRange.moddate
        })
      },
      click() {
        this.$router.push({ name: 'analytics.abc' })
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .btn {
    height: initial !important;
  }
  .anal__wiget {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    flex: 1;
    transition: all 0.2s ease-in-out;
    background: white;
    border-radius: 6px;
    padding: 16px;

    &:hover {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }
    .container_abc {
      flex-wrap: wrap;
      display: flex;
      .dropdowns_abc {
        .dropdown-period {
          color: #000000;
          margin: 0;
          border-bottom: 1px dotted #000000;
        }
      }
    }
  }
  .content_numbers {
    flex: 1;
    align-items: flex-end;
    display: flex;
    .segment {
      margin-right: 6px;
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      &__a {
        color: #00cb91;
      }
      &__b {
        color: #ffa800;
      }
      &__c {
        color: #e53835;
      }
      &__price {
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #313131;
      }
    }
  }
  .segment-container {
    display: flex;
    margin-right: 22px;
  }
</style>
