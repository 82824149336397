import Dashboard from '@/views/dashboard/Dashboard.vue'

export default {
  path: '/',
  name: 'dashboard',
  component: Dashboard,
  meta: {
    auth: true,
    title: 'Главное'
  }
}
