var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Печатная форма")]), _c('i-select', {
    attrs: {
      "default-label": "3 x 2"
    },
    on: {
      "input": _vm.inputFormat
    },
    model: {
      value: _vm.format,
      callback: function ($$v) {
        _vm.format = $$v;
      },
      expression: "format"
    }
  }, [_c('i-option', {
    attrs: {
      "value": "3x2"
    }
  }, [_vm._v(" 3 x 2 ")]), _c('i-option', {
    attrs: {
      "value": "6x4"
    }
  }, [_vm._v(" 6 x 4 ")])], 1)], 1)]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "form-group d-flex align-items-end h-100"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center",
    staticStyle: {
      "height": "30px"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.show_price,
      callback: function ($$v) {
        _vm.show_price = $$v;
      },
      expression: "show_price"
    }
  }), _c('p', {
    staticClass: "show-price-label",
    on: {
      "click": function ($event) {
        _vm.show_price = !_vm.show_price;
      }
    }
  }, [_vm._v(" С ценой ")])], 1)])])], 1)], 1), _c('div', {
    staticClass: "card mt-4 print-products"
  }, [_c('b-table', {
    ref: "table_products",
    staticClass: "table-products mt-4",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.products,
      "empty-filtered-text": "Не выбраны ценники для печати",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function (scope) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(_vm._s(scope.emptyFilteredText))])];
      }
    }, {
      key: "head(print)",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-form-checkbox', {
          model: {
            value: _vm.selected_all,
            callback: function ($$v) {
              _vm.selected_all = $$v;
            },
            expression: "selected_all"
          }
        }), _vm._v(" Печать ")], 1)];
      },
      proxy: true
    }, {
      key: "cell(print)",
      fn: function (data) {
        var _vm$printProducts$dat;

        return [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$printProducts$dat = _vm.printProducts[data.item.id]) === null || _vm$printProducts$dat === void 0 ? void 0 : _vm$printProducts$dat.enabled
          },
          on: {
            "change": function (value) {
              return _vm.setSelected(data.item.id, value);
            }
          }
        })];
      }
    }, {
      key: "cell(count)",
      fn: function (data) {
        return [_c('e-input', {
          staticClass: "input-count",
          attrs: {
            "type": "number",
            "value": 1
          },
          on: {
            "input": function (value) {
              return _vm.setSelectedProductPrintCount(data.item.id, value);
            }
          }
        })];
      }
    }])
  })], 1)]), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "card p-0 print-right"
  }, [_c('div', {
    staticClass: "print-right-header d-flex align-content-center justify-content-between"
  }, [_c('div', {
    staticClass: "print-pager"
  }, [_vm.printProductsCount / _vm.perPage > 0 ? [_c('button', {
    staticClass: "print-pager-prev",
    attrs: {
      "disabled": _vm.currentPage === 1
    },
    on: {
      "click": _vm.onPrevPage
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/arrow.svg",
      "alt": "Предыдущая страница"
    }
  })]), _c('span', [_vm._v(" " + _vm._s(_vm.currentPage) + " / " + _vm._s(Math.ceil(_vm.printProductsCount / _vm.perPage)) + " ")]), _c('button', {
    staticClass: "print-pager-next",
    attrs: {
      "disabled": _vm.currentPage === Math.ceil(_vm.printProductsCount / _vm.perPage)
    },
    on: {
      "click": _vm.onNextPage
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/arrow.svg",
      "alt": "Следующая страница"
    }
  })])] : _vm._e()], 2)]), _c('div', {
    ref: "printMain",
    staticClass: "print-right-main",
    class: {
      half: _vm.zoom === 1,
      full: _vm.zoom === 3
    }
  }, [_vm.showProducts.length > 0 ? _c('div', {
    staticClass: "print-grid-item lyap",
    staticStyle: {
      "margin-bottom": "16px",
      "margin-top": "16px"
    }
  }, [_c('product-label', {
    staticStyle: {
      "padding": "10px"
    },
    attrs: {
      "show_price": _vm.show_price,
      "weight_format": _vm.weightFormat,
      "format": _vm.format,
      "product": _vm.showProducts[_vm.currentPage - 1]
    }
  })], 1) : _vm._e(), _c('portal', {
    attrs: {
      "to": "dirty"
    }
  }, [_vm._l(_vm.printPages, function (page, pageIndex) {
    return [_c('div', {
      key: pageIndex,
      class: `label-print-grid-${_vm.format}`
    }, [_vm._l(page, function (product, index) {
      return [_c('div', {
        key: index + (product === null || product === void 0 ? void 0 : product.id),
        class: `print-grid-item-${_vm.format}`
      }, [_c('product-label', {
        attrs: {
          "show_price": _vm.show_price,
          "weight_format": _vm.weightFormat,
          "product": product,
          "format": _vm.format
        }
      })], 1)];
    })], 2)];
  })], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }