import { BranchModel } from '@/models/branch.model'
import { UserModel } from '@/models/user.model'
import { ActivityModel } from '@/models/activity.model'

export class CompanyModel {
  constructor(request = {}) {
    this.activities = request?.activities?.map((el) => new ActivityModel(el))
    this.branches = request?.users?.map((el) => new BranchModel(el))
    this.createdDate = new Date(request?.createdDate)
    this.id = request?.id
    this.name = request?.name
    this.users = request?.users?.map((el) => new UserModel(el))
  }
}
