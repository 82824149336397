export const getters = {
  getTreaty: (state) => state.treaty,

  getCurrentEntityId: (state) => {
    return state.current_supplier.entity_id
  },

  getCurrentSupplierId: (state) => {
    return state.current_supplier.supplier_id
  },

  getCurrentEntityData: (state) => {
    return state.current_supplier.entity_data
  },

  getCurrentTab: (state) => {
    return state.current_supplier.current_tab
  },

  getSuppliers: (state) => {
    return state.suppliers.table
  },

  getSupplierId: (state) => {
    return state.current_supplier.supplier_id
  },

  getSupplierIsEdit: (state) => {
    return state.current_supplier.is_edit
  },

  getCurrentSupplier: (state) => {
    return state.current_supplier
  },

  getPersons: (state) => {
    return state.persons.table
  },

  getBanks: (state) => {
    return state.banks.table
  }
}
