import { render, staticRenderFns } from "./SeizeMomentSvg.vue?vue&type=template&id=7ddf6823&scoped=true&"
import script from "./SeizeMomentSvg.vue?vue&type=script&lang=js&"
export * from "./SeizeMomentSvg.vue?vue&type=script&lang=js&"
import style0 from "./SeizeMomentSvg.vue?vue&type=style&index=0&id=7ddf6823&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ddf6823",
  null
  
)

export default component.exports