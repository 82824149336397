export default [
  {
    title: 'Главное',
    icon: 'main',
    name: 'dashboard',
    opened: false
  },
  {
    title: 'Документы',
    icon: 'operation_process',
    name: 'operation-process',
    opened: false,
    is_submenu: true
  },
  {
    title: 'Товары',
    icon: 'products',
    name: 'products',
    opened: false
  },
  {
    title: 'Контрагенты',
    icon: 'providers',
    name: 'contractors',
    opened: false
  },
  {
    title: 'Маркетинг',
    icon: 'marketing',
    name: 'marketing',
    opened: false,
    is_submenu: true
  },
  {
    title: 'Аналитика',
    icon: 'analytics',
    name: 'analytics',
    opened: false,
    is_submenu: true
  },
  {
    title: 'Оплаты',
    icon: 'payment',
    name: 'payment',
    opened: false,
    is_submenu: true
  },
  {
    title: 'Рекомендации',
    icon: 'diagnostics',
    name: 'diagnostics.list',
    is_submenu: false
  },
  // {
  //   title: 'Производство',
  //   icon: 'manufacture',
  //   name: 'manufacture',
  //   opened: false,
  //   is_submenu: true
  // },
  {
    title: 'Внешние системы',
    icon: 'external_systems',
    name: 'extsystems',
    opened: false,
    is_submenu: true
  },
  {
    title: 'Настройки',
    icon: 'settings',
    name: 'settings',
    opened: false,
    is_submenu: true
  }
]
