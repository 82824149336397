<template>
  <div
    :class="['e-switch', position]"
    :style="background_color"
    @click="click"
  >
    <div class="control"></div>
  </div>
</template>

<script>
  export default {
    name: 'ESwitch',
    props: {
      triple: Boolean,
      value: String,
      active_color: String
    },
    data() {
      return {
        index: 0,
        values: ['off', 'manual', 'all']
      }
    },
    computed: {
      position() {
        if (this.index === 0) return 'start'
        if (this.index === 1) return 'center'
        if (this.index === 2) return 'end'
        return 'start'
      },
      background_color() {
        let color
        if (this.index === 0) color = '#e2e2e2'
        if (this.index === 1) color = '#e5b509'
        if (this.index === 2) color = this.active_color || '#00a3ff'
        return { background: color }
      }
    },
    watch: {
      value(val) {
        this.index = this.values.findIndex((el) => el === val)
      }
    },
    mounted() {
      this.index = this.values.findIndex((el) => el === this.value)
    },
    methods: {
      click() {
        if (this.triple) {
          if (this.index === 0) this.index = 2
          else if (this.index === 1) this.index = 2
          else this.index = 0
        } else {
          if (this.index === 0) this.index = 2
          else if (this.index === 1) this.index = 2
          else this.index = 0
        }
        this.$emit('input', this.values[this.index])
        this.$emit('change', this.values[this.index])
      }
    }
  }
</script>

<style scoped lang="scss">
  .e-switch {
    background: #e2e2e2;
    border-radius: 28px;
    width: 32px;
    height: 16px;
    position: relative;
    cursor: pointer;

    .control {
      transition: 0.2s;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 90px;
      background: #ffffff;
    }
  }

  .e-switch.start {
    .control {
      left: 3px;
    }
  }
  .e-switch.center {
    //background: #e5b509;
    .control {
      left: 11px;
    }
  }

  .e-switch.end {
    //background: #00a3ff;
    .control {
      left: 19px;
    }
  }
</style>
