<template>
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.05793 11.4829C1.33098 11.7659 1.78434 11.7658 2.05729 11.4827L6.98555 6.37152C7.185 6.16467 7.185 5.83707 6.98555 5.63022L2.0573 0.519007C1.78434 0.235921 1.33098 0.235842 1.05793 0.51883C0.798453 0.787749 0.798392 1.21381 1.05779 1.4828L5.41475 6.00087L1.05779 10.5189C0.79839 10.7879 0.79845 11.214 1.05793 11.4829Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
  export default {
    name: 'TabArrow',
    props: ['fill']
  }
</script>

<style scoped></style>
