<template>
  <svg
    class="green-line"
    width="100"
    height="5"
    viewBox="0 0 100 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5V0H100V5H0Z"
      fill="#75B727"
    />
  </svg>
</template>
<script>
  export default {
    name: 'GreenLine100on140Svg'
  }
</script>
<style lang="scss" scoped></style>
