var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "423",
      "height": "53",
      "viewBox": "0 0 423 53",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M46.2207 32.2687C46.2207 26.5 40.2226 22.8345 35.283 21.6327V21.5125C40.399 20.4308 44.8094 16.7653 44.8094 12.1383C44.8094 5.10771 37.3117 0 20.1995 0C12.5255 0 5.11603 0.961453 0 2.34354V42.6043C4.58679 43.9263 12.4372 44.9478 20.1995 44.9478C37.5764 44.9478 46.2207 40.2007 46.2207 32.2687ZM30.608 13.22C30.608 15.0828 29.6377 16.585 28.1382 17.4263C26.8151 18.2075 24.6099 18.6882 21.258 18.6882H14.2014V8.11225C15.5245 7.81179 17.9061 7.57143 20.1113 7.57143C27.6971 7.57143 30.608 9.7347 30.608 13.22ZM31.4018 31.5476C31.4018 35.5737 27.9618 37.3764 20.1995 37.3764C18.0825 37.3764 15.6127 37.1361 14.2014 36.8356V25.839H21.3462C24.8745 25.839 26.8151 26.3197 28.491 27.1009C30.3433 28.0023 31.4018 29.6247 31.4018 31.5476Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M120.502 44.2268V0.72109H105.506V44.2268H120.502ZM99.5083 30.6463C99.5083 22.5941 91.2168 16.585 74.5456 16.585C72.6932 16.585 70.3998 16.7653 69.2532 16.9456V0.72109H54.6989V42.6043C59.815 44.1066 66.2541 44.9478 73.9281 44.9478C90.5111 44.9478 99.5083 39.2993 99.5083 30.6463ZM84.7777 30.7064C84.7777 35.2132 81.1612 37.3163 74.1046 37.3163C72.2522 37.3163 70.3998 37.076 69.2532 36.8356V24.5771C70.2234 24.3968 72.164 24.2166 73.8399 24.2166C81.1612 24.2166 84.7777 26.5601 84.7777 30.7064Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M165.33 9.25397V0.72109H131.193V44.2268H145.836V9.25397H165.33Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M219.059 22.4739C219.059 9.6746 211.209 0 194.184 0C177.16 0 169.31 9.6746 169.31 22.4739C169.31 35.2732 177.16 44.9478 194.184 44.9478C211.209 44.9478 219.059 35.2732 219.059 22.4739ZM204.064 22.4739C204.064 30.8866 200.712 35.9943 194.184 35.9943C187.657 35.9943 184.305 30.8866 184.305 22.4739C184.305 14.0612 187.657 8.95352 194.184 8.95352C200.712 8.95352 204.064 14.0612 204.064 22.4739Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M284.788 37.1961L279.937 35.6939L274.379 0.72109H240.508L238.567 11.6576C236.803 21.5125 233.451 31.8481 222.778 37.1961L223.484 53H233.716L234.069 44.2268H273.497L273.85 53H283.994L284.788 37.1961ZM265.471 36.2347H241.39C248.005 31.3673 251.005 21.212 252.416 12.7392L253.033 9.01361H261.501L265.471 36.2347Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M338.738 44.2268V0.72109H323.743V18.1474H308.13V0.72109H293.135V44.2268H308.13V26.5H323.743V44.2268H338.738Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M397.283 22.4739C397.283 9.6746 389.432 0 372.408 0C355.384 0 347.534 9.6746 347.534 22.4739C347.534 35.2732 355.384 44.9478 372.408 44.9478C389.432 44.9478 397.283 35.2732 397.283 22.4739ZM382.287 22.4739C382.287 30.8866 378.936 35.9943 372.408 35.9943C365.881 35.9943 362.529 30.8866 362.529 22.4739C362.529 14.0612 365.881 8.95352 372.408 8.95352C378.936 8.95352 382.287 14.0612 382.287 22.4739Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M422.647 0.72109H405.535L407.828 28.4229H420.442L422.647 0.72109ZM423 38.7585C423 35.3934 419.031 32.7494 414.091 32.7494C409.151 32.7494 405.182 35.3934 405.182 38.7585C405.182 42.1236 409.151 44.8277 414.091 44.8277C419.031 44.8277 423 42.1236 423 38.7585Z",
      "fill": "#F17D00"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }