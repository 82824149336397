import View from '@/views/layouts/layout-view'

export default {
  path: '/contractors',
  name: 'contractors',
  component: View,
  redirect: '/contractors/list',
  meta: {
    auth: true,
    title: 'Контрагенты'
  },
  children: [
    {
      path: 'list',
      name: 'contractos.list',
      component: () => import('@/views/contractors/pages/Contractors'),
      meta: {
        title: 'Контрагенты'
      }
    },
    {
      path: 'add',
      name: 'contractos.add',
      component: () => import('@/views/contractors/pages/ContractorsItem'),
      meta: {
        title: 'Новый контрагент',
        parrent: 'contractors'
      }
    },
    {
      path: 'edit/:id',
      name: 'contractos.edit',
      component: () => import('@/views/contractors/pages/ContractorsItem'),
      meta: {
        title: 'Редактирование контрагента',
        parrent: 'contractors',
        isEdit: true
      }
    }
  ]
}
