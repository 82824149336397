import { BranchModel } from '@/models/branch.model'
import { StorageModel } from '@/models/storage.model'
import { ProductModel } from '@/models/product.model'

export class SerialModel {
  constructor(request = {}) {
    this.block = request?.block
    this.branch = new BranchModel(request?.branch)
    this.date = new Date(request?.date)
    this.id = request?.id
    this.inventory_number = request?.inventory_number
    this.movement = request?.movement
    this.product = new ProductModel(request?.product)
    this.serial_number = request?.serial_number
    this.storage = new StorageModel(request?.storage)
  }
}
