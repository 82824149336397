export default [
  {
    name: 'store',
    children: []
  },
  {
    name: 'operation_process',
    children: [
      {
        title: 'Накладные',
        opened: true,
        submenu: [
          {
            title: 'Приходы',
            link_name: 'operation-process.overhead.incoming'
          },
          {
            title: 'Списание / возврат',
            link_name: 'operation-process.overhead.outcoming'
          },
          {
            title: 'Перемещения',
            link_name: 'operation-process.movement'
          }
        ]
      },
      {
        title: 'Касса',
        opened: true,
        submenu: [
          {
            title: 'Продажи',
            link_name: 'operation-process.cashbox.sale'
          }
          // {
          //   title: 'Возвраты',
          //   link_name: ''
          // }
        ]
      },
      {
        title: 'Оптовые продажи',
        opened: true,
        submenu: [
          {
            title: 'Продажа контрагенту',
            link_name: 'operation-process.overhead.cashless'
          },
          {
            title: 'Счет',
            link_name: 'operation-process.overhead.invoice'
          }
        ]
      },
      {
        title: 'Работа с поставщиками',
        opened: true,
        submenu: [
          {
            title: 'Автозаказ',
            link_name: 'order/auto'
          },
          {
            title: 'Заказы',
            link_name: 'orders'
          },
          {
            title: 'Контракты',
            link_name: 'operation-process.contracts'
          }
        ]
      },
      {
        title: 'Служебные документы',
        opened: true,
        submenu: [
          {
            title: 'Переоценка товара',
            link_name: 'revaluation.list'
          },
          {
            title: 'Инвентаризация',
            link_name: 'operation-process.overhead.inventory'
          }
        ]
      },
      {
        title: 'Производство',
        opened: true,
        submenu: [
          { title: 'Комплектация', link_name: 'operation-process.equipment' },
          { title: 'Перефасовка', link_name: 'operation-process.repacking' }
        ]
      },
      {
        title: 'Маркетинг',
        opened: true,
        submenu: [
          {
            title: 'Маркетинговые акции',
            link_name: 'operation-process.overhead.promo'
          }
        ]
      }
      /*,
      {
        title: 'Служебные документы',
        opened: false,
        submenu: [
          {
            title: 'Переоценка товара',
            link_name: ''
          },
          {
            title: 'Инвентаризация',
            link_name: ''
          },
          {
            title: 'Уценка товара',
            link_name: ''
          }
        ]
      },

      {
        title: 'Производство',
        opened: false,
        submenu: [
          {
            title: 'Комплектация блюд',
            link_name: ''
          }
        ]
      }*/
    ]
  },
  {
    name: 'analytics',
    children: [
      {
        title: 'Ежедневные отчеты',
        opened: true,
        submenu: [
          {
            title: 'Кассовая реализация',
            link_name: 'analytics.cash-sales.report'
          },
          {
            title: 'ABC - анализ',
            link_name: 'analytics.abc'
          },
          {
            title: 'Анализ товародвижения',
            link_name: 'analytics.sales-analysis.report'
          },
          {
            title: 'Остатки',
            link_name: 'analytics.remainders'
          },
          {
            title: 'Торговые наценки',
            link_name: 'analytics.trading-margins.report'
          }
        ]
      }

      /*,
      {
        title: 'Отчёты для бухгалтерии',
        link_name: ''
      },
      {
        title: 'Управленческие отчёты',
        link_name: ''
      }*/
    ]
  },
  // {
  //   name: 'manufacture',
  //   children: [
  //     // { title: 'Технологические карты', link_name: 'manufacture.maps' },
  //     // { title: 'Перефасовка', link_name: 'manufacture.repackaging' },
  //     { title: 'Комплектация', link_name: 'manufacture.equipment' }
  //   ]
  // },
  {
    name: 'external_systems',
    children: [
      {
        title: 'Государственные системы',
        opened: true,
        submenu: [
          // {
          //   title: 'ЕГАИС',
          //   link_name: ''
          // },
          // {
          //   title: 'Честный Знак',
          //   link_name: ''
          // },
          {
            title: 'Меркурий',
            link_name: 'mercury'
          },
          {
            title: 'ЕГАИС',
            link_name: 'egais'
          }
        ]
      },
      {
        title: 'Отчетность в налоговую',
        opened: true,
        submenu: [
          {
            title: 'Декларация о слаб. алк.',
            link_name: 'analytics.alco-declaration'
          }
        ]
      }
      /* {
          title: 'ЭДО',
          opened: false,
          submenu: [
            {
              title: 'Контрур',
              link_name: ''
            },
            {
              title: 'Сбис',
              link_name: ''
            },
            {
              title: 'Метро СС',
              link_name: ''
            }
          ]
        },*/
      /* {
          title: 'Служебные справочники',
          opened: false,
          submenu: [
            {
              title: 'Банки',
              link_name: ''
            },
            {
              title: 'Упаковки',
              link_name: ''
            },
            {
              title: 'Сотрудники',
              link_name: ''
            },
            {
              title: 'Операции',
              link_name: ''
            },
            {
              title: 'Виды документов',
              link_name: ''
            }
          ]
        }*/
    ]
  },
  {
    name: 'marketing',
    children: [
      {
        title: 'Программа лояльности',
        opened: true,
        submenu: [
          {
            title: 'Дисконтные карты',
            link_name: 'discount-cards'
          },
          {
            title: 'Сегменты',
            link_name: 'segments'
          }
        ]
      }
    ]
  },
  {
    name: 'settings',
    children: [
      {
        title: 'Магазины',
        link_name: 'company.branches'
      },
      {
        title: 'Конфигуратор',
        opened: true,
        submenu: [
          {
            title: 'Интеграции',
            link_name: 'integrations'
          },
          {
            title: 'Оборудование',
            link_name: 'devices.list'
          }
        ]
      },
      {
        title: 'Управление сотрудниками',
        opened: true,
        submenu: [
          {
            title: 'Сотрудники',
            link_name: 'users.list'
          },
          { title: 'Права доступа', link_name: 'access.rights' }
        ]
      }
    ]
  },
  {
    name: 'payment',
    children: [
      {
        title: 'Деньги',
        opened: true,
        submenu: [
          {
            title: 'Платёжные документы',
            link_name: 'payment.docs'
          },
          {
            title: 'План платежей',
            link_name: 'payment.plan'
          },
          {
            title: 'БДР',
            link_name: 'budget.bdr'
          }
        ]
      }
    ]
  }
]
