<template>
  <div class="containers">
    <div class="company">
      <p>{{ companyName }}</p>
    </div>
    <div
      v-if="franchise === 'fasol'"
      class="header"
    >
      <div class="logo-block">
        <left-line200on280-svg />
        <fasol200on280-svg />
        <right-line200on280-svg />
      </div>
      <green-line200on280-svg />
    </div>

    <div class="content-block">
      <b>{{
        product?.name?.length >= (franchise ? 55 : 65)
          ? product?.name?.slice(0, franchise ? 55 : 65) + '...'
          : product?.name
      }}</b>
    </div>
    <div class="footer">
      <div class="price">
        <b>{{
          1 >= product?.retailPrice?.toFixed(2)?.toString()?.split('.')?.length
            ? product?.retailPrice?.toFixed(2) + 'р.' + '00к.'
            : product?.retailPrice?.toFixed(2)?.toString()?.split('.')[0] +
              'р.' +
              product?.retailPrice?.toFixed(2)?.toString()?.split('.')[1] +
              'к.'
        }}</b>
      </div>
      <div class="barcode">
        <div class="barcode-block">
          <div class="price-for">
            <b>Цена:</b>
            <p>за {{ product?.measurement?.name?.slice(0, 2) }}</p>
          </div>
          <div style="width: 49px; height: 23.4px">
            <barcode-generator
              v-if="product?.package"
              :width="49"
              :height="23.4"
              :barcode="product?.package[0]?.barcode?.barcode"
            />
          </div>
        </div>
      </div>
      <div class="footer-text">
        <p>{{ date }}</p>
        <p>{{ product?.country?.name }}</p>
        <p>Арт: {{ product?.articul }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  import Fasol200on280Svg from '@/views/core/modal/price-tag/svg/Fasol200on280Svg.vue'
  import LeftLine200on280Svg from '@/views/core/modal/price-tag/svg/LeftLine200on280Svg.vue'
  import RightLine200on280Svg from '@/views/core/modal/price-tag/svg/RightLine200on280Svg.vue'
  import GreenLine200on280Svg from '@/views/core/modal/price-tag/svg/GreenLine200on280Svg.vue'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Tag200on280',
    components: {
      Fasol200on280Svg,
      LeftLine200on280Svg,
      RightLine200on280Svg,
      GreenLine200on280Svg,
      BarcodeGenerator
    },
    props: {
      product: Object,
      franchise: String
    },
    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return entity_name(this.product.entity) || entity_name(this.getCurrentBranch.entities?.[0]) || this.company_name
      },
      date() {
        return formatDate(new Date())
      }
    }
  }
</script>
<style lang="scss" scoped>
  p {
    padding: 0;
    margin: 0;
  }
  .containers {
    overflow: hidden;
    background-color: #fff;
    position: relative;
    width: 200px;
    height: 280px;
    border: 2px solid #000000;
  }
  .company {
    margin-top: 3px;
    margin-right: 6px;
    margin-bottom: 2px;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 7.3828px;
      line-height: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      color: #000000;
    }
  }
  .header {
    height: 44px;
    position: relative;

    .logo-block {
      display: flex;
      justify-content: space-between;
      .line__logo {
        transform: translateY(29px);
      }
    }
    .green-line {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .content-block {
    b {
      font-family: 'Tahoma';
      font-style: normal;
      font-weight: 700;
      font-size: 19.3788px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.015em;
      color: #000000;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    .price {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      b {
        font-family: 'Gilroy-Bold', sans-serif;
        font-weight: 700;
        font-size: 35.985px;
        line-height: 41px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;
      }
    }

    .barcode {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .barcode-block {
        .price-for {
          display: flex;
          margin-bottom: 3px;
          b {
            margin-left: 3px;
            font-style: normal;
            font-weight: 700;
            font-size: 7.63674px;
            line-height: 9px;
            display: flex;
            align-items: center;
            color: #000000;
          }
          p {
            text-transform: lowercase;
            margin-left: 3px;
            font-style: normal;
            font-weight: 400;
            font-size: 7.63674px;
            line-height: 9px;
            display: flex;
            align-items: center;
            color: #000000;
          }
        }
      }
    }
    .footer-text {
      display: flex;
      justify-content: space-between;
      margin: 9px 6px 6px 6px;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 7.63674px;
        line-height: 9px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }
</style>
