import { ContractHeadModel } from '@/views/operational-processes/models/contract-head.model'
export const state = {
  documentHead: new ContractHeadModel(),
  specList: [],
  listWarningSpec: [],
  filters: {
    supplier: [],
    storages: [],
    operation: [],
    status: [],
    types: [],
    owners: []
  }
}
