var render = function render(){
  var _vm$submenu_array;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "b-submenu__wrapper"
  }, [_c('div', {
    staticClass: "b-submenu-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "b-sidebar__menu b-submenu"
  }, [((_vm$submenu_array = _vm.submenu_array) === null || _vm$submenu_array === void 0 ? void 0 : _vm$submenu_array.name) === 'store' ? _c('div', [_c('div', {
    staticClass: "b-storelist__search"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search_str,
      expression: "search_str"
    }],
    staticClass: "store-search",
    attrs: {
      "type": "text",
      "placeholder": "Поиск..."
    },
    domProps: {
      "value": _vm.search_str
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search_str = $event.target.value;
      }
    }
  }), _vm.search_str ? _c('img', {
    staticClass: "clear-input",
    attrs: {
      "src": "/img/icons/Delete.svg",
      "alt": "clear input"
    },
    on: {
      "click": function () {
        return _vm.search_str = '';
      }
    }
  }) : _vm._e()]), _vm._l(_vm.filteredBranches, function (item) {
    var _item$name;

    return _c('div', {
      key: item === null || item === void 0 ? void 0 : item.id,
      on: {
        "click": function ($event) {
          return _vm.selectStore(item);
        }
      }
    }, [_c('div', {
      staticClass: "radio-block",
      class: {
        'child-block': item === null || item === void 0 ? void 0 : item.child
      }
    }, [_c('e-radio', {
      staticClass: "mr-2",
      attrs: {
        "checked": _vm.getCurrentBranch.id === item.id
      }
    }), (item === null || item === void 0 ? void 0 : item.__typename) === 'BranchClassifModel' ? _c('img', {
      staticClass: "mr-2",
      attrs: {
        "src": "/img/icons//settings/icon_group.svg",
        "alt": ""
      }
    }) : _vm._e(), (item === null || item === void 0 ? void 0 : item.__typename) === 'Branch' ? _c('img', {
      staticClass: "mr-2",
      attrs: {
        "src": "/img/icons//settings/icon_branch.svg",
        "alt": ""
      }
    }) : _vm._e(), _c('div', [_c('div', {
      staticClass: "radio-btns",
      class: {
        'radio-btns-without-address': !(item !== null && item !== void 0 && item.address)
      }
    }, [(item === null || item === void 0 ? void 0 : item.name.length) > 25 ? _c('p', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "name-entity",
      attrs: {
        "title": item === null || item === void 0 ? void 0 : item.name
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$name = item.name) === null || _item$name === void 0 ? void 0 : _item$name.slice(0, 25)) + '...') + " ")]) : _c('p', {
      staticClass: "name-entity"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])]), _vm.adressCompany(item).length > 35 ? _c('p', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "adress-entity",
      attrs: {
        "title": _vm.adressCompany(item)
      }
    }, [_vm._v(" " + _vm._s(_vm.adressCompany(item).slice(0, 35) + '...') + " ")]) : _c('p', {
      staticClass: "adress-entity"
    }, [_vm._v(" " + _vm._s(_vm.adressCompany(item)) + " ")])])], 1)]);
  }), _vm.filteredBranches.length === 0 ? _c('b', {
    staticClass: "emty"
  }, [_vm._v("По данному запросу ничего не найденно")]) : _vm._e()], 2) : _c('ul', [_vm._l(_vm.menu, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "menu",
      class: {
        opened: item.opened
      }
    }, [item.link_name ? [_c('router-link', {
      staticClass: "item item-link",
      attrs: {
        "to": {
          name: item.link_name
        }
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")])] : item.submenu ? [_c('span', {
      staticClass: "title cursor",
      staticStyle: {
        "font-weight": "600"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.toggleSubmenu(index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('span', {
      staticClass: "arrow cursor",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.toggleSubmenu(index);
        }
      }
    }), _c('ul', {
      staticClass: "b-submenu"
    }, _vm._l(item.submenu, function (subitem, index) {
      return _c('li', {
        key: index
      }, [_c('router-link', {
        staticClass: "submenu-item",
        attrs: {
          "to": {
            name: subitem.link_name
          },
          "exact-active-class": "active-router",
          "active-class": "active-router"
        }
      }, [_vm._v(" " + _vm._s(subitem.title) + " ")])], 1);
    }), 0)] : [_c('span', {
      staticClass: "title"
    }, [_vm._v(_vm._s(item.title))])]], 2);
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }