import View from '@/views/layouts/layout-view'

export default {
  path: 'operation-process',
  name: 'operation-process',
  component: View,
  children: [
    {
      path: 'revaluations',
      name: 'revaluation.list',
      component: () => import('@/views/service/revaluation/pages/Revaluations'),
      meta: { title: 'Переоценка' }
    },
    {
      path: 'revaluations/:id',
      name: 'operation-process.overhead.revaluation.document.edit',
      component: () => import('@/views/service/revaluation/pages/Revaluation'),
      meta: { title: 'Переоценка' }
    }
  ]
}
