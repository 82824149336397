<template>
  <b-row>
    <b-col cols="6">
      <div class="card">
        <b-row>
          <b-col cols="4">
            <div class="form-group">
              <label>Печатная форма</label>
              <i-select
                v-model="format"
                default-label="3 x 2"
                @input="inputFormat"
              >
                <i-option value="3x2"> 3 x 2 </i-option>
                <i-option value="6x4"> 6 x 4 </i-option>
              </i-select>
            </div>
          </b-col>
          <b-col cols="4">
            <div class="form-group d-flex align-items-end h-100">
              <div
                style="height: 30px"
                class="d-flex justify-content-center align-items-center"
              >
                <b-form-checkbox
                  v-model="show_price"
                  name="check-button"
                  switch
                />
                <p
                  class="show-price-label"
                  @click="show_price = !show_price"
                >
                  С ценой
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="card mt-4 print-products">
        <b-table
          ref="table_products"
          :fields="fields"
          :items="products"
          empty-filtered-text="Не выбраны ценники для печати"
          show-empty
          class="table-products mt-4"
        >
          <template #empty="scope">
            <p class="center mt-3">{{ scope.emptyFilteredText }}</p>
          </template>

          <template #head(print)>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="selected_all" />
              Печать
            </div>
          </template>

          <template #cell(print)="data">
            <b-form-checkbox
              :checked="printProducts[data.item.id]?.enabled"
              @change="(value) => setSelected(data.item.id, value)"
            />
          </template>

          <template #cell(count)="data">
            <e-input
              type="number"
              class="input-count"
              :value="1"
              @input="(value) => setSelectedProductPrintCount(data.item.id, value)"
            />
          </template>
        </b-table>
      </div>
    </b-col>
    <b-col cols="6">
      <div class="card p-0 print-right">
        <div class="print-right-header d-flex align-content-center justify-content-between">
          <div class="print-pager">
            <template v-if="printProductsCount / perPage > 0">
              <button
                class="print-pager-prev"
                :disabled="currentPage === 1"
                @click="onPrevPage"
              >
                <img
                  src="/img/icons/arrow.svg"
                  alt="Предыдущая страница"
                />
              </button>
              <span>
                {{ currentPage }} /
                {{ Math.ceil(printProductsCount / perPage) }}
              </span>
              <button
                class="print-pager-next"
                :disabled="currentPage === Math.ceil(printProductsCount / perPage)"
                @click="onNextPage"
              >
                <img
                  src="/img/icons/arrow.svg"
                  alt="Следующая страница"
                />
              </button>
            </template>
          </div>
          <!--          <div class="print-zoom-actions">-->
          <!--            <button-->
          <!--              class="btn btn-light"-->
          <!--              :disabled="zoom === 3"-->
          <!--              @click="onZoomPlus"-->
          <!--            >-->
          <!--              <img src="/img/icons/btn-plus-dark.svg" alt="" />-->
          <!--            </button>-->
          <!--            <button-->
          <!--              class="btn btn-light"-->
          <!--              :disabled="zoom === 1"-->
          <!--              @click="onZoomMinus"-->
          <!--            >-->
          <!--              <img src="/img/icons/btn-minus-dark.svg" alt="" />-->
          <!--            </button>-->
          <!--            <i-select-->
          <!--              v-model="zoom"-->
          <!--              placeholder="Масштаб"-->
          <!--              class="print-zoom-select"-->
          <!--              @change="resetPrintMainScroll"-->
          <!--            >-->
          <!--              <i-option :value="1">50%</i-option>-->
          <!--              <i-option :value="2">100%</i-option>-->
          <!--              <i-option :value="3">150%</i-option>-->
          <!--            </i-select>-->
          <!--          </div>-->
        </div>
        <div
          ref="printMain"
          class="print-right-main"
          :class="{ half: zoom === 1, full: zoom === 3 }"
        >
          <!-- <div
            v-if="currentPage > 1"
            class="print-grid-item lyap"
          >
            <product-label
              style="padding: 10px"
              :show_price="show_price"
              :format="format"
              :weight_format="weightFormat"
              :product="showProducts[currentPage - 2]"
            />
          </div> -->

          <div
            v-if="showProducts.length > 0"
            class="print-grid-item lyap"
            style="margin-bottom: 16px; margin-top: 16px"
          >
            <product-label
              style="padding: 10px"
              :show_price="show_price"
              :weight_format="weightFormat"
              :format="format"
              :product="showProducts[currentPage - 1]"
            />
          </div>

          <!-- <div
            v-if="showProducts.length > currentPage"
            class="print-grid-item lyap"
          >
            <product-label
              style="padding: 10px"
              :show_price="show_price"
              :weight_format="weightFormat"
              :format="format"
              :product="showProducts[currentPage]"
            />
          </div> -->
          <portal to="dirty">
            <template v-for="(page, pageIndex) of printPages">
              <div
                :key="pageIndex"
                :class="`label-print-grid-${format}`"
              >
                <template v-for="(product, index) of page">
                  <div
                    :key="index + product?.id"
                    :class="`print-grid-item-${format}`"
                  >
                    <product-label
                      :show_price="show_price"
                      :weight_format="weightFormat"
                      :product="product"
                      :format="format"
                    />
                  </div>
                </template>
              </div>
            </template>
          </portal>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
  import * as R from 'ramda'
  import ProductLabel from '@/views/core/modal/ProductLabel'

  export default {
    components: {
      ProductLabel
    },

    props: {
      products: {
        type: Array,
        default: () => []
      }
    },

    data: () => ({
      show_price: false,
      format: '',
      zoom: 2,
      selected_all: true,
      perPage: 1,
      printProducts: {},
      currentPage: 1,
      weightFormat: ''
    }),
    computed: {
      fields: function () {
        return [
          {
            key: 'name',
            label: 'Наименование товара',
            sortable: true
          },
          {
            key: 'print',
            label: 'Печать',
            class: 'w-15 text-left'
          },
          {
            key: 'count',
            label: 'Кол-во к печати',
            class: 'w-25 center',
            thStyle: 'padding: 0;'
          }
        ]
      },
      printProductsCount: function () {
        let count = 0
        R.forEachObjIndexed((product) => {
          if (product.count && product.enabled) {
            count += +product.count
          }
        }, this.printProducts)

        return count
      },
      printPages: function () {
        const pageCount = Math.ceil(this.showProducts?.length / this.perPage)
        const result = new Array(pageCount).fill([])

        this.showProducts.forEach((item, index) => {
          let pageIndex = Math.ceil((index + 1) / this.perPage) - 1
          result[pageIndex] = [...result[pageIndex], item]
        })

        return result
      },
      showProducts: function () {
        let result = []
        R.forEachObjIndexed((product, key) => {
          if (product?.enabled && product?.count) {
            const findProduct = this.getProduct(key)
            for (let i = 0; i < product.count; i++) {
              result.push(findProduct)
            }
          }
        }, this.printProducts)
        return result
      }
    },

    watch: {
      products(newProducts) {
        this.printProducts = {}

        newProducts.forEach((item) => {
          const id = item.id
          this.checkIdIsAdded(id)
          this.$set(this.printProducts[id], 'enabled', true)
          this.$set(this.printProducts[id], 'count', '1')
        })
      },

      selected_all(newVal) {
        if (!newVal) {
          this.printProducts = R.map(
            (product) => ({
              ...product,
              enabled: false
            }),
            this.printProducts
          )
        } else {
          this.products.forEach((item) => {
            const id = item.id
            this.checkIdIsAdded(id)
            this.$set(this.printProducts[id], 'enabled', true)
          })
        }
      },
      zoom() {
        this.resetPrintMainScroll()
      }
    },

    mounted() {
      this.loadFormat()
      this.loadWeightFormat()
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'modal-print-tags') {
          this.printProducts = []
          this.selected_all = false
        }
      })

      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        if (modalId === 'modal-print-tags') this.selected_all = true
      })

      this.products?.forEach((item) => {
        const id = item.id
        this.checkIdIsAdded(id)
        this.$set(this.printProducts[id], 'enabled', true)
        this.$set(this.printProducts[id], 'count', 1)
      })
    },

    methods: {
      onNextPage() {
        this.currentPage++
      },

      onPrevPage() {
        this.currentPage--
      },

      onZoomMinus() {
        this.zoom--
      },

      onZoomPlus() {
        this.zoom++
      },
      inputFormat() {
        localStorage.setItem('label-format', this.format)
      },
      setSelected(id, value) {
        this.checkIdIsAdded(id)
        this.currentPage = 1
        this.$set(this.printProducts[id], 'enabled', value)
      },

      setSelectedProductPrintCount(id, count) {
        setTimeout(() => {
          this.checkIdIsAdded(id)
          this.currentPage = 1
          this.$set(this.printProducts[id], 'count', count)
        }, 10)
      },

      resetPrintMainScroll() {
        this.$refs.printMain.scrollTo(0, 0)
      },

      checkIdIsAdded(id) {
        if (!this.printProducts[id]) {
          this.printProducts = Object.assign({}, this.printProducts, {
            [id]: {}
          })
        }
      },

      getProduct(id) {
        return this.products.find((product) => product.id === id)
      },

      printPriceTags() {
        // this.$set(this, 'showPrint', true)
        window.print()
        // this.$set(this, 'showPrint', false)
      },
      weightFormatChange() {
        localStorage.setItem('weightFormat', this.weightFormat)
      },
      loadWeightFormat() {
        this.weightFormat = localStorage.getItem('weightFormat') || 'kilogram'
      },
      loadFormat() {
        this.format = localStorage.getItem('label-format') || '3x2'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .card {
    padding: 15px;

    .form-group {
      margin-bottom: 0;

      label {
        font-size: 14px !important;
      }
    }
  }

  .print-products {
    height: 430px;
    padding: 0;
    margin-bottom: 0;
    overflow-y: scroll;
  }

  table.b-table {
    margin-top: -1px !important;

    .input-count {
      width: 46px;
    }
  }

  .print-right {
    height: 542px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
      flex-shrink: 0;
      position: relative;
      z-index: 1;
    }

    &-main {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      background: #ffe8c7;

      &.half {
        .print-right-page {
          transform-origin: 190px 105px;
          transform: scale(0.25);
          // margin-bottom: -1040px;
        }
      }

      &.full {
        overflow-x: auto;

        .print-right-page {
          transform: none;
          margin-bottom: 0;
        }
      }
    }

    &-page {
      padding: 9px 10px;
      overflow: auto;
      background: #fff;
    }
  }

  .grid {
    width: 100%;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);
  }

  .print-grid-item {
    //width: 300mm;
    //height: 200mm;
    display: flex;
    padding: 5px;
    flex-direction: column;
    flex-shrink: 0;
    //aspect-ratio: 3/2;
    //margin-left: -1px;
    min-width: 1px;

    &:first-child,
    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &:nth-child(-n + 3) {
      border-top-width: 1px;
    }

    &:last-child,
    &:nth-child(3n) {
      border-right-width: 1px;
    }
  }

  .print-pager {
    display: flex;
    align-items: center;
    gap: 10px;

    &-prev,
    &-next {
      background: none;
      border: 0;
      padding: 0;
      cursor: pointer;

      img {
        display: block;
      }

      &:disabled {
        cursor: default;
        opacity: 0.3;
      }
    }

    &-prev {
      transform: rotate(180deg);
    }
  }

  .print-zoom-actions {
    display: flex;
    align-items: center;
    gap: 8px;

    .btn {
      width: 32px;
      justify-content: center;
      padding: 0;
      flex-shrink: 0;

      &:disabled {
        cursor: default;
      }
    }
  }

  .print-zoom-select {
    min-width: 105px;
  }
</style>

<style lang="scss" scoped>
  .lyap {
    height: 185px;
    width: 275px;
    margin: 2px;
    background: white;
    padding: 5px;
    border-radius: 17px;
  }

  .modal-footer {
    justify-content: flex-start;

    button {
      width: auto;
      margin: 0 10px 0 0;
      padding: 0 32px;
    }
  }

  .label-print-grid {
    width: 100%;
    display: none;
    grid-template-columns: repeat(3, 1fr);
    page-break-after: always;

    .print-grid-item {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      page-break-inside: avoid;
      flex-shrink: 0;
      border: 1px solid #bcbcbc;
      //margin-left: -1px;
      min-width: 1px;

      &:first-child,
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &:nth-child(-n + 3) {
        border-top-width: 1px;
      }

      &:last-child,
      &:nth-child(3n) {
        border-right-width: 1px;
      }
    }

    .print-grid-line {
      page-break-after: auto;
    }
  }

  @page {
    size: auto; /* auto is the initial value */
    margin: 0; /* this affects the margin in the printer settings */
    padding: 0;
  }

  @media print {
    body {
      padding: 0;
      margin: 0;
    }
    .label-print-grid-3x2 {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .label-print-grid-6x4 {
      position: relative;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }

    .print-grid-item-3x2 {
      position: absolute;
      transform: rotate(90deg) scale(6);
    }

    .print-grid-item-6x4 {
      position: absolute;
      transform: rotate(90deg) scale(6);
    }
  }
  .show-price-label {
    cursor: pointer;
    margin-bottom: 0 !important;
  }
</style>
