import { EntityModel } from '@/models/entity.model'

export const state = {
  filters: {
    supplier: [],
    storages: [],
    operation: [],
    status: [],
    types: [],
    owners: [],
    branches: []
  },
  document: {},
  entity: new EntityModel()
}
