<template>
  <div
    class="anal__wiget"
    @click="clicked"
  >
    <div
      class="analytics__item"
      :class="{
        without_border: show_drop_down,
        'cursor-pointer': !!on_click
      }"
    >
      <div class="analytics__item-text">
        {{ data.name }}<span> {{ convertDate(data.date) }}</span>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: end; flex: 1">
        <div class="analytics__item-price">
          {{ data.currency ? getFormatMoney(data.value, 2) : data.value }}<span>{{ data.currency ? '₽' : 'Шт.' }}</span>
        </div>
        <div
          v-if="click_more && data.value > 0"
          class="show-more-arrow"
          :class="{ opened: show_drop_down }"
          @mouseover="onClick(true)"
          @mouseleave="onClick(false)"
          @click.stop
        >
          <img
            class="show-more-arrow-svg"
            src="/img/icons/arrow_up.svg"
          />
        </div>
      </div>

      <transition name="fade">
        <div
          v-if="show_drop_down"
          class="analytics-dropdown"
        >
          <div
            v-for="(item, index) in data.payment_types"
            :key="index"
            class="analytics-dropdown-item"
          >
            <div class="analytics-dropdown-title">{{ item.type }}, ₽</div>
            <div class="dropdown-money">{{ formatMoney(item.value) }}</div>
          </div>
          <div>
            <div class="analytics-dropdown-title">Возвраты, ₽</div>
            <div class="dropdown-money">{{ formatMoney(data.returns) }}</div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import { formatMoney } from '@/utils/formatMoney'

  export default {
    name: 'AnalyticsWidget',
    props: {
      data: {
        name: String,
        value: Number,
        date: Date,
        currency: Boolean,
        payment_types: Array,
        returns: Number
      },
      show_drop_down: Boolean,
      click_more: Function,
      on_click: Function
    },
    methods: {
      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },
      onClick(val) {
        if (this.click_more) {
          this.click_more(val)
        }
      },
      clicked() {
        if (this.on_click) this.on_click()
      },

      getFormatMoney(val, count) {
        return formatMoney(val, count)
      },

      formatMoney(value) {
        if (!value) value = 0
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB'

          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(value)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .analytics__item {
    display: flex;
    flex-direction: column;
    align-items: stretch !important;
    border: none;
    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    .analytics__item-price.price-up {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .analytics-dropdown {
    position: absolute;
    background: white;
    right: 0;
    left: 0;
    top: 100%;
    z-index: 1;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 6px 6px;
    padding: 16px;
    border-top: #e2e2e2 1px solid;
  }

  .without_border {
    border-radius: 6px 6px 0px 0px !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  }

  .show-more-arrow {
    border: 1px solid #efefef;
    border-radius: 27px;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.35s ease-in-out;
    transform: rotateZ(180deg);
  }
  .show-more-arrow-svg {
    height: 4.5px;
    width: 8px;
  }
  .opened {
    transform: rotateZ(360deg);
    background: #efefef;
  }

  .dropdown-money {
    color: #313131;
  }

  .analytics-dropdown-title {
    color: #888888;
  }
  .analytics-dropdown-item {
    margin-bottom: 12px;
  }

  .cursor-pointer {
    transition: 0.3s;
  }
  .cursor-pointer:hover {
    cursor: pointer;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  }
  .anal__wiget {
    flex: 1;
  }
</style>
