<template>
  <b-modal
    :id="id_modal ? id_modal : 'sort-table-modal'"
    static
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <h5 class="title-text">Настройка столбцов</h5>
    <div class="d-flex">
      <div class="header first">Все столбцы</div>
      <div class="header">Показанные столбцы</div>
    </div>
    <div class="body">
      <div class="columns first">
        <div class="d-flex check-box">
          <b-checkbox
            :checked="allSelected"
            @change="selectAll"
          />
          Выбрать все
        </div>
        <div
          v-for="(field, index) in defaultFields.filter((el) => el.key !== 'id')"
          :key="index"
        >
          <div class="d-flex check-box">
            <b-checkbox
              v-model="field.checked"
              @change="checkParent(field)"
            />
            <span>{{ field.label }}</span>
          </div>
          <div>
            <div class="ml-4">
              <div
                v-for="(el, index) in field.children"
                :key="index"
                class="d-flex check-box"
              >
                <b-checkbox
                  v-model="el.checked"
                  @change="checkChild(field)"
                />
                {{ el.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <draggable
          ghost-class="ghost"
          :list="newFields"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group>
            <div
              v-for="field in newFields"
              :key="field.key"
              class="d-flex item"
              :class="{ hide: !field.checked }"
            >
              <div class="d-flex check-box">
                <b-checkbox
                  v-model="field.checked"
                  @change="checkParent(field)"
                />
                {{ field.label }}
                <img
                  v-if="!no_drag"
                  src="/img/icons/move_button.svg"
                  class="ml-auto move_button"
                  alt=""
                />
              </div>
              <div class="ml-4">
                <div
                  v-for="(el, index) in field.children"
                  :key="index"
                  class="d-flex check-box"
                >
                  <b-checkbox
                    v-model="el.checked"
                    @change="checkChild(field)"
                  />
                  {{ el.label }}
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>

    <div class="d-flex footer-modal align-items-center">
      <b-button
        variant="outline-primary"
        @click="handleBack"
      >
        Отмена
      </b-button>
      <b-button
        variant="primary"
        class="ml-2"
        @click="handleOk"
      >
        Применить
      </b-button>
      <a
        class="ml-auto"
        @click="setDefault"
        >Вернуть по умолчанию</a
      >
    </div>
  </b-modal>
</template>

<script>
  import draggable from 'vuedraggable'

  export default {
    name: 'SortTableModal',
    components: {
      draggable
    },
    props: {
      id_modal: {
        type: String,
        default: ''
      },
      fields: {
        type: Array,
        default: () => []
      },
      no_drag: Boolean,
      default_fields: { type: Array, required: true }
    },
    data() {
      return {
        defaultFields: JSON.parse(JSON.stringify(this.default_fields)),
        newFields: this.fields?.filter((el) => el.key !== 'id'),
        drag: false
      }
    },
    computed: {
      allSelected() {
        for (const field of this.fields) {
          if (!field.checked) return false
        }
        return true
      }
    },
    watch: {
      default_fields() {
        this.defaultFields = JSON.parse(JSON.stringify(this.default_fields)) || []
        this.newFields = this.setFields(this.defaultFields, this.fields)
      },
      fields(newVal) {
        if (Array.isArray(this.defaultFields)) {
          this.newFields = this.setFields(this.defaultFields, JSON.parse(JSON.stringify(this.fields)))
        } else {
          this.newFields = newVal?.filter((el) => el.key !== 'id')
        }
      }
    },
    methods: {
      setFields(default_fields, user_fields) {
        const newArr = []
        default_fields?.forEach((el, index) => {
          const find_index = user_fields?.findIndex((field) => field.key === el.key)
          el.index = find_index ?? index
          el.checked = user_fields[find_index]?.checked ?? el.checked
          if (el.children) el.children = this.setFields(el.children, user_fields[find_index]?.children)
          newArr.push(el)
        })
        newArr.sort((a, b) => (a.index > b.index ? 1 : -1))
        return newArr?.filter((el) => el.key !== 'id')
      },
      handleOk() {
        const newFields = this.newFields
        if (this.fields.find((el) => el.key === 'id')) {
          newFields.unshift({
            key: 'id',
            label: '',
            thStyle: 'min-width: 45px',
            width: 45,
            checked: true
          })
        }
        this.$emit('change', JSON.parse(JSON.stringify(newFields)))
        if (this.id_modal) this.$bvModal.hide(this.id_modal)
        else this.$bvModal.hide('sort-table-modal')
      },

      setDefault() {
        this.fields.forEach((el) => {
          el.checked = true
          el?.children?.forEach((el) => (el.checked = true))
        })
        this.$emit('set-default')
        if (this.id_modal) this.$bvModal.hide(this.id_modal)
        else this.$bvModal.hide('sort-table-modal')
      },

      handleBack() {
        if (this.id_modal) this.$bvModal.hide(this.id_modal)
        else this.$bvModal.hide('sort-table-modal')
        // this.$bvModal.hide('sort-table-modal')
      },

      selectAll(val) {
        this.fields.forEach((el) => (el.checked = val))
      },
      checkChild(field) {
        const find = field.children?.find((el) => el.checked)
        field.checked = !!find
      },
      checkParent(field) {
        field.children?.forEach((el) => (el.checked = field.checked))
      }
    }
  }
</script>

<style scoped>
  .move_button {
    cursor: grab;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .item {
    display: flex;
    flex-direction: column;
  }
  .check-box {
    margin-bottom: 14px;
  }

  .footer-modal {
    border-top: #e1e1e1 1px solid;
    padding: 17px;
  }

  .body {
    display: flex;
  }

  .columns {
    flex: 1;
    padding: 16px;
  }

  .header {
    flex: 1;
    padding: 16px;
    border-bottom: 1px solid #e1e1e1;
    border-top: 1px solid #e1e1e1;
  }

  .header.first {
    border-right: 1px solid #e1e1e1;
  }

  .columns.first {
    border-right: 1px solid #e1e1e1;
  }

  .title-text {
    padding-left: 16px;
    padding-bottom: 8px;
  }

  .hide {
    display: none !important;
  }
</style>
