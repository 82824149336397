<template>
  <section id="app">
    <core-binding-modal />
    <barcode-prefix-modal />
    <alert-provider>
      <router-view />
    </alert-provider>
  </section>
</template>

<script>
  import axios from 'axios'
  import { mapActions, mapGetters } from 'vuex'
  import CoreBindingModal from '@/views/core/modal/CoreBindingModal.vue'
  import AlertProvider from '@/views/core/provider/AlertProvider.vue'
  import BarcodePrefixModal from '@/views/core/modal/BarcodePrefixModal.vue'

  export default {
    components: { BarcodePrefixModal, AlertProvider, CoreBindingModal },
    apollo: {
      GetTourStatus: {
        query: require('@/gql/tour/getTourStatus.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          if (data.GetTourStatus) this.setTour(data.GetTourStatus)
        },
        skip() {
          return !this.isAuth
        }
      },
      Branch: {
        fetchPolicy: 'no-cache',
        query: require('./gql/queries/Branch.graphql'),
        variables() {
          return {
            id: this.currentBranch.id
          }
        },
        result({ data }) {
          this.setCurrentBranch(data.Branch)
        },
        skip() {
          return !this.currentBranch?.id
        }
      }
    },
    sockets: {
      connect: function () {
        // this.$socket.emit('listenEgais', { branch: this.currentBranch.id })
      },
      async sendEgaisRequest(req) {
        const formData = new FormData()
        if (req.bodyAppend) {
          const blob = new Blob([req.bodyAppend], { type: 'text/xml' })
          formData.append('xml_file', blob)
        }
        const { data } = await axios({
          url: req.url,
          method: req.method,
          data: formData
        })
        this.$socket.emit('responseFromEgais', {
          message: data,
          id: req.id,
          branch: this.currentBranch.id,
          url: req.url
        })
      }
    },
    async created() {
      if (!localStorage.getItem('docHeader')) {
        localStorage.setItem('docHeader', 'true')
      }
      if (!localStorage.getItem('newDocHeader')) {
        localStorage.setItem('newDocHeader', 'false')
      }
      if (this.isAuth) {
        const { data } = await this.$apollo.mutate({
          mutation: require('./views/auth/gql/isConfirmed.graphql'),
          fetchPolicy: 'no-cache'
        })
        if (data?.IsConfirmed) {
          this.setIsConfirmed(data?.IsConfirmed?.status)
          if (data?.IsConfirmed?.status) {
            if (this.$route.meta.auth === false || this.$route.meta.register === true) {
              await this.$router.push({ name: 'dashboard' })
            }
          }
        }
      }
    },
    mounted() {
      document.onkeydown = () => {
        // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
        if (event.keyCode === 123) return false
      }
    },
    methods: {
      ...mapActions({
        setTour: 'core/setTourStatus',
        setIsConfirmed: 'core/setIsConfirmed',
        setCurrentBranch: 'settings/setCurrentBranch'
      })
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        tour: 'core/getTour',
        isAuth: 'core/isAuth'
      })
    }
  }
</script>

<style scoped lang="scss">
  #app {
    height: 100vh;
    //overflow: hidden;

    ::v-deep() {
      .blur {
        position: absolute;
        z-index: 100;
        height: 100vh;
        width: 100vw;
        background: #000000;
        opacity: 0.2;
      }
    }
  }
</style>
