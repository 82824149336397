<template>
  <b-modal
    id="comparison-modal"
    hide-header
    hide-footer
    centered
    @hide="closeModal"
  >
    <div class="container-comaparison">
      <h1 class="title">Связка моих групп товаров с группами франшизы</h1>
      <div class="groups-block">
        <div class="header">
          <p>Ваше наименование</p>
          <p>Наименование франшизы</p>
        </div>
        <div class="content-comaprison">
          <div
            v-if="loading"
            style="width: 100%; display: flex; justify-content: center; height: 100%; align-items: center"
          >
            <e-spinner
              width="35"
              height="35"
            />
          </div>
          <div v-else-if="error">
            <b>Ошибка на сервере</b>
          </div>
          <comparison-item
            v-for="item in menu_arr"
            v-else
            :key="item.id"
            :opened="opened"
            :item="item"
            :franchise_arr="franchise_arr"
            :category_path="category_path"
            :franchise_path="franchise_path"
            @setItemName="setItemName"
            @refetch="() => $apollo.queries.ReadCategoryByPath.refresh()"
            @set_category_path="set_category_path"
            @set_franchise_path="setFranchisePath"
            @openCategory="(id) => openCategory(id)"
          />
        </div>
      </div>
    </div>
    <div class="buttons">
      <e-button
        variant="primary"
        size="m"
        @click="closeModal('ok')"
      >
        Закрыть
      </e-button>
    </div>
  </b-modal>
</template>

<script>
  import draggable from 'vuedraggable'
  import ComparisonItem from '@/views/products/components/modal/ComparisonItem'
  import { mapGetters } from 'vuex/dist/vuex.esm.browser'
  export default {
    name: 'ComparisonModal',
    apollo: {
      ReadCategoryByPath: {
        query: require('../gql/getCategoryPathCompar.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            path: this.category_path
          }
        },
        async result({ data }) {
          if (data?.ReadCategoryByPath) {
            this.menu_arr = data?.ReadCategoryByPath ?? []
            this.loading = false
          }
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },
      ReadFranchiseCategoryByName: {
        query: require('../gql/ReadFranchiseCategoryByName.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              franchise: 'fasol',
              path: this.franchise_path
            }
          }
        },
        async result({ data }) {
          if (data?.ReadFranchiseCategoryByName) {
            this.franchise_arr = data?.ReadFranchiseCategoryByName ?? []
          }
        },
        error(error) {
          this.error = true
          console.error('Ошибка запроса: ', error)
        }
      }
    },
    components: { ComparisonItem, draggable },
    props: {},
    data() {
      return {
        error: false,
        category_path: [],
        franchise_path: [],
        categoryId: '',
        loading: true,
        menu_arr: [],
        franchise_arr: [],
        opened: []
      }
    },
    computed: {
      ...mapGetters({
        getCurrentBranch: 'settings/getCurrentBranch',
        getBranchList: 'settings/getBranchList'
      })
    },

    methods: {
      setItemName({ val, id }) {
        this.menu_arr = this.menu_arr.map((obj) => {
          if (obj.id === id) {
            obj.name = val
          }
          return obj
        })
      },
      closeModal() {
        this.$noty.show('Данные успешно обновлены')
        this.$bvModal.hide('comparison-modal')
      },
      set_category_path(path) {
        this.category_path = path
        this.$apollo.queries.ReadCategoryByPath.refresh()
      },
      setFranchisePath(path) {
        this.franchise_path = path
        this.$apollo.queries.ReadFranchiseCategoryByName.refresh()
      },
      async openCategory({ id, path }) {
        if (this.category_path.includes(id) && this.category_path.length) {
          const newArr = []
          for (let i = 0; i < this.category_path.length; i++) {
            if (this.category_path[i] === id) break
            newArr.push(this.category_path[i])
          }
          this.opened = newArr
          this.set_category_path(newArr)
        } else {
          this.opened = path
          this.set_category_path(path)
        }
        // if (this.opened.includes(id)) {
        //   this.opened = this.opened.filter((el) => el !== id)
        // } else {
        //   this.opened.push(id)
        //   this.categoryId = id
        //   // await this.getCategory()
        // }
      },
      async getCategory() {
        try {
          const { data } = await this.$apollo.query({
            query: require('../gql/getCategory.gql'),
            variables: {
              id: this.categoryId,
              branch: this.getCurrentBranch.id
            }
          })
          this.menu_arr = this.menu_arr.map((obj) => {
            if (obj.id === this.categoryId) {
              obj.child_groups = data?.Category?.child_groups
            }
            return obj
          })
        } catch (e) {
          this.$noty.error('Ошибка при получении категории, попробуйте позже')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .modal-dialog {
    max-width: 875px !important;
  }
  .container-comaparison {
    .title {
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #313131;
      margin-bottom: 22px;
    }
    .groups-block {
      background: #ffffff;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      height: 701px;
      overflow: hidden;
      .header {
        padding: 17px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e1e1e1;
        p {
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #313131;
          margin-bottom: 0;
        }
      }
      .content-comaprison {
        overflow-y: auto;
        height: 650px;
        position: relative;
        &::-webkit-scrollbar {
          width: 8px;
          height: 7px;
          background: #f8f8f9;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #e1e3ea;
        }
        .comaprison-item {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 17px;
          align-items: center;
          border-bottom: 1px solid #e1e1e1;
          .item-left {
            display: flex;
            align-items: center;
            .arrow-down {
              margin-right: 14px;
              &__rotate {
                transform: rotate(-90deg);
              }
            }
            .error-svg {
              margin-right: 6px;
            }
          }
          .item-right {
            display: flex;
            align-items: center;
            .connect {
              p {
                font-style: italic;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                display: flex;
                align-items: center;
                color: #bcbcbc;
                margin: 0;
              }
            }
            .connect-svg {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
  .buttons {
    display: flex;
    margin: 22px 0;
    button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
</style>
