import * as R from 'ramda'
import { apolloClient } from '@/apollo.config'
import {
  CREATE_ENTITY,
  // UPDATE_ENTITY,
  CREATE_BANK_ACCOUNT,
  // UPDATE_BANK_ACCOUNT,
  UPDATE_MAIN_BANK_ACCOUNT,
  REMOVE_BANK_ACCOUNT,
  CREATE_PERSON,
  CREATE_SUPPLIER,
  CREATE_CONTRACT,
  REMOVE_SUPPLIER
} from '@contractors/gql/mutations'
import { GET_SUPPLIERS, GET_PERSONS, GET_BANKS, GET_SUPPLIER } from '@contractors/gql/query'

export const actions = {
  // Methods 'Create/Update/Remove'

  async createBankAccount({ dispatch }, params) {
    const resp = await apolloClient.mutate({
      mutation: CREATE_BANK_ACCOUNT,
      variables: {
        supplier: params.supplier_id,
        bank: params.bank,
        bik: params.bik,
        corr_acc: params.corr_acc,
        check_acc: params.check_acc,
        main: params.main,
        comment: params.comment,
        entity: params.entity_id
      }
    })

    if (resp.data) dispatch('getBanks', params.supplier_id)
  },

  // async updateBankAccount({ dispatch }, params) {
  //   const { data } = await apolloClient.mutate({
  //     mutation: UPDATE_BANK_ACCOUNT,
  //     variables: {
  //       id: params.id,
  //       bank: params.bank,
  //       bik: params.bik,
  //       corr_acc: params.corr_acc,
  //       check_acc: params.check_acc,
  //       main: params.main,
  //       comment: params.comment
  //     }
  //   })

  //   if (data) dispatch('getBanks', params.supplier_id)
  // },

  async setMainBankAccount({ dispatch }, params) {
    const { data } = await apolloClient.mutate({
      mutation: UPDATE_MAIN_BANK_ACCOUNT,
      variables: {
        id: params.id,
        main: true
      }
    })

    if (data) dispatch('getBanks', params.supplier_id)
  },

  async removeBankAccount({ dispatch }, params) {
    const { data } = await apolloClient.mutate({
      mutation: REMOVE_BANK_ACCOUNT,
      variables: {
        ids: params.id
      }
    })

    if (data) dispatch('Supplier ', params.supplier_id)
  },

  setTreaty({ commit }, treaty) {
    commit('SET_TREATY', treaty)
  },

  async CreateContract({ commit }, contract) {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_CONTRACT,
      variables: {
        name: contract.name,
        external_number: contract.external_number,
        external_date: contract.external_date,
        begin_date: contract.begin_date,
        end_date: contract.end_date,
        supplier_id: contract.supplier,
        person: contract.person,
        delivery_schedule: contract.delivery_schedule
      }
    })

    const contract_info = R.pathOr([], ['CreateContract'], data)

    if (contract_info) commit('SET_CURRENT_CONTRACT', contract_info)
  },

  async createEntity({ commit }, entity) {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_ENTITY,
      variables: {
        name: entity.name,
        inn: entity.inn,
        kpp: entity.kpp,
        ogrn: entity.ogrn,
        fullname: entity.fullname,
        email: entity.email,
        phone: entity.phone,
        ceo: entity.ceo,
        address_entity: entity.address_entity,
        address_fact: entity.address_fact
      }
    })

    if (data) {
      const id = R.pathOr('', ['CreateEntity', 'id'], data)
      commit('SET_CURRENT_ENTITI_DATA', entity)
      commit('SET_CURRENT_ENTITI_ID', id)
      commit('SET_CURRENT_TAB', 1)
    }
  },

  // async updateEntity({ commit }, entity) {
  //   const { data } = await apolloClient.mutate({
  //     mutation: UPDATE_ENTITY,
  //     variables: {
  //       id: entity.id,
  //       name: entity.name,
  //       inn: entity.inn,
  //       kpp: entity.kpp,
  //       ogrn: entity.ogrn,
  //       fullname: entity.fullname,
  //       email: entity.email,
  //       phone: entity.phone,
  //       ceo: entity.ceo,
  //       address_entity: entity.address_entity,
  //       address_fact: entity.address_fact
  //     }
  //   })

  //   if (data) {
  //     commit('SET_CURRENT_ENTITI_DATA', entity)
  //   }
  // },

  async CreatePerson({ dispatch }, { person, id } = person) {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_PERSON,
      variables: {
        name: person.name,
        phone: person.phone,
        email: person.email,
        main: person.main,
        supplier_id: id
      }
    })

    const persons = R.pathOr('', ['CreatePerson'], data)
    if (persons) dispatch('getPersons')
  },

  async createSupplier({ commit }, info) {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_SUPPLIER,
      variables: {
        id: info.id,
        name: info.name
      }
    })

    if (data) {
      const id = R.pathOr('', ['CreateSupplier', 'id'], data)
      commit('SET_CURRENT_SUPPLIER_ID', id)
    }
  },

  async removeSupplier({ dispatch }, ids) {
    const { data } = await apolloClient.mutate({
      mutation: REMOVE_SUPPLIER,
      variables: {
        ids: ids
      }
    })

    if (data) dispatch('getSuppliers')
  },

  // Methods 'Get'

  async getBanks({ commit }, id) {
    const { data } = await apolloClient.mutate({
      mutation: GET_BANKS,
      variables: {
        id: id
      }
    })

    const banks = R.pathOr([], ['SupplierAccounts'], data)
    if (banks) return commit('SET_BANKS', banks)

    return commit('SET_BANKS', [])
  },

  async getPersons({ commit }) {
    const { data } = await apolloClient.query({
      query: GET_PERSONS
    })
    const persons = R.pathOr([], ['Persons'], data)
    if (persons) commit('SET_PERSONS', persons)
  },

  async getSuppliers({ commit }) {
    const { data } = await apolloClient.query({
      query: GET_SUPPLIERS
    })
    if (data) {
      const suppliers = R.pathOr([], ['Suppliers'], data)
      commit('SET_SUPPLIERS', suppliers)
    }
  },

  async getSupplier({ commit }, id) {
    const { data } = await apolloClient.query({
      query: GET_SUPPLIER,
      variables: {
        id: id
      }
    })

    if (data) {
      const supplier = R.pathOr([], ['Supplier'], data)
      commit('SET_SUPPLIER', supplier)
    }
  },

  // Methods 'Set'

  SetCurrentTab({ commit }, number) {
    commit('SET_CURRENT_TAB', number)
  },

  SetCurrentSupplier({ commit }, item) {
    commit('SET_CURRENT_SUPPLIER', item)
  },

  clearCurrentSupplier({ commit }) {
    commit('CLEAR_CURRENT_ENTITY')
  }
}
