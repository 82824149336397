<template>
  <div
    :class="{ critical: item?.level === 3 }"
    class="event__item"
  >
    <div class="event__title">
      <img
        :src="image"
        alt=""
      />
      {{ item?.title || 'Подготовьте товар к продаже' }}
    </div>
    <div class="event__text">
      {{ item?.message }}
    </div>
    <div class="event_actions">
      <div
        v-if="item?.actions?.includes('franchise')"
        class="franchise_event"
      >
        <e-button
          variant="primary"
          @click="openModalConnection"
        >
          Приступить
        </e-button>
        <e-button
          class="button-link"
          variant="link"
          @click="updateConnection"
        >
          Отложить на 3 дня
        </e-button>
      </div>
      <div
        v-else-if="item?.actions?.includes('rate')"
        class="event_actions_rate"
      >
        <b-button
          class="event_actions-button"
          style="margin-right: 15px"
          variant="outline-success"
          @click="rate('like')"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3865 11.3865C11.0731 11.6999 10.701 11.9485 10.2916 12.1181C9.88209 12.2877 9.44321 12.375 9 12.375C8.55679 12.375 8.11791 12.2877 7.70843 12.1181C7.29896 11.9485 6.9269 11.6999 6.6135 11.3865M15.75 9C15.75 9.88642 15.5754 10.7642 15.2362 11.5831C14.897 12.4021 14.3998 13.1462 13.773 13.773C13.1462 14.3998 12.4021 14.897 11.5831 15.2362C10.7642 15.5754 9.88642 15.75 9 15.75C8.11358 15.75 7.23583 15.5754 6.41689 15.2362C5.59794 14.897 4.85382 14.3998 4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 7.20979 2.96116 5.4929 4.22703 4.22703C5.4929 2.96116 7.20979 2.25 9 2.25C10.7902 2.25 12.5071 2.96116 13.773 4.22703C15.0388 5.4929 15.75 7.20979 15.75 9ZM7.3125 7.3125C7.3125 7.623 7.1865 7.875 7.03125 7.875C6.876 7.875 6.75 7.623 6.75 7.3125C6.75 7.002 6.876 6.75 7.03125 6.75C7.1865 6.75 7.3125 7.002 7.3125 7.3125ZM7.03125 7.3125H7.03725V7.32375H7.03125V7.3125ZM11.25 7.3125C11.25 7.623 11.124 7.875 10.9688 7.875C10.8135 7.875 10.6875 7.623 10.6875 7.3125C10.6875 7.002 10.8135 6.75 10.9688 6.75C11.124 6.75 11.25 7.002 11.25 7.3125ZM10.9688 7.3125H10.9748V7.32375H10.9688V7.3125Z"
              stroke="#00CB91"
              stroke-width="1.125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </b-button>
        <b-button
          class="event_actions-button"
          variant="outline-danger"
          @click="rate('dislike')"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.75 9C15.75 9.88642 15.5754 10.7642 15.2362 11.5831C14.897 12.4021 14.3998 13.1462 13.773 13.773C13.1462 14.3998 12.4021 14.897 11.5831 15.2362C10.7642 15.5754 9.88642 15.75 9 15.75C8.11358 15.75 7.23583 15.5754 6.41689 15.2362C5.59794 14.897 4.85382 14.3998 4.22703 13.773C3.60023 13.1462 3.10303 12.4021 2.76381 11.5831C2.42459 10.7642 2.25 9.88642 2.25 9C2.25 7.20979 2.96116 5.4929 4.22703 4.22703C5.4929 2.96116 7.20979 2.25 9 2.25C10.7902 2.25 12.5071 2.96116 13.773 4.22703C15.0388 5.4929 15.75 7.20979 15.75 9ZM7.3125 7.3125C7.3125 7.623 7.1865 7.875 7.03125 7.875C6.876 7.875 6.75 7.623 6.75 7.3125C6.75 7.002 6.876 6.75 7.03125 6.75C7.1865 6.75 7.3125 7.002 7.3125 7.3125ZM7.03125 7.3125H7.03725V7.32375H7.03125V7.3125ZM11.25 7.3125C11.25 7.623 11.124 7.875 10.9688 7.875C10.8135 7.875 10.6875 7.623 10.6875 7.3125C10.6875 7.002 10.8135 6.75 10.9688 6.75C11.124 6.75 11.25 7.002 11.25 7.3125ZM10.9688 7.3125H10.9748V7.32375H10.9688V7.3125Z"
              stroke="#E53835"
              stroke-width="1.125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.6135 12.441C6.9269 12.1276 7.29895 11.879 7.70843 11.7094C8.11791 11.5398 8.55678 11.4525 9 11.4525C9.44321 11.4525 9.88209 11.5398 10.2916 11.7094C10.701 11.879 11.0731 12.1276 11.3865 12.441"
              stroke="#E53835"
              stroke-width="1.125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </b-button>
      </div>
      <b-button
        v-else
        style="width: 73px; height: 26px; font-size: 12px"
        variant="primary"
        @click="onPrintPrice"
      >
        Перейти
      </b-button>
    </div>
    <div class="event__footer">
      <div class="date">{{ date }}</div>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'

  export default {
    name: 'EventItem',
    props: {
      item: Object,
      on_print_price: Function
    },
    computed: {
      date() {
        return formatDate(new Date(this.item?.date), 'datetime')
      },
      image() {
        return this.item?.type === 'manual' ? '/img/icons/dashboard/no-photo.svg' : '/img/icons/dashboard/monitor.svg'
      }
    },
    methods: {
      onPrintPrice() {
        if (this.on_print_price) this.on_print_price()
      },
      openModalConnection() {
        this.$emit('openModal')
      },
      rate(value) {
        this.$apollo.mutate({
          mutation: require('../gql/updateTask.gql'),
          variables: {
            input: {
              id: this.item.id,
              grade: value
            }
          }
        })
        this.$emit('refetch')
      },
      updateConnection() {
        this.$apollo.mutate({
          mutation: require('../gql/updateTask.gql'),
          variables: {
            input: {
              id: this.item.id,
              date: new Date().setDate(new Date().getDate() + 3)
            }
          }
        })
        this.$emit('refetch')
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .events {
      height: 100%;
    }
  }

  .button-link {
    margin-left: 12px;
    padding: 3px 0 !important;
    text-decoration: none !important;
  }
  .franchise_event {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .event__item {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #ffffff;
    border: 0.5px solid #b9d6ff;
    border-radius: 6px;
    width: 260px;
  }

  .event__text {
    margin-top: 12px;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #888888;
  }

  .event_actions {
    margin-top: 12px;
    display: flex;
    justify-content: stretch;
    font-family: 'Gilroy-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    .event_actions-button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .event_actions_rate {
      flex: 1;
      display: flex;
      justify-content: center;

      :hover > svg {
        path {
          stroke: white;
        }
      }
    }
  }
</style>
