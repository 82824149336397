<template>
  <div class="containers">
    <div class="header">
      <div class="company">
        <p>{{ companyName }}</p>
      </div>
      <div class="logo-block">
        <left-line100on140-svg />
        <fasol100on140-svg class="fasol" />
        <right-line100on140-svg />
        <green-line100on140-svg />
      </div>
    </div>

    <div class="name-block">
      <b>{{ product?.name?.length >= 43 ? product?.name?.slice(0, 43) + '...' : product?.name }}</b>
    </div>
    <div class="footer-block">
      <div class="price">
        <b>{{
          1 >= product?.retailPrice?.toFixed(2)?.toString().split('.')?.length
            ? product?.retailPrice + 'р.' + '00к.'
            : product?.retailPrice?.toFixed(2)?.toString()?.split('.')[0] +
              'р.' +
              product?.retailPrice?.toFixed(2)?.toString()?.split('.')[1] +
              'к.'
        }}</b>
      </div>
      <div class="barcode-block">
        <div class="price-for">
          <b>Цена:</b>
          <p>за {{ product?.measurement?.name?.slice(0, 2) }}</p>
        </div>
        <div style="width: 25px; height: 12px">
          <barcode-generator
            v-if="product.package"
            :width="25"
            :height="12"
            :barcode="product?.package[0]?.barcode?.barcode"
          />
        </div>
      </div>
      <div class="footer">
        <p>{{ date }}</p>
        <p>{{ product?.country?.name }}</p>
        <p>Арт: {{ product?.articul }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  import Fasol100on140Svg from '@/views/core/modal/price-tag/svg/Fasol100on140Svg.vue'
  import LeftLine100on140Svg from '@/views/core/modal/price-tag/svg/LeftLine100on140Svg.vue'
  import RightLine100on140Svg from '@/views/core/modal/price-tag/svg/RightLine100on140Svg.vue'
  import GreenLine100on140Svg from '@/views/core/modal/price-tag/svg/GreenLine100on140Svg.vue'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'
  export default {
    name: 'Tag200on280',
    components: {
      Fasol100on140Svg,
      LeftLine100on140Svg,
      RightLine100on140Svg,
      GreenLine100on140Svg,
      BarcodeGenerator
    },
    props: {
      product: Object
    },
    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return entity_name(this.product.entity) || entity_name(this.getCurrentBranch.entities?.[0]) || this.company_name
      },
      date() {
        return formatDate(new Date())
      }
    }
  }
</script>
<style lang="scss" scoped>
  p {
    padding: 0;
    margin: 0;
  }
  .containers {
    overflow: hidden;
    background-color: #fff;
    position: relative;
    width: 100px;
    height: 140px;
    border: 1px solid #000000;
  }
  .header {
    height: 29px;
    margin-bottom: 2px;
    position: relative;
    text-align: center;
    .company {
      padding-top: 3px;
      padding-right: 2px;
      padding-bottom: 1px;
      display: flex;
      justify-content: flex-end;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 3.78965px;
        line-height: 4px;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        color: #000000;
      }
      .logo-block {
        justify-content: space-between;
        display: flex;
        align-items: center;
        // justify-content: center;
      }
    }
    .green-line {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
    }
  }
  .name-block {
    display: flex;
    justify-content: center;
    align-items: center;
    b {
      // position: absolute;
      font-family: 'Tahoma';
      font-style: normal;
      font-weight: 700;
      font-size: 9.94731px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.015em;
      color: #000000;
    }
  }
  .footer-block {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    .footer {
      display: flex;
      justify-content: space-between;
      margin: 0px 2px 2px 2px;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 3.92px;
        line-height: 5px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
    .price {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-right: 2px;
      b {
        font-family: 'Gilroy-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 14.7579px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }
    }
    .barcode-block {
      margin-left: 7px;
      margin-bottom: 11px;
      .barcode {
      }
      .price-for {
        display: flex;

        b {
          margin-left: 2px;
          font-style: normal;
          font-weight: 700;
          font-size: 3.92px;
          line-height: 5px;
          display: flex;
          align-items: center;
          color: #000000;
        }
        p {
          text-transform: lowercase;
          margin-left: 1px;
          font-style: normal;
          font-weight: 400;
          font-size: 3.92px;
          line-height: 5px;
          display: flex;
          align-items: center;
          color: #000000;
        }
      }
    }
  }
</style>
