var render = function render(){
  var _vm$analytics$payment;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "analytics"
  }, [_c('div', {
    staticClass: "dashboard__title"
  }, [_vm._v("Аналитика")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('analytics-widget', {
    staticClass: "mr-3",
    attrs: {
      "on_click": _vm.openCashSales,
      "data": {
        name: 'Выручка за ',
        value: _vm.analytics.revenue,
        date: _vm.date,
        currency: true,
        returns: _vm.analytics.returns,
        payment_types: (_vm$analytics$payment = _vm.analytics.payment_types) !== null && _vm$analytics$payment !== void 0 ? _vm$analytics$payment : []
      },
      "click_more": _vm.showRevenueMore,
      "show_drop_down": _vm.revenueMore
    }
  }), _c('analytics-widget', {
    staticClass: "mr-3",
    attrs: {
      "data": {
        name: 'Количество чеков за ',
        value: _vm.analytics.quantity,
        date: _vm.date,
        currency: false
      }
    }
  }), _c('analytics-widget', {
    staticClass: "mr-3",
    attrs: {
      "data": {
        name: 'Средний чек за ',
        value: _vm.analytics.average,
        date: _vm.date,
        currency: true
      }
    }
  }), _c('analytics-widget', {
    staticClass: "mr-3",
    attrs: {
      "on_click": _vm.onClick,
      "data": {
        name: 'Маржинальная прибыль за ',
        value: _vm.margin,
        date: _vm.date,
        currency: true
      }
    }
  }), _c('abc-analytics-widget', {
    attrs: {
      "on_click": _vm.onClick,
      "data": {
        name: 'ABC - анализ,'
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }