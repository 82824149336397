export const getters = {
  getCurrentProduct: (state) => state.current_product || {},
  getQuantityProducts: (state) => state.quantity_products,
  getCurrentCategory: (state) => state.current_category || {},
  getSelectedCategories: (state) => state.selected_categories || [],
  getSelectedProducts: (state) => state.selected_products || [],
  getExcludedProducts: (state) => state.excluded_products || [],
  getExcludedCategories: (state) => state.excluded_categories || [],
  getCurrentCategoryPath: (state) => state.current_category_path || null,
  getCurrentIds: (state) => state.current_ids || [],
  getCurrentProducts: (state) => (Array.isArray(state.current_products) ? state.current_products : []),
  getMenuVisibleStatus: (state) => state.settings.menu_is_visible,
  getCurrentProductId: (state) => state.current_product_id,
  getAddgroupVisibleStatus: (state) => state.add_group_visible,
  getCurrentCategoryMenuId: (state) => state.current_category_menu_id,
  getPrintActions: (state) => state.print_actions,
  getSearchStr: (state) => state.search_str,
  getPlaceholder: (state) => state.placeholder,
  getPrintProductData: (state) => state.printProductData,
  getFilters: (state) => state.filters
}
