<template>
  <div class="b-pagination d-flex">
    <div class="b-perpage">
      <label>Показывать по:</label>
      <i-select
        v-model="option.take"
        @on-change="changeTake"
      >
        <i-option :value="20">20</i-option>
        <i-option :value="50">50</i-option>
        <i-option :value="100">100</i-option>
      </i-select>
    </div>
    <b-pagination
      v-if="total"
      v-model="option.current_page"
      :total-rows="total"
      :per-page="option.take"
      first-number
      last-number
      :aria-controls="table_name"
      @change="changeCurrentPage"
    >
      <template #prev-text>
        <img
          class="arrow-left"
          src="/img/icons/arrow_pagination.svg"
          alt=""
        />
      </template>
      <template #next-text>
        <img
          class="arrow-right"
          src="/img/icons/arrow_pagination.svg"
          alt=""
        />
      </template>
    </b-pagination>
    <div
      v-else
      class="d-flex"
    >
      <vue-skeleton-loader
        v-for="index in 5"
        :key="index"
        class="skeleton mr-2"
        type="rect"
        :height="42"
        :width="42"
        animation="fade"
        rounded
        color="rgba(0,0,0,0.03)"
        radius="11"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      total: {
        type: Number,
        default: 0
      },

      skip: {
        type: Number,
        default: 0
      },

      table_name: {
        type: String,
        default: ''
      },
      default_take: Number
    },

    data: function () {
      return {
        option: {
          current_page: 1,
          take: this.default_take || 20
        }
      }
    },

    computed: {
      ...mapGetters({
        getPagination: 'settings/getPagination',
        currentCategory: 'products/getCurrentCategory'
      })
    },

    watch: {
      getPagination(newVal) {
        setTimeout(() => {
          if (newVal?.[this.table_name]?.current_page > 1) {
            this.option.current_page = newVal?.[this.table_name]?.current_page ?? 1
          }
        }, 500)
      },

      total(newVal) {
        if (this.getPagination?.[this.table_name] != undefined) {
          if (this.getPagination?.[this.table_name]?.skip >= newVal) this.changeCurrentPage(1)
        } else {
          if (this.skip >= newVal) this.changeCurrentPage(1)
        }
      },

      currentCategory(newVal) {
        if (!newVal.from_search) {
          this.option.current_page = 1
          this.changeOption()
        }
      }
    },

    beforeMount() {
      const prev = this.getPagination?.[this.table_name]
      if (prev) {
        if (this.total && this.total < prev.current_page * prev.take) this.option = { take: prev.take, current_page: 1 }
        else this.option = prev
        this.changeOption()
      }
      this.changeOption()
    },

    methods: {
      changeTake(val) {
        this.option.take = val
        this.option.current_page = 1
        this.changeOption(true)
      },
      changeCurrentPage(val) {
        this.option.current_page = val
        this.changeOption(true)
      },
      async changeOption(manual) {
        sessionStorage.setItem(this.table_name, JSON.stringify(this.option))
        if (manual) this.$emit('change_pagination', this.option)
        if (this.getPagination?.[this.table_name]) this.option = this.getPagination?.[this.table_name]
      }
    }
  }
</script>

<style scoped lang="scss">
  :deep .page-item {
    width: 42px !important;
    height: 42px !important;
  }
  .b-pagination {
    margin: 15px 0;
    justify-content: space-between;

    ::v-deep() {
      .b-perpage {
        .ivu-select {
          width: auto;
          box-shadow: none !important;

          &-selection {
            border: none !important;
            background: none;
            height: auto;

            .ivu-select-arrow {
              right: 8px;
            }
          }

          &-selected-value {
            font-weight: 500;
            font-size: 14px;
            color: #313131;
          }
        }
      }

      .b-pagination {
        .page-item {
          width: 44px;
          height: 44px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.2s ease;
          border-radius: 11px;
          margin-left: 1px;

          button {
            color: #888888;
            font-weight: 500;
            font-size: 14px;
            background: none;
            border: none;
          }

          &.disabled {
            .page-link {
              background: none;
              border: none;
            }
          }
          &.active,
          &:hover {
            background: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 11px;
          }
          &.active,
          &:hover {
            button {
              color: #313131;
            }
          }
        }
      }
    }
    .b-perpage {
      display: flex;
      position: relative;
      align-items: center;
      width: auto;
      height: 44px;
      background: #ffffff;
      border: 0.5px solid #efefef;
      border-radius: 4px;
      padding: 10px;

      label {
        color: #313131;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #313131;
        margin-right: 9px;
      }
    }

    .b-pagination {
      margin: 0;

      .page-item {
        &.disabled {
          background: none;
        }

        &.active,
        &:hover {
          background: #ffffff;
        }

        img {
          &.arrow-right {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
</style>
