<template>
  <div :class="[getSend || sending ? 'confirm-block__sending' : 'confirm-block']">
    <div class="confirm-block__left">
      <warning-svg
        :color="getSend || sending ? '#313131' : 'white'"
        class="warning-img"
      />
      <div class="text">
        <p
          v-if="!sending"
          class="confirm-text"
        >
          Подтвердите ваш E-mail
        </p>
        <p
          v-if="sending"
          class="sending__mini"
        >
          Письмо отправлено
        </p>
        <p
          v-if="sending"
          class="sending"
        >
          Письмо с подтверждением отправлено на
        </p>
        <p class="email">{{ user.email }}</p>
        <p
          v-if="!sending"
          class="service-text"
        >
          чтобы продолжить пользоваться сервисом.
        </p>
      </div>
    </div>
    <div class="confirm-block__right">
      <p
        v-if="getSend || sending"
        class="not-sending"
      >
        Не пришло?
      </p>
      <div
        class="button-block"
        style="margin-right: 8px"
      >
        <e-button
          size="m"
          :disabled="disabled"
          @click="confirm"
        >
          {{ buttonText }}
        </e-button>
      </div>
      <div class="button-block">
        <e-button
          size="m"
          @click="showModal"
        >
          Изменить E-mail
        </e-button>
      </div>
    </div>
    <confirm-email-modal />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ConfirmEmailModal from '@/views/core/modal/ConfirmEmailModal'
  import WarningSvg from '@/views/core/header/components/WarningSvg'

  export default {
    name: 'ConfirmEmailAlert',
    components: { WarningSvg, ConfirmEmailModal },
    data() {
      return {
        buttonText: 'Подтвердить',
        date: new Date(),
        interval: null,
        disabled: false,
        sending: false
      }
    },
    computed: {
      ...mapGetters({
        user: 'core/user'
      }),
      getSend() {
        if (localStorage) {
          return localStorage.getItem('sendConfirm')
        } else {
          return false
        }
      }
    },
    mounted() {
      if (localStorage.getItem('interval_register_alert')) {
        this.disabled = true
        this.date = localStorage.getItem('interval_register_alert')
        this.timer()
      }
      if (localStorage.getItem('sendConfirm')) {
        this.sending = true
      }
    },
    methods: {
      async confirm() {
        this.sending = true
        localStorage.setItem('sendConfirm', 'true')
        this.date = new Date().setMinutes(new Date().getMinutes() + 2)
        localStorage.setItem('interval_register_alert', this.date.toString())
        this.timer()
        await this.$apollo.mutate({
          mutation: require('@/gql/mutations/ConfirmEmail.graphql')
        })
        this.$noty.show('Письмо с подтверждением отправлено')
      },
      showModal() {
        this.$bvModal.show('confirm-email-modal')
      },
      timer() {
        this.disabled = true
        this.interval = setInterval(() => {
          if (this.date > new Date()) {
            this.buttonText = `Отправить новое: ${Math.round((this.date - new Date()) / 1000)} сек.`
          } else {
            this.disabled = false
            this.buttonText = 'Отправить новое'
            clearInterval(this.interval)
            localStorage.setItem('interval_register_alert', 'send')
            this.interval = null
          }
        }, 100)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sending__mini {
    display: none !important;
  }
  .confirm-block {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    background: #e53835;
    align-items: center;
    padding: 18px 20px;

    &__left {
      display: flex;
      .warning-img {
        margin-right: 9px;
      }

      .text {
        display: flex;
        align-items: center;
        p {
          padding: 0;
          margin: 0;
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #ffffff;
          white-space: nowrap;
        }

        .email {
          color: #e53835;
          background: #ffffff;
          border-radius: 68px;
          padding: 2px 6px;
          margin: 0 6px;
        }
      }
    }

    &__right {
      display: flex;
      .button-block {
        .e_button__disabled {
          background: #f2f3f6 !important;
        }
        button {
          background: #d0322f;
          border-color: #d0322f;
          border-radius: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
  }
  .not-sending {
    margin-right: 8px;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #313131;
  }
  .confirm-block__sending {
    background: #cacaca;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;

    .confirm-block__left {
      display: flex;

      .warning-img {
        margin-right: 9px;
      }

      .text {
        display: flex;
        align-items: center;

        p {
          padding: 0;
          margin: 0;
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #313131;
          white-space: nowrap;
        }

        .email {
          color: #313131;
          background: #ffffff;
          border-radius: 68px;
          padding: 2px 6px;
          margin: 0 6px;
        }
      }
    }

    .confirm-block__right {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
      }

      .button-block {
        button {
          background: #e2e2e2;
          border-radius: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #313131;
        }
      }
    }
  }
  @media (max-width: 1180px) {
    .sending__mini {
      display: flex !important;
    }
    .confirm-block__sending .email {
      display: none !important;
    }
    .sending {
      display: none !important;
    }
  }
  @media (max-width: 1370px) {
    .service-text {
      display: none !important;
    }
  }
</style>
