<template>
  <div class="price-container">
    <div class="header">
      <div class="country">
        <p>{{ companyName }}</p>
      </div>
      <div class="logo-block">
        <div class="cart-block">
          <left-line200on140-svg />
          <cart200on140-svg />
        </div>
        <fasol200on140-svg />
        <right-line200on140-svg />
        <green-line200on140-svg />
      </div>
    </div>
    <div class="name-block">
      <b>
        {{ product?.name.length > 43 ? product?.name?.slice(0, 43) + '...' : product?.name }}
      </b>
    </div>
    <div class="footer-block">
      <div class="bar-block">
        <div class="container-barcode">
          <div class="barcode-block">
            <div class="price-for">
              <b>Цена:</b>
              <p>за {{ product?.measurement?.name?.slice(0, 2) }}</p>
            </div>
            <div style="width: 43px; height: 20px">
              <barcode-generator
                v-if="product?.package"
                :width="43"
                :height="20"
                :barcode="product?.package[0]?.barcode?.barcode"
              />
            </div>
          </div>
        </div>

        <div class="price">
          <b>{{ price }}</b>
        </div>
      </div>
      <div class="footer">
        <p>{{ date }}</p>
        <p>{{ product?.country?.name }}</p>
        <p>Арт: {{ product?.articul }}</p>
      </div>
    </div>
  </div>
</template>
<script>
  import LeftLine200on140Svg from '@/views/core/modal/price-tag/svg/LeftLine200on140Svg.vue'
  import RightLine200on140Svg from '@/views/core/modal/price-tag/svg/RightLine200on140Svg.vue'
  import Cart200on140Svg from '@/views/core/modal/price-tag/svg/Cart200on140Svg.vue'
  import Fasol200on140Svg from '@/views/core/modal/price-tag/svg/Fasol200on140Svg.vue'
  import GreenLine200on140Svg from '@/views/core/modal/price-tag/svg/GreenLine200on140Svg.vue'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  import formatDate from '@/utils/formatDate'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Tag200on140',
    components: {
      LeftLine200on140Svg,
      RightLine200on140Svg,
      Fasol200on140Svg,
      Cart200on140Svg,
      GreenLine200on140Svg,
      BarcodeGenerator
    },
    props: {
      product: Object
    },
    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return entity_name(this.product.entity) || entity_name(this.getCurrentBranch.entities?.[0]) || this.company_name
      },
      date() {
        return formatDate(new Date())
      },
      price() {
        return this.product.retailPrice.toFixed(2).toString().split('.').length <= 1
          ? this.product.retailPrice + 'р.' + '00к.'
          : this.product.retailPrice.toFixed(2).toString().split('.')[0] +
              'р.' +
              this.product.retailPrice.toFixed(2).toString().split('.')[1] +
              'к.'
      }
    }
  }
</script>
<style lang="scss" scoped>
  p {
    margin-bottom: 0px !important;
  }
  .name-block {
    margin-top: 1px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    b {
      width: 146px;
      font-family: 'Tahoma';
      font-style: normal;
      font-weight: 700;
      font-size: 11.5714px;
      line-height: 14px;
      letter-spacing: 0.015em;
      color: #000000;
    }
  }

  .price-container {
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    width: 200px;
    height: 140px;
    border: 2px solid #000000;
  }

  .header {
    height: 42.4px;
    width: 100%;
    position: relative;

    .country {
      display: flex;
      justify-content: flex-end;
      padding-top: 1.5px;
      padding-right: 4px;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 4.84499px;
        line-height: 6px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }

    .logo-block {
      position: relative;
      display: flex;
      justify-content: space-between;

      .cart-block {
        display: flex;
      }
    }

    .green-line {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .footer-block {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 20px;

    .footer {
      display: flex;
      justify-content: space-between;
      margin: 5px 4px 4px 4px;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 6.38556px;
        line-height: 7px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }

    .bar-block {
      display: flex;
      justify-content: space-between;
    }

    .container-barcode {
      position: relative;
      margin-left: 12px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .barcode-block {
      position: relative;

      .canvas {
        position: relative;
        transform: translateY(5px);
      }

      .price-for {
        display: flex;
        margin-left: 3.4px;

        b {
          font-style: normal;
          font-weight: 700;
          font-size: 6.38556px;
          line-height: 7px;
          display: flex;
          align-items: center;
          color: #000000;
        }

        p {
          margin-left: 2px;
          text-transform: lowercase;
          font-style: normal;
          font-weight: 400;
          font-size: 6.38556px;
          line-height: 7px;
          display: flex;
          align-items: center;
          color: #000000;
        }
      }
    }

    .price {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      b {
        font-style: normal;
        font-weight: 700;
        font-size: 25.4446px;
        line-height: 29px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;
      }
    }
  }
</style>
