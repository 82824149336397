export const getters = {
  getToken: (state) => state.token || '',
  getRefresh: (state) => state.refresh || '',
  isAuth: (state) => !!state.token,
  user: (state) => state.user,

  // Register flow
  getCurrentStep: (state) => {
    return state.regflow.storyline.current
  },
  getIsConfirmed: (state) => {
    return state.user.is_confirmed
  },

  getRegflowCompany: (state) => state.regflow.company,

  getStorylineStatus: (state) => {
    return state.regflow.storyline
  },

  getCompanyName: (state) => {
    return state.regflow.company.name
  },

  getUserCompanyName: (state) => {
    return state.user.company.name
  },

  getCompanyId: (state) => {
    return state.regflow.company.id
  },

  getActivitiesId: (state) => {
    return state.regflow.activity.selected_ids
  },

  get_is_fasol: (state) => {
    return state.regflow.activity.is_fasol
  },

  getCurrentStoreId: (state) => {
    return state.regflow.company.current_store_id
  },

  getActivityList: (state) => {
    return state.regflow.activity.list
  },

  activityIsLoaded: (state) => {
    return state.regflow.activity.isLoaded
  },

  getUserInfo: (state) => {
    return state.user
  },

  getTour: (state) => {
    return state.tour
  },

  getBindingModal: (state) => {
    return state.binding_modal
  },
  getInfoBindingModal(state) {
    return state.infoBindingModal
  }
}
