export const getters = {
  getFilters: (state) => state.filters,

  getSelectedArr(state) {
    return state.selectedArr
  },

  getSelectAll(state) {
    return state.select_all
  }
}
