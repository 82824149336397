import { BranchModel } from '@/models/branch.model'
import { StorageModel } from '@/models/storage.model'
import { EntityModel } from '@/models/entity.model'
import { IntegrationModel } from '@/models/integration.model'

export class CashModel {
  constructor(request = {}) {
    this.active = request?.active
    this.branch = new BranchModel(request?.branch)
    this.storage = new StorageModel(request?.storage)
    this.integration = new IntegrationModel(request?.integration)
    this.entity = new EntityModel(request?.entity)
    this.id = request?.id
    this.name = request?.name
    this.number = request?.number
    this.in_path = request?.in_path
    this.out_path = request?.out_path
    this.oper_path = request?.oper_path
  }

  get input() {
    return {
      branch: this.branch.id,
      storage: this.storage.id,
      entity: this.entity.id,
      id: this.id,
      name: this.name,
      number: +this.number,
      in_path: this.in_path,
      out_path: this.out_path
    }
  }
}
