<template>
  <svg
    width="274"
    height="52"
    viewBox="0 0 274 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.1993 30.7554C51.1993 23.2948 47.6683 17.8274 40.8342 17.8274C34 17.8274 30.469 23.2948 30.469 30.7554C30.469 38.2161 34 43.6834 40.8342 43.6834C47.6683 43.6834 51.1993 38.2161 51.1993 30.7554ZM36.0502 2.73529L30.2981 0.969788L15.149 42.0318L20.8442 43.7973L36.0502 2.73529ZM20.6733 14.0117C20.6733 6.55103 17.1993 1.08369 10.3651 1.08369C3.53093 1.08369 -6.10352e-05 6.55103 -6.10352e-05 14.0117C-6.10352e-05 21.4723 3.53093 26.9397 10.3651 26.9397C17.1993 26.9397 20.6733 21.4723 20.6733 14.0117ZM44.1374 30.6415C44.1374 35.3115 42.9983 37.5326 40.8342 37.5326C38.67 37.5326 37.531 35.3115 37.531 30.6415C37.531 25.9715 38.67 23.7504 40.8342 23.7504C42.9983 23.7504 44.1374 25.9715 44.1374 30.6415ZM13.6113 13.8978C13.6113 18.5678 12.5293 20.7889 10.3651 20.7889C8.20096 20.7889 7.06192 18.5678 7.06192 13.8978C7.06192 9.22775 8.20096 7.00665 10.3651 7.00665C12.5293 7.00665 13.6113 9.22775 13.6113 13.8978Z"
      fill="#F17D00"
    />
    <path
      d="M107.099 43L93.7728 1.76711H82.2686L68.9419 43H79.0793L81.4713 34.6281H94.5701L96.9621 43H107.099ZM92.7477 27.6231H83.2937L86.9386 14.9229C87.622 12.5309 87.9637 9.74032 87.9637 9.74032H88.0777C88.0777 9.74032 88.4194 12.5309 89.1028 14.9229L92.7477 27.6231Z"
      fill="#F17D00"
    />
    <path
      d="M143.604 43L137.908 28.5913C136.428 24.8325 134.15 21.9849 130.676 20.3333L143.262 1.76711H132.213L121.108 19.9916H120.994V1.76711H111.312V43H120.994V24.0921C124.354 24.0921 126.86 26.541 127.942 29.6164L132.783 43H143.604Z"
      fill="#F17D00"
    />
    <path
      d="M180.381 36.3367L177.306 34.9129V1.76711H167.624V34.9129H157.999V1.76711H148.317V43H173.091L173.319 51.3149H179.925L180.381 36.3367Z"
      fill="#F17D00"
    />
    <path
      d="M218.033 43V1.76711H210.003L197.816 22.4975C196.449 24.7755 195.367 27.4522 195.367 27.4522H195.253C195.253 27.4522 195.31 24.4908 195.31 21.7571V1.76711H186.824V43H194.512L207.042 21.5862C208.466 19.1943 209.548 16.6314 209.548 16.6314H209.662C209.662 16.6314 209.548 19.479 209.548 22.1557V43H218.033Z"
      fill="#F17D00"
    />
    <path
      d="M254.879 43V3.3048C251.462 1.88101 246.735 1.08369 241.837 1.08369C231.13 1.08369 225.606 5.75371 225.606 13.8978C225.606 19.536 229.137 22.5544 232.782 23.9212V24.0351C230.618 25.2881 229.137 27.6231 228.112 30.3568L223.214 43H233.352L237.851 30.7554C238.648 28.5343 240.243 26.9966 242.749 26.9966H245.368V43H254.879ZM245.368 20.1624H241.496C237.224 20.1624 235.117 18.283 235.117 14.4673C235.117 10.4237 237.053 8.31653 241.61 8.31653C242.976 8.31653 244.514 8.48739 245.368 8.77214V20.1624Z"
      fill="#F17D00"
    />
    <path
      d="M273.265 1.76711H262.217L263.697 28.0217H271.841L273.265 1.76711ZM273.493 37.8174C273.493 34.6281 270.93 32.1223 267.741 32.1223C264.552 32.1223 261.989 34.6281 261.989 37.8174C261.989 41.0067 264.552 43.5695 267.741 43.5695C270.93 43.5695 273.493 41.0067 273.493 37.8174Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'StockSvg'
  }
</script>
<style lang="scss" scoped>
  svg {
    margin-top: 3px;
  }
</style>
