import { BranchModel } from '@/models/branch.model'
import { UserModel } from '@/models/user.model'
import { AddressModel } from '@/models/address.model'
import { CashModel } from '@/models/cash.model'

export class StorageModel {
  constructor(request = {}) {
    this.address = new AddressModel(request?.address)
    this.branch = new BranchModel(request?.branch)
    this.cash = request?.cash?.map((el) => new CashModel(el))
    this.id = request?.id
    this.name = request?.name
    this.responsible = request?.responsible?.map((el) => new UserModel(el))
    this.type = request?.type
  }
}
