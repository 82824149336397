var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "line__logo",
    attrs: {
      "width": "7",
      "height": "1",
      "viewBox": "0 0 7 1",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 1V0H7V1H0Z",
      "fill": "#F17D00"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }