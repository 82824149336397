export const actions = {
  setIsNameError({ commit }, bool) {
    commit('SET_IS_NAME_ERROR', bool)
  },
  setIsDateError({ commit }, bool) {
    commit('SET_IS_DATE_ERROR', bool)
  },

  setIsDateStartError({ commit }, bool) {
    commit('SET_IS_DATE_START_ERROR', bool)
  },
  setIsDateEndError({ commit }, bool) {
    commit('SET_IS_DATE_END_ERROR', bool)
  }
}
