import View from '@/views/layouts/layout-view'

export default {
  path: '/operation-process',
  name: 'operation-process',
  component: View,
  redirect: '/operation-process/list',
  children: [
    {
      path: 'list',
      name: 'operation-process.list',
      component: () => import('@/views/operational-processes/pages/OperationProcess'),
      redirect: '/operation-process/cashbox/sales',
      meta: { title: 'Оперативные процессы' }
    },
    // Incoming
    {
      path: 'incoming',
      name: 'operation-process.overhead.incoming',
      component: () => import('@/views/operational-processes/pages/incoming/Documents'),
      meta: {
        title: 'Приходные накладные',
        type: 'incoming'
      }
    },
    {
      path: 'incoming/document/:id',
      name: 'operation-process.overhead.incoming.document.edit',
      component: () => import('@/views/operational-processes/pages/incoming/Specifications'),
      meta: {
        title: 'Документ приемки',
        type: 'incoming',
        parrent: 'operation-process.overhead.incoming',
        isEdit: true
      }
    },
    // Outcoming
    {
      path: 'outcoming',
      name: 'operation-process.overhead.outcoming',
      component: () => import('@/views/operational-processes/pages/outgoing/Documents'),
      meta: {
        title: 'Списание / возврат',
        type: 'outcoming'
      }
    },
    {
      path: 'outcoming/document/:id',
      name: 'operation-process.overhead.outgoing.document.edit',
      component: () => import('@/views/operational-processes/pages/outgoing/Specifications'),
      meta: {
        title: 'Документы расхода',
        type: 'outcoming',
        parrent: 'operation-process.overhead.outcoming',
        isEdit: true
      }
    },
    //outgoing-cashless
    {
      path: 'cashless',
      name: 'operation-process.overhead.cashless',
      component: () => import('@/views/operational-processes/pages/cashless/Documents'),
      meta: {
        title: 'Продажа контрагенту',
        type: 'cashless'
      }
    },
    {
      path: 'cashless/document/:id',
      name: 'operation-process.overhead.cashless.document.edit',
      component: () => import('@/views/operational-processes/pages/cashless/Specifications'),
      meta: {
        title: 'Документ продажи',
        type: 'cashless',
        parrent: 'operation-process.overhead.cashless',
        isEdit: true
      }
    },
    // Movement
    {
      path: 'movement',
      name: 'operation-process.overhead.movement',
      component: () => import('@/views/operational-processes/pages/movement/Documents'),
      meta: {
        title: 'Накладные перемещения',
        type: 'movement'
      }
    },
    {
      path: 'movement/document/:id',
      name: 'operation-process.overhead.movement.document.edit',
      component: () => import('@/views/operational-processes/pages/movement/Specifications'),
      meta: {
        title: 'Документы перемещения',
        type: 'movement',
        parrent: 'operation-process.overhead.movement',
        isEdit: true
      }
    },
    // ===
    {
      path: 'movement',
      name: 'operation-process.movement',
      component: () => import('@/views/operational-processes/pages/movement/Documents'),
      meta: {
        title: 'Накладные перемещения',
        type: 'movement'
      }
    },
    // ===
    {
      path: 'sales',
      name: 'operation-process.cashbox.sale',
      component: () => import('@/views/operational-processes/pages/cashbox/Sales'),
      meta: { title: 'Кассовые продажи' }
    },
    {
      path: 'sales/add',
      name: 'operation-process.cashbox.new',
      component: () => import('@/views/operational-processes/pages/SalesDocumentAddEdit'),
      meta: {
        title: 'Добавить документ',
        parrent: 'operation-process.cashbox.sale'
      }
    },
    {
      path: 'sales/detail/:id',
      name: 'operation-process.cashbox.detail',
      component: () => import('@/views/operational-processes/pages/cashbox/SalesDocumentDetail'),
      meta: {
        title: 'Кассовая реализация',
        parrent: 'operation-process.cashbox.sale'
      }
    },
    {
      path: 'sales/history',
      name: 'operation-process.cashbox.history',
      component: () => import('@/views/operational-processes/pages/HistoryChanges'),
      meta: {
        title: 'История изменений',
        parrent: 'operation-process.cashbox.sale'
      }
    },
    //Inventory,
    {
      path: 'inventory',
      name: 'operation-process.overhead.inventory',
      component: () => import('@/views/operational-processes/pages/inventory/Documents'),
      meta: {
        title: 'Инвентаризация',
        type: 'inventory'
      }
    },
    {
      path: 'inventory/document/:id',
      name: 'operation-process.overhead.inventory.document.edit',
      component: () => import('@/views/operational-processes/pages/inventory/Document'),
      meta: {
        title: 'Документ инвентаризации',
        parrent: 'operation-process.overhead.inventory',
        type: 'inventory'
      }
    },
    //Invoice
    {
      path: 'invoice',
      name: 'operation-process.overhead.invoice',
      component: () => import('@/views/operational-processes/pages/invoice/Documents'),
      meta: {
        title: 'Счет',
        type: 'invoice'
      }
    },
    {
      path: 'invoice/document/:id',
      name: 'operation-process.overhead.invoice.document.edit',
      component: () => import('@/views/operational-processes/pages/invoice/Specifications'),
      meta: {
        title: 'Счет',
        parrent: 'operation-process.invoice',
        type: 'invoice'
      }
    },
    // Promo
    {
      path: 'promo',
      name: 'operation-process.overhead.promo',
      component: () => import('@/views/operational-processes/pages/promo/Documents.vue'),
      meta: {
        title: 'Маркетинговые акции',
        type: 'promo'
      }
    },
    {
      path: 'promo/document/:id',
      name: 'operation-process.overhead.promo.document.edit',
      component: () => import('@/views/operational-processes/pages/promo/Specifications'),
      meta: {
        title: 'Маркетинговые акции',
        parrent: 'operation-process.promo',
        type: 'promo'
      }
    },
    {
      path: 'equipment',
      name: 'operation-process.equipment',
      component: () => import('@/views/manufacture/pages/equipment/Equipments'),
      meta: {
        title: 'Документы комплектаций'
      }
    },
    {
      path: 'equipment/:id',
      name: 'operation-process.equipment.edit',
      component: () => import('@/views/manufacture/pages/equipment/Equipment'),
      meta: {
        title: 'Документ комплектации',
        parrent: 'manufacture.equipment'
      }
    },
    {
      path: 'repacking',
      name: 'operation-process.repacking',
      component: () => import('@/views/manufacture/pages/repacking/RepackingList.vue'),
      meta: {
        title: 'Документы перефасовки'
      }
    },
    {
      path: 'repacking/:id',
      name: 'operation-process.repacking.edit',
      component: () => import('@/views/manufacture/pages/repacking/Repacking.vue'),
      meta: {
        title: 'Документ перефасовки',
        parrent: 'operation-process.repacking'
      }
    },
    {
      path: 'contracts',
      name: 'operation-process.contracts',
      component: () => import('@/views/operational-processes/pages/contract/Contracts.vue'),
      meta: {
        title: 'Контракты',
        type: 'contracts'
      }
    },
    {
      path: 'contracts/:id',
      name: 'operation-process.overhead.contracts.document.edit',
      component: () => import('@/views/operational-processes/pages/contract/Contract.vue'),
      meta: {
        title: 'Контракт'
      }
    }
  ]
}
