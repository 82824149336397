import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueApollo from 'vue-apollo'
import { apolloClient } from '@/apollo.config'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueDragscroll from 'vue-dragscroll'
import Fragment from 'vue-fragment'
import PortalVue from 'portal-vue'
import * as LottiePlayer from '@lottiefiles/lottie-player'
import 'view-design/dist/styles/iview.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/styles/styles.scss'
import VueMask from 'v-mask'
import VirtualList from 'vue-virtual-scroll-list'
// Global Components
import './registerServiceWorker'
import '@/plugins/multiselect'
import '@/plugins/iview'
import '@/plugins/vue-bootstrap'
import '@/plugins/vue-noty'
import elib from '@entersight/e-lib-components'

const userPermissions = ['create', 'read']
Vue.use(elib)
Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue()
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective, {
  placeholders: {
    B: /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\/(?:3[0-2]|[0-2]?[0-9])$/
  }
})
Vue.use(VueMask, {
  placeholders: {
    B: /^(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\/(?:3[0-2]|[0-2]?[0-9])$/
  }
})
Vue.mixin({
  methods: {
    can: (key) => userPermissions.includes(key)
  }
})
Vue.use(VueApollo)
Vue.use(VueDragscroll)
Vue.use(IconsPlugin)
Vue.use(Fragment.Plugin)
Vue.use(PortalVue)
Vue.use(LottiePlayer)
Vue.component('RecycleScroller', VirtualList)
Vue.use(VueYandexMetrika, {
  id: 87772177,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Энтерсайт'

  next()
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})
import VueSkeletonLoader from 'skeleton-loader-vue'
Vue.component('VueSkeletonLoader', VueSkeletonLoader)
function validate(binding) {
  if (typeof binding.value !== 'function') {
    console.warn('[Vue-click-outside:] provided expression', binding.expression, 'is not a function.')
    return false
  }

  return true
}

function isPopup(popupItem, elements) {
  if (!popupItem || !elements) return false

  for (var i = 0, len = elements.length; i < len; i++) {
    try {
      if (popupItem.contains(elements[i])) {
        return true
      }
      if (elements[i].contains(popupItem)) {
        return false
      }
    } catch (e) {
      return false
    }
  }

  return false
}

function isServer(vNode) {
  return typeof vNode.componentInstance !== 'undefined' && vNode.componentInstance.$isServer
}
Vue.directive('click-outside', {
  bind: function (el, binding, vNode) {
    if (!validate(binding)) return

    // Define Handler and cache it on the element
    function handler(e) {
      if (!vNode.context) return

      // some components may have related popup item, on which we shall prevent the click outside event handler.
      var elements = e.path || (e.composedPath && e.composedPath())
      elements && elements.length > 0 && elements.unshift(e.target)

      if (el.contains(e.target) || isPopup(vNode.context.popupItem, elements)) return

      el.__vueClickOutside__?.callback(e)
    }

    // add Event Listeners
    el.__vueClickOutside__ = {
      handler: handler,
      callback: binding.value
    }
    const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click'
    !isServer(vNode) && document.addEventListener(clickHandler, handler)
  },

  update: function (el, binding) {
    if (validate(binding)) el.__vueClickOutside__.callback = binding.value
  },

  unbind: function (el, binding, vNode) {
    // Remove Event Listeners
    const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click'
    !isServer(vNode) &&
      el.__vueClickOutside__ &&
      document.removeEventListener(clickHandler, el.__vueClickOutside__.handler)
    delete el.__vueClickOutside__
  }
})

export const EventBus = new Vue()

new Vue({
  store,
  router,
  BootstrapVue,
  apolloProvider,
  render: (h) => h(App)
}).$mount('#app')
