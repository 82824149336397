export class PersonModel {
  constructor(request = {}) {
    this.email = request?.email
    this.id = request?.id
    this.main = request?.main
    this.name = request?.name
    this.phone = request?.phone
    this.position = request?.position
  }
}
