import View from '@/views/layouts/layout-view'

export default {
  path: 'payment',
  name: 'payment',
  component: View,
  children: [
    {
      path: 'payment/docs',
      name: 'payment.docs',
      component: () => import('@/views/payment/pages/PaymentDocs.vue'),
      meta: { title: 'Платежные документы' }
    },
    {
      path: 'payment/plan',
      name: 'payment.plan',
      component: () => import('@/views/payment/pages/PaymentPlan.vue'),
      meta: { title: 'План платежей' }
    },
    {
      path: 'payment/docs/:id',
      name: 'payment.docs.id',
      component: () => import('@/views/payment/pages/PaymentDocsSpec.vue'),
      meta: { title: 'Платежные документы' }
    },
    {
      path: 'budget/bdr',
      name: 'budget.bdr',
      component: () => import('@/views/payment/pages/Bdr.vue'),
      meta: { title: 'БДР' }
    },
    {
      path: 'orders',
      name: 'orders',
      component: () => import('@/views/order/pages/Orders'),
      meta: { title: 'Заказы' }
    }
  ]
}
