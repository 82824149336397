export const getters = {
  getCompanies: (state) => {
    return state.companies
  },

  getCurrentBranch: (state) => {
    return state.branch.current
  },

  getBranchList: (state) => {
    return state.branch.list
  },

  getPaginationByName: (state) => (name) => {
    return state.pagination?.[name] ?? null
  },

  getPagination: (state) => {
    return state.pagination
  },

  getCurrentPrinterDevice: (state) => {
    return state.currentPrinterDevice
  },

  getBranchClassifList: (state) => {
    return state.branchClassifList
  },

  getCurrentBranchClassifList: (state) => {
    return state.currentBranchClassifList
  }
}
