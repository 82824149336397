export class ProductExternalIdModel {
  constructor(request = {}) {
    this.ext_name = request?.ext_name
    this.ext_quant = request?.ext_quant
    this.external_id = request?.external_id
    this.id = request?.id
    this.product = request?.product
    this.supplier = request?.supplier
    this.egaisParams = request?.egaisParams
  }
}
