import { ProductModel } from '@/models/product.model'
import { PackageModel } from '@/models/package.model'

export class BarcodeModel {
  constructor(request = {}) {
    this.barcode = request?.barcode
    this.id = request?.id
    this.package = request?.package ? new PackageModel(request?.package) : null
    this.product = new ProductModel(request?.product)
  }
}
