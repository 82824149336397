export const state = {
  branch: {
    list: [],
    current: {}
  },
  branchClassifList: [],
  currentBranchClassifList: [],
  currentPrinterDevice: 'window-print',
  pagination: {
    ['incoming']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['outgoing']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['movement-docs']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['cashless-table']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['invoice-docs']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['orders-docs']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['revaluations-docs']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['inv-docs']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['equipments-docs']: {
      skip: 0,
      take: 20,
      search: '',
      current_page: 1
    },
    ['products']: {
      skip: 0,
      take: 20,
      current_page: 1
    },
    ['payment-docs']: {
      skip: 0,
      take: 20,
      current_page: 1
    }
  }
}
