import { BranchModel } from '@/models/branch.model'
import { PriceModel } from '@/models/price.model'

export class PriceListModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request?.branch)
    this.id = request?.id
    this.prices = request?.map((el) => new PriceModel(el))
    this.type = request?.type
  }
}
