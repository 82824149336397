import * as R from 'ramda'
import { apolloClient } from '@/apollo.config'
import { GET_COMPANIES } from '@core/gql/query'
import { GET_BRANCHES } from '@gql/queries'
import { ADD_STORE, REMOVE_COMPANY } from '@/views/settings/gql/mutations'
import router from '@/router'

export const actions = {
  async getCompanies({ commit }) {
    const { data } = await apolloClient.query({
      query: GET_COMPANIES
    })
    const companies = R.pathOr('', ['Companies'], data)
    commit('SET_COMPANIES', companies)
  },

  initialState({ commit }) {
    commit('INITIAL_STATE')
  },

  async createBranch({ dispatch }, branch) {
    const { data } = await apolloClient.mutate({
      mutation: ADD_STORE,
      variables: {
        name: branch.name,
        address: branch.address
      }
    })

    const created_branch = R.pathOr('', ['CreateBranch'], data)

    if (created_branch) {
      dispatch('getBranches')
      router.push({ name: 'company.structure' })
    }
  },

  async removeCompnay(ctx, id) {
    const { data } = await apolloClient.mutate({
      mutation: REMOVE_COMPANY,
      variables: {
        id: id
      }
    })
    const name = R.pathOr('', ['RemoveCompany', 'name'], data)

    if (name) ctx.dispatch('getCompanies')
  },

  async getBranches({ commit }) {
    const { data } = await apolloClient.query({
      query: GET_BRANCHES
    })

    if (data) {
      const branches = R.pathOr([], ['Branches'], data)
      const branch_list = []

      branches.forEach((branch) => {
        const branch_item = R.mergeAll([branch, { active: false }])
        branch_list.push(branch_item)
      })
      return commit('SET_BRANCHES', branch_list)
    }

    //   return commit('SET_BRANCHES', [])
  },

  setBranchList({ commit }, branch_list) {
    commit('SET_BRANCHES', branch_list)
  },

  async setCurrentBranch({ commit }, branch) {
    console.log(branch)
    commit('SET_SELECT_BRANCH', branch)
    await apolloClient.mutate({
      mutation: require('../gql/SetBranch.graphql'),
      variables: {
        branch: branch.id
      }
    })
  },

  clear_settings_storage({ commit }) {
    commit('CLEAR_SETTINGS_STORAGE')
  },

  set_pagination({ commit }, pagination) {
    commit('SET_PAGINATION', pagination)
  }
}
