export const mutations = {
  SET_TREATY(state, treaty) {
    state.treaty = treaty
  },

  SET_CURRENT_ENTITI_ID(state, id) {
    state.current_supplier.entity_id = id
  },

  SET_CURRENT_SUPPLIER(state, params) {
    state.current_supplier.supplier_id = params.id
    state.current_supplier.is_edit = true
    state.current_supplier.title = params.title
  },

  SET_CURRENT_SUPPLIER_ID(state, id) {
    state.current_supplier.supplier_id = id
  },

  SET_CURRENT_ENTITI_DATA(state, entity_data) {
    state.current_supplier.entity_data = entity_data
  },

  SET_BANK_DATA(state, banks) {
    state.current_supplier.bank_data = banks
  },

  SET_BANKS(state, banks) {
    state.banks.table = banks
    state.banks.isLoaded = true
  },

  SET_CURRENT_TAB(state, tab) {
    state.current_supplier.current_tab = tab
  },

  SET_SUPPLIERS(state, table_data) {
    state.suppliers.table = table_data
  },

  SET_SUPPLIER(state, supplier) {
    state.current_supplier.entity_data = supplier
  },

  SET_PERSONS(state, persons) {
    state.persons.table = persons
    state.persons.isLoaded = true
  },

  SET_CURRENT_CONTRACT(state, contract) {
    state.current_supplier.contract = contract
  },

  SET_IS_EDIT_CONTRACTORS(state, value) {
    state.current_supplier.is_edit = value
  },

  CLEAR_CURRENT_ENTITY(state) {
    state.current_supplier = {
      entity_data: {},
      bank_data: [],
      supplier_id: '',
      entity_id: '',
      is_edit: false,
      current_tab: 0
    }
    state.persons = {}
  }
}
