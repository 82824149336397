var render = function render(){
  var _this$getBreadcrumbs, _this$getBreadcrumbs2, _this$getBreadcrumbs3, _vm$extra, _this$getBreadcrumbs4, _this$getBreadcrumbs5, _vm$statusClass, _vm$getBreadcrumbs, _vm$getBreadcrumbs2, _vm$getBreadcrumbs2$s, _vm$getBreadcrumbs3, _vm$getBreadcrumbs3$s, _vm$getBreadcrumbs4, _vm$getBreadcrumbs4$s, _vm$getBreadcrumbs5, _vm$getBreadcrumbs5$s, _vm$getBreadcrumbs6, _vm$getBreadcrumbs6$d, _vm$getBreadcrumbs7, _vm$getBreadcrumbs7$d;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header_blocks"
  }, [_c('help-modal'), _c('div', {
    staticClass: "breadcrumbs",
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm.getBreadcrumbs.is_go_back ? _c('div', {
    staticClass: "breadcrumbs__back cursor",
    on: {
      "click": _vm.go_back
    }
  }, [_c('img', {
    staticClass: "img_back",
    attrs: {
      "src": "/img/icons/breadcrumbs/arrow_back.svg",
      "alt": ""
    }
  }), _vm._v(" Назад ")]) : _vm._e(), _c('div', {
    staticClass: "breadcrumbs__title",
    class: {
      docs: !_vm.getBreadcrumbs.is_go_back
    }
  }, [_vm._v(" " + _vm._s(_vm.getBreadcrumbs.title || _vm.page_title) + " ")]), (_this$getBreadcrumbs = this.getBreadcrumbs) !== null && _this$getBreadcrumbs !== void 0 && _this$getBreadcrumbs.art ? _c('div', {
    staticClass: "line-title"
  }) : _vm._e(), (_this$getBreadcrumbs2 = this.getBreadcrumbs) !== null && _this$getBreadcrumbs2 !== void 0 && _this$getBreadcrumbs2.art ? _c('p', {
    staticClass: "article"
  }, [_vm._v(" " + _vm._s('Арт: ' + ((_this$getBreadcrumbs3 = this.getBreadcrumbs) === null || _this$getBreadcrumbs3 === void 0 ? void 0 : _this$getBreadcrumbs3.art)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "breadcrumbs__extra d-flex"
  }, [_vm.extra || _vm.getBreadcrumbs.number ? [_c('div', {
    staticClass: "b-number"
  }, [_vm._v("№ " + _vm._s(((_vm$extra = _vm.extra) === null || _vm$extra === void 0 ? void 0 : _vm$extra.number) || _vm.getBreadcrumbs.number))])] : _vm._e(), _vm.extra || _vm.getBreadcrumbs.date ? [_vm.extra ? _c('div', {
    staticClass: "b-date"
  }, [_vm._v(" от " + _vm._s(new Date(_vm.extra.date).toLocaleDateString()) + " ")]) : _c('div', {
    staticClass: "b-date"
  }, [_vm._v(" от " + _vm._s(new Date(_vm.getBreadcrumbs.date).toLocaleDateString()) + " ")])] : _vm._e(), _vm.extra || ((_this$getBreadcrumbs4 = this.getBreadcrumbs) === null || _this$getBreadcrumbs4 === void 0 ? void 0 : _this$getBreadcrumbs4.fixed) === false || ((_this$getBreadcrumbs5 = this.getBreadcrumbs) === null || _this$getBreadcrumbs5 === void 0 ? void 0 : _this$getBreadcrumbs5.fixed) === true ? [_c('div', {
    staticClass: "b-status",
    class: {
      success: _vm.statusClass.class
    }
  }, [_vm._v(" Статус: " + _vm._s((_vm$statusClass = _vm.statusClass) !== null && _vm$statusClass !== void 0 && _vm$statusClass.class ? 'Проведён' : 'Черновик') + " ")])] : _vm._e(), (_vm$getBreadcrumbs = _vm.getBreadcrumbs) !== null && _vm$getBreadcrumbs !== void 0 && _vm$getBreadcrumbs.scales ? [_c('div', {
    staticClass: "scales"
  }, [(_vm$getBreadcrumbs2 = _vm.getBreadcrumbs) !== null && _vm$getBreadcrumbs2 !== void 0 && (_vm$getBreadcrumbs2$s = _vm$getBreadcrumbs2.scales) !== null && _vm$getBreadcrumbs2$s !== void 0 && _vm$getBreadcrumbs2$s.name ? _c('div', {
    staticClass: "scales__name"
  }, [_vm._v(" " + _vm._s((_vm$getBreadcrumbs3 = _vm.getBreadcrumbs) === null || _vm$getBreadcrumbs3 === void 0 ? void 0 : (_vm$getBreadcrumbs3$s = _vm$getBreadcrumbs3.scales) === null || _vm$getBreadcrumbs3$s === void 0 ? void 0 : _vm$getBreadcrumbs3$s.name) + " ")]) : _vm._e(), _c('div', {
    staticClass: "b-status",
    class: {
      success: (_vm$getBreadcrumbs4 = _vm.getBreadcrumbs) === null || _vm$getBreadcrumbs4 === void 0 ? void 0 : (_vm$getBreadcrumbs4$s = _vm$getBreadcrumbs4.scales) === null || _vm$getBreadcrumbs4$s === void 0 ? void 0 : _vm$getBreadcrumbs4$s.visible
    }
  }, [_vm._v(" Статус: " + _vm._s((_vm$getBreadcrumbs5 = _vm.getBreadcrumbs) !== null && _vm$getBreadcrumbs5 !== void 0 && (_vm$getBreadcrumbs5$s = _vm$getBreadcrumbs5.scales) !== null && _vm$getBreadcrumbs5$s !== void 0 && _vm$getBreadcrumbs5$s.visible ? 'Видымый' : 'Отключен') + " ")])])] : _vm._e(), _vm.$route.name === 'analytics.sales-analysis.report' ? [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_vm.getAnalyticsParams.byRawMaterials ? _c('div', {
    staticClass: "mr-3 raw"
  }, [_vm._v(" Сырье ")]) : _vm._e(), _c('b-dropdown', {
    staticClass: "dropdown-help",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "dropdown-help"
        }, [_c('img', {
          staticStyle: {
            "margin-right": "10px",
            "transform": "scale(1.3)"
          },
          attrs: {
            "src": "/img/icons/more.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }], null, false, 1732113832)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.setByRawMaterials({
          byRawMaterials: false
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_c('div', [_vm._v("Анализ товародвижения")]), _c('img', {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "src": "/img/icons/icon-info.svg",
      "alt": ""
    }
  }), !_vm.getAnalyticsParams.byRawMaterials ? _c('img', {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "src": "/img/icons/accept-black.svg",
      "alt": ""
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.setByRawMaterials({
          byRawMaterials: true
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_vm._v("Анализ движения сырья")]), _c('img', {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "src": "/img/icons/icon-info.svg",
      "alt": ""
    }
  }), _vm.getAnalyticsParams.byRawMaterials ? _c('img', {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      "src": "/img/icons/accept-black.svg",
      "alt": ""
    }
  }) : _vm._e()])])], 1)], 1)] : _vm._e(), (_vm$getBreadcrumbs6 = _vm.getBreadcrumbs) !== null && _vm$getBreadcrumbs6 !== void 0 && (_vm$getBreadcrumbs6$d = _vm$getBreadcrumbs6.discount) !== null && _vm$getBreadcrumbs6$d !== void 0 && _vm$getBreadcrumbs6$d.value ? [_c('div', {
    staticClass: "discount-block"
  }, [_c('div', {
    staticClass: "delimiter"
  }), _c('div', [_vm._v("Скидка " + _vm._s((_vm$getBreadcrumbs7 = _vm.getBreadcrumbs) === null || _vm$getBreadcrumbs7 === void 0 ? void 0 : (_vm$getBreadcrumbs7$d = _vm$getBreadcrumbs7.discount) === null || _vm$getBreadcrumbs7$d === void 0 ? void 0 : _vm$getBreadcrumbs7$d.value) + " %")])])] : _vm._e()], 2)]), _c('div', [_c('b-dropdown', {
    staticClass: "dropdown-help",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "dropdown-help"
        }, [_c('img', {
          staticStyle: {
            "margin-right": "10px",
            "transform": "scale(1.3)"
          },
          attrs: {
            "src": "/img/icons/help.svg",
            "alt": ""
          }
        }), _vm._v(" Помощь ")])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.openDocs
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": "/img/icons/docs.svg",
      "alt": ""
    }
  }), _vm._v(" Справочный центр ")]), _c('b-dropdown-item', {
    on: {
      "click": _vm.openModal
    }
  }, [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "margin-right": "15px"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "src": "/img/icons/send-help.svg",
      "alt": ""
    }
  }), _c('div', [_vm._v("Написать в поддержку")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }