var render = function render(){
  var _vm$store_selected_br, _vm$store_selected_br2, _vm$store_selected_br3, _vm$store_selected_br4, _vm$store_selected_br5, _vm$store_selected_br6, _vm$store_selected_br7, _vm$store_selected_br8, _vm$store_selected_br9, _vm$store_selected_br10, _vm$store_selected_br11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('aside', [_c('div', {
    staticClass: "wrapper__sidebar",
    class: {
      hide: _vm.toggle_sidebar
    }
  }, [_c('div', {
    staticClass: "wrapper__sidebar-menu"
  }, [_c('a', {
    staticClass: "toggle-sidebar cursor",
    on: {
      "click": _vm.toggleSidebar
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/toggle-menu-arrow.svg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "b-sidebar-top",
    class: {
      'b-sidebar-top__hide': _vm.toggle_sidebar
    }
  }, [_c('sidebar-top'), _c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.toggle_sidebar,
      expression: "toggle_sidebar"
    }],
    staticClass: "search-toggle",
    on: {
      "click": _vm.searchHideClick
    }
  })], 1), _c('div', {
    staticClass: "container-favorites"
  }, [_c('div', {
    staticClass: "b-sidebar__favorites",
    class: {
      no_visible_text: _vm.toggle_sidebar,
      hidden: _vm.toggle_sidebar
    },
    on: {
      "click": function ($event) {
        return _vm.click_menu({
          is_submenu: true,
          name: 'store',
          opened: false,
          is_submenu: true,
          title: 'Выбор магазина'
        });
      }
    }
  }, [_c('img', {
    class: {
      nohover: !_vm.toggle_sidebar
    },
    attrs: {
      "src": "/img/icons/shop2.svg"
    }
  }), ((_vm$store_selected_br = _vm.store_selected_branch) === null || _vm$store_selected_br === void 0 ? void 0 : (_vm$store_selected_br2 = _vm$store_selected_br.name) === null || _vm$store_selected_br2 === void 0 ? void 0 : _vm$store_selected_br2.length) > 17 && !_vm.toggle_sidebar ? _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "shop",
    attrs: {
      "title": (_vm$store_selected_br3 = _vm.store_selected_branch) === null || _vm$store_selected_br3 === void 0 ? void 0 : _vm$store_selected_br3.name
    }
  }, [_vm._v(_vm._s(((_vm$store_selected_br4 = _vm.store_selected_branch) === null || _vm$store_selected_br4 === void 0 ? void 0 : (_vm$store_selected_br5 = _vm$store_selected_br4.name) === null || _vm$store_selected_br5 === void 0 ? void 0 : _vm$store_selected_br5.slice(0, 17)) + '...') + " ")]) : ((_vm$store_selected_br6 = _vm.store_selected_branch) === null || _vm$store_selected_br6 === void 0 ? void 0 : (_vm$store_selected_br7 = _vm$store_selected_br6.name) === null || _vm$store_selected_br7 === void 0 ? void 0 : _vm$store_selected_br7.length) <= 17 && !_vm.toggle_sidebar ? _c('span', {
    staticClass: "shop"
  }, [_vm._v(_vm._s((_vm$store_selected_br8 = _vm.store_selected_branch) === null || _vm$store_selected_br8 === void 0 ? void 0 : _vm$store_selected_br8.name) + " ")]) : _vm._e()]), _vm.toggle_sidebar ? _c('div', {
    staticClass: "text-container"
  }, [_c('p', [_vm._v(" " + _vm._s(((_vm$store_selected_br9 = _vm.store_selected_branch) === null || _vm$store_selected_br9 === void 0 ? void 0 : _vm$store_selected_br9.name.length) > 7 ? ((_vm$store_selected_br10 = _vm.store_selected_branch) === null || _vm$store_selected_br10 === void 0 ? void 0 : _vm$store_selected_br10.name.slice(0, 7)) + '...' : (_vm$store_selected_br11 = _vm.store_selected_branch) === null || _vm$store_selected_br11 === void 0 ? void 0 : _vm$store_selected_br11.name) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "b-sidebar__menu"
  }, [_c('ul', [_vm._l(_vm.menu_list, function (item, index) {
    return _c('li', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.right",
        modifiers: {
          "hover": true,
          "right": true
        }
      }],
      key: index,
      staticClass: "menu",
      class: {
        opened: item.opened
      },
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "title": _vm.toggle_sidebar ? item.title : ''
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.click_menu(item);
        }
      }
    }, [_c('router-link', {
      staticClass: "item",
      attrs: {
        "to": {
          name: item === null || item === void 0 ? void 0 : item.name
        },
        "exact-active-class": "active-router",
        "active-class": (item === null || item === void 0 ? void 0 : item.name) !== 'dashboard' ? 'active-router' : '',
        "disabled": item.is_submenu
      }
    }, [_c('img', {
      class: {
        hidden: _vm.toggle_sidebar
      },
      attrs: {
        "src": `/img/icons/sidebar/${item.icon}.svg`,
        "alt": ""
      }
    }), _c('span', {
      staticClass: "title link-text"
    }, [_vm._v(_vm._s(item.title))])]), item.submenu ? [_c('span', {
      staticClass: "arrow cursor",
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.toggleSubmenu(index);
        }
      }
    }), _c('ul', {
      staticClass: "b-submenu"
    }, _vm._l(item.submenu, function (subitem, index) {
      return _c('li', {
        key: index
      }, [_c('router-link', {
        staticClass: "item",
        attrs: {
          "to": {
            name: subitem === null || subitem === void 0 ? void 0 : subitem.name
          },
          "active-class": "active-router",
          "exact-active-class": "active-router"
        }
      }, [_vm._v(" " + _vm._s(subitem.title) + " ")])], 1);
    }), 0)] : _vm._e()], 2);
  })], 2)]), _c('user-info')], 1)]), _c('b-sidebar', {
    attrs: {
      "id": "sub-sidebar",
      "title": "Sidebar",
      "backdrop-variant": "dark",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function (_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "b-hide cursor",
          on: {
            "click": hide
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/sidebar/arrow-hide.svg",
            "alt": ""
          }
        }), _vm._v(" Свернуть ")])];
      }
    }]),
    model: {
      value: _vm.submenu,
      callback: function ($$v) {
        _vm.submenu = $$v;
      },
      expression: "submenu"
    }
  }, [_c('menu-sub-right', {
    attrs: {
      "submenu_array": _vm.submenu_array
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }