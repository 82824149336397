import store from '@store'

export function set_token(from, to, next) {
  const dispatch = store?.dispatch ?? null
  if (from.path === '/setToken' && from.query.token) {
    if (dispatch) {
      dispatch('core/logout')
      dispatch('core/setToken', from.query.token)
    }
    return window.open('/', '_self')
  }
  next()
}
