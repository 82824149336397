export const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters)
  },
  setDocument({ commit }, doc) {
    commit('SET_DOCUMENT', doc)
  },
  updateDocument({ commit }, doc) {
    commit('UPDATE_DOCUMENT', doc)
  },
  setEntity({ commit }, entity) {
    commit('SET_ENTITY', entity)
  }
}
