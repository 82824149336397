<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.63296 12.1955L4.74761 14.0808C3.96598 14.8624 2.70019 14.8624 1.91921 14.081C1.13808 13.2998 1.13808 12.0339 1.91906 11.253L5.69042 7.48159C6.4714 6.70058 7.73731 6.70058 8.51828 7.48159C8.77863 7.74193 9.20076 7.74193 9.4611 7.48159C9.72145 7.22124 9.72145 6.79912 9.4611 6.53877C8.15941 5.23708 6.0493 5.23708 4.74761 6.53877L0.976271 10.3101C-0.325424 11.6118 -0.325424 13.7219 0.976271 15.0236C2.27781 16.326 4.38807 16.326 5.69046 15.0236L7.57581 13.1382C7.83616 12.8779 7.83616 12.4558 7.57581 12.1954C7.31547 11.9351 6.89331 11.9351 6.63296 12.1955Z"
      :fill="color"
    />
    <path
      d="M15.0236 0.976271C13.7219 -0.325424 11.6111 -0.325424 10.3094 0.976271L8.04738 3.23828C7.78703 3.49863 7.78703 3.92076 8.04738 4.1811C8.30772 4.44145 8.72985 4.44145 8.9902 4.1811L11.2522 1.91909C12.0332 1.13808 13.2998 1.13808 14.0808 1.91909C14.8617 2.70006 14.8617 3.96598 14.0808 4.74695L9.93273 8.895C9.15173 9.67601 7.88585 9.67601 7.10487 8.895C6.84453 8.63466 6.4224 8.63466 6.16206 8.895C5.90171 9.15535 5.90171 9.57748 6.16206 9.83782C7.46375 11.1395 9.57386 11.1395 10.8756 9.83782L15.0236 5.6898C16.3253 4.3881 16.3253 2.27797 15.0236 0.976271Z"
      :fill="color"
    />
  </svg>
</template>

<script>
  export default {
    name: 'ConnectSvg',
    props: {
      color: {
        type: String,
        default: '#00CB91'
      }
    }
  }
</script>

<style scoped></style>
