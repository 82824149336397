<template>
  <div class="provider">
    <slot></slot>
    <div
      class="alert-container"
      @click.prevent="runAction"
    >
      <transition-group
        name="e-alert-container"
        tag="div"
      >
        <div
          v-for="(alert, index) of alerts"
          :key="alert.hash + '/' + index"
          class="e-alert"
          :class="alert.variant"
        >
          <div class="title">
            <img :src="`/img/icons/${alert.variant || 'primary'}-alert-icon.svg`" />
            <div class="t-text">{{ alert.title }}</div>
            <div
              class="ml-auto close-img"
              @click="clearNotification(alert.hash)"
            >
              <img src="/img/icons/Delete.svg" />
            </div>
          </div>
          <div
            v-if="alert.message"
            class="alert-message message-text"
          >
            {{ alert.message }}
          </div>
          <div
            v-if="alert.card"
            class="info"
          >
            <div class="message-text">{{ alert.card?.message }}</div>
            <div class="add-info">{{ alert.card?.info }}</div>
          </div>
          <div class="d-flex">
            <div
              v-for="(action, i) of alert.actions"
              :key="i"
              class="action-btn"
              @click="actionClick(alert, action.action)"
            >
              {{ action.title }}
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
  import { EventBus } from '@/main'

  export default {
    name: 'AlertProvider',
    data() {
      return {
        alerts: []
      }
    },
    mounted() {
      this.bindEvents()
    },
    destroyed() {
      EventBus.$off('notify', (data) => this.handleNotification(data))
    },
    methods: {
      bindEvents() {
        EventBus.$on('notify', (data) => this.handleNotification(data))
      },

      handleNotification(data) {
        const hash = Date.now()
        this.alerts
          .filter((el) => el.unique && el.unique === data.unique)
          .forEach((el) => this.clearNotification(el.hash))

        this.alerts.push({
          ...data,
          type: data.type,
          title: data.title,
          message: data.message,
          actions: data.actions,
          hash: hash
        })

        setTimeout(() => this.clearNotification(hash), 30000)
      },

      runAction() {
        switch (this.action) {
          case 'clear':
            this.clearNotification()
            break
        }
      },

      clearNotification(hash) {
        const index = this.alerts.findIndex((el) => el.hash === hash)
        this.alerts.splice(index, 1)
      },
      async actionClick(alert, action) {
        try {
          await action()
        } catch (e) {
          console.log(e)
        } finally {
          this.clearNotification(alert.hash)
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .e-alert-container-enter-active,
  .e-alert-container-leave-active {
    transition: all 1s;
  }
  .e-alert-container-enter, .e-alert-container-leave-to /* .list-leave-active до версии 2.1.8 */ {
    opacity: 0;
    //transform: translateY(30px);
  }
  .provider {
    position: relative;
    height: 100%;
  }
  .e-alert-container-move {
    transition: transform 0.5s;
  }

  .alert-container {
    bottom: 0;
    right: 25px;
    position: absolute;
    pointer-events: none;
    z-index: 100000;
  }

  .e-alert {
    width: 400px;
    border-radius: 2px;
    margin-bottom: 10px;
    pointer-events: auto;
    box-shadow: 0px 0px 20px 0px #0000001a;
    padding: 16px;
    border: 1px solid var(--gray-gray-150, #99daff);
    background: #f8fbff;
    &.danger {
      border: 1px solid var(--red-r-150, #ffcdd2);
      background: #fffafa;
    }
    &.warning {
      border: 1px solid var(--red-r-150, #fff0b6);
      background: #fffae7;
    }
    .title {
      display: flex;
      align-items: center;
      .close-img {
        display: flex;
        cursor: pointer;
      }
      .t-text {
        color: var(--text-primary-gray-700, #313131);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 6px;
      }
    }

    .info {
      margin-top: 10px;
      border-radius: 4px;
      border: 1px solid var(--gray-gray-150, #e1e1e1);
      background: var(--secondary-b-0, #fff);
      padding: 12px;
    }

    .add-info {
      color: #888888;
    }

    .action-btn {
      margin-top: 10px;
      color: #00a3ff;
      cursor: pointer;
    }
    .alert-message {
      margin-top: 12px;
    }
    .message-text {
      color: var(--text-primary-gray-700, #313131);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
</style>
