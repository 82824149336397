<template>
  <svg
    class="line__logo left_line"
    width="30"
    height="4"
    viewBox="0 0 30 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4V0H26L30 4H0Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'LeftLineSvg'
  }
</script>
<style scoped lang="scss">
  .line__logo {
    transform: translateY(66px);
  }
</style>
