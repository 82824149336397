import { BranchModel } from '@/models/branch.model'
import { CompanyModel } from '@/models/company.model'
import { RoleModel } from '@/models/role.model'

export class UserModel {
  constructor(request = {}) {
    this.branches = request?.branches?.map((el) => new BranchModel(el))
    this.company = new CompanyModel(request?.company)
    this.email = request?.email
    this.first_name = request?.first_name
    this.id = request?.id
    this.last_name = request?.last_name
    this.phone = request?.phone
    this.roles = request?.roles?.map((el) => new RoleModel(el))
    this.second_name = request?.second_name
    this.password = request?.password
    this.frontol_pass = request?.frontol_pass
    this.is_fired = request?.is_fired
  }

  get input() {
    return {
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name,
      second_name: this.second_name,
      phone: this.phone?.length ? this.phone : null,
      email: this.email?.length ? this.email : null,
      password: this.password,
      branches: this.branches?.map((el) => el.id),
      roles: this.roles?.map((el) => el.id),
      frontol_pass: this.frontol_pass,
      is_fired: this.is_fired
    }
  }

  get updateInput() {
    return {
      id: this.id,
      first_name: this.first_name,
      last_name: this.last_name,
      second_name: this.second_name,
      phone: this.phone?.length ? this.phone : null,
      email: this.email?.length ? this.email : null,
      branches: this.branches?.map((el) => el.id),
      roles: this.roles?.map((el) => el.id)
    }
  }

  get validateUser1() {
    return (
      (this.first_name?.length &&
        this.first_name?.length < 2 &&
        this.last_name?.length &&
        this.last_name?.length < 2) ||
      !this.first_name ||
      !this.last_name
    )
  }

  get validateUser2() {
    return !this.roles?.length || !this.branches?.length
  }

  get validateUser3() {
    const email = !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(this.email) || !this.email
    const pass = !this.password || this.password?.length < 3
    const passFrontol = !this.frontol_pass || this.frontol_pass?.length < 3
    const iscCshier = this.roles?.some((el) => el.name === 'Кассир')

    if (iscCshier) {

      if (!email && !pass && !passFrontol) {
        return false
      } else {
        return true
      }

    } else {

      if (!email && !pass) {
        return false
      } else {
        return true
      }
      
    }
  }

  setRoles = (roles) => {
    this.roles = roles?.map((el) => new RoleModel(el))
  }

  setBranches = (branches) => {
    this.branches = branches?.map((el) => new BranchModel(el))
  }

  setEmail = (email) => {
    this.email = email
  }

  setPhone = (phone) => {
    this.phone = phone
  }
  setFirstName = (phone) => {
    this.first_name = phone
  }
  setLastName = (phone) => {
    this.last_name = phone
  }
  setSecondName = (phone) => {
    this.second_name = phone
  }
  setPassword = (pass) => {
    this.password = pass
  }
  setFrontolPass = (pass) => {
    this.frontol_pass = pass
  }
  setIsFired = (data) => {
    this.is_fired = data
  }
}

export class UserModelView extends UserModel {
  constructor(req) {
    super(req)
    this.selected = req?.selected || true
  }
}
