var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "line",
    attrs: {
      "width": "168",
      "height": "29",
      "viewBox": "0 0 168 29",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "y": "25.4751",
      "width": "169",
      "height": "3",
      "rx": "1.5",
      "transform": "rotate(-8.48224 0 25.4751)",
      "fill": "#474747"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }