<template>
  <div class="containers">
    <div class="logo">
      <div class="logo_block">
        <div class="line-cart">
          <left-line-svg />
          <cart-svg />
        </div>
        <div class="title">
          <p class="address">{{ companyName }}</p>
          <two-for-one-svg />
        </div>
        <right-line-svg />
      </div>

      <green-line-svg />
    </div>
    <div class="info_block">
      <div class="nameBlock">
        <b class="name">{{ product?.name?.length >= 65 ? product?.name?.slice(0, 65) + '...' : product?.name }}</b>
      </div>

      <div class="price-text">
        <p class="country">{{ product?.country?.name }}</p>
      </div>
    </div>

    <div class="price-block">
      <div>
        <div class="left-block">
          <div class="little-text">
            <p>Старая цена за</p>
            <p>{{ product.measurement.name.slice(0, 2) }} (руб.)</p>
          </div>
          <div class="old-price">
            <b class="old"
              >{{ getOldPrice }}
              <line-through-svg />
            </b>
          </div>
          <div class="barcode">
            <div style="width: 201px; height: 73px">
              <barcode-generator
                v-if="product.package"
                :width="201"
                :height="73"
                :barcode="product.package[0].barcode.barcode"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="right-block">
        <div class="bold-text">
          <b>Цена за</b>
          <b>{{ product.measurement.name.slice(0, 2) }} (руб.)</b>
        </div>
        <div class="new_price">
          <img
            class="orange-block"
            src="../img/Vector 1.png"
            alt=""
          />
          <b>{{ getRetailPrice }}</b>
        </div>
      </div>
    </div>

    <green-line-big-svg />
  </div>
</template>
<script>
  import CartSvg from '@/views/core/modal/price-tag/svg/CartSvg.vue'
  import RightLineSvg from '@/views/core/modal/price-tag/svg/RightLineSvg.vue'
  import LeftLineSvg from '@/views/core/modal/price-tag/svg/LeftLineSvg.vue'
  import GreenLineSvg from '@/views/core/modal/price-tag/svg/GreenLineSvg.vue'
  import GreenLineBigSvg from '@/views/core/modal/price-tag/svg/GreenLineBigSvg.vue'
  import LineThroughSvg from '@/views/core/modal/price-tag/svg/LineThroughSvg.vue'
  import { mapGetters } from 'vuex'
  import formatDate from '@/utils/formatDate'
  import TwoForOneSvg from '@/views/core/modal/price-tag/svg/TwoForOneSvg'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator'
  export default {
    name: 'PriceTagDiscount',
    components: {
      CartSvg,
      TwoForOneSvg,
      RightLineSvg,
      LeftLineSvg,
      GreenLineSvg,
      GreenLineBigSvg,
      BarcodeGenerator,
      LineThroughSvg
    },
    props: {
      product: Object
    },
    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return entity_name(this.product.entity) || entity_name(this.getCurrentBranch.entities?.[0]) || this.company_name
      },
      date() {
        return formatDate(new Date())
      },
      getRetailPrice() {
        return 1 >= this.product.retailPrice.toFixed(2).toString().split('.').length
          ? this.product.retailPrice + '.00'
          : this.product.retailPrice.toFixed(2).toString().split('.')[0] +
              '.' +
              this.product.retailPrice.toFixed(2).toString().split('.')[1]
      },
      getOldPrice() {
        if (this.product?.promotion?.old_price) {
          return 1 >= this.product?.promotion?.old_price?.toFixed(2).toString().split('.').length
            ? this.product?.promotion?.old_price?.toFixed(2) + '.00'
            : this.product?.promotion?.old_price?.toFixed(2).toString().split('.')[0] +
                '.' +
                this.product?.promotion?.old_price?.toFixed(2).toString().split('.')[1]
        } else {
          return this.getRetailPrice
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  p {
    margin: 0;
    padding: 0;
  }
  .containers {
    background-color: #fff;
    overflow: hidden;
    position: relative;
    width: 651px;
    height: 389px;
    border: 2px solid black;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }

  .line-cart {
    display: flex;
  }

  .logo {
    margin-bottom: 12px;
    position: relative;
    height: 100px;
    &_block {
      display: flex;
      justify-content: space-between;
      .cart {
        margin-top: 2px;
        z-index: 2;
      }
      .line__logo {
        transform: translateY(66px);
      }
      .title {
        .address {
          padding-top: 5px;
          font-weight: 400;
          font-size: 16px;
        }
        svg {
          padding: 0;
          margin: 0;
          padding-bottom: 5px;
        }
      }
    }
  }
  .green-line {
    position: absolute;
    bottom: 0;
    left: 0;
    &__big {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .info_block {
    .nameBlock {
      height: 31px;
      position: relative;
      .name {
        position: absolute;
        transform: translate(-50%, 0%);
        top: 0;
        left: 50%;
        max-width: 502px;
        width: 100%;
        font-family: 'Tahoma', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 25.9953px;
        line-height: 31px;
        letter-spacing: 0.015em;
      }
    }

    .price-text {
      .country {
        margin-left: 10px;
        margin-top: 32px;
        text-align: start;
        font-family: Arial, Helvetica, sans-serif sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 25.3004px;
        line-height: 29px;
        display: flex;
        align-items: center;
      }
    }
  }
  .barcode {
    position: relative;
    z-index: 5;
  }
  .price-block {
    display: flex;
    justify-content: space-between;
    .left-block {
      margin-left: 32px;
      .little-text {
        height: 29px;
        display: flex;
        p {
          align-items: flex-end;
          font-family: Arial, Helvetica, sans-serif sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14.5889px;
          line-height: 17px;
          display: flex;
          &:nth-child(1) {
            margin-right: 32px;
          }
        }
      }
      .old-price {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 9px;
        margin-bottom: 4px;
        position: relative;
        overflow: hidden;

        .old {
          width: fit-content;
          overflow: hidden;
          color: #008000;
          font-family: 'Ubuntu-Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 47.4961px;
          line-height: 55px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          position: relative;
        }
        .line-through {
          position: absolute;
          transform: translate(-50%, 0%);
          left: 50%;
          z-index: 14;
          bottom: 31%;
        }
      }
    }
    .right-block {
      // margin-right: 80px;
      width: 381px;
      height: 29px;
      .bold-text {
        display: flex;
        margin-bottom: 15px;
        width: 100%;
        align-items: center;
        justify-content: center;
        b {
          font-family: Arial, Helvetica, sans-serif sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 25.0016px;
          line-height: 29px;
          display: flex;
          align-items: center;
          &:nth-child(1) {
            margin-right: 29px;
          }
        }
      }
      .new_price {
        position: relative;
        display: flex;
        justify-content: center;
        .orange-block {
          position: absolute;
          z-index: 9;
          right: 0;
        }
        b {
          padding-top: 15px;
          z-index: 10;
          position: relative;
          font-family: 'Ubuntu-Bold', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 85.9843px;
          line-height: 99px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
</style>
