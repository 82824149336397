<template>
  <div class="confirm-block">
    <div class="confirm-block__left">
      <warning-svg
        :color="'white'"
        class="warning-img"
      />
      <div class="text">
        <p class="confirm-text">{{ getDays }} Оплатите подписку чтобы продолжить пользоваться сервисом.</p>
      </div>
    </div>
    <div class="confirm-block__right">
      <div class="button-block">
        <e-button
          size="m"
          @click="showModal"
        >
          Оплатить
        </e-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ConfirmEmailModal from '@/views/core/modal/ConfirmEmailModal'
  import WarningSvg from '@/views/core/header/components/WarningSvg'

  export default {
    name: 'PaySubscriptionAlert',
    components: { WarningSvg, ConfirmEmailModal },
    data() {
      return {
        buttonText: 'Подтвердить',
        date: new Date(),
        interval: null,
        disabled: false,
        sending: false
      }
    },
    computed: {
      ...mapGetters({
        user: 'core/user',
        branch: 'settings/getCurrentBranch'
      }),
      getDays() {
        const days = Math.floor((new Date(this.branch?.subscription?.cancel_date).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000))
        console.log(days)
        if (days > 0) {
          return `До окончания подписки осталось ${days} дней.`
        } else {
          return 'Подписка закончилась.'
        }
      }
    },

    methods: {
      showModal() {
        this.$router.push({ name: 'company.branches', params: { needPay: true } })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sending__mini {
    display: none !important;
  }
  .confirm-block {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    background: #e53835;
    align-items: center;
    padding: 18px 20px;

    &__left {
      display: flex;
      .warning-img {
        margin-right: 9px;
      }

      .text {
        display: flex;
        align-items: center;
        p {
          padding: 0;
          margin: 0;
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #ffffff;
          white-space: nowrap;
        }

        .email {
          color: #e53835;
          background: #ffffff;
          border-radius: 68px;
          padding: 2px 6px;
          margin: 0 6px;
        }
      }
    }

    &__right {
      display: flex;
      .button-block {
        .e_button__disabled {
          background: #f2f3f6 !important;
        }
        button {
          background: #d0322f;
          border-color: #d0322f;
          border-radius: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
  }
  .not-sending {
    margin-right: 8px;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #313131;
  }
  .confirm-block__sending {
    background: #cacaca;
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;

    .confirm-block__left {
      display: flex;

      .warning-img {
        margin-right: 9px;
      }

      .text {
        display: flex;
        align-items: center;

        p {
          padding: 0;
          margin: 0;
          font-family: 'Gilroy-Medium', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #313131;
          white-space: nowrap;
        }

        .email {
          color: #313131;
          background: #ffffff;
          border-radius: 68px;
          padding: 2px 6px;
          margin: 0 6px;
        }
      }
    }

    .confirm-block__right {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
      }

      .button-block {
        button {
          background: #e2e2e2;
          border-radius: 2px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #313131;
        }
      }
    }
  }
  @media (max-width: 1180px) {
    .sending__mini {
      display: flex !important;
    }
    .confirm-block__sending .email {
      display: none !important;
    }
    .sending {
      display: none !important;
    }
  }
  @media (max-width: 1370px) {
    .service-text {
      display: none !important;
    }
  }
</style>
