import View from '@/views/layouts/layout-view'

export default {
  path: '/diagnostics',
  name: 'diagnostics',
  component: View,
  // redirect: '/ext-systems/list',
  children: [
    {
      path: '',
      name: 'diagnostics.list',
      component: () => import('@/views/diagnostics/pages/DiagnosticsList'),
      meta: { title: 'Рекомендации' },
      children: [
        {
          path: 'egais',
          name: 'diagnostics.list.egais',
          meta: { title: 'Рекомендации' },
          component: () => import('@/views/diagnostics/components/EgaisTab')
        },
        {
          path: 'crptech',
          name: 'diagnostics.list.crptech',
          meta: { title: 'Рекомендации' },
          component: () => import('@/views/diagnostics/components/CrpTechTab')
        },
        {
          path: 'negative',
          name: 'diagnostics.list.negative_remains',
          meta: { title: 'Рекомендации' },
          component: () => import('@/views/diagnostics/components/NegativeRemainders')
        },
        {
          path: 'expiring',
          name: 'diagnostics.list.expiring',
          meta: { title: 'Рекомендации' },
          component: () => import('@/views/diagnostics/components/Expiring')
        }
      ]
    }
  ]
}
