export const mutations = {
  SET_QUANTITY_PRODUCTS(state, quantity) {
    state.quantity_products = quantity
  },

  SET_PRINT_PRODUCT_DATA(state, data) {
    state.printProductData = data
  },

  SET_PRINT_PRODUCT_ITEM_DATA(state, data) {
    state.printProductData.forEach((item) => {
      if (item.product === data.id) {
        item.quantity = +data.count
      }
    })
  },

  SET_CURRENT_CATEGORY(state, category) {
    state.current_category = category
  },

  SET_CURRENT_PRODUCT(state, product) {
    state.current_product = product
  },

  INITIAL_STATE(state) {
    state.current_category = null
    state.current_category_path = null
    state.current_ids = []
    state.current_products = []
    state.settings = {
      current_category_path: null,
      menu_is_visible: true
    }
    state.search_str = ''
    state.placeholder = 'Поиск товаров по названию, коду или постав...'
    state.current_product_id = ''
    state.add_group_visible = false
    state.current_category_menu_id = null
    state.print_actions = {
      print: true,
      print_label: false
    }
  },

  SET_CURRENT_CATEGORY_PATH(state, categoryPath) {
    state.current_category_path = categoryPath
  },

  SET_SELECTED_CATEGORIES(state, categories) {
    state.selected_categories = categories
  },

  SET_SELECTED_PRODUCTS(state, products) {
    state.selected_products = products
  },

  SET_EXLUDED_PRODUCTS(state, excluded_products) {
    state.excluded_products = excluded_products
  },

  SET_EXLUDED_CATEGORIES(state, excluded_categories) {
    state.excluded_categories = excluded_categories
  },

  SET_CURRENT_IDS(state, ids) {
    state.current_ids = ids
  },

  SET_PLACEHOLDER(state, placeholder) {
    state.placeholder = placeholder
  },

  SET_SEARCH_STR(state, str) {
    state.search_str = str
  },

  SET_CURRENT_PRODUCTS(state, ids) {
    if (Array.isArray(ids)) state.current_products = ids
  },

  SET_MENU_VISIBLE_STATUS(state, status) {
    state.settings.menu_is_visible = status
  },

  SET_CURRENT_PRODUCT_ID(state, id) {
    state.current_product_id = id
  },

  SET_ADDGROUP_VISIBLE_STATUS(state, status) {
    state.add_group_visible = status
  },

  SET_CURRENT_CATEGORY_MENU_ID(state, id) {
    state.current_category_menu_id = id
  },

  SET_PRINT_ACTIONS(state, acts) {
    state.print_actions = acts
  },
  SET_FILTERS(state, filters) {
    state.filters = filters
  }
}
