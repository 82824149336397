<template>
  <aside>
    <div
      class="wrapper__sidebar"
      :class="{ hide: toggle_sidebar }"
    >
      <div class="wrapper__sidebar-menu">
        <a
          class="toggle-sidebar cursor"
          @click="toggleSidebar"
        >
          <img
            src="/img/icons/toggle-menu-arrow.svg"
            alt=""
          />
        </a>
        <div
          class="b-sidebar-top"
          :class="{ 'b-sidebar-top__hide': toggle_sidebar }"
        >
          <sidebar-top />
          <button
            v-show="toggle_sidebar"
            class="search-toggle"
            @click="searchHideClick"
          ></button>
          <!-- <search v-show="!toggle_sidebar" ref="sidebarSearch" /> -->
        </div>
        <div class="container-favorites">
          <div
            class="b-sidebar__favorites"
            :class="{ no_visible_text: toggle_sidebar, hidden: toggle_sidebar }"
            @click="
              click_menu({
                is_submenu: true,
                name: 'store',
                opened: false,
                is_submenu: true,
                title: 'Выбор магазина'
              })
            "
          >
            <img
              src="/img/icons/shop2.svg"
              :class="{ nohover: !toggle_sidebar }"
              style=""
            />
            <span
              v-if="store_selected_branch?.name?.length > 17 && !toggle_sidebar"
              v-b-tooltip.hover
              class="shop"
              :title="store_selected_branch?.name"
              >{{ store_selected_branch?.name?.slice(0, 17) + '...' }}
            </span>
            <span
              v-else-if="store_selected_branch?.name?.length <= 17 && !toggle_sidebar"
              class="shop"
              >{{ store_selected_branch?.name }}
            </span>
          </div>
          <div
            v-if="toggle_sidebar"
            class="text-container"
          >
            <p>
              {{
                store_selected_branch?.name.length > 7
                  ? store_selected_branch?.name.slice(0, 7) + '...'
                  : store_selected_branch?.name
              }}
            </p>
          </div>
        </div>

        <div class="b-sidebar__menu">
          <ul>
            <template>
              <li
                v-for="(item, index) in menu_list"
                :key="index"
                v-b-tooltip.hover.right
                :title="toggle_sidebar ? item.title : ''"
                class="menu"
                :class="{ opened: item.opened }"
                style="cursor: pointer"
                @click.prevent="click_menu(item)"
              >
                <router-link
                  :to="{ name: item?.name }"
                  class="item"
                  exact-active-class="active-router"
                  :active-class="item?.name !== 'dashboard' ? 'active-router' : ''"
                  :disabled="item.is_submenu"
                >
                  <img
                    :src="`/img/icons/sidebar/${item.icon}.svg`"
                    :class="{ hidden: toggle_sidebar }"
                    alt=""
                  />
                  <span class="title link-text">{{ item.title }}</span>
                </router-link>
                <template v-if="item.submenu">
                  <span
                    class="arrow cursor"
                    @click.prevent="toggleSubmenu(index)"
                  ></span>
                  <ul class="b-submenu">
                    <li
                      v-for="(subitem, index) in item.submenu"
                      :key="index"
                    >
                      <router-link
                        :to="{ name: subitem?.name }"
                        class="item"
                        active-class="active-router"
                        exact-active-class="active-router"
                      >
                        {{ subitem.title }}
                      </router-link>
                    </li>
                  </ul>
                </template>
              </li>
            </template>
          </ul>
        </div>
        <user-info />
      </div>
      <!--<div v-if="submenu_view" class="wrapper__sidebar-submenu" :class="{ turn: submenu_hide }">
        <div class="wrapper__sidebar-submenu-top">
          <div class="cursor">
            <img src="/img/icons/sidebar-menu__back.svg" alt="" />
            Свернуть
          </div>
        </div>
        <div class="wrapper__sidebar-submenu-content"></div>
      </div>-->
    </div>
    <b-sidebar
      id="sub-sidebar"
      v-model="submenu"
      title="Sidebar"
      backdrop-variant="dark"
      backdrop
      shadow
    >
      <template #header="{ hide }">
        <div
          class="b-hide cursor"
          @click="hide"
        >
          <img
            src="/img/icons/sidebar/arrow-hide.svg"
            alt=""
          />
          Свернуть
        </div>
      </template>
      <menu-sub-right :submenu_array="submenu_array" />
    </b-sidebar>
  </aside>
</template>

<script>
  // import Search from './components/Search'
  import SidebarTop from './components/SidebarTop'
  import UserInfo from './components/UserInfo'
  import MenuSubRight from './components/MenuSubRight'
  import menu_tree from '@/assets/menu/menu_tree'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      SidebarTop,
      // Search,
      UserInfo,
      MenuSubRight
    },

    data: () => ({
      toggle_sidebar: false,
      menu_list: [],
      submenu_view: false,
      submenu_hide: false,
      submenu: false,
      submenu_array: null
    }),

    // watch: {
    //   $route() {
    //     if (this.$route.meta.submenu) return this.submenu_view = true
    //
    //     return this.submenu_view = false
    //   }
    // },

    created() {
      this.menu_list = menu_tree
    },

    mounted() {
      if (localStorage.hideSidebar) {
        this.toggle_sidebar = JSON.parse(localStorage.hideSidebar)
      }

      const innerWidth = window.innerWidth
      if (innerWidth <= 1024) {
        this.toggle_sidebar = true
      }
    },

    methods: {
      toggleSidebar() {
        localStorage.hideSidebar = JSON.stringify(!this.toggle_sidebar)
        this.toggle_sidebar = !this.toggle_sidebar
      },
      toggleSubmenu(index) {
        this.menu_list[index].opened = !this.menu_list[index].opened
      },
      searchHideClick() {
        this.toggleSidebar()
        this.$nextTick(function () {
          this.$refs?.sidebarSearch?.$children[0]?.$el?.focus()
        })
      },
      click_menu(item) {
        if (item.is_submenu) {
          this.submenu_array = item
          this.submenu = true
          return
        }
      }
    },
    computed: {
      ...mapGetters({
        store_selected_branch: 'settings/getCurrentBranch'
      }),
      currentRouteName() {
        return this.$route.name
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    #sub-sidebar {
      width: 335px !important;
    }
    .b-sidebar__menu {
      overflow-y: visible !important;
    }
    #sub-sidebar.b-sidebar {
      left: 250px;
      z-index: 999;
      background: #fff !important;
      border-left: 1px solid #efefef;
    }

    .wrapper__sidebar ~ .b-sidebar-outer {
      z-index: 998;
    }

    .wrapper__sidebar ~ .b-sidebar-outer .b-sidebar-backdrop {
      z-index: 800;
      left: 300px;
    }

    .wrapper__sidebar.hide ~ .b-sidebar-outer {
      .b-sidebar-backdrop {
        left: 83px;
      }
    }

    .b-sidebar-header {
      height: 85px;
      border-bottom: 1px solid #efefef;
      padding-left: 25px;

      .b-hide {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 14px;
        color: #888888;

        img {
          margin-right: 14px;
        }
      }
    }

    .b-sidebar-body {
      padding: 32px 14px 14px;
    }

    .active-router {
      background: #f2f3f6;
      color: black !important;
      border-left: 2px solid #00a3ff !important;
    }

    .wrapper__sidebar.hide ~ .b-sidebar-outer {
      #sub-sidebar {
        left: 83px;
      }

      .b-sidebar-backdrop {
        left: 83px;
      }
    }
  }

  .wrapper__sidebar {
    z-index: 1000;
  }

  .wrapper__sidebar ~ .b-sidebar-outer {
    z-index: 998;
  }

  .wrapper__sidebar ~ .b-sidebar-outer .b-sidebar-backdrop {
    z-index: 800;
    left: 300px;
  }

  .wrapper__sidebar.hide ~ .b-sidebar-outer {
    #sub-sidebar {
      left: 83px;
    }

    .b-sidebar-backdrop {
      left: 83px;
    }
  }

  .b-sidebar-header {
    // height: 85px;
    // border-bottom: 1px solid #efefef;
    // padding-left: 25px;

    .b-hide {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      color: #888888;

      img {
        margin-right: 14px;
      }
    }
  }
</style>
