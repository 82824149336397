import gql from 'graphql-tag'

export const GET_SUPPLIERS = gql`
  query Suppliers {
    Suppliers {
      id
      name
      inn
      kpp
      full_name
      address_entity {
        post_index
        country
        city
        street
        house
        apartment
      }
      contacts {
        name
        phone
        main
      }
    }
  }
`

export const GET_SUPPLIER = gql`
  query Supplier($id: ID!) {
    Supplier(id: $id) {
      name
      id
      inn
      kpp
      full_name
      address_entity {
        post_index
        country
        city
        street
        house
        apartment
      }
      contacts {
        name
        phone
        main
      }
    }
  }
`

export const GET_PERSONS = gql`
  query Persons {
    Persons {
      id
      name
      phone
      email
      main
    }
  }
`

export const GET_BANKS = gql`
  query SupplierAccounts($id: ID!) {
    SupplierAccounts(id: $id) {
      id
      bank
      bik
      corr_acc
      check_acc
      main
      comment
    }
  }
`
