var render = function render(){
  var _vm$selected, _vm$selected$0$name;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "select-container"
  }, [_c('div', {
    ref: "select",
    staticClass: "select",
    class: [`select${_vm.item.id}`],
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.open.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "select-block"
  }, [!((_vm$selected = _vm.selected) !== null && _vm$selected !== void 0 && _vm$selected.length) ? _c('div', {
    staticClass: "placeholder"
  }, [_vm._v(" Выберите связь ")]) : _c('div', {
    staticClass: "selected-item"
  }, [_vm._v(" " + _vm._s(((_vm$selected$0$name = _vm.selected[0].name) === null || _vm$selected$0$name === void 0 ? void 0 : _vm$selected$0$name.length) > 8 ? _vm.selected[0].name.slice(0, 8) + '...' : _vm.selected[0].name) + " "), _c('div', {
    staticClass: "delete-selected",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeSelected.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/close-comparison.svg",
      "alt": "remove"
    }
  })])])]), _c('div', {
    staticClass: "arrow-block",
    class: {
      rotate: _vm.opened
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/arrow-comparison.svg",
      "alt": "open"
    }
  })])]), _c('div', {
    staticClass: "menu",
    class: [`menu${_vm.item.id}`, _vm.opened ? '' : 'menu__hidden']
  }, [_c('div', {
    staticClass: "path"
  }, [_c('p', {
    staticClass: "menu--title",
    class: {
      active: !_vm.selectedPath.length
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.clearPath.apply(null, arguments);
      }
    }
  }, [_vm._v(" Группы ")]), _vm._l(_vm.selectedPath, function (name) {
    return _c('p', {
      key: name.id,
      staticClass: "menu--title",
      class: {
        active: name.id === _vm.selectedPath[_vm.selectedPath.length - 1].id
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.selectPath(name);
        }
      }
    }, [_c('img', {
      staticStyle: {
        "transform": "rotate(-90deg)"
      },
      attrs: {
        "src": "/img/icons/arrow-comparison.svg",
        "alt": "open"
      }
    }), _vm._v(" " + _vm._s(name.name.length > 10 ? name.name.slice(0, 10) + '...' : name.name) + " ")]);
  })], 2), _c('ul', {
    staticClass: "menu--list"
  }, _vm._l(_vm.franchise_arr, function (item) {
    return _c('li', {
      key: item.id,
      staticClass: "menu--item",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.select(item);
        }
      }
    }, [_c('p', [_vm._v(_vm._s(item.name))]), !(item !== null && item !== void 0 && item.last) ? _c('img', {
      staticStyle: {
        "transform": "rotate(-90deg)"
      },
      attrs: {
        "src": "/img/icons/arrow-comparison.svg",
        "alt": "open"
      }
    }) : _vm._e()]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }