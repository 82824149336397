export const actions = {
  setDeclaration({ commit }, declaration) {
    commit('SET_DECLARATION', declaration)
  },
  setBranches({ commit }, branches) {
    commit('SET_BRANCHES', branches)
  },
  setSpecifications({ commit }, specifications) {
    commit('SET_SPECIFICATIONS', specifications)
  },
  setDateRange({ commit }, date_range) {
    commit('SET_DATA_RANGE', date_range)
  },
  setOpenedCategory({ commit }, opened_category) {
    commit('SET_OPENED_CATEGORY', opened_category)
  },
  setProducts({ commit }, products) {
    commit('SET_PRODUCTS', products)
  },
  setChecked({ commit }, checked) {
    commit('SET_CHECKED', checked)
  },
  setAbcChecked({ commit }, checked) {
    commit('SET_CHECKED', checked)
  },
  setAnalyticsParams({ commit }, checked) {
    commit('SET_ANALYTICS_PARAMS', checked)
  },
  setAbcDateRange({ commit }, checked) {
    commit('SET_ABC_DATA_RANGE', checked)
  },
  setAbcProduct({ commit }, checked) {
    commit('SET_ABC_PRODUCTS', checked)
  },
  setAbcSuppliers({ commit }, checked) {
    commit('SET_ABC_SUPPLIERS', checked)
  },
  setAbcOpenedCategory({ commit }, checked) {
    commit('SET_ABC_OPENED_CATEGORY', checked)
  },
  setAbcBranches({ commit }, checked) {
    commit('SET_ABC_BRANCHES', checked)
  },
  setGroupBy({ commit }, checked) {
    commit('SET_GROUP_BY', checked)
  }
}
