<template>
  <svg
    width="414"
    height="44"
    viewBox="0 0 414 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.5627 42L32.0636 0.767099H9.39686L7.57441 18.3651C6.77709 26.2814 5.06855 32.0335 -0.000137329 34.7102L2.67658 43.139C12.3014 39.8928 15.8893 30.1541 16.9145 19.3902L17.9396 8.62641H23.4069L26.6532 42H36.5627Z"
      fill="#F17D00"
    />
    <path
      d="M73.231 21.3835C73.231 9.25287 68.1624 0.0836792 57.1707 0.0836792C46.1791 0.0836792 41.1104 9.25287 41.1104 21.3835C41.1104 33.5142 46.1791 42.6834 57.1707 42.6834C68.1624 42.6834 73.231 33.5142 73.231 21.3835ZM63.5493 21.3835C63.5493 29.3568 61.3851 34.1976 57.1707 34.1976C52.9563 34.1976 50.7921 29.3568 50.7921 21.3835C50.7921 13.4103 52.9563 8.56946 57.1707 8.56946C61.3851 8.56946 63.5493 13.4103 63.5493 21.3835Z"
      fill="#F17D00"
    />
    <path
      d="M109.598 30.6666C109.598 25.1993 105.725 21.7253 102.536 20.5862V20.4723C105.839 19.4472 108.686 15.9731 108.686 11.5879C108.686 4.92456 103.846 0.0836792 92.797 0.0836792C87.8422 0.0836792 83.0583 0.994905 79.7551 2.30479V40.4623C82.7166 41.7152 87.7852 42.6834 92.797 42.6834C104.016 42.6834 109.598 38.1842 109.598 30.6666ZM99.5173 12.613C99.5173 14.3785 98.8908 15.8023 97.9226 16.5996C97.0684 17.34 95.6446 17.7956 93.4804 17.7956H88.9243V7.77213C89.7786 7.48738 91.3162 7.25957 92.74 7.25957C97.6379 7.25957 99.5173 9.30983 99.5173 12.613ZM100.03 29.9832C100.03 33.799 97.8087 35.5075 92.797 35.5075C91.4301 35.5075 89.8355 35.2797 88.9243 34.9949V24.5728H93.5374C95.8154 24.5728 97.0684 25.0284 98.1504 25.7688C99.3464 26.6231 100.03 28.1608 100.03 29.9832Z"
      fill="#F17D00"
    />
    <path
      d="M147.135 42V0.767099H139.105L126.918 21.4974C125.551 23.7755 124.469 26.4522 124.469 26.4522H124.355C124.355 26.4522 124.412 23.4907 124.412 20.7571V0.767099H115.926V42H123.614L136.144 20.5862C137.568 18.1943 138.65 15.6314 138.65 15.6314H138.764C138.764 15.6314 138.65 18.479 138.65 21.1557V42H147.135Z"
      fill="#F17D00"
    />
    <path
      d="M213.672 42L208.717 0.767099H197.896L191.802 22.01C191.119 24.4589 190.606 27.4204 190.606 27.4204H190.492C190.492 27.4204 189.98 24.4589 189.296 22.01L183.203 0.767099H172.382L167.427 42H176.368L178.76 18.1943C179.045 15.5175 179.159 12.3852 179.159 12.3852H179.273C179.273 12.3852 179.842 15.4606 180.64 18.1943L187.588 42H193.511L200.459 18.1943C201.256 15.4606 201.826 12.3852 201.826 12.3852H201.94C201.94 12.3852 202.11 15.5175 202.395 18.1943L204.73 42H213.672Z"
      fill="#F17D00"
    />
    <path
      d="M250.303 21.3835C250.303 9.25287 245.235 0.0836792 234.243 0.0836792C223.252 0.0836792 218.183 9.25287 218.183 21.3835C218.183 33.5142 223.252 42.6834 234.243 42.6834C245.235 42.6834 250.303 33.5142 250.303 21.3835ZM240.622 21.3835C240.622 29.3568 238.458 34.1976 234.243 34.1976C230.029 34.1976 227.865 29.3568 227.865 21.3835C227.865 13.4103 230.029 8.56946 234.243 8.56946C238.458 8.56946 240.622 13.4103 240.622 21.3835Z"
      fill="#F17D00"
    />
    <path
      d="M301.03 42L296.075 0.767099H285.254L279.161 22.01C278.477 24.4589 277.965 27.4204 277.965 27.4204H277.851C277.851 27.4204 277.338 24.4589 276.655 22.01L270.561 0.767099H259.74L254.785 42H263.727L266.119 18.1943C266.403 15.5175 266.517 12.3852 266.517 12.3852H266.631C266.631 12.3852 267.201 15.4606 267.998 18.1943L274.946 42H280.869L287.817 18.1943C288.614 15.4606 289.184 12.3852 289.184 12.3852H289.298C289.298 12.3852 289.469 15.5175 289.753 18.1943L292.089 42H301.03Z"
      fill="#F17D00"
    />
    <path
      d="M329.225 42V33.9129H316.525V25.1993H327.858V17.283H316.525V8.85421H329.225V0.767099H307.014V42H329.225Z"
      fill="#F17D00"
    />
    <path
      d="M365.398 42V0.767099H355.717V17.283H345.636V0.767099H335.955V42H345.636V25.1993H355.717V42H365.398Z"
      fill="#F17D00"
    />
    <path
      d="M396.82 8.85421V0.767099H371.419V8.85421H379.279V42H388.96V8.85421H396.82Z"
      fill="#F17D00"
    />
    <path
      d="M413.553 0.767099H402.504L403.985 27.0217H412.129L413.553 0.767099ZM413.78 36.8174C413.78 33.6281 411.218 31.1222 408.028 31.1222C404.839 31.1222 402.276 33.6281 402.276 36.8174C402.276 40.0067 404.839 42.5695 408.028 42.5695C411.218 42.5695 413.78 40.0067 413.78 36.8174Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'SeizeMomentSvg'
  }
</script>
<style lang="scss" scoped>
  svg {
    margin-top: 7px;
  }
</style>
