<template>
  <svg
    class="green-line"
    width="651"
    height="24"
    viewBox="0 0 651 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 24V0H651V24H0Z"
      fill="#75B727"
    />
  </svg>
</template>
<script>
  export default {
    name: 'GreenLineSvg'
  }
</script>
<style lang="scss" scoped></style>
