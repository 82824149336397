import Vue from 'vue'
import VueNoty from 'vuejs-noty'
import NotyVanila from 'noty'
import 'vuejs-noty/dist/vuejs-noty.css'
// import { get_error } from '@/utils/error'

Vue.use(VueNoty, {
  timeout: 3000,
  theme: 'nest',
  progressBar: false,
  layout: 'bottomCenter',
  killer: true,
  animation: {
    open: null,
    close: null
  }
})

Vue.noty.done = () => Vue.noty.success('Выполнено')

// Vue.noty.server_error = (event) => {
//   Vue.noty.error(get_error(event).message)
// }

NotyVanila.overrideDefaults({
  timeout: 3000,
  theme: 'nest',
  progressBar: false,
  layout: 'bottomCenter',
  killer: true,
  animation: {
    open: null,
    close: null
  }
})

export { NotyVanila as Noty }
