<template>
  <svg
    width="81"
    height="46"
    viewBox="0 0 81 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.278198 10.2646H6.41809V13.9204C7.21488 12.6706 8.29287 11.6551 9.65209 10.8739C11.0113 10.0927 12.5189 9.70216 14.175 9.70216C17.0653 9.70216 19.5181 10.8348 21.5335 13.1002C23.5489 15.3655 24.5566 18.5214 24.5566 22.5678C24.5566 26.7236 23.5411 29.9576 21.5101 32.2698C19.479 34.5664 17.0184 35.7147 14.1281 35.7147C12.7533 35.7147 11.5034 35.4413 10.3786 34.8945C9.26932 34.3477 8.09758 33.4103 6.86335 32.0823V44.6199H0.278198V10.2646ZM6.79305 22.2866C6.79305 25.0831 7.34767 27.1532 8.45691 28.4968C9.56616 29.8248 10.9176 30.4888 12.5111 30.4888C14.0422 30.4888 15.3155 29.8794 16.331 28.6608C17.3465 27.4266 17.8542 25.4112 17.8542 22.6147C17.8542 20.0056 17.3309 18.0683 16.2841 16.8029C15.2374 15.5374 13.9406 14.9047 12.3939 14.9047C10.7848 14.9047 9.44899 15.5296 8.38661 16.7794C7.32424 18.0137 6.79305 19.8494 6.79305 22.2866Z"
      fill="black"
    />
    <path
      d="M26.033 10.2646H33.0399L38.9924 27.9344L44.8042 10.2646H51.6237L42.8356 34.2149L41.2655 38.5503C40.6875 40.0033 40.1328 41.1125 39.6017 41.878C39.0861 42.6436 38.4846 43.2607 37.7972 43.7294C37.1254 44.2137 36.2896 44.5886 35.2897 44.8542C34.3054 45.1198 33.1884 45.2526 31.9385 45.2526C30.673 45.2526 29.431 45.1198 28.2124 44.8542L27.6265 39.6986C28.6576 39.9017 29.5872 40.0032 30.4152 40.0032C31.9463 40.0032 33.079 39.5502 33.8133 38.644C34.5476 37.7535 35.11 36.613 35.5006 35.2226L26.033 10.2646Z"
      fill="black"
    />
    <path
      d="M74.5428 0.000183105H79.0189C78.8782 1.98432 78.3549 3.49196 77.4487 4.52308C76.5582 5.53859 74.3475 6.04634 70.8167 6.04634C70.3949 6.04634 69.973 6.03853 69.5512 6.02291H68.7076C66.6141 6.02291 64.9112 6.25725 63.5988 6.72595C62.2865 7.17902 61.2085 8.06954 60.3648 9.3975C59.5368 10.7255 59.1072 12.8658 59.0759 15.8186C60.0445 13.9907 61.2944 12.6237 62.8255 11.7175C64.3722 10.8114 66.2079 10.3583 68.3326 10.3583C71.5979 10.3583 74.3632 11.6316 76.6285 14.1782C78.8939 16.7091 80.0265 19.6463 80.0265 22.9896C80.0265 26.4736 78.8314 29.4654 76.441 31.9651C74.0663 34.4648 70.9573 35.7147 67.114 35.7147C64.4737 35.7147 62.1537 35.082 60.1539 33.8165C58.1541 32.551 56.7246 30.8246 55.8653 28.6374C55.0061 26.4345 54.5764 22.7944 54.5764 17.7168C54.5764 11.7488 55.6701 7.46024 57.8573 4.85117C60.0602 2.2421 63.8956 0.937572 69.3637 0.937572H72.6915C73.6132 0.937572 74.2304 0.625109 74.5428 0.000183105ZM61.2788 22.8959C61.2788 25.1613 61.8412 26.9579 62.9661 28.2859C64.1066 29.5982 65.497 30.2544 67.1375 30.2544C68.9029 30.2544 70.348 29.5748 71.4729 28.2156C72.6134 26.8407 73.1836 25.0753 73.1836 22.9193C73.1836 20.7165 72.6056 18.9432 71.4494 17.5997C70.309 16.2404 68.8951 15.5608 67.2078 15.5608C65.5673 15.5608 64.1691 16.2248 63.0129 17.5528C61.8568 18.8808 61.2788 20.6618 61.2788 22.8959Z"
      fill="black"
    />
  </svg>
</template>
<script>
  export default {
    name: 'RubSvg'
  }
</script>
<style lang="sscss" scoped></style>
