var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "line-through",
    attrs: {
      "width": "250",
      "height": "5",
      "viewBox": "0 0 250 5",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "250",
      "height": "5",
      "fill": _vm.gray ? '#808080' : '#008000'
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }