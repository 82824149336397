<template>
  <svg
    class="line-through"
    width="250"
    height="5"
    viewBox="0 0 250 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="250"
      height="5"
      :fill="gray ? '#808080' : '#008000'"
    />
  </svg>
</template>
<script>
  export default {
    name: 'LineThroughSvg',
    props: {
      gray: Boolean
    }
  }
</script>
<style lang="scss" scoped></style>
