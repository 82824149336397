const formatterOptions = {
  short: {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  },
  full: {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  },
  numeric: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  time: {
    hour: '2-digit',
    minute: '2-digit'
  },
  date: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  },
  datetime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }
}

function formatDate(dateSource, type = 'short', todayOnlyHours = false) {
  try {
    let date
    let displayFormat = typeof type === 'string' ? formatterOptions[type] : type

    if (dateSource) {
      date = dateSource
    } else if (typeof dateSource === 'string') {
      // safari fix
      date = new Date(dateSource.replace(' ', 'T'))
    } else {
      date = new Date(dateSource)
    }

    if (todayOnlyHours && date.toLocaleDateString() === new Date().toLocaleDateString()) {
      displayFormat = formatterOptions['time']
    }
    const formatter = new Intl.DateTimeFormat('ru', displayFormat)
    return formatter.format(date)
  } catch (e) {
    return ''
  }
}

export default formatDate
