var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "line__logo",
    attrs: {
      "width": "20",
      "height": "2",
      "viewBox": "0 0 20 2",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 1.9628V0.0146484H16.884L19.4815 1.9628H0Z",
      "fill": "#F17D00"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }