var render = function render(){
  var _vm$status$segment_a, _vm$status$segment_b, _vm$status$segment_c;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "anal__wiget",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.click.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "container_abc"
  }, [_c('div', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.data.name))]), _c('div', {
    staticClass: "dropdowns_abc"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('p', {
          staticClass: "dropdown-period mr-2"
        }, [_vm._v(" " + _vm._s(_vm.activeType.name) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.setActiveType('profit');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("Прибыль")]), _vm.activeType.key === 'profit' ? _c('img', {
    attrs: {
      "src": "/img/icons/Check.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.setActiveType('quantity');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("Количество")]), _vm.activeType.key === 'quantity' ? _c('img', {
    attrs: {
      "src": "/img/icons/Check.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.setActiveType('revenue');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("Выручка")]), _vm.activeType.key === 'revenue' ? _c('img', {
    attrs: {
      "src": "/img/icons/Check.svg",
      "alt": "check"
    }
  }) : _vm._e()])])], 1), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('p', {
          staticClass: "dropdown-period"
        }, [_c('work-period', {
          attrs: {
            "type_doc": "abc",
            "open": _vm.opened,
            "none": ""
          },
          on: {
            "set_open": function ($event) {
              _vm.opened = false;
            }
          }
        }), _vm._v(" " + _vm._s(_vm.getPeriodName) + " ")], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.setPeriod({
          period: '30days'
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("30 дней")]), _vm.dateRange.type === '30days' ? _c('img', {
    attrs: {
      "src": "/img/icons/Check.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.setPeriod({
          period: '90days'
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("90 дней")]), _vm.dateRange.type === '90days' ? _c('img', {
    attrs: {
      "src": "/img/icons/Check.svg",
      "alt": "check"
    }
  }) : _vm._e()])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.opened = true;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', [_vm._v("Выбрать период")]), _vm.dateRange.type === 'custom' ? _c('img', {
    attrs: {
      "src": "/img/icons/Check.svg",
      "alt": "check"
    }
  }) : _vm._e()])])], 1)], 1)]), _c('div', {
    staticClass: "content_numbers"
  }, [_c('div', {
    staticClass: "segment-container"
  }, [_c('div', {
    staticClass: "segment segment__a"
  }, [_vm._v("A")]), _c('div', {
    staticClass: "segment__price"
  }, [_vm._v(_vm._s(_vm.localeData(((_vm$status$segment_a = _vm.status.segment_a) === null || _vm$status$segment_a === void 0 ? void 0 : _vm$status$segment_a[_vm.activeType.key]) / _vm.getProc * 100)) + "%")])]), _c('div', {
    staticClass: "segment-container"
  }, [_c('div', {
    staticClass: "segment segment__b"
  }, [_vm._v("B")]), _c('div', {
    staticClass: "segment__price"
  }, [_vm._v(_vm._s(_vm.localeData(((_vm$status$segment_b = _vm.status.segment_b) === null || _vm$status$segment_b === void 0 ? void 0 : _vm$status$segment_b[_vm.activeType.key]) / _vm.getProc * 100)) + "%")])]), _c('div', {
    staticClass: "segment-container"
  }, [_c('div', {
    staticClass: "segment segment__c"
  }, [_vm._v("C")]), _c('div', {
    staticClass: "segment__price"
  }, [_vm._v(_vm._s(_vm.localeData(((_vm$status$segment_c = _vm.status.segment_c) === null || _vm$status$segment_c === void 0 ? void 0 : _vm$status$segment_c[_vm.activeType.key]) / _vm.getProc * 100)) + "%")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }