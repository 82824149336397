var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "green-line",
    attrs: {
      "width": "651",
      "height": "24",
      "viewBox": "0 0 651 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 24V0H651V24H0Z",
      "fill": "#75B727"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }