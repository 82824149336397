var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": `modal-remove-group${_vm.group.id}`,
      "hide-footer": "",
      "centered": "",
      "title": "Удалить группу"
    }
  }, [_c('div', {
    staticClass: "center"
  }, [_vm._v("Вы действительно хотите удалить группу " + _vm._s(_vm.group.name) + " ?")]), _c('div', {
    staticClass: "modal-footer mt-4"
  }, [_c('e-button', {
    staticClass: "center",
    attrs: {
      "variant": "outline-primary",
      "disabled": _vm.remove_group_is_loading
    },
    on: {
      "click": _vm.hideModalRemove
    }
  }, [_vm._v(" Отмена ")]), _c('e-button', {
    staticClass: "center",
    attrs: {
      "variant": "primary",
      "button_text": "Удалить",
      "disabled": _vm.remove_group_is_loading,
      "loading": _vm.remove_group_is_loading
    },
    on: {
      "click": _vm.removeGroup
    }
  }, [_vm._v(" Удалить ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }