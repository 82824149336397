import { IncomingSpecificationModel } from '@/models/incoming-spec.model'

export const mutations = {
  SET_CURRENT_DOCUMENT(state, { id, fixed, date, number }) {
    state.current_document.id = id
    state.current_document.fixed = fixed
    state.current_document.date = date
    state.current_document.number = number
  },

  CLEAR_CURRENT_DOCUMENT(state) {
    state.current_document.id = ''
    state.current_document.fixed = ''
    state.current_document.date = ''
    state.current_document.number = ''
  },

  SET_DATA_RANGE(state, date_range) {
    state.date_range = date_range
  },

  SET_SHOWING_INPUT_TOOLTIP(state, status) {
    state.showing_input_tooltip = status
  },
  SET_INCOMING_PAGINATION(state, pagination) {
    state.incoming.pagination = { ...state.incoming.pagination, ...pagination }
  },
  SET_INCOMING_DOCUMENT(state, document) {
    state.incoming.document = document
  },
  SET_INCOMING_SPECIFICATIONS(state, specs) {
    state.incoming.specs = specs
  },
  SET_INCOMING_SPECIFICATIONS_BY_ID(state, spec) {
    const index = state.incoming.specs.findIndex((el) => el.id === spec.id)
    if (index === -1) return
    const array = JSON.parse(JSON.stringify(state.incoming.specs))
    array[index] = {
      ...array[index],
      ...spec,
      product: { ...array[index].product, ...spec.product },
      original_product: { ...array[index].original_product, ...spec.original_product }
    }
    state.incoming.specs = array
  },
  SET_INCOMING_SPEC_BY_PARAM(state, param) {
    const index = state.incoming.specs.findIndex((el) => el.id === param.spec_id)
    if (index === -1) return
    const array = JSON.parse(JSON.stringify(state.incoming.specs))
    const spec = new IncomingSpecificationModel(state.incoming.specs[index], state?.incoming?.document?.operation)
    if (param.key === 'sum') spec.setSum(param.value)
    if (param.key === 'package') spec.setPackage(param.value)
    if (param.key === 'price') spec.setPrice(param.value)
    if (param.key === 'nds') spec.setNds(param.value)
    if (param.key === 'delta') spec.setDelta(param.value)
    if (param.key === 'retailPrice') spec.setRetailPrice(param.value)
    if (param.key === 'productionDate') spec.setProductionDate(param.value)
    if (param.key === 'markup') spec.setMarkup(param.value, state?.incoming?.document?.operation?.type)
    array[index] = spec
    state.incoming.specs = array
  },
  SET_INCOMING_CURSOR(state, cursor) {
    state.incoming.cursor = { ...state.incoming.cursor, ...cursor }
  }
}
