export class DeliveryModel {
  constructor(request = {}) {
    this.id = request?.id
    this.delivery_days = request?.delivery_days ?? []
    this.delivery_weeks = request?.delivery_weeks ?? []
    this.order_days = request?.order_days ?? []
    this.order_weeks = request?.order_weeks ?? []
    this.min_sum = request?.min_sum
    this.active = request?.active === false ? false : true
  }

  get getActive() {
    return this.active
  }

  get getMinSum() {
    return this.min_sum
  }

  get getDeliveryDays() {
    return this.delivery_days
  }

  get getDeliveryWeeks() {
    return this.delivery_weeks
  }

  get getDeliveryOrderDays() {
    return this.order_days
  }

  get getDeliveryOrderWeeks() {
    return this.order_weeks
  }

  setActive(bool) {
    this.active = bool
  }

  setOrderDays(data) {
    this.order_days = data
  }

  setOrderWeeks(data) {
    this.order_weeks = data
  }

  setDeliveryDays(data) {
    this.delivery_days = data
  }

  setDeliveryWeeks(data) {
    this.delivery_weeks = data
  }

  setMinSum(sum) {
    this.min_sum = sum
  }
}
