<template>
  <svg
    class="green-line"
    height="8"
    viewBox="0 0 200 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 7.39634V0.0230713H200V7.39634H0Z"
      fill="#75B727"
    />
  </svg>
</template>
<script>
  export default {
    name: 'GreenLine200on140Svsg'
  }
</script>
<style lang="scss" scoped></style>
