export default {
  namespaced: true,
  state: {
    current: {}
  },
  actions: {
    set_current({ commit }, document) {
      return commit('current', document)
    }
  },

  getters: {
    current: (state) => state.current || {}
  },

  mutations: {
    current: (state, value) => (state.current = value)
  }
}
