<template>
  <svg
    width="337"
    height="51"
    viewBox="0 0 337 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5143 40.3786L22.5528 32.4624C21.4707 33.2597 19.7622 34 17.7689 34C13.0419 34 9.96651 29.387 9.96651 21.186C9.96651 12.9849 13.0419 8.42883 17.7689 8.42883C19.7622 8.42883 21.4707 9.11224 22.5528 9.90956L25.5143 2.22111C23.521 0.911226 20.1608 0 16.5159 0C7.28979 0 0 7.00504 0 21.2999C0 35.5947 7.28979 42.5997 16.5159 42.5997C20.3317 42.5997 23.521 41.6885 25.5143 40.3786Z"
      fill="#F17D00"
    />
    <path
      d="M61.4876 0.683419H51.2363C50.5529 8.77053 48.5027 16.1173 45.9398 22.2111H45.8259C42.7505 15.9464 40.3016 8.82748 39.5043 0.683419H29.253C30.1643 11.2194 34.8343 21.5277 40.9851 30.2412L34.2648 41.9163H45.1425L51.6919 29.9565C56.305 21.5846 60.6903 10.593 61.4876 0.683419Z"
      fill="#F17D00"
    />
    <path
      d="M96.5844 41.9163V0.683419H67.1405V41.9163H76.8222V8.54273H86.9026V41.9163H96.5844Z"
      fill="#F17D00"
    />
    <path
      d="M126.468 41.9163V33.8292H113.768V25.1156H125.101V17.1994H113.768V8.77053H126.468V0.683419H104.257V41.9163H126.468Z"
      fill="#F17D00"
    />
    <path
      d="M162.243 13.4975C162.243 5.29649 156.434 0 145.727 0C140.772 0 136.501 0.797321 133.198 2.22111V41.9163H142.708V26.5394C143.449 26.6533 144.816 26.7672 145.955 26.7672C156.832 26.7672 162.243 21.129 162.243 13.4975ZM152.732 13.4975C152.732 17.3702 150.34 19.5344 145.499 19.5344C144.417 19.5344 143.335 19.4205 142.708 19.3066V7.68845C143.449 7.46065 144.645 7.23284 145.841 7.23284C150.397 7.23284 152.732 9.22615 152.732 13.4975Z"
      fill="#F17D00"
    />
    <path
      d="M214.578 35.253L211.502 33.8292V0.683419H201.821V33.8292H192.196V0.683419H182.514V41.9163H207.288L207.516 50.2312H214.122L214.578 35.253Z"
      fill="#F17D00"
    />
    <path
      d="M243.232 41.9163V33.8292H230.532V25.1156H241.865V17.1994H230.532V8.77053H243.232V0.683419H221.021V41.9163H243.232Z"
      fill="#F17D00"
    />
    <path
      d="M279.405 41.9163V0.683419H269.724V17.1994H259.643V0.683419H249.961V41.9163H259.643V25.1156H269.724V41.9163H279.405Z"
      fill="#F17D00"
    />
    <path
      d="M321.761 41.9163L308.435 0.683419H296.93L283.604 41.9163H293.741L296.133 33.5444H309.232L311.624 41.9163H321.761ZM307.41 26.5394H297.956L301.6 13.8392C302.284 11.4473 302.626 8.65663 302.626 8.65663H302.74C302.74 8.65663 303.081 11.4473 303.765 13.8392L307.41 26.5394Z"
      fill="#F17D00"
    />
    <path
      d="M336.681 0.683419H325.632L327.113 26.9381H335.257L336.681 0.683419ZM336.909 36.7337C336.909 33.5444 334.346 31.0386 331.156 31.0386C327.967 31.0386 325.404 33.5444 325.404 36.7337C325.404 39.923 327.967 42.4858 331.156 42.4858C334.346 42.4858 336.909 39.923 336.909 36.7337Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'SuperPriceSvg'
  }
</script>
<style lang="scss" scoped>
  svg {
    margin-top: 3px;
  }
</style>
