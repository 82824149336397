<template>
  <div class="events">
    <div class="dashboard__title">События</div>
    <div
      style="width: 311px; max-height: 517px"
      class="card events-body border-none d-flex justify-content-start align-items-stretch"
    >
      <!--      События отсутсвуют-->

      <!--      <div class="event__item critical">-->
      <!--        <div class="event__title">-->
      <!--          <img src="/img/icons/dashboard/fire.svg" alt="" />-->
      <!--          Критич. системное событие-->
      <!--        </div>-->
      <!--        <div class="event__text">-->
      <!--          Приезжает мерчендайзер от Молочника, проверить наличие!-->
      <!--        </div>-->
      <!--        <div class="event__footer">-->
      <!--          <div class="date">12.01.2021 12:04</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        v-if="!loading && !tasks.length"
        class="no-events"
      >
        <img
          src="/img/icons/smile.svg"
          alt="smile"
        />
        <p>Важных событий нет</p>
      </div>
      <event-item
        v-for="item in tasks"
        v-else
        :key="item.id"
        :item="item"
        :on_print_price="printPriceTags"
        @openModal="openModal"
        @refetch="$apollo.queries.tasks.refetch()"
      />
      <!--      <div class="event__item">-->
      <!--        <div class="event__title">-->
      <!--          <img src="/img/icons/dashboard/no-photo.svg" alt="" />-->
      <!--          Подготовьте товар к продаже-->
      <!--        </div>-->
      <!--        <div class="event__text">-->
      <!--          {{ item.message }}-->
      <!--        </div>-->
      <!--      </div>-->
      <!--        <div class="event__footer">-->
      <!--          <select>-->
      <!--            <option value="new">Новое</option>-->
      <!--            <option value="inprogress">В работе</option>-->
      <!--            <option value="done">Готово</option>-->
      <!--            <option value="canceled">Перенести</option>-->
      <!--          </select>-->
      <!--          <div class="date">12.01.2021 12:04</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="event__item">-->
      <!--        <div class="event__title">-->
      <!--          <img src="/img/icons/dashboard/no-photo.svg" alt="" />-->
      <!--          Скрытие системного события-->
      <!--        </div>-->
      <!--        <div class="event__checkbox">-->
      <!--          <input type="checkbox" />-->
      <!--          <div class="event__text">Не показывать это событие</div>-->
      <!--        </div>-->
      <!--        <div class="event__footer">-->
      <!--          <b-button variant="outline-primary">Отмена</b-button>-->
      <!--          <b-button variant="primary">Подтвердить</b-button>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="event__item critical">-->
      <!--        <div class="event__title">Какое-то пользоват. событие</div>-->
      <!--        <div class="event__text">Описание какого-то события</div>-->
      <!--        <div class="event__footer">-->
      <!--          <select>-->
      <!--            <option value="new">Новое</option>-->
      <!--            <option value="inprogress">В работе</option>-->
      <!--            <option value="done">Готово</option>-->
      <!--            <option value="canceled">Перенести</option>-->
      <!--          </select>-->
      <!--          <div class="date">12.01.2021 12:04</div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <comparison-modal />
    <modal-print-price-tags :products="products" />
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import EventItem from '@/views/dashboard/components/EventItem'
  import ModalPrintPriceTags from '@/views/core/modal/ModalPrintPriceTags'
  import ComparisonModal from '@/views/products/modal/ComparisonModal'
  import { ProductModel } from '@/models/product.model'

  export default {
    components: { ComparisonModal, EventItem, ModalPrintPriceTags },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      })
    },
    data: () => ({
      tasks: [],
      products: [],
      loading: true
    }),
    apollo: {
      tasks: {
        fetchPolicy: 'no-cache',
        query: require('../gql/readTask.gql'),
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.tasks = data?.tasks ?? []
          this.loading = false
        },
        subscribeToMore: {
          throttle: 1000,
          debounce: 1000,

          document: require('../gql/streamTask.gql'),
          // Variables passed to the subscription. Since we're using a function,
          // they are reactive
          variables() {
            return {
              branch: this.currentBranch.id
            }
          },
          // Mutate the previous result
          updateQuery(previousResult, { subscriptionData }) {
            // The previous result is immutable
            const newResult = {
              tasks: previousResult.tasks ? previousResult.tasks : []
            }
            // Add the question to the list
            const index = previousResult?.tasks?.findIndex((el) => el.id === subscriptionData.data?.streamTask.id)
            if (subscriptionData.data?.streamTask?.crud === 'delete') {
              newResult.tasks = newResult.tasks.filter((el) => el.id !== subscriptionData.data?.streamTask.id)
            } else if (index > -1) {
              newResult.tasks = previousResult.tasks.map((el, taskIndex) => {
                if (taskIndex === index) return subscriptionData.data?.streamTask
                return el
              })
            } else {
              newResult.tasks.push(subscriptionData.data.streamTask)
            }
            this.tasks = newResult.tasks
            return newResult
            // Here, return the new result from the previous with the new data
          }
        }
      }
    },
    methods: {
      openModal() {
        this.$bvModal.show('comparison-modal')
      },
      async printPriceTags() {
        const { data } = await this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: require(`../gql/ReadPriceTags.gql`),
          variables: {
            input: {
              // product: this.product_list.map((el) => el.product_id),
              branch: this.currentBranch.id
            }
          }
        })
        this.products = data?.ReadPriceTags?.map((el) => {
          return new ProductModel({ ...el?.Product, retailPrice: el?.value ?? el?.Product?.retailPrice })
        })
        this.$bvModal.show('modal-print-tags')
      }
    }
  }
</script>

<style scoped lang="scss">
  .no-events {
    height: 100%;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      margin-top: 11px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #888888;
    }
  }
  .events {
    height: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
  }
  .card {
    &.events-body {
      flex: 1;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #bcbcbc;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #e1e3ea;
        border-radius: 11px;
      }
    }
  }
</style>
