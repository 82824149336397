var render = function render(){
  var _vm$product, _vm$product$name, _vm$product2, _vm$product2$name, _vm$product3, _vm$product4, _vm$product4$retailPr, _vm$product4$retailPr2, _vm$product4$retailPr3, _vm$product5, _vm$product6, _vm$product6$retailPr, _vm$product6$retailPr2, _vm$product6$retailPr3, _vm$product7, _vm$product7$retailPr, _vm$product7$retailPr2, _vm$product7$retailPr3, _vm$product8, _vm$product8$measurem, _vm$product8$measurem2, _vm$product9, _vm$product9$package$, _vm$product9$package$2, _vm$product10, _vm$product10$country, _vm$product11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "containers"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "company"
  }, [_c('p', [_vm._v(_vm._s(_vm.companyName))])]), _c('div', {
    staticClass: "logo-block"
  }, [_c('left-line100on140-svg'), _c('fasol100on140-svg', {
    staticClass: "fasol"
  }), _c('right-line100on140-svg'), _c('green-line100on140-svg')], 1)]), _c('div', {
    staticClass: "name-block"
  }, [_c('b', [_vm._v(_vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$name = _vm$product.name) === null || _vm$product$name === void 0 ? void 0 : _vm$product$name.length) >= 43 ? ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$name = _vm$product2.name) === null || _vm$product2$name === void 0 ? void 0 : _vm$product2$name.slice(0, 43)) + '...' : (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name))])]), _c('div', {
    staticClass: "footer-block"
  }, [_c('div', {
    staticClass: "price"
  }, [_c('b', [_vm._v(_vm._s(1 >= ((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$retailPr = _vm$product4.retailPrice) === null || _vm$product4$retailPr === void 0 ? void 0 : (_vm$product4$retailPr2 = _vm$product4$retailPr.toFixed(2)) === null || _vm$product4$retailPr2 === void 0 ? void 0 : (_vm$product4$retailPr3 = _vm$product4$retailPr2.toString().split('.')) === null || _vm$product4$retailPr3 === void 0 ? void 0 : _vm$product4$retailPr3.length) ? ((_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : _vm$product5.retailPrice) + 'р.' + '00к.' : ((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : (_vm$product6$retailPr = _vm$product6.retailPrice) === null || _vm$product6$retailPr === void 0 ? void 0 : (_vm$product6$retailPr2 = _vm$product6$retailPr.toFixed(2)) === null || _vm$product6$retailPr2 === void 0 ? void 0 : (_vm$product6$retailPr3 = _vm$product6$retailPr2.toString()) === null || _vm$product6$retailPr3 === void 0 ? void 0 : _vm$product6$retailPr3.split('.')[0]) + 'р.' + ((_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : (_vm$product7$retailPr = _vm$product7.retailPrice) === null || _vm$product7$retailPr === void 0 ? void 0 : (_vm$product7$retailPr2 = _vm$product7$retailPr.toFixed(2)) === null || _vm$product7$retailPr2 === void 0 ? void 0 : (_vm$product7$retailPr3 = _vm$product7$retailPr2.toString()) === null || _vm$product7$retailPr3 === void 0 ? void 0 : _vm$product7$retailPr3.split('.')[1]) + 'к.'))])]), _c('div', {
    staticClass: "barcode-block"
  }, [_c('div', {
    staticClass: "price-for"
  }, [_c('b', [_vm._v("Цена:")]), _c('p', [_vm._v("за " + _vm._s((_vm$product8 = _vm.product) === null || _vm$product8 === void 0 ? void 0 : (_vm$product8$measurem = _vm$product8.measurement) === null || _vm$product8$measurem === void 0 ? void 0 : (_vm$product8$measurem2 = _vm$product8$measurem.name) === null || _vm$product8$measurem2 === void 0 ? void 0 : _vm$product8$measurem2.slice(0, 2)))])]), _c('div', {
    staticStyle: {
      "width": "25px",
      "height": "12px"
    }
  }, [_vm.product.package ? _c('barcode-generator', {
    attrs: {
      "width": 25,
      "height": 12,
      "barcode": (_vm$product9 = _vm.product) === null || _vm$product9 === void 0 ? void 0 : (_vm$product9$package$ = _vm$product9.package[0]) === null || _vm$product9$package$ === void 0 ? void 0 : (_vm$product9$package$2 = _vm$product9$package$.barcode) === null || _vm$product9$package$2 === void 0 ? void 0 : _vm$product9$package$2.barcode
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "footer"
  }, [_c('p', [_vm._v(_vm._s(_vm.date))]), _c('p', [_vm._v(_vm._s((_vm$product10 = _vm.product) === null || _vm$product10 === void 0 ? void 0 : (_vm$product10$country = _vm$product10.country) === null || _vm$product10$country === void 0 ? void 0 : _vm$product10$country.name))]), _c('p', [_vm._v("Арт: " + _vm._s((_vm$product11 = _vm.product) === null || _vm$product11 === void 0 ? void 0 : _vm$product11.articul))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }