import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
import { SerialModel } from '@/models/serial.model'
import { ProductModel } from '@/models/product.model'
import { TaxRateModel } from '@/models/tax-rate.model'
import { PackageModel } from '@/models/package.model'
import { OperationModel } from '@/models/operation.model'
const bigDecimal = require('js-big-decimal')
export class IncomingSpecificationModel {
  constructor(request = {}, operation) {
    this.cost = request?.cost
    this.delta = request?.delta
    this.document_head = new IncomingHeadModel(request?.document_head)
    this.id = request?.id
    this.order = request?.order
    this.price = request?.price
    this.product = new ProductModel(request?.product)
    this.original_product = new ProductModel(request?.original_product)
    this.nds = new TaxRateModel(request?.nds || this.product.taxRate)
    this.serial_count = request?.serial_count
    this.requestDelta = request?.requestDelta
    this.requestPrice = request?.requestPrice
    this.retailPrice = request?.retailPrice || 0
    this.serial = new SerialModel(request?.serial)
    this.operation = new OperationModel(operation)
    this.sum = request?.sum || this.price * this.delta || 0
    this.cost = request?.cost || 0
    this.production_date = request?.production_date
    this.packages = request?.packages ? new PackageModel(request?.packages) : new PackageModel({ id: '' })
  }

  setSum = (val) => {
    this.sum = val
    this.price = Number((this.sum / (this.delta * (this.packages?.value ?? 1))).toFixed(2))
    if (this.operation?.type !== 'commission')
      if (this.product.markup) {
        this.setMarkup(this.product.markup)
      } else if (!this.product.markup && this.product.category?.markup?.value) {
        this.setMarkup(this.product.category?.markup?.value, this.product.category?.markup?.round_to)
      }
  }
  setPackage = (val) => {
    if (val.id === '') this.packages = null
    this.packages = new PackageModel(val)
    this.setSum(this.sum)
  }

  setPrice = (val) => {
    this.price = val
    this.sum = +(this.price * this.delta * (this.packages?.value ?? 1)).toFixed(2)
  }

  setNds = (val) => {
    this.nds = val
  }

  setDelta = (val) => {
    this.delta = val
    this.sum = +(this.price * this.delta * (this.packages?.value ?? 1)).toFixed(2)
  }

  setRetailPrice = (val) => {
    this.retailPrice = val
  }

  setProductionDate = (val) => {
    this.production_date = new Date(val)
  }

  setMarkup = (val) => {
    if (!val) return
    if (val === this.getMarkup()) return
    //price = retail_price * (1 - markup/100 )
    //retail_price = price * (markup/100 )
    if (this.operation?.type === 'commission') {
      this.setPrice(
        +bigDecimal.divide(
          bigDecimal.floor(
            bigDecimal.multiply(bigDecimal.multiply(this.retailPrice, bigDecimal.divide(100 - val, 100, 10)), 100)
          ),
          100,
          10
        )
      )
    } else {
      const round_to =
        (this.product?.markup ? this.product?.round_to : null) || this.product?.category?.markup?.round_to || 'none'
      val = val + 100
      if (round_to === 'none')
        this.retailPrice = +bigDecimal.divide(
          bigDecimal.floor(bigDecimal.multiply(bigDecimal.multiply(this.price, bigDecimal.divide(val, 100, 10)), 100)),
          100,
          10
        )
      else if (round_to === 'half') this.retailPrice = this.round((this.price * (val / 100)).toFixed(2))
      else if (round_to === 'rubles')
        this.retailPrice = +bigDecimal.ceil(bigDecimal.multiply(this.price, bigDecimal.divide(val, 100, 10)))
    }
  }

  get taxRateSum() {
    return Number((this.sum * (1 - 1 / (1 + this.nds.value) || 0)).toFixed(2))
  }

  get markup() {
    return this.getMarkup()
  }
  getMarkup() {
    if (this.operation?.type === 'commission') return Number(((1 - this.price / this.retailPrice) * 100).toFixed(2))
    else return Number((((this.retailPrice - this.price) / this.price) * 100).toFixed(2))
  }
  get input() {
    return {
      id: this.id ?? null,
      product: this.product.id,
      delta: +this.delta,
      price: +this.price,
      retailPrice: +this.retailPrice,
      nds: this.nds.id,
      sum: +this.sum,
      packages: this.packages?.id ?? null,
      production_date: this.production_date
    }
  }

  round(number) {
    number = bigDecimal.multiply(number, 100)

    number = bigDecimal.multiply(bigDecimal.ceil(bigDecimal.divide(number, 50, 100)), 50)

    number = bigDecimal.divide(number, 100, 100)

    return +number
  }

  get edo_input() {
    return {
      id: this.id ?? null,
      delta: +this.delta,
      price: +this.price,
      retailPrice: +this.retailPrice,
      nds: this.nds.id,
      sum: +this.sum,
      packages: this.packages?.id ?? null
    }
  }

  get packages_list() {
    return [
      {
        name: this.product.measurement.name,
        id: ''
      },
      ...(this.product?.package?.filter((el) => el.value && el.value !== 1) || [])
    ]
  }
}
