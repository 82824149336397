<template>
  <b-modal
    id="core-binding-modal"
    size="xl"
    centered
    hide-footer
    @shown="shownModal"
  >
    <template #modal-header>
      <div>
        <h5>
          Привязка товара {{ product?.articul }} |
          {{ product?.name ?? product?.productItem_name ?? '-' }}
        </h5>
        <!-- <div>{{ product.info }}</div> -->
        <div class="title-description">
          <div>{{ modal_binding.supplier_name }}</div>
          <div
            v-if="product.info"
            class="delimiter"
          ></div>
          <div>{{ product.info }}</div>
        </div>
      </div>
      <b-button
        class="close"
        @click="hideModal"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>

    <div
      v-if="getInfoBindingModal"
      class="ext-caption mb-3"
    >
      <div>
        <img
          src="/img/icons/info-icon-33.svg"
          class="mr-2"
        />
        <span class="text-info">Чтобы привязать товар необходимо нажать на товарную карточку или добавить новый </span>
      </div>
      <img
        class="close-help cursor"
        src="/img/icons/blue-close.svg"
        alt="close"
        @click="closeInfo"
      />
    </div>
    <div
      class="mb-3"
      style="
        display: flex;
        flex-direction: row;

        overflow: hidden;
      "
    >
      <binding-products-menu style="min-height: 500px; min-width: 25%; height: 70vh" />
      <binding-product-table
        ref="bindingProductTable"
        style="display: flex; margin-bottom: 0 !important; min-height: 600px; flex: 1"
        :product="product"
        :document_head="modal_binding?.spec_id"
        :target_product="modal_binding.target_product"
        :type="modal_binding?.type"
        @bind="bind"
      />
    </div>
  </b-modal>
</template>

<script>
  import BindingProductTable from '@/views/operational-processes/components/incoming/binding/BindingProductTable'
  import BindingProductsMenu from '@/views/operational-processes/components/incoming/binding/BindingProductsMenu'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { ProductModel } from '@/models/product.model'
  import { EventBus } from '@/main'

  export default {
    name: 'CoreBindingModal',

    components: { BindingProductsMenu, BindingProductTable },

    props: {
      document_head: {
        type: String,
        default: () => ''
      }
    },

    data() {
      return {}
    },

    computed: {
      ...mapGetters({
        modal_binding: 'core/getBindingModal',
        getInfoBindingModal: 'core/getInfoBindingModal'
      }),
      product() {
        return new ProductModel(this.modal_binding.product)
      }
    },

    methods: {
      ...mapActions({
        setIncomingSpecificationById: 'operprocess/setIncomingSpecificationById'
      }),
      ...mapMutations({
        SET_INFO_BINDING_MODAL: 'core/SET_INFO_BINDING_MODAL'
      }),

      shownModal() {
        const input = this.$refs.bindingProductTable.$refs.inputBindingProduct.$refs.input
        input.select()
      },

      hideModal() {
        this.$bvModal.hide('core-binding-modal')
      },

      closeInfo() {
        this.SET_INFO_BINDING_MODAL(false)
      },

      bind(spec) {
        if (this.modal_binding?.type === 'mercury') {
          EventBus.$emit('refetchMercuryVsds')
        } else if (this.modal_binding?.type === 'crp-tech') {
          EventBus.$emit('refetchMarkedProducts')
        } else {
          if (spec) this.setIncomingSpecificationById(spec)
          EventBus.$emit('refetchIncomingHead')
        }
      }
    }
  }
</script>

<style scoped>
  :deep .modal-dialog {
    height: 200px !important;
  }
  :deep .modal {
    overflow-y: clip !important;
  }

  .title-description {
    display: flex;
    align-items: center;
  }
  .delimiter {
    height: 12px;
    width: 1px;
    background-color: var(--text-placeholder-gray-300, #bcbcbc);
    margin-left: 8px;
    margin-right: 8px;
  }
  .ext-caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    align-items: center;
    background: #e6f0ff;
    border-radius: 2px;
    background: var(--blue-50, #eef9ff);
    border-left: 3px solid var(--blue-200, #99daff);
  }
  .text-info {
    color: var(--text-primary-gray-700, #313131) !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
</style>
