export class IntegrationModel {
  constructor(request = {}) {
    this.id = request.id
    this.type = request.type
    this.login = request.login
    this.password = request.password
    this.int_branches = request.int_branches
    this.ext_branches = request.ext_branches
    this.int_roles = request.int_roles
    this.ext_roles = request.ext_roles
    this.branch_mapping = request.branch_mapping
    this.role_mapping = request.role_mapping
    this.tags = request.tags
  }
}
