var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "operational-processes"
  }, [_c('div', {
    staticClass: "dashboard__title"
  }, [_vm._v("Операционные процессы")]), _c('div', {
    staticClass: "card border-none d-flex"
  }, [_c('div', {
    staticClass: "op-block"
  }, [_c('div', {
    staticClass: "op-card mr16"
  }, [_c('div', {
    staticClass: "op",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.goToIncoming.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "op__logo income-goods"
  }, [_vm.count_incoming > 0 ? _c('span', [_vm._v(_vm._s(_vm.count_incoming))]) : _vm._e()]), _c('div', {
    staticClass: "op__title"
  }, [_vm._v("Приёмка товара")]), _c('div', {
    staticClass: "op__text"
  }, [_vm._v("Регистрируйте документы прихода от поставщиков")])])]), _c('div', {
    staticClass: "op-card"
  }, [_c('div', {
    staticClass: "op",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.goToOutcoming.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "op__logo return"
  }, [_vm.count_outgoing > 0 ? _c('span', [_vm._v(_vm._s(_vm.count_outgoing))]) : _vm._e()]), _c('div', {
    staticClass: "op__title"
  }, [_vm._v("Списание/Возврат")]), _c('div', {
    staticClass: "op__text"
  }, [_vm._v("Проводите документы по просроченным товарам")])])])]), _c('div', {
    staticClass: "op-block"
  }, [_c('div', {
    staticClass: "op-card mr16"
  }, [_c('div', {
    staticClass: "op",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.goMercury.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "op__logo merkuriy"
  }, [_vm.count_mercury > 0 ? _c('span', [_vm._v(_vm._s(_vm.count_mercury))]) : _vm._e()]), _c('div', {
    staticClass: "op__title"
  }, [_vm._v("Меркурий")]), _c('div', {
    staticClass: "op__text"
  }, [_vm._v("Обрабатывайте входящие ВСД")])])]), _c('div', {
    staticClass: "op-card"
  }, [_c('div', {
    staticClass: "op",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.goOrder.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "op__logo goods"
  }, [_vm.count_order > 0 ? _c('span', [_vm._v(_vm._s(_vm.count_order))]) : _vm._e()]), _c('div', {
    staticClass: "op__title"
  }, [_vm._v("Автозаказ")]), _c('div', {
    staticClass: "op__text"
  }, [_vm._v("Просматривайте и заказывайте предложенные системой товары")])])])]), _c('div', {
    staticClass: "op-block"
  }, [_c('div', {
    staticClass: "op-card mr16"
  }, [_c('div', {
    staticClass: "op",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.goRec.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "op__logo goods"
  }, [_vm.count_rec > 0 ? _c('span', [_vm._v(_vm._s(_vm.count_rec))]) : _vm._e()]), _c('div', {
    staticClass: "op__title"
  }, [_vm._v("Рекомендации")]), _c('div', {
    staticClass: "op__text"
  }, [_vm._v("Ознакомьтесь с действиями для корректного ведения учёта")])])]), _c('div', {
    staticClass: "op-card"
  }, [_c('div', {
    staticClass: "op",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.goPlan.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "op__logo goods"
  }, [_vm.count_plan > 0 ? _c('span', [_vm._v(_vm._s(_vm.count_plan))]) : _vm._e()]), _c('div', {
    staticClass: "op__title"
  }, [_vm._v("План платежей")]), _c('div', {
    staticClass: "op__text"
  }, [_vm._v("Узнавайте по каким поставщикам ожидается платеж")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }