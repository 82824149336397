export class PaymentModel {
  constructor(request = {}) {
    this.id = request?.id
    this.payment_term = request?.payment_term ? request?.payment_term : 'AFTER_SELL'
    this.payment_due_date = request?.payment_due_date
    this.active = request?.active === false ? false : true
  }

  get getActive() {
    return this.active
  }

  get getPaymentTerm() {
    return this.payment_term
  }

  get getPaymentDueDate() {
    return this.payment_due_date
  }

  setPaymentTerm(data) {
    this.payment_term = data
  }

  setPaymentDueDate(data) {
    this.payment_due_date = data
  }

  setActive(bool) {
    this.active = bool
  }
}
