import View from '@/views/layouts/layout-view'

export default {
  path: '/products',
  name: 'products',
  component: View,
  redirect: '/products/list',
  children: [
    {
      path: 'list',
      name: 'products.list',
      component: () => import('@/views/products/pages/ProductList'),
      meta: { title: 'Товары' }
    },
    {
      path: 'add',
      name: 'products.new',
      component: () => import('@/views/products/pages/ProductAddEdit'),
      meta: {
        title: 'Новый товар',
        parrent: 'products.list'
      }
    },
    {
      path: 'edit/:id',
      name: 'products.edit',
      component: () => import('@/views/products/pages/ProductAddEdit'),
      meta: {
        title: '',
        parrent: 'products.list',
        isEdit: true
      }
    },
    {
      path: 'history',
      name: 'products.history',
      component: () => import('@/views/products/pages/ProductHistory'),
      meta: {
        title: 'История изменений',
        parrent: 'products.list'
      }
    }
  ]
}
