import { EntityModel } from '@/models/entity.model'

export const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  SET_DOCUMENT(state, doc) {
    state.document = doc
  },
  UPDATE_DOCUMENT(state, doc) {
    state.document = { ...state.document, ...doc }
  },
  SET_ENTITY(state, entity) {
    state.entity = new EntityModel(entity)
  }
}
