var render = function render(){
  var _vm$fields$filter, _vm$fields, _vm$fields$filter$map, _vm$fields$filter2, _vm$fields$filter2$ma, _vm$items, _vm$fields$filter$map2, _vm$fields$filter3, _vm$fields$filter3$ma, _vm$items2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "resizeable-table"
    }
  }, [_c('div', {
    staticClass: "p-0",
    class: _vm.inside_card ? 'card' : 'resizeable-table',
    staticStyle: {
      "flex": "1",
      "height": "100%",
      "overflow": "hidden"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('sort-table-modal', {
    attrs: {
      "fields": _vm.fields,
      "default_fields": _vm.default_fields,
      "no_drag": _vm.no_drag,
      "id_modal": _vm.sort_table_modal_id
    },
    on: {
      "set-default": _vm.setDefault,
      "change": _vm.changeFields
    }
  }), _vm._t("table_header"), _c('div', {
    staticClass: "table-inner",
    staticStyle: {
      "height": "100%"
    }
  }, [_c('b-table-simple', {
    class: 'table-docs ' + this.table_name + ' table-fixed',
    attrs: {
      "responsive": "",
      "hover": "",
      "fixed": "",
      "sticky-header": _vm.height,
      "bordered": "",
      "no-border-collapse": "",
      "table-class": 'resizable-table ' + this.table_name
    }
  }, [_c('b-thead', [_c('b-tr', _vm._l(_vm.fields.filter(function (el) {
    return el.checked;
  }), function (td, index) {
    var _td$children, _td$children$filter, _td$children2, _td$children2$filter, _td$hint;

    return _c('b-th', {
      key: index,
      class: (_vm.first_fixed && index === 0 ? 'first-fixed ' : '') + (td.class + ' field-column unselectable text-center text-nowrap ' + td.sortable ? 'cursor' : ''),
      style: {
        'min-width': td.width + 'px',
        width: td.width + 'px'
      },
      attrs: {
        "colspan": ((_td$children = td.children) === null || _td$children === void 0 ? void 0 : (_td$children$filter = _td$children.filter(function (el) {
          return el.checked;
        })) === null || _td$children$filter === void 0 ? void 0 : _td$children$filter.length) || 1,
        "rowspan": (_td$children2 = td.children) !== null && _td$children2 !== void 0 && (_td$children2$filter = _td$children2.filter(function (el) {
          return el.checked;
        })) !== null && _td$children2$filter !== void 0 && _td$children2$filter.length ? 1 : 2
      }
    }, [_c('div', {
      staticClass: "td-div",
      staticStyle: {
        "display": "flex",
        "flex-direction": "row",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_vm._t('head_' + td.key, function () {
      return [_c('div', {
        staticClass: "td-inner",
        staticStyle: {
          "display": "flex",
          "flex-direction": "row",
          "align-items": "center",
          "justify-content": "center"
        },
        on: {
          "click": function ($event) {
            return _vm.change_sort(td);
          }
        }
      }, [_c('span', [_vm._v(_vm._s(td.label))]), td.sortable && !td.sort ? _c('img', {
        staticClass: "ml-1 no-hover",
        attrs: {
          "src": "/img/icons/table/sort_none.svg"
        }
      }) : _vm._e(), td.sortable && !td.sort ? _c('img', {
        staticClass: "ml-1 sort-hover",
        attrs: {
          "src": "/img/icons/table/sort_hover.svg"
        }
      }) : _vm._e(), td.sort ? _c('img', {
        staticClass: "cursor img-sorting ml-1",
        staticStyle: {
          "transition": "150ms"
        },
        style: td.sort === 'ASC' ? 'transform:rotate(180deg)' : '',
        attrs: {
          "src": "/img/icons/table/hover_active.svg",
          "alt": ""
        }
      }) : _vm._e()])];
    }), (_td$hint = td.hint) !== null && _td$hint !== void 0 && _td$hint.length ? _c('span', {
      staticClass: "ml-2"
    }, [_c('img', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      attrs: {
        "src": "/img/icons/icon-info.svg",
        "title": td.hint,
        "height": "12",
        "width": "12"
      }
    })]) : _vm._e(), _vm.resizable ? _c('div', {
      staticClass: "resize-field",
      on: {
        "mousedown": function (el) {
          return _vm.mouseDown(el, td);
        }
      }
    }, [_vm._v("   ")]) : _vm._e()], 2)]);
  }), 1), (_vm$fields$filter = _vm.fields.filter(function (el) {
    var _el$children, _el$children$filter;

    return el.checked && ((_el$children = el.children) === null || _el$children === void 0 ? void 0 : (_el$children$filter = _el$children.filter(function (el) {
      return el.checked;
    })) === null || _el$children$filter === void 0 ? void 0 : _el$children$filter.length);
  })) !== null && _vm$fields$filter !== void 0 && _vm$fields$filter.length ? _c('b-tr', _vm._l(_vm.fields.filter(function (el) {
    var _el$children2, _el$children2$filter;

    return el.checked && ((_el$children2 = el.children) === null || _el$children2 === void 0 ? void 0 : (_el$children2$filter = _el$children2.filter(function (el) {
      return el.checked;
    })) === null || _el$children2$filter === void 0 ? void 0 : _el$children2$filter.length);
  }).map(function (el) {
    return el.children.filter(function (el) {
      return el.checked;
    });
  }).flat(), function (tr, index) {
    return _c('b-th', {
      key: index,
      staticClass: "center text-nowrap children",
      staticStyle: {
        "border-right": "1px solid #e1e1e1",
        "overflow": "hidden",
        "border-top": "none !important"
      }
    }, [_vm._t('children_' + tr.key, function () {
      return [_c('div', {
        on: {
          "click": function ($event) {
            return _vm.change_sort(tr);
          }
        }
      }, [_vm._v(" " + _vm._s(tr.label) + " "), tr.sortable && !tr.sort ? _c('img', {
        staticClass: "ml-1 no-hover",
        attrs: {
          "src": "/img/icons/table/sort_none.svg"
        }
      }) : _vm._e(), tr.sort ? _c('img', {
        staticClass: "cursor img-sorting ml-1",
        staticStyle: {
          "transition": "150ms"
        },
        style: tr.sort === 'ASC' ? 'transform:rotate(180deg)' : '',
        attrs: {
          "src": "/img/icons/table/hover_active.svg",
          "alt": ""
        }
      }) : _vm._e()])];
    })], 2);
  }), 1) : _vm._e()], 1), _vm.top_sticky_row && !_vm.busy ? _c('b-thead', {
    staticStyle: {
      "position": "sticky",
      "top": "54px"
    },
    attrs: {
      "id": "top-sticky-row"
    }
  }, _vm._l(1, function (tr, tr_index) {
    return _c('b-tr', {
      key: tr_index,
      attrs: {
        "id": tr.id
      }
    }, _vm._l(_vm.checkedFields, function (field, index) {
      return _c('b-td', {
        key: index
      }, [_vm._t('top-sticky-row_' + field.key, function () {
        return [_vm._v(" " + _vm._s(tr[field.key]) + " ")];
      }, {
        "value": tr[field.key],
        "item": tr,
        "index": tr_index
      })], 2);
    }), 1);
  }), 1) : _vm._e(), _c('b-tbody', [_vm.busy ? _c('b-tr', [_c('td', {
    attrs: {
      "colspan": ((_vm$fields = _vm.fields) === null || _vm$fields === void 0 ? void 0 : _vm$fields.length) + ((_vm$fields$filter$map = (_vm$fields$filter2 = _vm.fields.filter(function (el) {
        var _el$children3, _el$children3$filter;

        return el.checked && ((_el$children3 = el.children) === null || _el$children3 === void 0 ? void 0 : (_el$children3$filter = _el$children3.filter(function (el) {
          return el.checked;
        })) === null || _el$children3$filter === void 0 ? void 0 : _el$children3$filter.length);
      })) === null || _vm$fields$filter2 === void 0 ? void 0 : (_vm$fields$filter2$ma = _vm$fields$filter2.map(function (el) {
        return el.children.filter(function (el) {
          return el.checked;
        });
      }).flat()) === null || _vm$fields$filter2$ma === void 0 ? void 0 : _vm$fields$filter2$ma.length) !== null && _vm$fields$filter$map !== void 0 ? _vm$fields$filter$map : 0) - (_vm.table_name == 'order-list' ? 1 : 0)
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Загрузка..."
    }
  }), _c('div', {
    staticClass: "mt-2"
  }, [_c('strong', [_vm._v("Загрузка...")])])], 1)])]) : _vm._l(_vm.items, function (tr, tr_index) {
    return _c('b-tr', {
      key: tr_index,
      staticClass: "cursor",
      style: {
        'background-color': tr.rowTableColor ? tr.rowTableColor : ''
      },
      attrs: {
        "id": tr.id
      },
      on: {
        "click": function ($event) {
          return _vm.onRowClick(tr);
        }
      }
    }, _vm._l(_vm.checkedFields, function (field, index) {
      var _class, _field$children, _field$children$filte;

      return _c('b-td', {
        key: index,
        staticClass: "text-nowrap",
        class: (_class = {
          'position-relative': field.key === 'id',
          'first-fixed': _vm.first_fixed && index === 0
        }, _class[field.class] = !!field.class, _class),
        staticStyle: {
          "overflow": "hidden"
        },
        style: {
          'background-color': tr.rowTableColor ? tr.rowTableColor : ''
        },
        attrs: {
          "colspan": (field.default_colspan || 0) + ((_field$children = field.children) === null || _field$children === void 0 ? void 0 : (_field$children$filte = _field$children.filter(function (el) {
            return el.checked;
          })) === null || _field$children$filte === void 0 ? void 0 : _field$children$filte.length) || 1
        }
      }, [_vm._t('body_' + field.key, function () {
        return [field.slot_builder_name ? [_vm._t(field.slot_builder_name, function () {
          return [_vm._v(" " + _vm._s(tr[field.key]) + " ")];
        }, {
          "parent": field.parent,
          "field": field,
          "item": tr
        })] : [_vm._v(" " + _vm._s(tr[field.key]) + " ")]];
      }, {
        "value": tr[field.key],
        "item": tr,
        "index": tr_index
      })], 2);
    }), 1);
  }), ((_vm$items = _vm.items) === null || _vm$items === void 0 ? void 0 : _vm$items.length) === 0 && !_vm.busy ? _c('b-tr', [_c('td', {
    attrs: {
      "colspan": _vm.fields.length + ((_vm$fields$filter$map2 = (_vm$fields$filter3 = _vm.fields.filter(function (el) {
        var _el$children4, _el$children4$filter;

        return el.checked && ((_el$children4 = el.children) === null || _el$children4 === void 0 ? void 0 : (_el$children4$filter = _el$children4.filter(function (el) {
          return el.checked;
        })) === null || _el$children4$filter === void 0 ? void 0 : _el$children4$filter.length);
      })) === null || _vm$fields$filter3 === void 0 ? void 0 : (_vm$fields$filter3$ma = _vm$fields$filter3.map(function (el) {
        var _el$children5;

        return (_el$children5 = el.children) === null || _el$children5 === void 0 ? void 0 : _el$children5.filter(function (el) {
          return el.checked;
        });
      }).flat()) === null || _vm$fields$filter3$ma === void 0 ? void 0 : _vm$fields$filter3$ma.length) !== null && _vm$fields$filter$map2 !== void 0 ? _vm$fields$filter$map2 : 0) - (_vm.table_name == 'order-list' ? 1 : 0)
    }
  }, [_c('p', {
    staticClass: "center mt-3"
  }, [_vm._v(_vm._s(_vm.empty_text))])])]) : _vm._e(), _vm.show_product_search && !_vm.scrollExist ? _c('b-tr', [_c('b-td', {
    attrs: {
      "colspan": _vm.fields.filter(function (el) {
        return el.checked;
      }).length
    }
  }, [_vm._t("productSearch")], 2)], 1) : _vm._e(), (_vm$items2 = _vm.items) !== null && _vm$items2 !== void 0 && _vm$items2.length && _vm.foot_row ? _c('div', {
    staticClass: "info-table"
  }, [_vm._t("infoTable")], 2) : _vm._e()], 2), _vm.foot && !_vm.busy ? _c('b-tfoot', _vm._l(_vm.foot, function (tr, tr_index) {
    return _c('b-tr', {
      key: tr_index,
      staticClass: "foot",
      attrs: {
        "id": tr.id
      }
    }, _vm._l(_vm.checkedFields, function (field, index) {
      var _field$children2, _field$children2$filt;

      return _c('b-td', {
        key: index,
        staticClass: "text-nowrap",
        class: _vm.first_fixed && index === 0 ? 'first-fixed foot ' : '',
        staticStyle: {
          "overflow": "hidden",
          "border-right": "none",
          "border-left": "none",
          "border-top": "none"
        },
        attrs: {
          "colspan": (field.default_colspan || 0) + ((_field$children2 = field.children) === null || _field$children2 === void 0 ? void 0 : (_field$children2$filt = _field$children2.filter(function (el) {
            return el.checked;
          })) === null || _field$children2$filt === void 0 ? void 0 : _field$children2$filt.length) || 1
        }
      }, [_vm._t('foot_' + field.key, function () {
        return [field.slot_builder_name ? [_vm._t('foot_' + tr_index + '_' + field.slot_builder_name, function () {
          return [_vm._v(" " + _vm._s(tr[field.key]) + " ")];
        }, {
          "parent": field.parent,
          "field": field,
          "item": tr
        })] : [_vm._v(" " + _vm._s(tr[field.key]) + " ")]];
      }, {
        "value": tr[field.key],
        "item": tr,
        "index": tr_index
      })], 2);
    }), 1);
  }), 1) : _vm._e()], 1)], 1), _vm._t("footer-block", function () {
    return [_vm.show_product_search && _vm.scrollExist || _vm.hasTotal ? _c('div', {
      staticClass: "table-footer incoming-table-footer align-middle d-flex align-items-center p-3"
    }, [_c('div', {
      staticClass: "mr-auto"
    }, [_vm.scrollExist ? _vm._t("productSearch") : _vm._e()], 2), _c('div', [_vm._t("total")], 2)]) : _vm._e()];
  }, {
    "scrollExist": _vm.scrollExist
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }