export class ContactModal {
  constructor(request = {}) {
    this.id = request?.id
    this.name = request?.name
    this.phone = request?.phone
    this.email = request?.email
    this.main = request?.main
    this.position = request?.position
  }

  get inputContact() {
    return {
      id: this.id,
      name: this.name,
      phone: this.phone,
      email: this.email
    }
  }

  get getName() {
    return this.name
  }

  get getPhone() {
    return this.phone
  }

  get getEmail() {
    return this.email
  }

  setName(name) {
    this.name = name
  }

  setPhone(phone) {
    this.phone = phone
  }

  setEmail(email) {
    this.email = email
  }
}
