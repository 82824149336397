export class FieldsModel {
  constructor(request) {
    this.label = request.label
    this.key = request.key
    this.width = request.width
    this.checked = request.checked
    this.sort = request?.sort
    this.hint = request?.hint
    this.children = request.children?.map((el) => new FieldsModel(el))
  }
}
