var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "392",
      "height": "53",
      "viewBox": "0 0 392 53",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M49.4055 45.5397V34.3821H29.7138L40.9978 25.2907C44.9804 22.0674 48.0779 18.1828 48.0779 14.2157C48.0779 6.03339 41.219 0 24.4037 0C16.6598 0 7.80959 1.40503 2.38886 3.22332L7.25646 13.8024C10.9072 12.48 16.6598 11.2403 21.1955 11.2403C26.8375 11.2403 29.1607 13.3065 29.1607 16.1993C29.1607 18.1002 27.1694 20.2491 25.2887 21.9847L0.508194 45.5397H49.4055Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M108.129 45.5397V17.9981H76.0514V45.5397H87.3366V24.4875H96.9119V45.5397H108.129Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M149.928 31.7434C149.928 23.5166 143.636 17.3849 132.624 17.3849C121.613 17.3849 115.32 23.5166 115.32 31.7434C115.32 39.9701 121.613 46.1529 132.624 46.1529C143.636 46.1529 149.928 39.9701 149.928 31.7434ZM138.643 31.6412C138.643 36.6998 136.591 39.408 132.624 39.408C128.657 39.408 126.605 36.6998 126.605 31.6412C126.605 26.5825 128.657 23.9254 132.624 23.9254C136.591 23.9254 138.643 26.5825 138.643 31.6412Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M209.394 40.1745L206.111 39.0503V17.9981H194.826V39.0503H185.593V17.9981H174.308V45.5397H200.777L201.119 53H208.847L209.394 40.1745Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M249.108 30.3126C249.108 22.7502 243.363 17.3849 232.625 17.3849C221.545 17.3849 214.979 23.3122 214.979 31.7434C214.979 39.919 221.272 46.1529 234.062 46.1529C239.602 46.1529 245.142 45.3353 248.493 44.1601L245.347 37.9262C242.611 38.9481 238.644 39.6124 234.951 39.6124C230.368 39.6124 226.88 38.1306 225.991 35.1158L248.972 32.8675C249.108 31.9988 249.108 31.1813 249.108 30.3126ZM238.234 28.6775L225.239 29.9038C225.307 25.3562 228.385 23.3122 232.352 23.3122C235.977 23.3122 238.234 25.2029 238.234 28.6775Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M288.319 45.5397V17.9981H277.102V28.933H267.595V17.9981H256.31V45.5397H267.595V34.4004H277.102V45.5397H288.319Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M329.641 30.3126C329.641 22.7502 323.896 17.3849 313.158 17.3849C302.078 17.3849 295.512 23.3122 295.512 31.7434C295.512 39.919 301.804 46.1529 314.594 46.1529C320.134 46.1529 325.674 45.3353 329.025 44.1601L325.879 37.9262C323.143 38.9481 319.176 39.6124 315.483 39.6124C310.901 39.6124 307.413 38.1306 306.523 35.1158L329.504 32.8675C329.641 31.9988 329.641 31.1813 329.641 30.3126ZM318.766 28.6775L305.771 29.9038C305.839 25.3562 308.917 23.3122 312.884 23.3122C316.509 23.3122 318.766 25.2029 318.766 28.6775Z",
      "fill": "#F17D00"
    }
  }), _c('path', {
    attrs: {
      "d": "M391.492 45.5397V0.991788H376.889C373.017 4.38041 362.286 8.26492 352.551 9.42201L355.206 19.6705C360.516 19.2573 369.034 17.5216 373.017 15.8687V45.5397H391.492Z",
      "fill": "#F17D00"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }