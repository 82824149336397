import Vue from 'vue'
import Router from 'vue-router'
import AuthLayoutWrapper from '@/views/auth/index.vue'
import DefaultLayout from '@/views/layouts/MainLayout'
import NotFound from '@/views/layouts/404'
import { is_auth } from './middleware/auth'
import AuthRoutes from '@/views/auth/routes'
import DashboardRoutes from '@/views/dashboard/routes'
import ProductsRoutes from '@/views/products/routes'
import SettingsRoutes from '@/views/settings/routes'
import ContractorsRoutes from '@/views/contractors/routes'
import AnalyticRoutes from '@/views/analytics/routes'
import ExternalSystems from '@/views/external-systems/routes'
import OperProcessesRoutes from '@/views/operational-processes/routes'
import Integrations from '@/views/integrations/routes'
import ManufactureRoutes from '@/views/manufacture/routes/index'
import ServiceRoutes from '@/views/service/routes/index'
import TourRoutes from '@/views/tour/routes/index'
import OrderRoutes from '@/views/order/routes/index'
import DiagnosticsRoutes from '@/views/diagnostics/routes/index'
import PaymentRoutes from '@/views/payment/routes/index'
import Marketing from '@/views/marketing/routes'
import { set_token } from '@/router/middleware/set_token'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      beforeEnter: is_auth,
      component: AuthLayoutWrapper,
      children: [
        {
          path: '',
          component: DefaultLayout,
          meta: {
            bodyClass: 'layout'
          },
          children: [
            DashboardRoutes,
            ProductsRoutes,
            SettingsRoutes,
            ContractorsRoutes,
            AnalyticRoutes,
            ExternalSystems,
            OperProcessesRoutes,
            Integrations,
            ManufactureRoutes,
            ServiceRoutes,
            OrderRoutes,
            DiagnosticsRoutes,
            PaymentRoutes,
            Marketing
          ]
        }
      ]
    },
    AuthRoutes,
    TourRoutes,
    {
      beforeEnter: set_token,
      path: '/:pathMatch(.*)*',
      component: NotFound
    }
  ]
})
