<template>
  <svg
    width="316"
    height="54"
    viewBox="0 0 316 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3601 45V34.2H21.1201L29.2801 25.4C32.1601 22.28 34.4001 18.52 34.4001 14.68C34.4001 6.76 29.4401 0.919998 17.2801 0.919998C11.6801 0.919998 5.28008 2.27999 1.36008 4.04L4.88008 14.28C7.52008 13 11.6801 11.8 14.9601 11.8C19.0401 11.8 20.7201 13.8 20.7201 16.6C20.7201 18.44 19.2801 20.52 17.9201 22.2L7.62939e-05 45H35.3601Z"
      fill="#F07C00"
    />
    <path
      d="M83.6267 45V14.3031H56.9165V45H66.3135V21.536H74.2867V45H83.6267Z"
      fill="#F07C00"
    />
    <path
      d="M118.433 29.6231C118.433 20.4539 113.193 13.6197 104.024 13.6197C94.8546 13.6197 89.6151 20.4539 89.6151 29.6231C89.6151 38.7923 94.8546 45.6834 104.024 45.6834C113.193 45.6834 118.433 38.7923 118.433 29.6231ZM109.036 29.5092C109.036 35.1474 107.327 38.1658 104.024 38.1658C100.721 38.1658 99.0121 35.1474 99.0121 29.5092C99.0121 23.871 100.721 20.9095 104.024 20.9095C107.327 20.9095 109.036 23.871 109.036 29.5092Z"
      fill="#F07C00"
    />
    <path
      d="M167.949 39.0201L165.216 37.7672V14.3031H155.819V37.7672H148.13V14.3031H138.733V45H160.773L161.058 53.3149H167.494L167.949 39.0201Z"
      fill="#F07C00"
    />
    <path
      d="M201.018 28.0285C201.018 19.5996 196.235 13.6197 187.293 13.6197C178.067 13.6197 172.6 20.2261 172.6 29.6231C172.6 38.7353 177.839 45.6834 188.489 45.6834C193.102 45.6834 197.715 44.7722 200.506 43.4623L197.886 36.5142C195.608 37.6533 192.305 38.3936 189.229 38.3936C185.414 38.3936 182.509 36.742 181.769 33.3819L200.905 30.876C201.018 29.9079 201.018 28.9966 201.018 28.0285ZM191.963 26.206L181.142 27.5728C181.199 22.5042 183.762 20.2261 187.065 20.2261C190.084 20.2261 191.963 22.3333 191.963 26.206Z"
      fill="#F07C00"
    />
    <path
      d="M233.668 45V14.3031H224.328V26.4908H216.412V14.3031H207.015V45H216.412V32.5846H224.328V45H233.668Z"
      fill="#F07C00"
    />
    <path
      d="M268.077 28.0285C268.077 19.5996 263.293 13.6197 254.351 13.6197C245.125 13.6197 239.658 20.2261 239.658 29.6231C239.658 38.7353 244.897 45.6834 255.547 45.6834C260.16 45.6834 264.773 44.7722 267.564 43.4623L264.944 36.5142C262.666 37.6533 259.363 38.3936 256.288 38.3936C252.472 38.3936 249.567 36.742 248.827 33.3819L267.963 30.876C268.077 29.9079 268.077 28.9966 268.077 28.0285ZM259.021 26.206L248.201 27.5728C248.257 22.5042 250.82 20.2261 254.123 20.2261C257.142 20.2261 259.021 22.3333 259.021 26.206Z"
      fill="#F07C00"
    />
    <path
      d="M315.071 45V1.88H304.511C301.711 5.16 293.951 8.91999 286.911 10.04L288.831 19.96C292.671 19.56 298.831 17.88 301.711 16.28V45H315.071Z"
      fill="#F07C00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'TwoForOneSvg'
  }
</script>
<style lang="scss" scoped>
  svg {
    margin-top: 0.92px;
  }
</style>
