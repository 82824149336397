var render = function render(){
  var _vm$product, _vm$product$name, _vm$product2, _vm$product2$name, _vm$product3, _vm$product4, _vm$product4$measurem, _vm$product4$measurem2, _vm$product5, _vm$product5$country, _vm$product6, _vm$product7, _vm$product7$package$, _vm$product7$package$2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "containers"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('div', {
    staticClass: "logo_block"
  }, [_c('div', {
    staticClass: "line-cart"
  }, [_c('left-line-svg'), _c('cart-svg')], 1), _c('two-for-one-big-svg'), _c('right-line-svg')], 1)]), _c('div', {
    staticClass: "green-block"
  }, [_c('twoin-one-green-line-svg'), _c('b', [_vm._v("за")]), _c('div', {
    staticClass: "price"
  }, [_c('h2', [_vm._v(" " + _vm._s(_vm.getRetailPrice) + " ")]), _c('div', {
    staticClass: "rub"
  }, [_c('rub-svg')], 1)])], 1), _c('div', {
    staticClass: "content-block"
  }, [_c('div', {
    staticClass: "left-content"
  }, [_c('b', [_vm._v(_vm._s(((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : (_vm$product$name = _vm$product.name) === null || _vm$product$name === void 0 ? void 0 : _vm$product$name.length) >= 48 ? ((_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$name = _vm$product2.name) === null || _vm$product2$name === void 0 ? void 0 : _vm$product2$name.slice(0, 48)) + '...' : (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.name))])]), _c('div', {
    staticClass: "right-content"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('p', [_vm._v("Старая цена")]), _c('p', [_vm._v(_vm._s((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : (_vm$product4$measurem = _vm$product4.measurement) === null || _vm$product4$measurem === void 0 ? void 0 : (_vm$product4$measurem2 = _vm$product4$measurem.name) === null || _vm$product4$measurem2 === void 0 ? void 0 : _vm$product4$measurem2.slice(0, 2)) + " (руб.)")])]), _c('div', {
    staticClass: "price"
  }, [_c('b', {
    staticClass: "old-price"
  }, [_vm._v(_vm._s(_vm.getOldPrice) + " "), _c('line-through-svg', {
    attrs: {
      "gray": ""
    }
  })], 1)]), _c('div', {
    staticClass: "country"
  }, [_c('p', [_vm._v(_vm._s((_vm$product5 = _vm.product) === null || _vm$product5 === void 0 ? void 0 : (_vm$product5$country = _vm$product5.country) === null || _vm$product5$country === void 0 ? void 0 : _vm$product5$country.name))])])])]), _c('div', {
    staticClass: "footer"
  }, [_c('orange-line-svg'), _c('div', {
    staticClass: "footer__block"
  }, [_c('div', {
    staticClass: "barcode"
  }, [_c('div', {
    staticStyle: {
      "width": "201px",
      "height": "73px"
    }
  }, [(_vm$product6 = _vm.product) !== null && _vm$product6 !== void 0 && _vm$product6.package ? _c('barcode-generator', {
    attrs: {
      "width": 201,
      "height": 73,
      "barcode": (_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : (_vm$product7$package$ = _vm$product7.package[0]) === null || _vm$product7$package$ === void 0 ? void 0 : (_vm$product7$package$2 = _vm$product7$package$.barcode) === null || _vm$product7$package$2 === void 0 ? void 0 : _vm$product7$package$2.barcode
    }
  }) : _vm._e()], 1)]), _c('p', {
    staticClass: "address"
  }, [_vm._v(_vm._s(_vm.companyName))])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }