export class ContactModel {
  constructor(request = {}) {
    this.id = request.id
    this.name = request.name
    this.phone = request.phone
    this.main = request.main
    this.email = request.email
    this.position = request.position
  }
}
