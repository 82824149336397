export class TourModel {
  constructor(request = {}) {
    this.current_page = request?.current_page
    this.current_step = request?.current_step
    this.status = true
    this.tour_modal = false
    this.tours = new Tours(request?.tours)
  }

  get input() {
    return {
      current_page: this.current_page,
      current_step: +this.current_step,
      status: this.status,
      tour_modal: this.tour_modal
    }
  }
}

export class Tours {
  constructor(request = {}) {
    this.page_name = request?.page_name
    this.steps = request?.steps?.map((el) => new StepModel(el))
  }
}

export class StepModel {
  constructor(request = {}) {
    this.title = request?.title
    this.step = request?.step
    this.redirect = request?.redirect
    this.position = request?.position
    this.image = request?.image
    this.content = request?.content
    this.block_id = request?.block_id
    this.append_class = request?.append_class
    this.blur = request?.blur
  }
}
