var render = function render(){
  var _vm$modal_binding, _vm$modal_binding2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "core-binding-modal",
      "size": "xl",
      "centered": "",
      "hide-footer": ""
    },
    on: {
      "shown": _vm.shownModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        var _vm$product, _ref, _vm$product$name, _vm$product2, _vm$product3;

        return [_c('div', [_c('h5', [_vm._v(" Привязка товара " + _vm._s((_vm$product = _vm.product) === null || _vm$product === void 0 ? void 0 : _vm$product.articul) + " | " + _vm._s((_ref = (_vm$product$name = (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : _vm$product2.name) !== null && _vm$product$name !== void 0 ? _vm$product$name : (_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : _vm$product3.productItem_name) !== null && _ref !== void 0 ? _ref : '-') + " ")]), _c('div', {
          staticClass: "title-description"
        }, [_c('div', [_vm._v(_vm._s(_vm.modal_binding.supplier_name))]), _vm.product.info ? _c('div', {
          staticClass: "delimiter"
        }) : _vm._e(), _c('div', [_vm._v(_vm._s(_vm.product.info))])])]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": _vm.hideModal
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_vm.getInfoBindingModal ? _c('div', {
    staticClass: "ext-caption mb-3"
  }, [_c('div', [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/info-icon-33.svg"
    }
  }), _c('span', {
    staticClass: "text-info"
  }, [_vm._v("Чтобы привязать товар необходимо нажать на товарную карточку или добавить новый ")])]), _c('img', {
    staticClass: "close-help cursor",
    attrs: {
      "src": "/img/icons/blue-close.svg",
      "alt": "close"
    },
    on: {
      "click": _vm.closeInfo
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "mb-3",
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "overflow": "hidden"
    }
  }, [_c('binding-products-menu', {
    staticStyle: {
      "min-height": "500px",
      "min-width": "25%",
      "height": "70vh"
    }
  }), _c('binding-product-table', {
    ref: "bindingProductTable",
    staticStyle: {
      "display": "flex",
      "margin-bottom": "0 !important",
      "min-height": "600px",
      "flex": "1"
    },
    attrs: {
      "product": _vm.product,
      "document_head": (_vm$modal_binding = _vm.modal_binding) === null || _vm$modal_binding === void 0 ? void 0 : _vm$modal_binding.spec_id,
      "target_product": _vm.modal_binding.target_product,
      "type": (_vm$modal_binding2 = _vm.modal_binding) === null || _vm$modal_binding2 === void 0 ? void 0 : _vm$modal_binding2.type
    },
    on: {
      "bind": _vm.bind
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }