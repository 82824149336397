import { CompanyModel } from '@/models/company.model'
import { UserModel } from '@/models/user.model'

export class RoleModel {
  constructor(request = {}) {
    this.branch = new RoleDefault(request?.branch)
    this.company = new CompanyModel(request?.branch)
    this.document = request?.document?.map((el) => new RoleDocument(el))
    this.id = request?.id
    this.name = request?.name
    this.product = new RoleDefault(request?.product)
    this.supplier = new RoleDefault(request?.supplier)
    this.user = new UserModel(request?.user)
    this.users = new RoleDefault()
    this.resources = request?.resources?.map((el) => new ResourceModel(el))
  }

  permission_by_resource(resource, operation) {
    return !!this.resources?.find((el) => el.type === resource?.type)?.permission[operation]
  }

  all_permissions(resource) {
    if (resource?.permission instanceof Object) return Object.values(resource?.permission)?.every((el) => el)
    return false
  }
}

class RoleDefault {
  constructor(request = {}) {
    this.create = request?.create
    this.delete = request?.delete
    this.read = request?.read
    this.update = request?.update
  }
}
class RoleDocument {
  constructor(request = {}) {
    this.create = request?.create
    this.delete = request?.delete
    this.read = request?.delete
    this.type = request?.delete
    this.update = request?.delete
  }
}

export class ResourceModel {
  constructor(request = {}) {
    this.id = request?.id
    this.type = request?.type
    this.permission = new PermissionModel(request?.permission)
  }
}

export class PermissionModel {
  constructor(request = {}) {
    this.create = request?.create
    this.read = request?.read
    this.update = request?.update
    this.delete = request?.delete
  }
}

export class RoleModelView extends RoleModel {
  constructor(req) {
    super(req)
    this.selected = req?.selected
  }
}
