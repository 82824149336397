<template>
  <div class="b-add-group">
    <b-form
      class="form-add-group p-3"
      @submit.prevent="createGroup"
    >
      <b-row>
        <b-col class="pos-r">
          <div class="b-elm-wrapper">
            <div class="b-level-count">{{ number }}</div>
            <b-form-input
              ref="name"
              v-model="name"
              type="text"
              :is-keyup="true"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="b-btns">
          <b-button
            class="cursor"
            @click="createGroup"
          >
            <b-spinner
              v-if="isCreateProgress"
              small
              variant="primary"
            />
            <img
              v-if="!isCreateProgress"
              src="/img/icons/icon_yes.svg"
              alt="Добавить группу"
            />
          </b-button>
          <b-button
            class="cursor"
            @click="close_add_group"
          >
            <img
              src="/img/icons/icon_cancel.svg"
              alt="Отменить"
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      number: {
        type: String,
        default: ''
      },

      id: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      name: '',
      isCreateProgress: false
    }),

    computed: {
      ...mapGetters({
        current_category: 'products/getCurrentCategory'
      })
    },

    created() {
      this.$nextTick(() => this.$refs.name.focus())
    },

    methods: {
      close_add_group() {
        this.$emit('closeAddGroup')
      },

      createGroup() {
        if (this.name.length < 1 || this.isCreateProgress) return false
        this.isCreateProgress = true

        this.$apollo
          .mutate({
            mutation: require('../../gql/CreateCategory.gql'),
            fetchPolicy: 'no-cache',
            variables: {
              name: this.name,
              parent: this.id
            }
          })
          .then((data) => {
            this.groupAddedSuccessfully(data)
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При создании группы "${this.name}" что-то пошло не так. Попробуйте еще раз`)
          })
          .finally(() => {
            this.isCreateProgress = false
            this.close_add_group()
          })
      },

      groupAddedSuccessfully({ data }) {
        this.isCreateProgress = false
        const group = data.CreateCategory
        const category = {
          ...group,
          number: this.current_category.number
        }

        this.$emit('groupAddedSuccessfully', category)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .form-add-group {
    position: relative;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .spinner-border {
      margin-top: 0;
    }

    .b-elm-wrapper {
      background: #ffffff;
      border-radius: 2px;
      box-shadow: 0px 0px 0px 2px #b9d6ff;
      display: flex;
      align-items: center;
      padding-left: 10px;

      .b-level-count {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #bcbcbc;
        height: 17px;
        display: flex;
        align-items: center;
      }

      input {
        background: #ffffff;
        border: none;
        padding-left: 5px;

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .b-btns {
      display: flex;
      justify-content: flex-end;

      button {
        background: #fff;
        border-radius: 2px;
        margin-left: 4px;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
        transition: all 0.2s ease !important;
        transform: scale(1);

        &:hover,
        &:active {
          opacity: 0.98;
          background: #f8f8f9 !important;
        }

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
</style>
