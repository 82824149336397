import { ContractHeadModel } from '@/views/operational-processes/models/contract-head.model'
export const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = filters
  },
  SET_DOCUMENT_HEAD(state, document) {
    state.documentHead = new ContractHeadModel(document)
  },

  SET_SPEC_LIST(state, specList) {
    state.specList = specList
  },

  SET_LIST_WARNING_SPEC(state, specList) {
    state.listWarningSpec = specList
  },

  SET_SPEC_PARAM(state, param) {
    if (param.key === 'future_price') {
      state.specList.forEach((el) => {
        if (el.id === param.id) {
          el.setFuturePrice(param.value)
        }
      })
    }

    if (param.key === 'future_begin') {
      const spec = state.specList.find((el) => el.id === param.id)
      spec.setFutureBegin(param.value)
    }
  },

  SET_DOCUMENR_PARAM(state, param) {
    if (param.key === 'date') state.documentHead.setDate(param.value)
    if (param.key === 'name') state.documentHead.setName(param.value)
    if (param.key === 'entity') state.documentHead.setEntity(param.value)
    if (param.key === 'period') state.documentHead.setPeriod(param.value)
    if (param.key === 'branch') state.documentHead.setBranch(param.value)
    if (param.key === 'supplier') state.documentHead.setSupplier(param.value)
    if (param.key === 'operation') state.documentHead.setOperation(param.value)
    if (param.key === 'contact_name') state.documentHead.setContactName(param.value)
    if (param.key === 'contact_phone') state.documentHead.setContactPhone(param.value)
    if (param.key === 'contact_email') state.documentHead.setContactEmail(param.value)
    if (param.key === 'payment_term') state.documentHead.setPaymentTerm(param.value)
    if (param.key === 'payment_active') state.documentHead.setPaymentActive(param.value)
    if (param.key === 'payment_due_date') state.documentHead.setPaymentDueDate(param.value)
    if (param.key === 'delivery_days') state.documentHead.setDeliveryDays(param.value)
    if (param.key === 'delivery_active') state.documentHead.setDeliveryActive(param.value)
    if (param.key === 'delivery_weeks') state.documentHead.setDeliveryWeeks(param.value)
    if (param.key === 'delivery_min_sum') state.documentHead.setDeliveryMinSum(param.value)
    if (param.key === 'delivery_order_days') state.documentHead.setDeliveryOrderDays(param.value)
    if (param.key === 'delivery_order_weeks') state.documentHead.setDeliveryOrderWeeks(param.value)
  }
}
