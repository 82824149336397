<template>
  <svg
    class=""
    width="28"
    height="3"
    viewBox="0 0 28 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.964844 2.17968V0.950806H28.0002V2.17968H0.964844Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'RightLine200on140Svg'
  }
</script>
<style lang="scss" scoped>
  svg {
    transform: translateY(24.5px);
  }
</style>
