import { IncomingSpecificationModel } from '@/models/incoming-spec.model'

export class IncomingSpecificationPaginationModel {
  constructor(request = {}) {
    this.avgMarkup = request?.avgMarkup
    this.factTotal = request?.factTotal
    this.requestTotal = request?.requestTotal
    this.prev_cursor = request?.prev_cursor
    this.next_cursor = request?.next_cursor
    this.specification = request?.specification?.map((el) => new IncomingSpecificationModel(el)) ?? []
    this.sumTotal = request?.sumTotal
    this.taxRateSum = request?.taxRateSum
    this.total = request?.total
  }
  addSpec(spec) {
    this.specification.push(new IncomingSpecificationModel(spec))
  }
  addTotal(val) {
    this.total += val
  }

  setTotal(request) {
    this.avgMarkup = request?.avgMarkup
    this.factTotal = request?.factTotal
    this.requestTotal = request?.requestTotal
    this.sumTotal = request?.sumTotal
    this.taxRateSum = request?.taxRateSum
    this.total = request?.total
  }
}
