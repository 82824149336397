var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "anal__wiget",
    on: {
      "click": _vm.clicked
    }
  }, [_c('div', {
    staticClass: "analytics__item",
    class: {
      without_border: _vm.show_drop_down,
      'cursor-pointer': !!_vm.on_click
    }
  }, [_c('div', {
    staticClass: "analytics__item-text"
  }, [_vm._v(" " + _vm._s(_vm.data.name)), _c('span', [_vm._v(" " + _vm._s(_vm.convertDate(_vm.data.date)))])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "end",
      "flex": "1"
    }
  }, [_c('div', {
    staticClass: "analytics__item-price"
  }, [_vm._v(" " + _vm._s(_vm.data.currency ? _vm.getFormatMoney(_vm.data.value, 2) : _vm.data.value)), _c('span', [_vm._v(_vm._s(_vm.data.currency ? '₽' : 'Шт.'))])]), _vm.click_more && _vm.data.value > 0 ? _c('div', {
    staticClass: "show-more-arrow",
    class: {
      opened: _vm.show_drop_down
    },
    on: {
      "mouseover": function ($event) {
        return _vm.onClick(true);
      },
      "mouseleave": function ($event) {
        return _vm.onClick(false);
      },
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('img', {
    staticClass: "show-more-arrow-svg",
    attrs: {
      "src": "/img/icons/arrow_up.svg"
    }
  })]) : _vm._e()]), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.show_drop_down ? _c('div', {
    staticClass: "analytics-dropdown"
  }, [_vm._l(_vm.data.payment_types, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "analytics-dropdown-item"
    }, [_c('div', {
      staticClass: "analytics-dropdown-title"
    }, [_vm._v(_vm._s(item.type) + ", ₽")]), _c('div', {
      staticClass: "dropdown-money"
    }, [_vm._v(_vm._s(_vm.formatMoney(item.value)))])]);
  }), _c('div', [_c('div', {
    staticClass: "analytics-dropdown-title"
  }, [_vm._v("Возвраты, ₽")]), _c('div', {
    staticClass: "dropdown-money"
  }, [_vm._v(_vm._s(_vm.formatMoney(_vm.data.returns)))])])], 2) : _vm._e()])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }