<template>
  <b-modal
    id="confirm-email-modal"
    hide-footer
    title="Введите новый E-mail"
    centered
  >
    <template #modal-header="{ close }">
      <h5>Введите новый E-mail</h5>
      <b-button
        class="close"
        @click="close()"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <div class="container-modal">
      <div class="input-block">
        <e-input
          v-model="email"
          :error="emailError"
          type="email"
          label="E-mail"
          error_text="Введите корректный E-mail"
          placeholder="E-mail"
          @input="emailError = false"
        />
      </div>

      <e-button
        size="l"
        variant="primary"
        @click="send"
      >
        Отправить письмо с подтверждением
      </e-button>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'ConfirmEmailModal',
    data() {
      return {
        email: '',
        emailError: false
      }
    },
    methods: {
      send() {
        if (!this.validateEmail(this.email)) {
          this.emailError = true
          return this.$noty.error('Введите корректный E-mail')
        }
        this.emailError = false
        this.$noty.show('Письмо с подтверждением отправлено')
        this.$bvModal.hide('confirm-email-modal')
      },
      validateEmail(email) {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }
    }
  }
</script>

<style lang="scss" scoped>
  h5 {
    margin-bottom: 0;
    font-family: 'Gilroy-Medium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #313131;
  }

  ::v-deep() {
    .e_button {
      width: 100% !important;
      margin-bottom: 22px;
    }
  }

  ::v-deep .input {
    height: 42px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: 'Gilroy-Regular', sans-serif !important;

    ::placeholder {
      height: 42px !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      font-family: 'Gilroy-Regular', sans-serif !important;
    }
  }

  .container-modal {
    .input-block {
      margin-bottom: 20px;
    }
  }
</style>
