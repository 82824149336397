export const mutations = {
  SET_DECLARATION(state, declaration) {
    state.declaration = declaration
  },
  SET_BRANCHES(state, branches) {
    state.branches = branches
  },
  SET_SPECIFICATIONS(state, specifications) {
    state.specifications = specifications
  },
  SET_DATA_RANGE(state, date_range) {
    state.date_range = date_range
  },
  SET_OPENED_CATEGORY(state, opened_category) {
    state.opened_category = opened_category
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_CHECKED(state, checked) {
    state.checked = checked
  },
  SET_ABC_CHECKED(state, checked) {
    state.abc_checked = checked
  },
  SET_ABC_DATA_RANGE(state, abc_date_range) {
    state.abc_date_range = abc_date_range
  },
  SET_ABC_PRODUCTS(state, abc_products) {
    state.abc_products = abc_products
  },
  SET_ABC_SUPPLIERS(state, abc_suppliers) {
    state.abc_suppliers = abc_suppliers
  },
  SET_ABC_OPENED_CATEGORY(state, opened_category) {
    state.abc_opened_category = opened_category
  },
  SET_ABC_BRANCHES(state, abc_branches) {
    state.abc_branches = abc_branches
  },
  SET_GROUP_BY(state, group_by) {
    state.group_by = { ...state.group_by, ...group_by }
  },
  SET_ANALYTICS_PARAMS(state, ap) {
    state.analytics_params = { ...state.analytics_params, ...ap }
  }
}
