<template>
  <svg
    class="line"
    width="168"
    height="29"
    viewBox="0 0 168 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="25.4751"
      width="169"
      height="3"
      rx="1.5"
      transform="rotate(-8.48224 0 25.4751)"
      fill="#474747"
    />
  </svg>
</template>
<script>
  export default {
    name: 'OldPriceLineSvg'
  }
</script>
<style lang="scss" scoped></style>
