export class EdoModel {
  constructor(request = {}) {
    this.id = request?.id
    this.type = request?.type
    this.status = request?.status
    this.action = request?.action
    this.thumbprint = request?.thumbprint
    this.can_change_branch = request?.can_change_branch
  }
}
