<template>
  <b-modal
    id="binding-accept"
    centered
  >
    <template #modal-header>
      <h5>Подтвердите привязку товара</h5>
    </template>
    <div class="modal-inner">
      <div class="product-name">
        {{ product.name || product.productItem_name }}
      </div>
      <img
        src="/img/icons/ext_systems/bind.svg"
        alt="bind"
        class="m-3"
      />
      <div class="product-name">
        {{ selected_product.name }}
      </div>
    </div>
    <template #modal-footer>
      <div class="btn-footer">
        <e-button
          variant="primary"
          class="text-nowrap w-auto"
          :disabled="loading"
          :loading="loading"
          @click="replaceProduct"
        >
          Привязать
        </e-button>
        <b-button
          variant="outline-primary"
          @click="hideModal"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'BindingAcceptModal',
    props: {
      product: {
        type: Object,
        default: () => {}
      },
      selected_product: {
        type: Object,
        default: () => {}
      },
      document_head: {
        type: String,
        default: () => ''
      },
      type: {
        type: String,
        default: () => ''
      }
    },
    data() {
      return {
        loading: false
      }
    },

    computed: {
      ...mapGetters({
        modal_binding: 'core/getBindingModal'
      })
    },

    methods: {
      hideModal() {
        this.$bvModal.hide('binding-accept')
      },
      async replaceProduct() {
        this.loading = true
        let spec
        if (this.type === 'mercury' || this.type === 'crp-tech') {
          try {
            await this.$apollo.mutate({
              mutation: require('../../../../external-systems/gql/mercury/ReplaceProduct.gql'),
              variables: {
                input: {
                  product_id: this.modal_binding?.target_product?.id,
                  original_product_id: this.modal_binding?.product?.id,
                  target_product_id: this.selected_product.id,
                  source: this.type
                }
              }
            })
          } catch (e) {
            console.error(e)
            this.$noty.error('Ошибка привязки')
            return
          }
        } else {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../../gql/replaceProduct.graphql'),
            variables: {
              input: {
                product_id: this.product.id,
                target_product_id: this.selected_product.id,
                spec_id: this.document_head
              }
            }
          })
          spec = data.ReplaceProduct
        }
        this.loading = false
        this.$emit('bind', spec)
        this.$bvModal.hide('binding-accept')
        this.$bvModal.hide('product-binding-modal')
        this.$bvModal.hide('core-binding-modal')
      }
    }
  }
</script>

<style scoped>
  .modal-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-name {
    font-size: 14px;
    font-weight: bold;
    color: black;
    padding: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    flex: 1;
  }

  .btn-footer {
    display: flex;
    gap: 10px;
  }
</style>
