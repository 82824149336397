import View from '@/views/layouts/layout-view'

export default {
  path: '/analytics',
  name: 'analytics',
  component: View,
  redirect: '/analytics/dashboard',
  meta: {
    auth: true
  },
  children: [
    {
      path: 'dashboard',
      name: 'analytics.dashboard',
      component: () => import('@/views/analytics/pages/Analytics'),
      meta: { title: 'Аналитика' }
    },
    {
      path: 'cash-sales',
      name: 'analytics.cash-sales.report',
      component: () => import('@/views/analytics/pages/CashSales'),
      meta: { title: 'Кассовая реализация' }
    },
    {
      path: 'trading-margins',
      name: 'analytics.trading-margins.report',
      component: () => import('@/views/analytics/pages/TradeMargins'),
      meta: { title: 'Торговые наценки' }
    },
    {
      path: 'sales-analysis',
      name: 'analytics.sales-analysis.report',
      component: () => import('@/views/analytics/pages/SalesAnalysis'),
      meta: { title: 'Анализ товародвижения' }
    },
    {
      path: 'remainder-reports',
      name: 'analytics.remainders',
      component: () => import('@/views/analytics/pages/Remainders'),
      meta: { title: 'Остатки' }
    },
    {
      path: 'abc',
      name: 'analytics.abc',
      component: () => import('@/views/analytics/pages/AbcAnalytics'),
      meta: { title: 'ABC - анализ' }
    },
    {
      path: 'alco-declaration',
      name: 'analytics.alco-declaration',
      component: () => import('@/views/analytics/pages/AlcoDeclaration'),
      meta: { title: 'Декларации' }
    },
    {
      path: 'alco-declaration/:id',
      name: 'analytics.alco-declaration.new',
      component: () => import('@/views/analytics/pages/NewAlcoDeclaration'),
      meta: { title: 'Новая декларация' }
    }
  ]
}
