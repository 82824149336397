import * as R from 'ramda'
import { apolloClient } from '@/apollo.config'
import { REGISTER_USER, CREATE_COMPANY, REMOVE_STORE_ITEM } from '@/gql/mutations/auth'
import { GET_ACTIVITYS_LIST, GET_USER } from '@/gql/queries'
import { isNillOrEmpty } from '@/utils/helpers'
import router from '@/router'
import { TourModel } from '@/models/tour.model'
import store from '@/store'

export const actions = {
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },

  async refreshGQL({ commit, dispatch }) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: require('@/gql/mutations/auth/Refresh.graphql'),
        variables: {
          input: {
            refresh: store.state.core.refresh
          }
        }
      })
      commit('SET_TOKEN', data?.Refresh?.token)
      commit('SET_REFRESH', data?.Refresh?.refresh)
    } catch (e) {
      dispatch('logout')
    }
  },

  async setTourStatus({ commit }, tour) {
    const tour_model = new TourModel(tour)

    const { data } = await apolloClient.mutate({
      mutation: require('@/gql/tour/updateTourStatus.graphql'),
      variables: { input: tour_model.input }
    })

    if (data?.UpdateTourStatus?.status) commit('SET_TOUR', tour_model)
  },

  setUserData({ commit }, userData) {
    commit('LOGIN_USER', userData)
    router.push({ name: 'dashboard' })
  },

  setRefresh({ commit }, refresh) {
    commit('SET_REFRESH', refresh)
  },

  async registerUser({ commit }, userData) {
    const resp = await apolloClient.mutate({
      mutation: REGISTER_USER,
      variables: userData
    })

    const token = R.pathOr(null, ['data', 'Register', 'token'], resp)
    const id = R.pathOr(null, ['data', 'Register', 'user', 'id'], resp)
    const email = R.pathOr(null, ['data', 'Register', 'user', 'email'], resp)
    const is_confirmed = resp?.data?.Register?.user?.is_confirmed

    if (!isNillOrEmpty(token)) {
      commit('SET_TOKEN', token)
      commit('SET_USER_ID', id)
      commit('SET_USER_EMAIL', email)
      commit('SET_IS_CONFIRMED', is_confirmed)
      commit('SET_STORYLINE_STATUS', 'activity')
      router.push({ name: 'registr.activity' })
    }
  },

  setIsConfirmed({ commit }, bool) {
    commit('SET_IS_CONFIRMED', bool)
  },

  setRegflowCompany({ commit }, company) {
    commit('SET_REGFLOW_COMPANY', company)
  },

  async setActivities({ commit }, Activities) {
    commit('SET_ACTIVITIES', Activities)
    commit('SET_STORYLINE_STATUS', 'company')
    router.push({ name: 'registr.company' })
  },

  async setStoryLineStatus({ commit }, status) {
    commit('SET_STORYLINE_STATUS', status)
  },

  async setFranchise({ commit }, is_fasol) {
    commit('SET_IS_FASOL', is_fasol)
  },

  setCurrentStep({ commit }, currentStep) {
    commit('SET_CURRENT_STEP', currentStep)
  },

  async getActivitysList({ commit }) {
    const { data } = await apolloClient.query({
      query: GET_ACTIVITYS_LIST
    })
    let activity_arr = []

    if (data.Activities.length > 0) {
      data.Activities.forEach(function (element) {
        const activity_item = {
          ...element
        }
        activity_item.active = false
        activity_arr.push(activity_item)
      })

      commit('SET_ACTIVITY_LIST', activity_arr)
    }
  },

  async createCompany({ commit }, company) {
    const { data } = await apolloClient.mutate({
      mutation: CREATE_COMPANY,
      variables: {
        name: company.name,
        activities: company.activities,
        entity: company.entity,
        branch_name: company.branch_name,
        franchise: company.franchise
      }
    })

    const name = R.pathOr('', ['CreateCompany', 'name'], data)
    commit('SET_COMPANY_NAME', name)
    router.push({ name: 'registr.confirm' })
  },

  updateCompnayName({ commit }, name) {
    commit('UPDATE_COMPANY_NAME', name)
  },

  // async updateBranch({ commit }, id) {
  //   const { data } = await apolloClient.mutate({
  //     mutation: UPDATE_BRANCH,
  //     variables: {
  //       id: id
  //     }
  //   })

  //   const name = R.pathOr('', ['UpdateBranch', 'name'], data)

  //   if (name) commit(name)
  // },

  async removeStoreItem({ commit }, store_id) {
    const { data } = await apolloClient.mutate({
      mutation: REMOVE_STORE_ITEM,
      variables: {
        id: store_id
      }
    })
    commit(data)
  },

  async getUserInfo({ commit }) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: GET_USER
      })

      const user = data?.CurrentProfile ?? null

      if (user) commit('SET_USER', user)
    } catch (e) {
      for (let item of e?.graphQLErrors) {
        if (item.extensions) return console.info(item.extensions.ru)
        if (item.message === 'Unauthorized') {
          // window.location.reload(true)
          if (store.state.core.refresh) {
            store.dispatch('core/refreshGQL')
          } else {
            store.dispatch('core/logout')
          }
        }
      }
    }
  },

  logout({ commit }) {
    commit('LOGOUT_USER')
  },
  setBindingModal({ commit }, data) {
    commit('SET_BINDING_MODAL', data)
  }
}
