<template>
  <b-modal
    :id="`modal-remove-group${group.id}`"
    hide-footer
    centered
    title="Удалить группу"
  >
    <div class="center">Вы действительно хотите удалить группу {{ group.name }} ?</div>
    <div class="modal-footer mt-4">
      <e-button
        class="center"
        variant="outline-primary"
        :disabled="remove_group_is_loading"
        @click="hideModalRemove"
      >
        Отмена
      </e-button>
      <e-button
        class="center"
        variant="primary"
        button_text="Удалить"
        :disabled="remove_group_is_loading"
        :loading="remove_group_is_loading"
        @click="removeGroup"
      >
        Удалить
      </e-button>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: 'RemoveModal',
    props: {
      group: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        remove_group_is_loading: false
      }
    },
    methods: {
      hideModalRemove() {
        this.$bvModal.hide(`modal-remove-group${this.group.id}`)
      },
      removeGroup() {
        if (this.group.product_count) {
          this.hideModalRemove()
          return this.$noty.error('Категория содержит товары')
        }
        this.remove_group_is_loading = true
        this.$apollo
          .mutate({
            mutation: require('../gql/removeCategory.gql'),
            variables: {
              id: this.group.id
            }
          })
          .then(() => {
            this.$noty.show(`Группа "${this.group.name}" удалена`)
            this.$emit('remove-item')
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При удалении группы "${this.group.name}" что-то пошло не так. Попробуйте еще раз`)
          })
          .finally(() => {
            this.remove_group_is_loading = false
            this.$bvModal.hide(`modal-remove-group${this.group.id}`)
          })
      }
    }
  }
</script>

<style scoped></style>
