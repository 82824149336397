export const state = {
  selectedItems: [],
  select_all: false,
  filters: {
    supplier: [],
    storages: [],
    operation: [],
    status: [],
    types: [],
    owners: []
  }
}
