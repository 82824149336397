import gql from 'graphql-tag'

export const GET_ACTIVITYS_LIST = gql`
  query getActivityList {
    Activities {
      id
      name
      parent
    }
  }
`

// export const GET_COMPANIES = gql`
//   query Companies {
//     Companies {
//       id
//       name
//       branches {
//         entities {
//           name
//           inn
//           kpp
//         }
//       }
//     }
//   }
// `

export const GET_BRANCHES = gql`
  query Branches {
    Branches {
      name
      id
      address {
        country
        city
        street
        house
        apartment
        post_index
      }
      storage {
        id
        name
      }
      cash {
        id
        name
        number
      }
      entities {
        id
        name
        fullname
      }
    }
  }
`

// export const GET_USERS = gql`
//   query users {
//     users {
//       id
//       first_name
//       last_name
//       branches {
//         name
//       }
//     }
//   }
// `

export const GET_USER = gql`
  query CurrentUser {
    CurrentProfile {
      id
      first_name
      last_name
      email
      phone
      is_confirmed
      is_old
      company {
        name
        id
      }
    }
  }
`
