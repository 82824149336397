<template>
  <canvas
    id="canvas"
    ref="canvas"
    class="canvas"
  ></canvas>
</template>
<script>
  export default {
    name: 'BarcodeGenerator',
    props: {
      barcode: String,
      width: {
        type: Number,
        default: 201
      },
      height: {
        type: Number,
        default: 73
      },
      bgcolor: {
        type: String,
        default: 'transparent'
      }
    },
    data() {
      return {
        canvas: null,
        structs: ['LLLLLL', 'LLGLGG', 'LLGGLG', 'LLGGGL', 'LGLLGG', 'LGGLLG', 'LGGGLL', 'LGLGLG', 'LGLGGL', 'LGGLGL'],
        KEY_LG: {
          L: [
            '0001101',
            '0011001',
            '0010011',
            '0111101',
            '0100011',
            '0110001',
            '0101111',
            '0111011',
            '0110111',
            '0001011'
          ],
          G: [
            '0100111',
            '0110011',
            '0011011',
            '0100001',
            '0011101',
            '0111001',
            '0000101',
            '0010001',
            '0001001',
            '0010111'
          ]
        },
        R_KEY: [
          '1110010',
          '1100110',
          '1101100',
          '1000010',
          '1011100',
          '1001110',
          '1010000',
          '1000100',
          '1001000',
          '1110100'
        ],
        L_KEY: [
          '0001101',
          '0011001',
          '0010011',
          '0111101',
          '0100011',
          '0110001',
          '0101111',
          '0111011',
          '0110111',
          '0001011'
        ]
      }
    },
    watch: {
      barcode() {
        this.render()
      },
      width() {
        this.render()
      },
      height() {
        this.render()
      }
    },
    mounted() {
      this.render()
    },
    methods: {
      render() {
        this.whichEAN()
        this.$refs.canvas.style.width = +this.width + 'px'
        this.$refs.canvas.style.height = +this.height + 'px'
      },
      whichEAN() {
        this.canvas = this.$refs.canvas
        if (this.barcode.length > 8) {
          this.drawEAN13Barcode(this.canvas, this.barcode)
        } else {
          this.drawEAN8Barcode(this.canvas, this.barcode)
        }
      },
      drawEAN13Barcode(canvas, barcode) {
        const ratio = window.devicePixelRatio
        canvas.width = 211 * ratio
        canvas.height = 73 * ratio
        canvas.style.width = 211 + 'px'
        canvas.style.height = 73 + 'px'
        canvas.getContext('2d')
        let ctx = canvas.getContext('2d')
        ctx.scale(ratio, ratio)
        let x = 15
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Set barcode dimensions
        let barWidth = 2
        let barHeight = 73 * 0.7
        let current_struct

        const draw = (data, height = 0) => {
          for (const el of data) {
            ctx.fillStyle = el === '1' ? 'black' : this.bgcolor
            ctx.fillRect(x, 0, barWidth, barHeight + height)
            x += 2
          }
        }
        for (const [index, digit] of barcode.split('').entries()) {
          if (index === 0) {
            current_struct = this.structs[+digit]
            draw('101', 15)
            ctx.fillStyle = 'black'
            ctx.font = 'bold 19px Arial, sans-serif'
            ctx.fillText(digit, x - 18, barHeight + 18)
          } else {
            ctx.font = 'bold 19px Arial, sans-serif'
            ctx.fillStyle = 'black'
            ctx.fillText(digit, x + 2, barHeight + 18)
          }
          if (index <= 6 && index !== 0) {
            draw(this.KEY_LG[current_struct[index - 1]][+digit])
          }
          if (index > 6) draw(this.R_KEY[+digit])

          if (index === 6) {
            draw('01010', 15)
          }
        }
        draw('101', 15)
      },
      drawEAN8Barcode(canvas, barcode) {
        const ratio = window.devicePixelRatio
        canvas.width = 201 * ratio
        canvas.height = 73 * ratio
        canvas.style.width = 201 + 'px'
        canvas.style.height = 73 + 'px'
        canvas.getContext('2d')
        let ctx = canvas.getContext('2d')
        ctx.scale(ratio, ratio)
        let x = 35
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Set barcode dimensions
        let barWidth = 2
        let barHeight = 55

        const draw = (data, height = 0) => {
          for (const el of data) {
            ctx.fillStyle = el === '1' ? 'black' : this.bgcolor
            ctx.strokeStyle = 'rgba(0,0,0,1)'
            ctx.fillRect(x, 0, barWidth, barHeight + height)
            x += 2
          }
        }

        for (const [index, digit] of barcode.split('').entries()) {
          if (index === 0) {
            draw('101', 15)
            ctx.fillStyle = 'black'
            ctx.font = 'bold 20px Arial, sans-serif'
            draw(this.L_KEY[+digit])
            ctx.fillText(digit, x - 10, barHeight + 18)
          } else {
            ctx.font = 'bold 20px Arial, sans-serif'
            ctx.fillStyle = 'black'
            ctx.fillText(digit, x + 2, barHeight + 18)
          }
          if (index <= 3 && index !== 0) {
            draw(this.L_KEY[+digit])
          }
          if (index > 3) draw(this.R_KEY[+digit])

          if (index === 3) {
            draw('01010', 15)
          }
        }
        draw('101', 15)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .canvas {
  }
</style>
