import View from '@/views/layouts/layout-view'

export default {
  path: 'operation-process',
  name: 'operation-process',
  component: View,
  children: [
    {
      path: 'order/auto',
      name: 'order/auto',
      component: () => import('@/views/order/pages/AutoOrder'),
      meta: { title: 'Автозаказ' }
    },
    {
      path: 'orders',
      name: 'orders',
      component: () => import('@/views/order/pages/Orders'),
      meta: { title: 'Заказы' }
    },
    {
      path: 'orders/:id',
      name: 'operation-process.overhead.order.document.edit',
      component: () => import('@/views/order/pages/Order'),
      meta: { title: 'Заказ' }
    }
  ]
}
