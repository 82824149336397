export const getters = {
  getDeclaration: (state) => state.declaration,
  getBranches: (state) => state.branches,
  getSpecifications: (state) => state.specifications,
  getDateRange: (state) => state.date_range,
  getOpenedCategory: (state) => state.opened_category,
  getProduct: (state) => state.products,
  getChecked: (state) => state.checked,
  getAbcChecked: (state) => state.abc_checked,
  getAnalyticsParams: (state) => state.analytics_params,
  getAbcDateRange: (state) => state.abc_date_range,
  getAbcProduct: (state) => state.abc_products,
  getAbcSuppliers: (state) => state.abc_suppliers,
  getAbcOpenedCategory: (state) => state.abc_opened_category,
  getAbcBranches: (state) => state.abc_branches,
  getAbcGroupBy: (state) => state.group_by
}
