import { SupplierModel } from '@/models/supplier.model'
import { CountryModel } from '@/models/country.model'
import { MarkingTypeModel } from '@/models/marking-type.model'
import { MeasurementModel } from '@/models/measurement.model'
import { PackageModel } from '@/models/package.model'
import { PriceListModel } from '@/models/price-list.model'
import { ProductExternalIdModel } from '@/models/product-external-id.model'
import { ProductTypeModel } from '@/models/product-type.model'
import { TaxRateModel } from '@/models/tax-rate.model'
import { RemainderModel } from '@/models/remainder.model'
import { CategoryModel } from '@/models/category.model'
import { SerialModel } from '@/models/serial.model'
import { CompoundModel } from '@/models/compound.model'
import { EntityModel } from '@/models/entity.model'
import { ProductDescriptionModel } from '@/models/product-description.model'

export class ProductModel {
  constructor(request = {}) {
    this.articul = request?.articul
    this.brutto = request?.brutto
    this.ext_product = request?.ext_product
    this.category = new CategoryModel(request?.category)
    this.country = new CountryModel(request?.country)
    this.description = request?.description
    this.expiration_date = request?.expiration_date
    this.found_by_barcode = request?.found_by_barcode
    this.id = request?.id
    this.printDelta = request?.printDelta || 1
    this.image = request?.image
    this.manufacturer = new EntityModel(request?.manufacturer)
    this.entity = new EntityModel(request?.entity)
    this.markingType = new MarkingTypeModel(request?.markingType)
    this.markup = request?.markup
    this.measurement = new MeasurementModel(request?.measurement)
    this.movement = request?.movement
    this.name = request?.name
    this.netto = request?.netto
    this.package = request?.package?.map((el) => new PackageModel(el))
    this.promotion = request?.promotion
    this.price_list = request?.price_list?.map((el) => new PriceListModel(el))
    this.print_name = request?.print_name
    this.productExternalId = request?.productExternalId?.map((el) => new ProductExternalIdModel(el))
    this.productType = new ProductTypeModel(request?.productType)
    this.purchasePrice = request?.purchasePrice
    this.remainders = request?.remainders?.map((el) => new RemainderModel(el))
    this.retailPrice = request?.retailPrice
    this.serial = request?.serial?.map((el) => new SerialModel(el))
    this.supplier = new SupplierModel(request?.supplier)
    this.taxRate = new TaxRateModel(request?.taxRate)
    this.volume = request?.volume
    this.ext_system = request?.ext_system
    this.hide = request?.hide
    this.plu = request?.plu
    this.production_date = new Date(request?.production_date)
    this.productDescription = request?.productDescription?.map((el) => new ProductDescriptionModel(el)) ?? []
    this.round_to = request?.round_to

    if (request?.current_compound) this.current_compound = new CompoundModel(request.current_compound)
  }
  setProductDescription = (decs) => {
    this.productDescription.push(new ProductDescriptionModel(decs))
  }

  get info() {
    const product = this
    let str = ''
    if (product?.productExternalId?.[0]?.egaisParams?.type)
      str += 'Вид: ' + product?.productExternalId?.[0]?.egaisParams?.type + ' '
    if (product?.productExternalId?.[0]?.egaisParams?.strength)
      str += 'крепкость: ' + product?.productExternalId?.[0]?.egaisParams?.strength + ' '
    if (product?.productExternalId?.[0]?.egaisParams?.volume)
      str += 'крепкость: ' + product?.productExternalId?.[0]?.egaisParams?.volume + ' '
    if (product?.ext_system?.other?.[0]?.code) str += product?.ext_system?.other?.[0]?.code
    return str
  }
}
