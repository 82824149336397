<template>
  <svg
    class="background"
    width="596"
    height="396"
    viewBox="0 0 600 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="600"
      height="400"
      fill="#FFE999"
    />
  </svg>
</template>
<script>
  export default {
    name: 'Background600on400Svg'
  }
</script>
<style lang="scss" scoped></style>
