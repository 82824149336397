<template>
  <section id="binding-product-table">
    <sort-table-modal
      :fields="fields"
      :default_fields="fieldsDefault"
      id_modal="bindingProductTable"
      @change="changeFields"
      @set-default="setDefault"
    />
    <div
      class="products-wrapper"
      style="height: inherit; flex: 1"
    >
      <div
        class="card p-0"
        :class="{
          'no-margin': items_total < 20,
          'table-wrapper-flex': currentCategory.id === undefined,
          'table-wrapper-width': currentCategory.id !== undefined
        }"
        style="min-height: inherit"
      >
        <div class="head-table">
          <div
            style="position: relative"
            class="m-3 w-50 head-table__input-block"
          >
            <b-form-input
              ref="inputBindingProduct"
              v-model="search_str"
              type="text"
              class="large"
              :is-keyup="true"
              placeholder="Поиск товаров..."
            />
            <b-button 
              @click="clearInput"
              class="icon btn-delete-text">
              <img
                class="scanner"
                src="/img/icons/incoming/Delete.svg"
                alt="scanner"
              />
            </b-button>
            <b-button
              id="tooltip-btn-scan"
              class="icon btn-scan"
            >
              <img
                class="scanner"
                src="/img/icons/mini-scanner.svg"
                alt="scanner"
              />
            </b-button>
            <b-tooltip
              target="tooltip-btn-scan"
              triggers="hover"
              custom-class="tooltip-scan"
            >
              Возможен поиск с помощью считывания штрих-кода сканнером
            </b-tooltip>

            <b-dropdown
              no-caret
              variant="none"
            >
              <template #button-content>
                <div class="btn-more">
                  <img
                    src="/img/icons/settings.svg"
                    alt=""
                  />
                </div>
              </template>
              <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
            </b-dropdown>
          </div>

          <b-button
            class="btn-add mr-3 small-button"
            variant="light"
            @click="add_product"
          >
            <img
              src="/img/icons/plus_dark.svg"
              alt="plus"
            />
          </b-button>

          <b-button
            class="btn-add mr-3"
            variant="light"
            @click="add_product"
          >
            <img
              src="/img/icons/plus_dark.svg"
              alt=""
              class="mr"
            />
            <span>Добавить товар</span>
          </b-button>
        </div>
        <template v-if="currentCategory.id !== undefined">
          <resizable-table
            id="products-table"
            ref="table_products"
            class="table-products"
            table_name="binding-tab"
            :items="products"
            :default_fields="fields.filter((el) => el.checked)"
            empty_text="Нет товаров"
            :resizable="true"
            :busy="tableIsBusy"
            @row-click="bindProduct"
          >
            <template #head_id>
              <div class="head-lable"></div>
            </template>

            <template #body_id="{ item }">
              <img
                :src="icon(item)"
                alt="bind"
                class="cursor ml-2"
                @click.stop="bindProduct(item)"
              />
            </template>

            <template #body_name="data">
              <div style="display: flex; flex-direction: column">
                {{ data.value }}
                <div class="product-info-div">
                  <span class="mr-2">{{ data.item.articul }}</span
                  ><span class="supplier">
                    {{ data.item.supplier?.name ?? '-' }}
                  </span>
                </div>
              </div>
            </template>

            <template #body_measurement="data">
              {{ data.value.name }}
            </template>

            <template #body_markingType="data">
              {{ data.value.name }}
            </template>

            <template #body_remainders="data">
              {{ getTotalRemainders(data) }}
            </template>

            <template #body_productType="data">
              {{ data.value.name }}
            </template>

            <template #body_markup="data">
              {{ data.value ? data.value + ' %' : '-' }}
            </template>

            <template #body_retailPrice="data"> {{ formatPrice(data.value) }} ₽ </template>

            <!-- <template #empty="scope">
              <p class="center mt-3">{{ scope.emptyFilteredText }}</p> //!!
            </template> -->

            <template #body_purchasePrice="data"> {{ formatPrice(data.item?.purchasePrice) }} ₽ </template>

            <template #body_nds="data">
              {{ data.item?.taxRate?.name || '-' }}
            </template>

            <template #body_package="data">
              {{ getBarcode(data) }}

              <!-- {{ data.item?.package?.barcode?.barcode || '-' }} -->
            </template>

            <template #infoTable>
              <div
                v-if="items_total > 0"
                class="p-2 total-info"
              >
                Всего: {{ items_total }}
              </div>
            </template>
          </resizable-table>
        </template>
        <template v-else>
          <div class="center empty-block w-100">
            Выберите группу товаров, создайте группу товаров или загрузите со своей кассы
          </div>
        </template>
      </div>
      <pagination

        class="pagination-wrapper"
        :total="items_total"
        @change_pagination="handlerPagination"
      />
      <binding-accept-modal
        :product="product"
        :selected_product="selectedProduct"
        :document_head="document_head"
        :type="type"
        @bind="bind"
      />
    </div>
  </section>
</template>

<script>
  import * as R from 'ramda'
  import { mapActions, mapGetters } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'
  import BindingAcceptModal from '@/views/operational-processes/components/incoming/binding/BindingAcceptModal'
  import { ProductModel } from '@/models/product.model'
  import SortTableModal from '@/views/operational-processes/components/SortTableModal'
  import ResizableTable from '@/components/ResizableTable'

  export default {
    components: {
      BindingAcceptModal,
      Pagination,
      SortTableModal,
      ResizableTable
    },
    props: {
      product: {
        type: Object,
        default: () => {}
      },
      target_product: {
        type: Object,
        default: () => {}
      },
      document_head: {
        type: String,
        default: () => ''
      },
      type: {
        type: String,
        default: () => ''
      }
    },
    apollo: {
      ProductsV2: {
        query: require('../../../../products/gql/getProducts.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            category: {
              id: this.currentCategory.id
            },
            branch: this.currentBranch.id,
            pagination: this.pagination
          }
        },
        result({ data }) {
          this.items_total = R.pathOr(0, ['ProductsV2', 'total'], data)
          this.products = R.pathOr([], ['ProductsV2', 'products'], data)
          this.tableIsBusy = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
        },
        skip() {
          return this.currentCategory.id === undefined
        }
      }
    },

    data() {
      return {
        products: [],
        select_all: false,
        tableIsBusy: true,
        selected: [],
        items_total: 0,
        pagination: {
          skip: 0,
          take: 20
        },
        selectedProduct: new ProductModel(),
        search_str: '',
        fields: [],
        debounce: null
      }
    },

    computed: {
      ...mapGetters({
        currentCategory: 'products/getCurrentCategory',
        currentBranch: 'settings/getCurrentBranch',
        modal_binding: 'core/getBindingModal'
      }),
      fieldsDefault: function () {
        return [
          {
            key: 'id',
            checked: true,
            label: '',
            isRowHeader: true,
            class: 'center p-0',
            thStyle: 'min-width: 45px',
            width: 50
          },
          {
            key: 'name',
            checked: true,
            label: 'Наименование',
            thClass: 'white-bg',
            sortable: true,
            thStyle: 'min-width: 200px',
            width: 200
          },
          {
            key: 'retailPrice',
            checked: true,
            label: 'Розн. цена',
            class: 'center',
            thClass: 'white-bg',
            thStyle: 'min-width: 125px',
            width: 125
          },
          {
            key: 'remainders',
            checked: true,
            label: 'Остаток',
            class: 'center',
            thClass: 'white-bg',
            thStyle: 'min-width: 100px',
            width: 100
          },
          {
            key: 'measurement',
            checked: true,
            class: 'center',
            label: 'Ед. изм.',
            thClass: 'white-bg',
            thStyle: 'min-width: 100px',
            width: 100
          },
          {
            key: 'purchasePrice',
            checked: true,
            label: 'Закупочная цена',
            class: 'center',
            thClass: 'white-bg',
            thStyle: 'min-width: 150px',
            width: 150
          },
          {
            key: 'markup',
            checked: true,
            label: 'Наценка',
            class: 'center',
            thClass: 'white-bg',
            thStyle: 'min-width: 100px',
            width: 100
          },
          {
            key: 'nds',
            checked: true,
            label: 'НДС',
            class: 'center',
            thClass: 'white-bg',
            thStyle: 'min-width: 100px',
            width: 100
          },
          {
            key: 'package',
            checked: true,
            label: 'Штрих код',
            class: 'center',
            thClass: 'white-bg',
            thStyle: 'min-width: 180px',
            width: 180
          }
        ]
      },

      selectedProducts: function () {
        return this.products.filter((product) => this.selected.includes(product.id))
      }
    },

    watch: {
      select_all(newVal) {
        if (!newVal) return (this.selected = [])

        this.products.forEach((item) => {
          const id = item.id
          if (!this.selected.includes(id)) this.selected.push(id)
        })
      },
      async search_str(newVal) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          this.tableIsBusy = true
          const { data } = await this.$apollo.query({
            query: require('../../../../products/gql/searchProduct.gql'),
            variables: {
              branch: this.currentBranch?.id,
              name: newVal
            },
            fetchPolicy: 'no-cache'
          })
          if (data) {
            this.products = data?.ProductSearch?.products ?? []
            this.items_total = data?.ProductSearch?.total ?? 0
          } else this.products = []
          this.tableIsBusy = false
        }, 500)
      }
    },

    created() {
      this.search_str = this.modal_binding?.product?.name ?? ''
    },

    mounted() {
      this.fields = this.fieldsDefault
      if (this.target_product?.category?.id) this.setCurrentCategory(this.target_product.category)
      document.body.classList.add('body-page-products')
      this.listenerKeyUp()

      const srtFields = localStorage.getItem('binding_products_fields')
      this.fields = srtFields ? JSON.parse(srtFields) : this.fieldsDefault
      this.loadFields()
    },

    destroyed() {
      document.body.classList.remove('body-page-products')
    },

    methods: {
      ...mapActions({
        setCurrentCategory: 'products/setCurrentCategory'
      }),

      changeFields(val) {
        this.fields = val
        this.saveFields()
      },
      setDefault() {
        console.log(this.fieldsDefault)
        this.fields = this.fieldsDefault
        console.log(this.fields)
        this.saveFields()
      },

      saveFields() {
        localStorage.setItem('binding_products_fields', JSON.stringify(this.fields))
        this.$apollo.mutate({
          mutation: require('../../../gql/saveFields.graphql'),
          variables: {
            input: {
              table_name: 'binding_products_table',
              fields: this.fields.map((el) => {
                return {
                  key: el.key,
                  checked: el.checked,
                  width: el.width,
                  label: el.label
                }
              })
            }
          }
        })
      },

      async loadFields() {
        const { data } = await this.$apollo.query({
          query: require('../../../gql/ReadUserTable.graphql')
        })
        const table = data?.ReadUserTable?.tables?.find((el) => el.table_name === 'binding_products_table')
        if (table?.fields) this.fields = table.fields
      },

      clearInput() {
        this.search_str = ''
      },

      showSortModal() {
        this.$bvModal.show('bindingProductTable')
      },

      icon(product) {
        return product.id === this.target_product?.id ? '/img/icons/binding-ok.svg' : '/img/icons/ext_systems/bind.svg'
      },
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },
      bind(spec) {
        this.$emit('bind', spec)
      },
      formatNumber(summ = 0) {
        const val = summ === null ? 0 : summ
        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      },

      add_product() {
        this.$router.push({
          name: 'products.edit',
          params: {
            name: this.product.name ?? this.product.productItem_name,
            id: this.product.id
          },
          query: {
            spec_id: this.document_head
          }
        })
        this.$bvModal.hide('core-binding-modal')
      },

      getTotalRemainders({ item }) {
        let total = 0
        if (item.productType?.id === '52a4eab0-55c2-49f1-a152-f02cbc61e0b8') return '-'
        if (!item.remainders) return 0

        item.remainders.forEach((i) => {
          total = total + i.value
        })

        return this.formatNumber(total)
      },

      getBarcode({ item }) {
        return item?.package?.[0]?.barcode?.barcode ?? '-'
      },

      listenerKeyUp() {
        const regexpRu = /^[?!,.а-яА-ЯёЁ0-9]+$/
        const regexpEn = /^[?!,.a-zA_Z0-9]+$/
        let interval = null

        window.addEventListener('keydown', (item) => {
          if (item.target.localName === 'input' || item.altKey || item.ctrlKey || item.metaKey) return
          const key = item.key
          if (interval) clearInterval(interval)
          if (this.time === 0) interval = setInterval(this.counterSeconds, 100)

          if (item.target.attributes.isKeyup || key === 'Alt') return false
          if (key === 'Escape' || key === 'Delete') return (this.search_str = '')

          if (key === 'Backspace') return (this.search_str = this.search_str.substring(0, this.search_str.length - 1))

          if (key === 'Enter') {
            if (this.time < 1) {
              this.$apollo
                .mutate({
                  mutation: require('../../../../products/gql/checkBarcode.gql'),
                  variables: {
                    barcode: this.search_str
                  }
                })
                .then(({ data }) => {
                  const statusOnServe = R.pathOr(false, ['CheckBarcode', 'status'], data)

                  if (!statusOnServe) {
                    this.resetTimer()
                    return this.modal_scan()
                  }
                })
                .catch((e) => {
                  console.error('error: ', e)
                })
            }

            this.resetTimer()
            clearInterval(interval)
          }

          if (regexpRu.test(key) || regexpEn.test(key))
            if (typeof item.key === 'string') return (this.search_str = this.search_str + key)

          this.handler_search_str = this.handler_search_str + key
        })
      },

      clearSelected() {
        this.selected = []
      },

      checkIdsToDelete(ids) {
        this.tableIsBusy = true

        let idsToDelete = []
        const productsToDelete = (product) => {
          ids.filter((id) => {
            product.map((item) => {
              if (item.id === id && item.movement) return this.$noty.show(`Невозможно удалить товар ${item.name}`)

              if (!R.includes(id, idsToDelete)) idsToDelete.push(id)
            })
          })
        }

        productsToDelete(this.products)
        if (idsToDelete.length > 0) this.removeItems(idsToDelete)
      },

      printPriceTags() {
        this.$bvModal.show('modal-print-tags')
      },

      handlerPagination({ current_page = null, take = null }) {
        this.tableIsBusy = true

        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take

        this.$apollo.queries.ProductsV2.refetch()
      },

      rowClick() {
        // this.$router.push({ name: 'products.edit', params: { name, id } })
      },
      bindProduct(item) {
        this.selectedProduct = new ProductModel(item)
        this.$bvModal.show('binding-accept')
      }
    }
  }
</script>

<style scoped lang="scss">
  .total-info {
    color: #6c757d;
  }
  :deep .b-table-sticky-header,
  .table-responsive,
  .table-docs,
  .binding-product-table,
  .table-fixed {
    max-height: 400px !important;
  }
  :deep .table-responsive {
    // overflow-x: clip !important;
    min-height: 95% !important;
  }
  ::v-deep() {
    table {
      .head-lable {
        width: 50px;
      }
      tr {
        cursor: pointer;

        th {
          border-bottom: 1px solid #e1e1e1 !important;
          // border-top: none;
        }

        td {
          // border-bottom: 1px solid #e1e1e1;
          // border-left: none;
          border-right: none;
          border-bottom: 1px solid #e1e1e1 !important;
        }
      }
    }

    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    .body-page-products .wrapper__inner {
      height: 100%;
      overflow-y: hidden;
      padding: 24px 20px 15px;
    }

    .products__item {
      overflow-y: hidden;
    }

    .products-wrapper {
      & > .card {
        .head-row > th:first-child {
          border-right: none !important;
        }
      }
      .custom-checkbox {
        padding: 0;
        width: 100%;
        height: 100%;

        .custom-control-label {
          width: 100%;
          min-height: 48px;
          cursor: pointer;

          &:before,
          &:after {
            top: 50%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
            box-shadow: none;
          }

          &:after {
            top: 50%;
            left: 50%;
          }
        }
      }
    }

    .link-product {
      text-decoration: none;
      color: #313131;
      font-weight: 500;

      &:hover {
        color: #0066ff;
      }
    }
  }

  #binding-product-table {
    .table-b-table-default + .white-bg {
      background-color: white !important;
      border-bottom: 1px solid #e1e1e1 !important;
    }

    .body-page-products .wrapper__inner {
      height: 100%;
      overflow-y: hidden;
      padding: 24px 20px 15px;
    }

    .products-wrapper {
      height: 100%;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      width: 733px;

      .product .empty-block {
        border-top: 1px solid #e1e1e1;
        padding-top: 16px;
      }

      & > .card {
        height: 100%;
        // overflow: auto;
        overflow: hidden;
        padding-bottom: 0;

        .table-products {
          min-height: calc(100% - 50px);
          margin-bottom: 0;
        }

        .head-row > th:first-child {
          border-right: none !important;
        }
      }

      .custom-checkbox {
        padding: 0;
        width: 100%;
        height: 100%;

        .custom-control-label {
          width: 100%;
          min-height: 48px;
          cursor: pointer;

          &:before,
          &:after {
            top: 50%;
            left: 50%;
            cursor: pointer;
            transform: translate(-50%, -50%);
            box-shadow: none;
          }

          &:after {
            top: 50%;
            left: 50%;
          }
        }
      }
    }

    .link-product {
      text-decoration: none;
      color: #313131;
      font-weight: 500;

      &:hover {
        color: #0066ff;
      }
    }

    .product-info-div {
      display: flex;
      flex-direction: row;
      width: 90%;
      max-width: 380px;

      & > span {
        color: #888888;
        font-weight: 500;
      }

      & .supplier {
        color: #888888;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: 1rem;
      }
    }

    .btn-scan {
      position: absolute;
      top: 0;
      right: 45px;
      width: 25px;
      height: 31px;
      margin: 0 !important;
      justify-content: center;
      cursor: default !important;

      &:hover,
      &:active,
      &:focus {
        background: none !important;
      }
    }

    .no-margin {
      margin-bottom: 0 !important;
    }
  }

  .btn-delete-text {
    position: absolute;
    top: 0;
    right: 73px;
    width: 25px;
    height: 31px;
    margin: 0 !important;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      background: none !important;
    }
  }
  .head-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    &__input-block {
      position: relative;
      display: flex;
      gap: 10px;
      width: 65% !important;
    }
  }

  .table-wrapper-flex {
    flex: 1;
  }
  .table-wrapper-width {
    // width: 82%;
    width: 100%;
  }
  .pagination-wrapper {
    margin: 0;
    width: 100%;
  }

  .small-button {
    display: none;
  }

  @media (max-width: 1199px) {
    .head-table {
      &__input-block {
        flex-grow: 2;
      }
    }

    .large {
      width: 281px !important;
      width: 342px !important;
    }

    .btn-add {
      display: none;
    }

    .small-button {
      display: block;
    }

    .products-wrapper {
      width: 470px !important;
    }
    :deep .pagination {
      width: 320px;
    }
  }
</style>
