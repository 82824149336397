import { BranchModel } from '@/models/branch.model'
import { ProductModel } from '@/models/product.model'
import { StorageModel } from '@/models/storage.model'

export class RemainderModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request.branch)
    this.id = request?.id
    this.product = new ProductModel(request.product)
    this.storage = new StorageModel(request.storage)
    this.value = request?.value
  }
}
