var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "provider"
  }, [_vm._t("default"), _c('div', {
    staticClass: "alert-container",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.runAction.apply(null, arguments);
      }
    }
  }, [_c('transition-group', {
    attrs: {
      "name": "e-alert-container",
      "tag": "div"
    }
  }, _vm._l(_vm.alerts, function (alert, index) {
    var _alert$card, _alert$card2;

    return _c('div', {
      key: alert.hash + '/' + index,
      staticClass: "e-alert",
      class: alert.variant
    }, [_c('div', {
      staticClass: "title"
    }, [_c('img', {
      attrs: {
        "src": `/img/icons/${alert.variant || 'primary'}-alert-icon.svg`
      }
    }), _c('div', {
      staticClass: "t-text"
    }, [_vm._v(_vm._s(alert.title))]), _c('div', {
      staticClass: "ml-auto close-img",
      on: {
        "click": function ($event) {
          return _vm.clearNotification(alert.hash);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": "/img/icons/Delete.svg"
      }
    })])]), alert.message ? _c('div', {
      staticClass: "alert-message message-text"
    }, [_vm._v(" " + _vm._s(alert.message) + " ")]) : _vm._e(), alert.card ? _c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "message-text"
    }, [_vm._v(_vm._s((_alert$card = alert.card) === null || _alert$card === void 0 ? void 0 : _alert$card.message))]), _c('div', {
      staticClass: "add-info"
    }, [_vm._v(_vm._s((_alert$card2 = alert.card) === null || _alert$card2 === void 0 ? void 0 : _alert$card2.info))])]) : _vm._e(), _c('div', {
      staticClass: "d-flex"
    }, _vm._l(alert.actions, function (action, i) {
      return _c('div', {
        key: i,
        staticClass: "action-btn",
        on: {
          "click": function ($event) {
            return _vm.actionClick(alert, action.action);
          }
        }
      }, [_vm._v(" " + _vm._s(action.title) + " ")]);
    }), 0)]);
  }), 0)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }