var render = function render(){
  var _vm$currentBranch;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', [_vm._v("Загрузка касс")]), _c('div', {
    staticClass: "col-6 cash-card mt-3"
  }, [_c('div', {
    staticClass: "cash-card-title"
  }, [_vm._v("Список касс для загрузки")]), _c('b-form-checkbox-group', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.selectedCash,
      callback: function ($$v) {
        _vm.selectedCash = $$v;
      },
      expression: "selectedCash"
    }
  }, _vm._l((_vm$currentBranch = _vm.currentBranch) === null || _vm$currentBranch === void 0 ? void 0 : _vm$currentBranch.cash, function (cash) {
    return _c('div', {
      key: cash.id
    }, [_c('b-form-checkbox', {
      staticClass: "custom-checkbox-centred",
      attrs: {
        "value": cash.id
      }
    }, [_vm._v(" " + _vm._s(cash.name) + " ")])], 1);
  }), 0)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }