import { DiscontCardsList } from '../models/discont-cards-list.model'
import { DiscontCard } from '../models/discont-card.model'

export const state = {
  discontCardsDocument: new DiscontCardsList(),
  discontCard: new DiscontCard(),
  cardTypeList: [
    {
      name: 'Скидочная',
      value: true,
      description:
        'Предоставляет покупателям скидки на товары или услуги. Конечная величина скидки может быть выражена в процентах от общей стоимости покупки'
    },
    {
      name: 'Бонусная',
      value: false,
      description:
        'Предоставляет покупателям бонусы за каждую покупку. Правила начисления и списания бонусов настраиваются в кассовой программе'
    }
  ]
}
