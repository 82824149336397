import gql from 'graphql-tag'

export const CREATE_ENTITY = gql`
  mutation CreateEntity(
    $name: String
    $inn: String
    $kpp: String
    $ogrn: String
    $fullname: String
    $address_entity: AddressInput
    # $address_fact: AddressInput
    # $phone: String
    $ceo: String
  ) {
    CreateEntity(
      input: {
        name: $name
        inn: $inn
        kpp: $kpp
        ogrn: $ogrn
        fullname: $fullname
        address_entity: $address_entity
        # address_fact: $address_fact
        ceo: $ceo
        # phone: $phone
      }
    ) {
      id
      company
    }
  }
`

// export const UPDATE_ENTITY = gql`
//   mutation UpdateEntityInput(
//     $id: ID
//     $name: String
//     $inn: String
//     $kpp: String
//     $ogrn: String
//     $fullname: String
//     $email: String
//     # $phone: String
//     $address_entity: AddressInput
//     $ceo: String
//   ) {
//     UpdateEntityInput(
//       input: {
//         id: $id
//         name: $name
//         inn: $inn
//         kpp: $kpp
//         ogrn: $ogrn
//         fullname: $fullname
//         email: $email
//         # phone: $phone
//         address_entity: $address_entity
//         ceo: $ceo
//       }
//     )
//   }
// `

export const CREATE_BANK_ACCOUNT = gql`
  mutation CreateSupplierBankAccount(
    $supplier: ID
    $bank: String
    $bik: String
    $corr_acc: String
    $check_acc: String
    $main: Boolean
    $comment: String
    $entity: ID
  ) {
    CreateSupplierBankAccount(
      input: {
        supplier: $supplier
        bank: $bank
        bik: $bik
        corr_acc: $corr_acc
        check_acc: $check_acc
        main: $main
        comment: $comment
        entity: $entity
      }
    ) {
      id
    }
  }
`

// export const UPDATE_BANK_ACCOUNT = gql`
//   mutation UpdateCompanyBankAccount(
//     $id: ID
//     $bank: String
//     $bik: String
//     $corr_acc: String
//     $check_acc: String
//     $main: Boolean
//     $comment: String
//   ) {
//     UpdateCompanyBankAccount(
//       input: {
//         id: $id
//         bank: $bank
//         bik: $bik
//         corr_acc: $corr_acc
//         check_acc: $check_acc
//         main: $main
//         comment: $comment
//       }
//     ) {
//       id
//     }
//   }
// `

export const UPDATE_MAIN_BANK_ACCOUNT = gql`
  mutation UpdateCompanyBankAccount($id: ID, $main: Boolean) {
    UpdateCompanyBankAccount(input: { id: $id, main: $main }) {
      main
    }
  }
`

export const REMOVE_BANK_ACCOUNT = gql`
  mutation RemoveBankAccount($ids: [ID]) {
    RemoveBankAccount(ids: $ids) {
      status
    }
  }
`

export const REMOVE_SUPPLIER = gql`
  mutation RemoveSupplier($ids: [ID]!) {
    RemoveSupplier(ids: $ids) {
      id
    }
  }
`

export const CREATE_PERSON = gql`
  mutation CreatePerson($name: String, $phone: String, $email: String, $main: Boolean) {
    CreatePerson(input: { name: $name, phone: $phone, email: $email, main: $main }) {
      name
      phone
      email
      main
    }
  }
`

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier($name: String!, $entity: [UpdateEntityInput]) {
    CreateSupplier(input: { name: $name, entity: $entity }) {
      id
    }
  }
`

export const CREATE_CONTRACT = gql`
  mutation CreateContract(
    $name: String
    $external_number: String
    $external_date: String
    $begin_date: String
    $end_date: String
    $deferment: Int
    $supplier_id: ID
    $person: [ID]
  ) {
    CreateContract(
      input: {
        name: $name
        external_number: $external_number
        external_date: $external_date
        begin_date: $begin_date
        end_date: $end_date
        deferment: $deferment
        person: $person
        supplier: $supplier_id
      }
    ) {
      id
      name
    }
  }
`
