<template>
  <svg
    class="line__logo right_line"
    width="80"
    height="4"
    viewBox="0 0 80 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4V0H80V4H0Z"
      fill="#F17D00"
    />
  </svg>
</template>
<script>
  export default {
    name: 'RightLineSvg'
  }
</script>
<style scoped lang="scss">
  .line__logo {
    transform: translateY(66px);
  }
</style>
