<template>
  <price-tag600on400
    v-if="product.promotion && promotion"
    class="price-tag600on400"
    :product="product"
    :weight_format="weight_format"
  />
  <div
    v-else
    class="price-tag"
  >
    <div class="header d-flex justify-content-between">
      <div class="date">{{ currentDate }}</div>
      <div class="company">{{ companyName }}</div>
    </div>
    <div class="name">{{ product?.print_name || product?.name }}</div>
    <div class="price d-flex">
      <div class="price-item d-flex">
        <div class="price-total">{{ priceMain }}</div>
        <div class="price-info">
          <div class="price-part">{{ pricePart }}</div>
          <div class="currency">₽/{{ measurement }}</div>
        </div>
      </div>
      <div class="price-qr">
        <qrcode
          :value="qrcode"
          size="35"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="barcode-item">
        <div class="barcode-row">
          <div class="barcode">
            <template v-if="product?.package?.[0]?.barcode?.barcode">
              <div class="barcode-body">
                <!-- <img-->
                <!-- style="height: 50px"-->
                <!--                  alt="Barcode Generator TEC-IT"-->
                <!--                  :src="getUrlBarcode"-->
                <!--                  class="bcode-img"-->
                <!--                /> -->
                <barcode-generator
                  :width="120"
                  :height="45"
                  :barcode="getUrlBarcode"
                />
              </div>
            </template>
          </div>

          <div
            class="info"
            style="flex: 1"
          >
            <div class="country">
              <!-- Страна:  -->
              {{ product?.country?.name }}
            </div>
            <div class="manufacturer">
              <!-- Производитель:  -->
              {{ product?.manufacturer?.name }}
            </div>

            <div class="d-flex">
              <div class="manufacturer mr-1">
                <!-- Артикул:  -->
                {{ product?.articul }}
              </div>
              <div
                v-if="product?.plu"
                class="manufacturer"
              >
                <div class="plu-title">PLU:</div>
                <div
                  v-for="(item, idx) of getPluArr"
                  :key="idx"
                  class="plu-content"
                >
                  <div class="plu-text">{{ item }}</div>
                  <div
                    v-if="getPluArr[idx + 1]"
                    class="plu-delimiter"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PriceTag600on400 from './PriceTag600on400.vue'
  import formatDate from '@/utils/formatDate'
  import QrcodeVue from 'qrcode.vue'
  import { mapGetters } from 'vuex'
  import BarcodeGenerator from '@/views/core/modal/price-tag/BarcodeGenerator.vue'

  export default {
    components: {
      BarcodeGenerator,
      qrcode: QrcodeVue,
      PriceTag600on400
    },
    props: {
      promotion: Boolean,
      product: {
        type: Object,
        default: () => {}
      },
      default: () => {},
      weight_format: {
        type: String,
        default: ''
      }
    },

    data: () => ({
      currentDate: formatDate(new Date(), 'numeric')
    }),

    computed: {
      ...mapGetters({
        company_name: 'core/getUserCompanyName',
        getCurrentBranch: 'settings/getCurrentBranch'
      }),
      companyName() {
        const entity_name = (entity) => entity?.name || entity?.full_name
        return entity_name(this.product.entity) || entity_name(this.getCurrentBranch.entities?.[0]) || this.company_name
      },
      getUrlBarcode() {
        const barcode = this.getBarcode(this.product)
        // let type = 'EAN13'
        // if (barcode.length === 8) type = 'EAN8'
        return barcode
      },
      priceMain() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return Math.trunc(price)
      },
      pricePart() {
        let price = this.product?.retailPrice || 0
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') price /= 10
        return (+price % 1).toFixed(2).split('.')[1]
      },
      qrcode() {
        const barcode = this.product?.package?.[0]?.barcode?.barcode || '-'
        const article = this.product?.articul || '-'
        const price = this.product?.retailPrice.toFixed(2) || '-'

        return `${barcode} ${article} ${price}`
      },
      measurement() {
        if (this.weight_format === 'gram' && this.product.measurement?.name === 'КГ.') return '100г'
        return this.product?.measurement?.name?.toLowerCase()
      },
      getPluArr() {
        const pluArr = this.product?.plu.map((el) => el.plu)
        const result = new Set(pluArr)
        return [...result]
      }
    },

    methods: {
      getBarcode(product) {
        let barcode = product.package[0].barcode.barcode
        if (['КГ.'].includes(product.measurement?.name)) {
          barcode += '01000'
          return barcode + this.getCheckSum(barcode)
        }
        return barcode
      },
      getPrice(product) {
        return product.retailPrice
      },
      getCheckSum(ean) {
        if (!ean || ean.length !== 12) return ''
        const multiply = [1, 3]
        let total = 0
        ean.split('').forEach((letter, index) => {
          total += parseInt(letter, 10) * multiply[index % 2]
        })
        const base10Superior = Math.ceil(total / 10) * 10
        return base10Superior - total
      }
    }
  }
</script>

<style lang="scss" scoped>
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .price-tag * {
    font-family: Source Sans Pro, serif !important;
  }
  .price-tag {
    //width: 28%;
    //height: 50%;
    //height: 270px;
    width: 357px;
    height: 238px;
    padding: 7px;
    color: #000;
    user-select: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    aspect-ratio: 6/4;
    flex: 1;

    .header {
      text-align: center;
      overflow: hidden;
      color: #000000;
      font-size: 11px;
      margin-bottom: 8px;
    }

    .name {
      font-weight: 600;
      font-size: 22px;
      line-height: 23px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      text-align: center;
    }

    .desc {
      font-weight: bold;
      white-space: nowrap;
      font-size: 16px;
    }

    .price-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .bottom {
      justify-content: flex-start;
    }

    .code {
      font-size: 11px;
    }

    .barcode-body {
      font-family: 'EAN', sans-serif;
      font-size: 50px;
      line-height: 50px;
      display: flex;
      align-items: end;
    }

    .barcode-row {
      display: flex;
      height: 50px;
    }

    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      padding-bottom: 18px;

      .price-qr {
        flex: 1;
        display: flex;
        justify-content: end;
      }
      .price-item {
        flex: 3;
        display: flex;
        justify-content: end;
      }
      &-total {
        font-size: 66px;
        font-weight: 600;
        margin-right: 5px;
        line-height: 44px;
        height: 45px;
      }

      &-part {
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
      }
    }

    .currency {
      line-height: 1;
      font-size: 22px;
    }

    .country,
    .manufacturer {
      display: flex !important;
      font-size: 11px;
      line-height: 1.2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .barcode {
      margin-right: 10px;
      display: flex;
      align-items: end;
      &-graph {
        height: 30px;
        overflow: hidden;
        margin-left: -5px;
      }
    }

    ::v-deep .vue-barcode-element {
      width: 120px;
      height: auto;
      margin-top: -10px;
      margin-right: -10px;
    }
  }
  .price-tag600on400 {
    position: absolute;
  }

  .plu-content {
    display: flex;
    margin-right: 5px;
    align-items: center;
  }

  .plu-delimiter {
    height: 2px;
    width: 2px;
    border-radius: 50%;
    background-color: #000000;
    margin-left: 5px;
  }

  .plu-title {
    margin-right: 4px;
  }
</style>
